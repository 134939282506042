<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div @click.stop class="d-flex justify-content-center">
                        <div class="bg-light content">
                            <div class="d-flex justify-content-end">
                                <b-button
                                    @click="close"
                                    class="close-btn bg-transparent border-0 text-292929 text-ikonoMini-4"
                                >
                                    
                                </b-button>
                            </div>
                            <div
                                style="min-width: 250px; overflow: auto; max-width: 650px"
                                class="content-container py-sm-4 px-4"
                            >
                                <div
                                    id="tab"
                                    v-if="currentView <= 1"
                                    class="d-flex mb-3"
                                >
                                    <b-button
                                        @click="selectView(0)"
                                        class="p-0 m-0 flex-fill bg-transparent border-0"
                                    >
                                        <p
                                            :class="
                                                currentView === 0
                                                    ? 'text-title-selected'
                                                    : 'text-title'
                                            "
                                            class="text-15 text-s-bold text-d1d1d1 text-center text-uppercase mb-2"
                                        >
                                            {{ $t('signin') }}
                                        </p>
                                        <hr
                                            :class="
                                                currentView === 0
                                                    ? 'h-line-selected'
                                                    : 'h-line'
                                            "
                                        />
                                    </b-button>
                                    <b-button
                                        @click="selectView(1)"
                                        class="p-0 m-0 flex-fill bg-transparent border-0"
                                    >
                                        <p
                                            :class="
                                                currentView === 1
                                                    ? 'text-title-selected'
                                                    : 'text-title'
                                            "
                                            class="text-15 text-s-bold text-d1d1d1 text-center text-uppercase mb-2"
                                        >
                                            {{ $t('signup') }}
                                        </p>
                                        <hr
                                            :class="
                                                currentView === 1
                                                    ? 'h-line-selected'
                                                    : 'h-line'
                                            "
                                        />
                                    </b-button>
                                </div>
                                <div
                                    id="social-tab"
                                    v-if="
                                        currentView === 2 || currentView === 3
                                    "
                                    class="d-flex mb-3"
                                >
                                    <b-button
                                        @click="goBackToLoginView"
                                        class="p-0 m-0 bg-transparent border-0"
                                    >
                                        <p
                                            class="text-15 text-292929 text-ikonoMini-4 p-2"
                                        >
                                            
                                        </p>
                                    </b-button>
                                </div>
                                <div
                                    id="tab-login"
                                    v-if="currentView === 0"
                                    style="opacity: 1; transition: all 0.3s ease;"
                                >
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.email') }}
                                        </p>
                                        <b-form-input
                                            id="email-element"
                                            v-model="login.email"
                                            class="input-field text-18"
                                            type="email"
                                        ></b-form-input>
                                    </div>
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.password') }}
                                        </p>
                                        <b-form-input
                                            id="password-element"
                                            v-model="login.password"
                                            @keyup.enter="signInWithEmail"
                                            class="input-field"
                                            type="password"
                                        ></b-form-input>
                                    </div>
                                    <div class="mb-3 d-flex">
                                        <div v-if="false" class="flex-fill">
                                            <b-button
                                                @click="toggleSaveLogin"
                                                class="bg-transparent border-0 p-0 d-flex align-items-center"
                                            >
                                                <p
                                                    v-if="login.save"
                                                    class="text-12 text-ikonoMini-4 text-606060 mr-2"
                                                >
                                                    
                                                </p>
                                                <p
                                                    v-if="!login.save"
                                                    class="text-12 text-ikonoMini-4 text-606060 mr-2"
                                                >
                                                    
                                                </p>
                                                <p
                                                    class="text-12 text-reg text-606060"
                                                >
                                                    {{ $t('member.re_email') }}
                                                </p>
                                            </b-button>
                                        </div>
                                        <TextButton
                                            @handleBtnClick="selectView(3)"
                                            class="text-primary"
                                        >
                                            {{
                                                $t('member.forgot_pw') + '?'
                                            }}</TextButton
                                        >
                                    </div>
                                    <b-button
                                        :disabled="login.isLoading"
                                        @click="signInWithEmail"
                                        variant="primary"
                                        class="container-fluid m-0 mb-3"
                                        style="height: 45px"
                                    >
                                        <b-spinner
                                            v-if="login.isLoading"
                                            variant="light"
                                            small
                                        ></b-spinner>
                                        <p
                                            v-if="!login.isLoading"
                                            class="text-15 text-bold text-606060 text-light"
                                        >
                                            {{ $t('signin') }}
                                        </p>
                                    </b-button>
                                    <hr
                                        class="m-0 mb-3"
                                        style="border-bottom: 1px solid #e1e1e1"
                                    />
                                    <b-button
                                        @click="signInWithSocialAcc('Facebook')"
                                        style="background-color: #425994; border-radius: 5px; height: 50px;"
                                        class="container-fluid border-0 mb-2"
                                    >
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <FacebookIcon
                                                style="height: 20px; width: 20px"
                                                class="mr-2"
                                            />
                                            <p
                                                class="text-15 text-bold text-606060 text-light"
                                            >
                                                {{
                                                    $t('member.con_with') +
                                                        'Facebook' +
                                                        $t('member.account')
                                                }}
                                            </p>
                                        </div>
                                    </b-button>
                                    <b-button
                                        @click="signInWithSocialAcc('Google')"
                                        style="border: 1px solid #cdcdcd; border-radius: 5px; height: 50px;"
                                        class="container-fluid bg-light mb-2"
                                    >
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <ImageContainer
                                                style="width: 20px"
                                                class="align-self-center mr-2"
                                                alt="google icon"
                                                :src="require('~/assets/images/icon/google.png')"
                                            />
                                            <p
                                                class="text-15 text-bold text-606060 align-self-center"
                                            >
                                                {{
                                                    $t('member.con_with') +
                                                        'Google' +
                                                        $t('member.account')
                                                }}
                                            </p>
                                        </div>
                                    </b-button>
                                    <b-button
                                        @click="signInWithSocialAcc('Apple')"
                                        style="border: 1px solid #cdcdcd; border-radius: 5px; height: 50px;"
                                        class="container-fluid bg-dark mb-2"
                                    >
                                        <div
                                            class="d-flex justify-content-center"
                                        >
                                            <AppleSvg
                                                style="height: 20px; width: 20px; fill: #FFFFFF"
                                                class="mr-2"
                                            />
                                            <p
                                                class="text-15 text-bold text-606060 text-light"
                                            >
                                                {{
                                                    $t('member.con_with') +
                                                        'Apple' +
                                                        $t('member.account')
                                                }}
                                            </p>
                                        </div>
                                    </b-button>
                                    <b-button
                                        @click="navigateToRetrieveBooking"
                                        class="w-100 p-3"
                                        variant="transparent"
                                        style="border: 1px solid #cdcdcd; border-radius: 5px;"
                                    >
                                        <div
                                            class="text-15 text-bold text-606060"
                                        >
                                            {{
                                                $t(
                                                    'retrieve_booking.guest_button_hint'
                                                )
                                            }}
                                        </div>
                                    </b-button>
                                </div>
                                <div
                                    v-if="currentView === 1"
                                    style="position: relative; max-height: 80vh;"
                                >
                                    <div class="d-flex">
                                        <div class="mb-3 mr-3 flex-fill">
                                            <p
                                                class="text-14 text-bold text-606060 mb-2"
                                            >
                                                {{ $t('member.f_name') }}
                                            </p>
                                            <b-form-input
                                                v-model="signup.firstName"
                                                :disabled="signup.smsSent"
                                                :class="
                                                    signup.smsSent
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                            ></b-form-input>
                                            <p
                                                v-if="
                                                    !isNameValid(
                                                        signup.firstName
                                                    ) &&
                                                        signup.firstName
                                                            .length > 0
                                                "
                                                class="text-danger text-14 text-bold"
                                            >
                                                {{
                                                    $t('err_english_first_name')
                                                }}
                                            </p>
                                        </div>
                                        <div class="mb-3 flex-fill">
                                            <p
                                                class="text-14 text-bold text-606060 mb-2"
                                            >
                                                {{ $t('member.l_name') }}
                                            </p>
                                            <b-form-input
                                                v-model="signup.lastName"
                                                :disabled="signup.smsSent"
                                                :class="
                                                    signup.smsSent
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                            ></b-form-input>
                                            <p
                                                v-if="
                                                    !isNameValid(
                                                        signup.lastName
                                                    ) &&
                                                        signup.lastName.length >
                                                            0
                                                "
                                                class="text-danger text-14 text-bold"
                                            >
                                                {{
                                                    $t('err_english_last_name')
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mb-3 mr-3 flex-even">
                                            <p
                                                class="text-14 text-bold text-606060 mb-2"
                                            >
                                                {{
                                                    $t('member.dob') +
                                                        ' (' +
                                                        $t('member.optional') +
                                                        ')'
                                                }}
                                            </p>
                                            <b-dropdown
                                                :disabled="signup.smsSent"
                                                :class="
                                                    signup.smsSent
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                                @show="openDobDropdown"
                                                lazy
                                                no-flip
                                                class="p-0 m-0 phone-code-dropdown d-flex fill-width form-control"
                                                toggle-class="dropdown-button"
                                                variant="transparent"
                                            >
                                                <template #button-content>
                                                    <span
                                                        class="text-14 text-mid text-606060"
                                                    >
                                                        {{ signup.dob.text }}
                                                    </span>
                                                </template>
                                            </b-dropdown>
                                            <b-collapse
                                                id="col-datepicker"
                                                v-model="visible"
                                                class="py-2"
                                            >
                                                <p class="text-14 text-small">
                                                    <datepicker
                                                        :language="
                                                            getDatePickerLocale()
                                                        "
                                                        :inline="true"
                                                        :disabled-dates="
                                                            disabledDates
                                                        "
                                                        :value="
                                                            signup.dob.value
                                                        "
                                                        :minimum-view="'month'"
                                                        :open-date="
                                                            defaultOpenDate
                                                        "
                                                        @selected="setDate"
                                                        class="vdp-datepicker"
                                                    />
                                                </p>
                                            </b-collapse>
                                        </div>
                                        <div class="mb-3 flex-even">
                                            <p
                                                class="text-14 text-bold text-606060 mb-2"
                                            >
                                                {{ $t('member.gender') }}
                                            </p>
                                            <b-dropdown
                                                :disabled="signup.smsSent"
                                                :class="
                                                    signup.smsSent
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                                lazy
                                                no-flip
                                                class="p-0 m-0 phone-code-dropdown d-flex"
                                                toggle-class="dropdown-button"
                                                variant="transparent"
                                            >
                                                <template #button-content>
                                                    <span
                                                        class="text-14 text-mid text-606060"
                                                    >
                                                        {{
                                                            $t(
                                                                signup.gender
                                                                    .text
                                                            )
                                                        }}
                                                    </span>
                                                </template>
                                                <b-dropdown-item
                                                    v-for="(item,
                                                    index) in genderList"
                                                    :key="index"
                                                    @click="
                                                        signup.gender = item
                                                    "
                                                    class="mb-2"
                                                >
                                                    <p
                                                        class="text-14 text-mid d-flex justify-content-center"
                                                    >
                                                        {{ $t(item.text) }}
                                                    </p>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.email') }}
                                        </p>
                                        <b-form-input
                                            v-model="signup.email"
                                            :disabled="signup.smsSent"
                                            :class="
                                                signup.smsSent
                                                    ? 'input-field-disable'
                                                    : 'input-field'
                                            "
                                            type="email"
                                        ></b-form-input>
                                    </div>
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.password') }}
                                        </p>
                                        <b-form-input
                                            v-model="signup.password"
                                            :disabled="signup.smsSent"
                                            :class="
                                                signup.smsSent
                                                    ? 'input-field-disable'
                                                    : 'input-field'
                                            "
                                            type="password"
                                        ></b-form-input>
                                        <p
                                            v-if="
                                                signup.password.length > 0 &&
                                                    signup.password.length < 6
                                            "
                                            class="text-danger text-14 text-bold"
                                        >
                                            {{ $t('err_auth_pw_too_short') }}
                                        </p>
                                    </div>
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.re_enter_password') }}
                                        </p>
                                        <b-form-input
                                            v-model="signup.confirmPassword"
                                            :disabled="signup.smsSent"
                                            :class="
                                                signup.smsSent
                                                    ? 'input-field-disable'
                                                    : 'input-field'
                                            "
                                            type="password"
                                        ></b-form-input>
                                        <p
                                            v-if="
                                                signup.confirmPassword.length >
                                                    0 &&
                                                    signup.confirmPassword !==
                                                        signup.password
                                            "
                                            class="text-danger text-14 text-bold"
                                        >
                                            {{
                                                $t('err_auth_con_pw_not_match')
                                            }}
                                        </p>
                                    </div>
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{
                                                $t('member.invite_code') +
                                                    ' (' +
                                                    $t('member.optional') +
                                                    ')'
                                            }}
                                        </p>
                                        <b-form-input
                                            v-model="signup.inviteCode"
                                            :disabled="signup.smsSent"
                                            :class="
                                                signup.smsSent
                                                    ? 'input-field-disable'
                                                    : 'input-field'
                                            "
                                            type="text"
                                        ></b-form-input>
                                    </div>
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.receive_offers') }}
                                        </p>
                                        <b-dropdown
                                            :disabled="signup.smsSent"
                                            :class="
                                                signup.smsSent
                                                    ? 'input-field-disable'
                                                    : 'input-field'
                                            "
                                            lazy
                                            no-flip
                                            class="p-0 m-0 phone-code-dropdown d-flex"
                                            toggle-class="dropdown-button"
                                            variant="transparent"
                                        >
                                            <template #button-content>
                                                <span
                                                    class="text-15 text-mid text-606060"
                                                >
                                                    {{
                                                        $t(
                                                            signup.offersLang
                                                                .text
                                                        )
                                                    }}
                                                </span>
                                            </template>
                                            <b-dropdown-item
                                                v-for="(item,
                                                index) in offersLangList"
                                                :key="index"
                                                @click="
                                                    signup.offersLang = item
                                                "
                                                class="py-1 text-center"
                                            >
                                                <p class="text-15 text-mid">
                                                    {{ $t(item.text) }}
                                                </p>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.phone') }}
                                        </p>
                                        <div class="d-flex mb-3">
                                            <b-dropdown
                                                :disabled="signup.count > 0"
                                                :class="
                                                    signup.count > 0
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                                lazy
                                                no-flip
                                                class="p-0 m-0 phone-code-dropdown flex-fill form-control w-auto"
                                                toggle-class="dropdown-button"
                                                variant="transparent"
                                            >
                                                <template #button-content>
                                                    <span
                                                        class="text-14 text-mid text-606060 mr-1"
                                                    >
                                                        {{
                                                            signup.phoneCode
                                                                .code
                                                        }}
                                                    </span>
                                                </template>
                                                <b-dropdown-item
                                                    v-for="(item,
                                                    index) in defaultCountryCode"
                                                    :key="index"
                                                    @click="
                                                        signup.phoneCode.code =
                                                            item.code
                                                    "
                                                    class="mb-2"
                                                >
                                                    <p class="text-15 text-mid">
                                                        {{
                                                            item[
                                                                getCountryCodeLocale
                                                            ]
                                                        }}
                                                    </p>
                                                    <p
                                                        class="text-14 text-s-bold text-9d9d9d"
                                                    >
                                                        {{ item.code }}
                                                    </p>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                            <b-form-input
                                                v-model="signup.phone"
                                                :disabled="signup.count > 0"
                                                :class="
                                                    signup.count > 0
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                                class="ml-2"
                                                type="tel"
                                            />
                                        </div>
                                        <b-button
                                            v-if="!isBypassPhoneRegister"
                                            :disabled="
                                                isSubmitting ||
                                                    !isEmailSignupInfoFilled ||
                                                    signup.count > 0
                                            "
                                            @click="sendSms"
                                            variant="primary"
                                            style="white-space: nowrap; height: 45px"
                                            class="text-14 text-mid text-light container-fluid m-0"
                                        >
                                            <div
                                                v-if="
                                                    isSubmitting ||
                                                        signup.count > 0
                                                "
                                                class="d-flex justify-content-center align-items-center"
                                            >
                                                <b-spinner
                                                    variant="light"
                                                    class="mr-2"
                                                    small
                                                ></b-spinner>
                                                <p v-if="signup.count > 0">
                                                    {{ signup.count }}
                                                </p>
                                            </div>
                                            <p
                                                v-if="
                                                    !isSubmitting &&
                                                        signup.count <= 0
                                                "
                                                class="text-15"
                                            >
                                                {{ $t('member.get_v_code') }}
                                            </p>
                                        </b-button>
                                    </div>
                                    <div
                                        v-if="!isBypassPhoneRegister"
                                        class="mb-3"
                                    >
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.v_code') }}
                                        </p>
                                        <b-form-input
                                            v-model="signup.passcode"
                                            :disabled="!signup.smsSent"
                                            :class="
                                                !signup.smsSent
                                                    ? 'input-field-disable'
                                                    : 'input-field'
                                            "
                                        />
                                    </div>
                                    <b-button
                                        :disabled="isEmailSignupButtonDisabled"
                                        @click="verifyPhoneAndSubmit"
                                        variant="primary"
                                        class="container-fluid"
                                        style="margin-bottom: 60px; height: 45px;"
                                    >
                                        <b-spinner
                                            v-if="signup.vidSending"
                                            variant="light"
                                            class="mr-2"
                                            small
                                        />
                                        <p
                                            v-if="!signup.vidSending"
                                            class="text-15 text-bold text-606060 text-light"
                                        >
                                            {{ $t('signup') }}
                                        </p>
                                    </b-button>
                                </div>
                                <div
                                    v-if="currentView === 2"
                                    style="position: relative; max-height: 80vh"
                                >
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.email') }}
                                        </p>
                                        <b-form-input
                                            v-model="socialSignup.email"
                                            :disabled="true"
                                            class="input-field-disable"
                                        ></b-form-input>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mb-3 mr-3 flex-fill">
                                            <p
                                                class="text-14 text-bold text-606060 mb-2"
                                            >
                                                {{ $t('member.f_name') }}
                                            </p>
                                            <b-form-input
                                                v-model="socialSignup.firstName"
                                                :disabled="socialSignup.smsSent"
                                                :class="
                                                    socialSignup.smsSent
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                                type="email"
                                            ></b-form-input>
                                            <p
                                                v-if="
                                                    !isNameValid(
                                                        socialSignup.firstName
                                                    ) &&
                                                        socialSignup.firstName
                                                            .length > 0
                                                "
                                                class="text-danger text-14 text-bold"
                                            >
                                                {{
                                                    $t('err_english_first_name')
                                                }}
                                            </p>
                                        </div>
                                        <div class="mb-3 flex-fill">
                                            <p
                                                class="text-14 text-bold text-606060 mb-2"
                                            >
                                                {{ $t('member.l_name') }}
                                            </p>
                                            <b-form-input
                                                v-model="socialSignup.lastName"
                                                :disabled="socialSignup.smsSent"
                                                :class="
                                                    socialSignup.smsSent
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                                type="email"
                                            ></b-form-input>
                                            <p
                                                v-if="
                                                    !isNameValid(
                                                        socialSignup.lastName
                                                    ) &&
                                                        socialSignup.lastName
                                                            .length > 0
                                                "
                                                class="text-danger text-14 text-bold"
                                            >
                                                {{
                                                    $t('err_english_last_name')
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mb-3 mr-3 flex-even">
                                            <p
                                                class="text-14 text-bold text-606060 mb-2"
                                            >
                                                {{
                                                    $t('member.dob') +
                                                        ' (' +
                                                        $t('member.optional') +
                                                        ')'
                                                }}
                                            </p>
                                            <b-dropdown
                                                :disabled="socialSignup.smsSent"
                                                :class="
                                                    socialSignup.smsSent
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                                @show="openDobDropdown"
                                                lazy
                                                no-flip
                                                class="p-0 m-0 phone-code-dropdown d-flex fill-width"
                                                variant="transparent"
                                            >
                                                <template #button-content>
                                                    <span
                                                        class="text-14 text-mid text-606060"
                                                    >
                                                        {{
                                                            socialSignup.dob
                                                                .text
                                                        }}
                                                    </span>
                                                </template>
                                            </b-dropdown>
                                            <b-collapse
                                                id="col-datepicker"
                                                v-model="visible"
                                                class="py-2"
                                            >
                                                <p class="text-14 text-small">
                                                    <datepicker
                                                        :language="
                                                            getDatePickerLocale()
                                                        "
                                                        :inline="true"
                                                        :disabled-dates="
                                                            disabledDates
                                                        "
                                                        :value="
                                                            socialSignup.dob
                                                                .value
                                                        "
                                                        :minimum-view="'month'"
                                                        :open-date="
                                                            defaultOpenDate
                                                        "
                                                        @selected="setDate"
                                                        class="vdp-datepicker"
                                                    />
                                                </p>
                                            </b-collapse>
                                        </div>
                                        <div class="mb-3 flex-even">
                                            <p
                                                class="text-14 text-bold text-606060 mb-2"
                                            >
                                                {{ $t('member.gender') }}
                                            </p>
                                            <b-dropdown
                                                :disabled="socialSignup.smsSent"
                                                :class="
                                                    socialSignup.smsSent
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                                lazy
                                                no-flip
                                                class="p-0 m-0 phone-code-dropdown d-flex"
                                                variant="transparent"
                                            >
                                                <template #button-content>
                                                    <span
                                                        class="text-14 text-mid text-606060"
                                                    >
                                                        {{
                                                            $t(
                                                                socialSignup
                                                                    .gender.text
                                                            )
                                                        }}
                                                    </span>
                                                </template>
                                                <b-dropdown-item
                                                    v-for="(item,
                                                    index) in genderList"
                                                    :key="index"
                                                    @click="
                                                        socialSignup.gender = item
                                                    "
                                                    class="mb-2"
                                                >
                                                    <p class="text-14 text-mid">
                                                        {{ $t(item.text) }}
                                                    </p>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{
                                                $t('member.invite_code') +
                                                    ' (' +
                                                    $t('member.optional') +
                                                    ')'
                                            }}
                                        </p>
                                        <b-form-input
                                            v-model="socialSignup.inviteCode"
                                            :disabled="socialSignup.smsSent"
                                            :class="
                                                socialSignup.smsSent
                                                    ? 'input-field-disable'
                                                    : 'input-field'
                                            "
                                            type="text"
                                        ></b-form-input>
                                    </div>
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.receive_offers') }}
                                        </p>
                                        <b-dropdown
                                            :disabled="socialSignup.smsSent"
                                            :class="
                                                socialSignup.smsSent
                                                    ? 'input-field-disable'
                                                    : 'input-field'
                                            "
                                            lazy
                                            no-flip
                                            class="p-0 m-0 phone-code-dropdown d-flex"
                                            variant="transparent"
                                        >
                                            <template #button-content>
                                                <span
                                                    class="text-14 text-mid text-606060"
                                                >
                                                    {{
                                                        $t(
                                                            socialSignup
                                                                .offersLang.text
                                                        )
                                                    }}
                                                </span>
                                            </template>
                                            <b-dropdown-item
                                                v-for="(item,
                                                index) in offersLangList"
                                                :key="index"
                                                @click="
                                                    socialSignup.offersLang = item
                                                "
                                                class="py-1 text-center"
                                            >
                                                <p class="text-14 text-mid">
                                                    {{ $t(item.text) }}
                                                </p>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.phone') }}
                                        </p>
                                        <div class="d-flex mb-3">
                                            <b-dropdown
                                                :disabled="
                                                    socialSignup.count > 0
                                                "
                                                :class="
                                                    socialSignup.count > 0
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                                lazy
                                                no-flip
                                                class="p-0 m-0 phone-code-dropdown flex-fill"
                                                variant="transparent"
                                            >
                                                <template #button-content>
                                                    <span
                                                        class="text-14 text-mid text-606060"
                                                    >
                                                        {{
                                                            socialSignup
                                                                .phoneCode.code
                                                        }}
                                                    </span>
                                                </template>
                                                <b-dropdown-item
                                                    v-for="(item,
                                                    index) in defaultCountryCode"
                                                    :key="index"
                                                    @click="
                                                        socialSignup.phoneCode.code =
                                                            item.code
                                                    "
                                                    class="mb-2"
                                                >
                                                    <p class="text-15 text-mid">
                                                        {{
                                                            item[
                                                                getCountryCodeLocale
                                                            ]
                                                        }}
                                                    </p>
                                                    <p
                                                        class="text-14 text-s-bold text-9d9d9d"
                                                    >
                                                        {{ item.code }}
                                                    </p>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                            <b-form-input
                                                v-model="socialSignup.phone"
                                                :disabled="
                                                    socialSignup.count > 0
                                                "
                                                :class="
                                                    socialSignup.count > 0
                                                        ? 'input-field-disable'
                                                        : 'input-field'
                                                "
                                                class="ml-2"
                                                type="tel"
                                            />
                                        </div>
                                        <b-button
                                            v-if="!isBypassPhoneRegister"
                                            :disabled="
                                                isSubmitting ||
                                                    !isSocialSignupInfoFilled ||
                                                    socialSignup.count > 0
                                            "
                                            @click="sendSms"
                                            variant="primary"
                                            style="white-space: nowrap;"
                                            class="text-14 text-mid text-light container-fluid m-0"
                                        >
                                            <div
                                                v-if="
                                                    isSubmitting ||
                                                        socialSignup.count > 0
                                                "
                                                class="d-flex justify-content-center align-items-center"
                                            >
                                                <b-spinner
                                                    variant="light"
                                                    class="mr-2"
                                                    small
                                                ></b-spinner>
                                                <p
                                                    v-if="
                                                        socialSignup.count > 0
                                                    "
                                                >
                                                    {{ socialSignup.count }}
                                                </p>
                                            </div>
                                            <p
                                                v-if="
                                                    !isSubmitting &&
                                                        socialSignup.count <= 0
                                                "
                                            >
                                                {{ $t('member.get_v_code') }}
                                            </p>
                                        </b-button>
                                    </div>
                                    <div
                                        v-if="!isBypassPhoneRegister"
                                        class="mb-3"
                                    >
                                        <p
                                            class="text-14 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.v_code') }}
                                        </p>
                                        <b-form-input
                                            v-model="socialSignup.passcode"
                                            :disabled="!socialSignup.smsSent"
                                            :class="
                                                !socialSignup.smsSent
                                                    ? 'input-field-disable'
                                                    : 'input-field'
                                            "
                                        ></b-form-input>
                                    </div>
                                    <b-button
                                        :disabled="isSocialSignupButtonDisabled"
                                        @click="verifyPhoneAndSubmit"
                                        variant="primary"
                                        class="container-fluid m-0 mb-5"
                                    >
                                        <b-spinner
                                            v-if="socialSignup.vidSending"
                                            variant="light"
                                            class="mr-2"
                                            small
                                        ></b-spinner>
                                        <p
                                            v-if="!socialSignup.vidSending"
                                            class="text-14 text-mid text-606060 text-light"
                                        >
                                            {{ $t('signup') }}
                                        </p>
                                    </b-button>
                                </div>
                                <div
                                    id="view-forget"
                                    v-if="currentView === 3"
                                    style="position: relative; max-height: 80vh"
                                >
                                    <div class="mb-3">
                                        <b
                                            class="text-24 text-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.forgot_pw') + '?' }}
                                        </b>
                                        <p
                                            class="text-14 text-s-bold text-606060 mb-4"
                                        >
                                            {{ $t('member.forgot_pw_intro') }}
                                        </p>
                                        <p
                                            class="text-12 text-s-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.email') }}
                                        </p>
                                        <b-form-input
                                            v-model="resetPassword.email"
                                            class="input-field"
                                            type="email"
                                        ></b-form-input>
                                    </div>
                                    <b-button
                                        :disabled="
                                            resetPassword.isLoading ||
                                                resetPassword.email === ''
                                        "
                                        @click="sendResetPasswordEmail"
                                        variant="primary"
                                        class="container-fluid m-0 mb-3"
                                    >
                                        <b-spinner
                                            v-if="resetPassword.isLoading"
                                            variant="light"
                                            small
                                        ></b-spinner>
                                        <p
                                            v-if="!resetPassword.isLoading"
                                            class="text-14 text-mid text-606060 text-light"
                                        >
                                            {{ $t('send') }}
                                        </p>
                                    </b-button>
                                </div>
                                <div
                                    id="view-account-linking"
                                    v-if="currentView === 4"
                                    style="position: relative; max-height: 80vh"
                                >
                                    <div class="mb-3">
                                        <p
                                            class="text-14 text-s-bold text-606060 mb-4"
                                        >
                                            {{
                                                $t(
                                                    'member.password_linking_dis_1'
                                                ) +
                                                    accountLinking.currentSignInMethod +
                                                    $t(
                                                        'member.password_linking_dis_2'
                                                    )
                                            }}
                                        </p>
                                        <p
                                            class="text-12 text-s-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.email') }}
                                        </p>
                                        <b-form-input
                                            v-model="accountLinking.email"
                                            :disabled="true"
                                            class="input-field-disable mb-2"
                                            type="email"
                                        ></b-form-input>
                                        <p
                                            class="text-12 text-s-bold text-606060 mb-2"
                                        >
                                            {{ $t('member.password') }}
                                        </p>
                                        <b-form-input
                                            id="password-element"
                                            v-model="accountLinking.password"
                                            class="input-field mb-2"
                                            type="password"
                                        ></b-form-input>
                                    </div>
                                    <b-button
                                        :disabled="
                                            accountLinking.isLoading ||
                                                accountLinking.email === '' ||
                                                accountLinking.password === ''
                                        "
                                        @click="linkingAccountByEmail"
                                        variant="primary"
                                        class="container-fluid m-0 mb-3"
                                    >
                                        <b-spinner
                                            v-if="accountLinking.isLoading"
                                            variant="light"
                                            small
                                        ></b-spinner>
                                        <p
                                            v-if="!accountLinking.isLoading"
                                            class="text-14 text-mid text-606060 text-light"
                                        >
                                            {{ $t('send') }}
                                        </p>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="recaptcha-container"
                data-badge="inline"
                style="position: absolute; bottom: 0; right: 0;"
            ></div>
        </div>
    </transition>
</template>

<script>
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    getAdditionalUserInfo
} from 'firebase/auth'
import _filter from 'lodash/filter'
import _sortBy from 'lodash/sortBy'
import countryCode from 'assets/json/country-code.json'
import inputValidation from '../../services/inputValidation'
import firebase from '../../services/firebase'
import dateServices from '../../services/dateServices'
import Api from '~/services/api'
import TextButton from '@/components/item/TextButton'
import analysis from '@/services/analysis'
import FacebookIcon from '@/components/iconSvg/Facebook.vue'
import AppleSvg from '@/components/iconSvg/AppleSvg.vue'
import ImageContainer from '@/components/ui/ImageContainer.vue'

const api = Api.create()

export default {
    name: 'MemberLogin',
    components: {ImageContainer, AppleSvg, FacebookIcon, TextButton },
    data() {
        return {
            visible: false,
            isSubmitting: false,
            currentView: 0, // 0 = login, 1 = signup, 2 = socialSignup, 3 = forget password
            defaultCountryCode: [],
            date: {
                max: '',
                min: ''
            },
            genderList: [
                {
                    text: 'member.male',
                    value: 'm'
                },
                {
                    text: 'member.female',
                    value: 'f'
                }
            ],
            offersLangList: [
                {
                    text: 'lang.en',
                    value: 'en'
                },
                {
                    text:
                        this.$route.params.region === 'sg' ||
                        this.$route.params.region === 'my'
                            ? 'lang.sc'
                            : 'lang.zh',
                    value: 'zh'
                }
            ],

            login: {
                isLoading: false,
                email: '',
                password: '',
                save: false
            },
            signup: {
                email: '',
                password: '',
                confirmPassword: '',
                phone: '',
                phoneCode: {
                    code: '+852'
                },
                passcode: '',
                firstName: '',
                lastName: '',
                dob: {
                    text: 'YYYY/MM',
                    value: ''
                },
                gender: {
                    text: 'member.male',
                    value: 'm'
                },
                offersLang: {
                    text: 'lang.en',
                    value: 'en'
                },
                inviteCode: '',
                count: -1,
                vId: '',
                smsSent: false,
                vidSending: false
            },
            socialSignup: {
                credential: {},
                email: '',
                password: '',
                confirmPassword: '',
                phone: '',
                phoneCode: {
                    code: '+852'
                },
                passcode: '',
                firstName: '',
                lastName: '',
                dob: {
                    text: 'YYYY/MM',
                    value: ''
                },
                gender: {
                    text: 'member.male',
                    value: 'm'
                },
                offersLang: {
                    text: 'lang.en',
                    value: 'en'
                },
                inviteCode: '',
                count: -1,
                vId: '',
                smsSent: false,
                vidSending: false,
                social: ''
            },
            resetPassword: {
                isLoading: false,
                email: ''
            },
            accountLinking: {
                isLoading: false,
                email: '',
                password: '',
                pendingCred: {},
                currentSignInMethod: '',
                PreviousSignInMethod: ''
            },
            // option: {
            //     mode: 'native',
            //     // vuescroll: {
            //     //     sizeStrategy: 'number'
            //     // },
            //     bar: {
            //         showDelay: 2000
            //     },
            //     scrollPanel: {
            //         scrollingX: false,
            //         scrollingY: true,
            //         speed: 300
            //     }
            // },
            disabledDates: {
                to: dateServices.subtractYears(90),
                from: dateServices.subtractYears(13)
            }
        }
    },
    computed: {
        isSigned() {
            return this.$store.getters['member/isSigned']
        },
        isEmailSignupInfoFilled() {
            return (
                this.isEmailValid(this.signup.email) &&
                this.signup.password.length >= 6 &&
                this.signup.confirmPassword === this.signup.password &&
                this.isPhoneValid(this.signup.phone) &&
                this.signup.phoneCode.code.length > 0 &&
                this.isNameValid(this.signup.firstName) &&
                this.isNameValid(this.signup.lastName)
            )
        },
        isSocialSignupInfoFilled() {
            return (
                this.isEmailValid(this.socialSignup.email) &&
                this.isPhoneValid(this.socialSignup.phone) &&
                this.socialSignup.phoneCode.code.length > 0 &&
                this.isNameValid(this.socialSignup.firstName) &&
                this.isNameValid(this.socialSignup.lastName)
            )
        },
        region() {
            return this.$route.params.region
        },
        lang() {
            return this.$route.params.locale
        },
        getSortedCountryCode() {
            const list = _sortBy(countryCode, 'English')
            return _sortBy(list, (item) => {
                if (item.ISO3 === 'HKG') {
                    return 0
                } else if (item.ISO3 === 'SGP') {
                    return 0
                } else if (item.ISO3 === 'MAC') {
                    return 0
                } else if (item.ISO3 === 'TWN') {
                    return 0
                } else if (item.ISO3 === 'MYS') {
                    return 0
                } else if (item.ISO3 === 'VNM') {
                    return 0
                } else {
                    return 1
                }
            })
        },
        getCountryCodeLocale() {
            const currentLang = this.lang + '-' + this.region
            switch (currentLang) {
                case 'en':
                case 'ms':
                    return 'English'
                case 'zh-hk':
                case 'zh-mo':
                    return 'Hongkong'
                case 'zh-my':
                case 'zh-sg':
                    return 'China'
                default:
                    return 'English'
            }
        },
        isEmailSignupButtonDisabled() {
            if (this.isBypassPhoneRegister) {
                return !this.isEmailSignupInfoFilled
            }
            return this.signup.passcode === '' && !this.signup.vidSending
        },
        isSocialSignupButtonDisabled() {
            if (this.isBypassPhoneRegister) {
                return !this.isSocialSignupInfoFilled
            }
            return (
                this.socialSignup.passcode === '' &&
                !this.socialSignup.vidSending
            )
        },
        defaultOpenDate() {
            return dateServices.subtractYears(13)
        }
    },
    mounted() {
        this.hideSlideBar(true)
        this.selectView(0)
        this.defaultCountryCode = this.getSortedCountryCode
        this.setDefaultPhoneCode()
        this.setMaxMinDay()
        window.recaptchaVerifier = firebase.setReCAPTCHA(
            'recaptcha-container',
            {
                size: 'invisible'
            }
        )
        this.isInvited()
    },
    methods: {
        setDate(date) {
            const dateValue = dateServices.getDateByFormat(date, false)
            const dateDisplay = dateServices.getDisplayDateByFormat(date, false)
            this.visible = !this.visible
            if (this.currentView === 1) {
                this.signup.dob.text = dateDisplay
                this.signup.dob.value = dateValue
            } else if (this.currentView === 2) {
                this.socialSignup.dob.text = dateDisplay
                this.socialSignup.dob.value = dateValue
            }
        },
        isEmailValid(email) {
            return inputValidation.validate(inputValidation.type.email, email)
        },
        isPhoneValid(phone) {
            return inputValidation.validate(inputValidation.type.numeric, phone)
        },
        close() {
            this.hideSlideBar(false)
            this.$emit('close')
            this.socialViewSignout()
        },
        toggleSaveLogin() {
            this.login.save = !this.login.save
        },
        hideSlideBar(hide) {
            if (hide) {
                document.documentElement.style.overflow = 'hidden'
            } else {
                document.documentElement.style.overflow = 'auto'
            }
        },
        selectView(view) {
            this.currentView = view
        },
        isInvited() {
            if (this.$route.query.code != null) {
                this.selectView(1)
                this.signup.inviteCode = this.$route.query.code
            }
        },
        navigateToDashboard() {
            this.hideSlideBar(false)
            this.$emit('close')
        },
        navigate(path) {
            this.$router.push({
                path
            })
        },
        signInWithEmail() {
            const obj = {
                email: this.login.email,
                password: this.login.password
            }
            if (!this.isEmailValid(obj.email)) {
                this.showOkModal(this.$t('member.val_email'))
                return
            }

            if (obj.password === '') {
                this.showOkModal(this.$t('member.val_password'))
                return
            }

            this.login.isLoading = true

            firebase
                .signInWithEmailAndPassword(obj)
                .then((res) => {
                    if (res.user) {
                        this.login.email = ''
                        this.login.password = ''
                        this.navigateToDashboard()
                        this.sendLoginLogEvent('Email Address')
                    }
                })
                .finally(() => {
                    this.login.isLoading = false
                })
                .catch((error) => {
                    let msg = this.$t('err_auth_email_pw_incorrect')
                    switch (error.code) {
                        case 'auth/invalid-email':
                            msg = this.$t('err_auth_invalid_email')
                            break
                        case 'auth/user-disabled':
                            msg = this.$t('err_auth_user_disabled')
                            break
                    }
                    this.showOkModal(msg)
                    this.$sentry.captureException(msg)
                })
        },
        signInWithSocialAcc(social) {
            let provider = null
            switch (social) {
                case 'Facebook':
                    provider = firebase.getFacebookProvider()
                    provider.addScope('email')
                    break
                case 'Google':
                    provider = firebase.getGoogleProvider()
                    break
                case 'Apple':
                    provider = firebase.getAppleProvider()
                    provider.addScope('email')
                    break
            }

            if (provider === null) {
                return
            }

            firebase
                .signInWithPopup(provider)
                .then((info) => {
                    return Promise.all([firebase.getIdToken(), info])
                })
                .then(([idToken, info]) => {
                    const details = getAdditionalUserInfo(info)
                    let obj = {}
                    switch (social) {
                        case 'Facebook':
                            const email =
                                info.user.email ?? details?.profile?.email
                            if (!email) {
                                throw new Error('NO_FACEBOOK_EMAIL')
                            }
                            obj = { email }
                            break
                        case 'Google':
                            obj = {
                                email: details.profile.email
                            }
                            break
                        case 'Apple':
                            obj = {
                                email: info.user.email
                            }
                            break
                    }
                    obj.lang = this.lang
                    return Promise.all([
                        api.isMemberEmailExist(obj),
                        idToken,
                        info
                    ])
                })
                .then(async ([isMemberRes, idToken, res]) => {
                    if (isMemberRes.data.Data.state !== null) {
                        this.sendLoginLogEvent(social)
                        this.navigateToDashboard()
                    } else {
                        const details = getAdditionalUserInfo(res)
                        let email
                        switch (social) {
                            case 'Facebook':
                                email =
                                    res.user.email ?? details?.profile?.email
                                break
                            case 'Google':
                                email = details.profile.email
                                break
                            case 'Apple':
                                email = res.user.email
                                break
                        }
                        await firebase.updateEmail(email)
                        await api.checkSocialAuthState(
                            {
                                id_token: idToken
                            },
                            this.lang,
                            this.region
                        )
                        this.socialSignup.social = social
                        switch (social) {
                            case 'Facebook':
                                this.socialSignup.email =
                                    res.user.email ?? details?.profile?.email
                                break
                            case 'Google':
                                this.socialSignup.email = details.profile.email
                                this.socialSignup.firstName = details.profile.given_name
                                this.socialSignup.lastName = details.profile.family_name
                                break
                            case 'Apple':
                                this.socialSignup.email = res.user.email
                                break
                        }
                        // switch to signUp
                        this.selectView(2)
                    }
                })
                .catch((error) => {
                    if (
                        error.code ===
                        'auth/account-exists-with-different-credential'
                    ) {
                        this.accountLinking.currentSignInMethod = social
                        let credFromError
                        switch (social) {
                            case 'Facebook':
                                credFromError = FacebookAuthProvider.credentialFromError(error)
                                break
                            case 'Google':
                                credFromError = GoogleAuthProvider.credentialFromError(error)
                                break
                            case 'Apple':
                                credFromError = OAuthProvider.credentialFromError(error)
                                break
                        }
                        this.handleAccountLinking(credFromError, social)
                    } else if (error.message === 'NO_FACEBOOK_EMAIL') {
                        this.showOkModal(
                            this.$t('member.err_no_facebook_email')
                        )
                    }
                })
        },
        handleAccountLinking(credFromError, social) {
            const pendingProvider = credFromError.providerId
            api.postEmailAddressByOAuthToken(this.paramsRegion, this.paramsLang, credFromError)
                .then((res) => {
                    this.accountLinking.email = res.data.Data.email
                    const verifiedProviders = res.data.Data.verified_providers
                    if (verifiedProviders.includes('password')) {
                        this.selectView(4)
                    }
                    let provider = null
                    switch (pendingProvider) {
                        case 'facebook.com':
                            this.accountLinking.pendingCred = firebase.getFacebookCredential(
                                credFromError.accessToken
                            )
                            break
                        case 'google.com':
                            this.accountLinking.pendingCred = firebase.getGoogleCredential(
                                firebase.getIdToken(),
                                credFromError.accessToken
                            )
                            break
                        case 'apple.com':
                            this.accountLinking.pendingCred = firebase.getFacebookCredential(
                                credFromError.accessToken
                            )
                            break
                    }

                    if (verifiedProviders.includes('facebook.com')) {
                        provider = firebase.getFacebookProvider()
                    } else if (verifiedProviders.includes('google.com')) {
                        provider = firebase.getGoogleProvider()
                    } else if (verifiedProviders.includes('apple.com')) {
                        provider = firebase.getAppleProvider()
                    }

                    if (provider !== null) {
                        firebase
                            .signInWithPopup(provider)
                            .then((result) => {
                                if (result) {
                                    return firebase.linkWithCredential(
                                        this.accountLinking.pendingCred
                                    )
                                } else {
                                    throw new Error('flow/error')
                                }
                            })
                            .then((res) => {
                                if (res) {
                                    this.sendLoginLogEvent(social)
                                    this.navigateToDashboard()
                                } else {
                                    throw new Error('flow/error')
                                }
                            })
                    }
                })
                .catch((err) => {
                    let msg = ''
                    switch (err.message) {
                        case 'flow/email_exists':
                            msg = this.$t('err_email_exists')
                            break
                        case 'flow/error':
                            msg = this.$t('err_occurred')
                            break
                        default:
                            msg = err.message
                            break
                    }
                    this.showOkModal(msg)
                })
        },
        linkingAccountByEmail() {
            this.accountLinking.isLoading = true

            const obj = {
                email: this.accountLinking.email,
                password: this.accountLinking.password
            }

            firebase
                .signInWithEmailAndPassword(obj)
                .then((user) => {
                    return firebase.linkWithCredential(
                        this.accountLinking.pendingCred
                    )
                })
                .then(() => {
                    this.sendLoginLogEvent('Email Address')
                    this.navigateToDashboard()
                })
                .finally(() => {
                    this.accountLinking.isLoading = false
                })
                .catch((error) => {
                    let msg = this.$t('err_auth_email_pw_incorrect')
                    switch (error.code) {
                        case 'auth/invalid-email':
                            msg = this.$t('err_auth_invalid_email')
                            break
                        case 'auth/user-disabled':
                            msg = this.$t('err_auth_user_disabled')
                            break
                    }
                    this.showOkModal(msg)
                })
        },
        showOkModal(msg = '', action = '') {
            this.$bvModal
                .msgBoxOk(msg, {
                    okTitle: this.$t('ok'),
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    contentClass: 'justify-content-center text-center',
                    footerClass: 'justify-content-center',
                    okVariant: 'dark',
                    size: 'sm',
                    buttonSize: 'sm',
                    centered: true
                })
                .then(() => {
                    if (action !== '') {
                        switch (action) {
                            case 'resetPwGoBack':
                                this.resetPassword.email = ''
                                this.selectView(0)
                                break
                            case 'goToDashboard':
                                this.navigateToDashboard()
                                break
                        }
                    }
                })
        },
        sendResetPasswordEmail() {
            const email = this.resetPassword.email
            this.resetPassword.isLoading = true
            if (!this.isEmailValid(email)) {
                this.showOkModal(this.$t('member.val_email'))
                return
            }

            firebase
                .resetPassword(email)
                .then(() => {
                    this.showOkModal(
                        this.$t('member.forgot_pw_success'),
                        'resetPwGoBack'
                    )
                })
                .finally(() => {
                    this.resetPassword.isLoading = false
                })
                .catch(() => {
                    this.showOkModal(this.$t('error.forgot_pw'))
                })
        },
        sendSms() {
            let phoneNum = ''
            let countryCode = ''
            let email = ''
            let inviteCode = ''

            this.isSubmitting = true

            if (this.currentView === 1) {
                phoneNum = this.signup.phone
                countryCode = this.signup.phoneCode.code
                email = this.signup.email
                inviteCode = this.signup.inviteCode
            } else {
                phoneNum = this.socialSignup.phone
                countryCode = this.socialSignup.phoneCode.code
                email = this.socialSignup.email
                inviteCode = this.socialSignup.inviteCode
            }
            api.isMemberEmailPhoneExist({
                email,
                dial_code: countryCode,
                phone: phoneNum,
                lang: this.lang,
                region: this.region
            })
                .then((res) => {
                    if (
                        // email does not exist
                        res.data.Data.state === null ||
                        res.data.Data.state === 1
                    ) {
                        return res
                    } else if (res.data.Data.state === 2) {
                        // email exist
                        throw new Error('flow/email_exists')
                    } else if (res.data.Data.message) {
                        // Both email and phone have been registered by different accounts
                        throw new Error(res.data.Data.message)
                    }
                })
                .then((res) => {
                    if (inviteCode.length > 0) {
                        // have refer code
                        const obj = {
                            refer_code: inviteCode,
                            lang: this.lang
                        }
                        return api.isInviteCodeValid(obj)
                    } else {
                        return res
                    }
                })
                .then((res) => {
                    if (res.data.Success) {
                        return firebase.getIdToken()
                    } else {
                        throw new Error(res.data.Data.message)                    }
                })
                .then((idToken) => {
                    const body = {
                        id_token: idToken,
                        dial_code: countryCode,
                        phone: phoneNum
                    }
                    return api.postMemberSendPhoneNumber(body, this.lang, this.region)
                })
                .then((res) => {
                    if (res.data.Success) {
                        this.setSmsResendTimer()
                    } else {
                        throw new Error(res.data.Data.message)
                    }
                })
                .finally(() => {
                    this.isSubmitting = false
                })
                .catch((err) => {
                    if (err.code === 'auth/invalid-phone-number') {
                        switch (err.message) {
                            case 'TOO_SHORT':
                                this.showOkModal(this.$t('err_phone_too_short'))
                                break
                            case 'TOO_LONG':
                                this.showOkModal(this.$t('err_phone_too_long'))
                                break
                            default:
                                this.showOkModal(this.$t('err_phone_invalid'))
                        }
                    } else if (err.code === 'auth/invalid-email') {
                        this.showOkModal(this.$t('err_auth_invalid_email'))
                    } else {
                        let msg = ''
                        switch (err.message) {
                            case 'flow/email_exists':
                                msg = this.$t('err_email_exists')
                                break
                            case 'flow/error':
                                msg = this.$t('err_occurred')
                                break
                            default:
                                msg = err.message
                                break
                        }
                        this.showOkModal(msg)
                    }
                })
        },
        async verifyPhoneAndSubmit() {
            let passcode = ''
            let email = ''
            let password = ''
            let dialCode = ''
            let phone = ''
            let firstName = ''
            let lastName = ''
            let gender = ''
            let dob = ''
            let inviteCode = ''
            let offersLang = ''
            const visitorData = await this.$fpjs.getVisitorData({
                extendedResult: true
            })
            if (this.currentView === 1) {
                this.signup.vidSending = true
                email = this.signup.email
                password = this.signup.confirmPassword
                passcode = this.signup.passcode
                dialCode = this.signup.phoneCode.code
                phone = this.signup.phone
                firstName = this.signup.firstName
                lastName = this.signup.lastName
                gender = this.signup.gender.value
                dob = this.signup.dob.value
                inviteCode = this.signup.inviteCode
                offersLang = this.signup.offersLang.value
                const obj = {
                    email,
                    dial_code: dialCode,
                    phone,
                    dob,
                    surname: lastName,
                    given_name: firstName,
                    gender,
                    info_lang: offersLang,
                    refer_code: inviteCode
                }
                let createUserPromise
                if (this.isBypassPhoneRegister) {
                    createUserPromise = api
                        .isMemberEmailPhoneExist({
                            email,
                            dial_code: dialCode,
                            phone,
                            lang: this.lang,
                            region: this.region
                        })
                        .then((res) => {
                            if (
                                // email does not exist
                                res.data.Data.state === null ||
                                res.data.Data.state === 1
                            ) {
                                return res
                            } else if (res.data.Data.state === 2) {
                                // email exist
                                throw new Error('flow/email_exists')
                            } else if (res.data.Data.message) {
                                // Both email and phone have been registered by different accounts
                                throw new Error(res.data.Data.message)
                            }
                        })
                        .then((res) => {
                            if (inviteCode.length > 0) {
                                // have referral code
                                const obj = {
                                    refer_code: inviteCode,
                                    lang: this.lang
                                }
                                return api.isInviteCodeValid(obj)
                            } else {
                                return res
                            }
                        })
                        .then((res) => {
                            if (res.data.Success && inviteCode.length > 0) {
                                // correct refer code
                                return firebase.isEmailAvailable(email)
                            } else if (
                                !res.data.Success &&
                                inviteCode.length > 0
                            ) {
                                // wrong refer code
                                throw new Error(res.data.Data.message)
                            } else {
                                return firebase.isEmailAvailable(email)
                            }
                        })
                        .then((res) => {
                            if (res) {
                                return firebase.createUserWithEmail(
                                    email,
                                    password
                                )
                            } else {
                                throw new Error('flow/email_exists')
                            }
                        })
                } else {
                    const body = {
                        id_token: await firebase.getIdToken(),
                        code: passcode,
                        dial_code: dialCode,
                        phone
                    }
                    const res = await api.postMemberVerifyPhoneNumber(body, this.lang, this.region)
                    if (!res.data.Success) {
                        throw new Error(res.data.Data.message)
                    }
                }
                createUserPromise = firebase
                    .createUserWithEmail(email, password)
                    .then((res) => {
                        if (res) {
                            return res
                        } else {
                            throw new Error('flow/error')
                        }
                    })
                createUserPromise
                    .then((res) => {
                        if (res) {
                            // return firebase.getIdToken()
                            return res.user.getIdToken()    // PAT: "res.user.getIdToken()" might be more secure
                        } else {
                            throw new Error('flow/error')
                        }
                    })
                    .then((idToken) => {
                        obj.id_token = idToken
                        obj.fingerprint = visitorData.visitorId
                        const lang = this.lang
                        const region = this.region
                        return api.emailSignup(obj, lang, region)
                    })
                    .then((res) => {
                        if (res.data.Success) {
                            this.$store.dispatch('member/setUserSigned')
                            this.sendSignUpLogEvent('Email Address')
                            this.showOkModal(
                                this.$t('member.register_success'),
                                'goToDashboard'
                            )
                        } else {
                            this.showOkModal(res.data.Data.message)
                            // throw new Error('flow/error')
                        }
                    })
                    .finally(() => {
                        this.signup.vidSending = false
                        this.socialSignup.vidSending = false
                    })
                    .catch((err) => {
                        let message
                        if (err.code === 'auth/invalid-verification-code') {
                            message = this.$t('err_invalid_verification_code')
                        } else if (
                            err.code === 'auth/credential-already-in-use' ||
                            err.code === 'auth/provider-already-linked'
                        ) {
                            message = this.$t('err_phone_number_exists')
                        } else if (err.code === 'auth/invalid-email') {
                            message = this.$t('err_auth_invalid_email')
                        } else {
                            switch (err.message) {
                                case 'flow/email_exists':
                                    message = this.$t('err_email_exists')
                                    break
                                case 'flow/error':
                                    message = this.$t('err_occurred')
                                    break
                                default:
                                    message = err.message
                                    break
                            }
                        }
                        if (message === undefined) {
                            message = this.$t('err_occurred')
                        }
                        this.showOkModal(message)
                    })
            } else {
                this.socialSignup.vidSending = true
                email = this.socialSignup.email
                passcode = this.socialSignup.passcode
                dialCode = this.socialSignup.phoneCode.code
                phone = this.socialSignup.phone
                firstName = this.socialSignup.firstName
                lastName = this.socialSignup.lastName
                gender = this.socialSignup.gender.value
                dob = this.socialSignup.dob.value
                inviteCode = this.socialSignup.inviteCode
                offersLang = this.socialSignup.offersLang.value
                const obj = {
                    email,
                    dial_code: dialCode,
                    phone,
                    dob,
                    surname: lastName,
                    given_name: firstName,
                    gender,
                    info_lang: offersLang,
                    refer_code: inviteCode
                }
                let socialSignupPromise
                if (this.isBypassPhoneRegister) {
                    socialSignupPromise = api
                        .isMemberEmailPhoneExist({
                            email,
                            dial_code: dialCode,
                            phone,
                            lang: this.lang,
                            region: this.region
                        })
                        .then((res) => {
                            if (
                                // email does not exist
                                res.data.Data.state === null ||
                                res.data.Data.state === 1
                            ) {
                                return res
                            } else if (res.data.Data.state === 2) {
                                // email exist
                                throw new Error('flow/email_exists')
                            } else if (res.data.Data.message) {
                                // Both email and phone have been registered by different accounts
                                throw new Error(res.data.Data.message)
                            }
                        })
                        .then((res) => {
                            if (inviteCode.length > 0) {
                                // have refer code
                                const obj = {
                                    refer_code: inviteCode,
                                    lang: this.lang
                                }
                                return api.isInviteCodeValid(obj)
                            } else {
                                return res
                            }
                        })
                        .then((res) => {
                            if (res.data.Success && inviteCode.length > 0) {
                                // correct refer code
                                return firebase.getIdToken()
                            } else if (
                                !res.data.Success &&
                                inviteCode.length > 0
                            ) {
                                // wrong refer code
                                throw new Error(res.data.Data.message)
                            } else {
                                return firebase.getIdToken()
                            }
                        })
                } else {
                    const body = {
                        id_token: await firebase.getIdToken(),
                        code: passcode,
                        dial_code: dialCode,
                        phone
                    }
                    const res = await api.postMemberVerifyPhoneNumber(body, this.lang, this.region)
                    if (res.data.Success) {
                        socialSignupPromise = firebase.getIdToken()
                    } else {
                        throw new Error(res.data.Data.message)
                    }
                }
                socialSignupPromise
                    .then((idToken) => {
                        obj.id_token = idToken
                        obj.fingerprint = visitorData.visitorId
                        const lang = this.lang
                        const region = this.region
                        return api.emailSignup(obj, lang, region)
                    })
                    .then((res) => {
                        if (res.data.Success) {
                            this.$store.dispatch('member/setUserSigned')
                            this.sendSignUpLogEvent(this.socialSignup.social)
                            this.showOkModal(
                                this.$t('member.register_success'),
                                'goToDashboard'
                            )
                        } else {
                            this.showOkModal(res.data.Data.message)
                            // throw new Error('flow/error')
                        }
                    })
                    .finally(() => {
                        this.signup.vidSending = false
                        this.socialSignup.vidSending = false
                    })
                    .catch((err) => {
                        let message
                        if (err.code === 'auth/invalid-verification-code') {
                            message = this.$t('err_invalid_verification_code')
                        } else if (
                            err.code === 'auth/credential-already-in-use'
                        ) {
                            message = this.$t('err_phone_number_exists')
                        } else if (err.code === 'auth/invalid-email') {
                            message = this.$t('err_auth_invalid_email')
                        } else {
                            switch (err.message) {
                                case 'flow/email_exists':
                                    message = this.$t('err_email_exists')
                                    break
                                case 'flow/error':
                                    message = this.$t('err_occurred')
                                    break
                                default:
                                    message = err.message
                                    break
                            }
                        }
                        if (message === undefined) {
                            message = this.$t('err_occurred')
                        }
                        this.showOkModal(message)
                    })
            }
        },
        setDefaultPhoneCode() {
            if (this.region) {
                const country = _filter(countryCode, [
                    'ISO2',
                    this.region?.toUpperCase()
                ])
                if (country) {
                    this.signup.phoneCode.code = country[0].code
                    this.socialSignup.phoneCode.code = country[0].code
                }
            }
        },
        setSmsResendTimer() {
            const countDown = 60
            if (this.currentView === 1) {
                this.signup.count = countDown
                this.signup.smsSent = true
                this.emailTimer = setInterval(() => {
                    this.signup.count = this.signup.count - 1
                    if (this.signup.count <= 0) {
                        clearInterval(this.emailTimer)
                    }
                }, 1000)
            } else {
                this.socialSignup.count = countDown
                this.socialSignup.smsSent = true
                this.socialTimer = setInterval(() => {
                    this.socialSignup.count = this.socialSignup.count - 1
                    if (this.socialSignup.count <= 0) {
                        clearInterval(this.socialTimer)
                    }
                }, 1000)
            }
        },
        dismissResendTimer() {
            this.isSubmitting = false
            if (this.emailTimer) {
                this.signup.smsSent = false
                this.signup.count = 0
                clearInterval(this.emailTimer)
            }
            if (this.socialTimer) {
                this.socialSignup.smsSent = false
                this.socialSignup.count = 0
                clearInterval(this.socialTimer)
            }
        },
        goBackToLoginView() {
            this.socialViewSignout()
            this.selectView(0)
        },
        socialViewSignout() {
            if (this.currentView === 2) {
                firebase.signOut()
            }
        },
        setMaxMinDay() {
            const now = new Date()
            const maxYear = now.getFullYear()
            const minYear = now.getFullYear() - 100
            const month =
                now.getMonth().toString().length === 1
                    ? '0' + (now.getMonth() + 1)
                    : now.getMonth() + 1
            const date =
                now.getDate().toString().length === 1
                    ? '0' + now.getDate()
                    : now.getDate()

            this.date.max = maxYear + '-' + month + '-' + date
            this.date.min = minYear + '-' + month + '-' + date
        },
        sendSignUpLogEvent(method) {
            analysis.sendSignUp(method)
        },
        sendLoginLogEvent(method) {
            analysis.sendLogin(method)
        },
        navigateToRetrieveBooking() {
            this.close()
            const path = `/${this.region}/${this.lang}/retrieve-booking`
            this.navigate(path)
        },
        openDobDropdown(bvEvent) {
            bvEvent.preventDefault()
            this.visible = !this.visible
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: table;
    transition: opacity 0.3s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-container {
    transition: all 300ms;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
}
.full-screen {
    height: 100vh;
    width: 100vw;
}
.content {
    position: relative;
    border-radius: 10px;
}
.close-btn {
    font-size: 22px;
    position: absolute;
    right: -50px;
    top: 0;
    color: #ffffff;
    transform: rotate(45deg);
}
.h-line {
    padding: 0;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0);
}
.h-line-selected {
    padding: 0;
    margin: 0;
    border: 1px solid rgb(242, 188, 31);
}
.v-line {
    padding: 0;
    margin: 0;
    height: 30px;
    border-right: 1px solid rgb(0, 0, 0);
}
.input-field {
    border-radius: 3px;
    border-width: 1px;
    border-color: #ebebeb;
    border-style: solid;
    background-color: #fdfdfd;
}
.input-field-disable {
    border-radius: 3px;
    border-width: 1px;
    border-color: #ebebeb;
    border-style: solid;
    background-color: #eceff0;
    color: #939696 !important;
}
.text-btn {
    color: rgba(242, 188, 31, 0.8);
    text-decoration: underline;
}
.text-btn:hover {
    color: rgb(242, 188, 31);
    text-decoration: none;
}
.text-title {
    color: #d1d1d1;
}
.text-title-selected {
    color: #231f20;
}
.slide-fade-enter-active .content-container {
}
.slide-fade-leave-active .content-container {
}
.slide-fade-enter, .slide-fade-leave-to .content-container
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}

.slide-fadeeeeeee-enter-active,
.slide-fadeeeeeee-leave-active {
    transition: opacity 10s;
}
.slide-fadeeeeeee-enter, .slide-fadeeeeeee-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.phone-code-dropdown >>> .dropdown-menu {
    max-height: 25vh;
    width: 100%;
    overflow-y: auto;
}
.phone-code-dropdown >>> .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.flex-even {
    flex: 1;
}
.fill-width {
    width: -webkit-fill-available;
}
.vdp-datepicker
    >>> .vdp-datepicker__calendar
    .cell:not(.blank):not(.disabled).year:hover {
    border: 1px solid var(--primary) !important;
    border-radius: 500px;
}
.vdp-datepicker
    >>> .vdp-datepicker__calendar
    .cell:not(.blank):not(.disabled).month:hover {
    border: 1px solid var(--primary) !important;
    border-radius: 500px;
}
.vdp-datepicker
    >>> .vdp-datepicker__calendar
    .cell:not(.blank):not(.disabled).day:hover {
    border: 1px solid var(--primary) !important;
    border-radius: 500px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar .cell.selected {
    background: none;
    background-color: var(--primary) !important;
    color: var(--light) !important;
    border-radius: 500px;
}
.vdp-datepicker >>> .vdp-datepicker__calendar .cell.selected:hover {
    background: none;
    background-color: var(--primary) !important;
    color: var(--light) !important;
    border-radius: 500px;
}
@media (max-width: 575.98px) {
    .content {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
    }
    .close-btn {
        font-size: 22px;
        position: unset;
        right: 0;
        top: 0;
        color: #505050;
        transform: rotate(45deg);
    }
}
</style>
