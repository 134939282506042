var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-btn',{staticClass:"book-btn d-flex align-items-center justify-content-center",class:[_vm.buttonClass + ' ' + _vm.buttonPadding + ' ' + `text-${_vm.fontSize}`],attrs:{"disabled":_vm.isLoading || _vm.isDisable,"variant":"transparent"},on:{"click":_vm.buttonClicked}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"position-absolute",attrs:{"variant":"light","small":""}}):_vm._e(),_vm._v(" "),_c('p',{class:`text-${_vm.fontWeight}`,style:(_vm.isLoading
                ? 'color: transparent'
                : _vm.buttonClass === 'btn-white'
                ? 'color: #313131'
                : _vm.buttonClass === 'btn-text-grey'
                ? 'color: #afafaf'
                : 'color: #ffffff')},[_vm._v("\n        "+_vm._s(_vm.bookButtonText)+"\n    ")]),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }