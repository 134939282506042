<template>
    <div class="channel-wrapper">
        <div class="d-flex flex-wrap">
            <div
                v-for="(item, index) in paymentIcons"
                :key="index"
                class="d-flex align-items-center"
            >
                <ImageContainer
                    :src="item.icon"
                    isResize
                    tail="payicon"
                    target="public"
                    :alt="item.name"
                    class="p-0 payment-icon mr-3 mb-3"
                />
            </div>
        </div>
    </div>
</template>
<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'PaymentChannels',
    components: {ImageContainer},
    async fetch() {
        await this.getPaymentIcons()
    },
    computed: {
        paymentIcons() {
            return this.$store.getters['api/paymentIcons']
        }
    },
    watch: {
        globalIsSigned(newVal) {
            if (newVal !== null) {
                this.getPaymentIcons()
            }
        },
        paramsLangRegion(newVal) {
            if (newVal !== null) {
                this.getPaymentIcons()
            }
        }
    },
    async mounted() {
        await this.getPaymentIcons()
    },
    methods: {
        async getPaymentIcons() {
            // eslint-disable-next-line standard/computed-property-even-spacing
            const isLocaleValid = this.$store.state.localeRegion[
                this.paramsRegion
            ]?.includes(this.paramsLang)
            if (isLocaleValid) {
                await this.$store.dispatch('api/GET_PAYMENT_ICONS', {
                    lang: this.paramsLang,
                    region: this.paramsRegion
                })
            }
        }
    }
}
</script>

<style scoped>
.payment-icon {
    height: 26px;
    width: 40px;
    object-fit: cover;
    border-radius: 3px;
}
@media (min-width: 992px) {
    .channel-wrapper {
        max-width: 300px;
    }
}
</style>
