<template>
    <img
        :src="imagePath"
        :alt="customImgAlt"
        :loading="loading"
        :height="height"
        :width="width"
        class="image"
        @click="handleImageClick"
    />
</template>
<script>

import processImage from "@/services/processImage"

export default {
    name: 'ImageContainer',
    props: {
        alt: {
            type: String,
            required: true
        },
        src: {
            type: String,
            required: true
        },
        isResize: {
            type: Boolean,
            default: false
        },
        tail: {
            type: String,
            default: 'public'
        },
        target: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: ''
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },
    data() {
        return {
            customImgAlt: '',
        }
    },
    computed: {
        imagePath() {
            if (this.isResize) {
                return this.resizeImage(this.src, this.tail, this.target)
            } else {
                return this.src
            }
        }
    },
    async fetch() {
      await this.setCustomImgAlt()
    },
    async mounted() {
        await this.setCustomImgAlt()
    },
    methods: {
        async setCustomImgAlt() {
            try {
                const isValidRoute =
                    this.$route.name === 'region-locale'
                    || this.$route.name === 'region-locale-spaceName-space'
                const isDevelopmentMode = window.location.hostname === 'localhost'
                if (this.src.includes('https://cdn.flowtheroom.com/') && isValidRoute && !isDevelopmentMode){
                    const imgUrl = this.src.replace('https://cdn.flowtheroom.com/', 'https://cdns.flowtheroom.com/');
                    this.customImgAlt = await processImage.getImageAltFromImgUrl(imgUrl, this.globalAlgoliaLang) || this.alt;
                } else {
                    this.customImgAlt = this.alt;
                }
            } catch (error) {
                this.customImgAlt = this.alt; // Fallback to default alt text in case of error
            }
        },
        handleImageClick() {
            this.$emit('handleImageClick')
        }
    }
}
</script>
<style scoped></style>
