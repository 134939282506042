const FETCH_STATE = {
    SPACE: 'FETCH_STATE_SPACE',
    NEARBY: 'FETCH_STATE_NEARBY',
    AREA: 'FETCH_STATE_AREA',
    DISTRICT: 'FETCH_STATE_DISTRICT',
    META_DISTRICT: 'FETCH_STATE_META_DISTRICT',
    CITY: 'FETCH_STATE_CITY',
    STATE: 'FETCH_STATE_STATE',
    AVAILABLE: 'FETCH_STATE_AVAILABLE',
    ALTERNATIVE: 'FETCH_STATE_ALTERNATIVE',
    UNAVAILABLE: 'FETCH_STATE_UNAVAILABLE',
    DONE: 'FETCH_STATE_DONE',
    FALLBACK_ANYTIME: 'FETCH_STATE_FALLBACK_ANYTIME',
    FALLBACK_ASAP_TIME: 'FETCH_STATE_FALLBACK_ASAP_TIME',
    FALLBACK_EXACT_TIME: 'FETCH_STATE_EXACT_TIME'
}
/** For website ONLY to store the source as query parameter */
const QUERY_SOURCE = {
    SUGGESTION: 'qs',
    CURRENT_LOCATION: 'cl',
    SEARCH_HISTORY: 'sh',
    POPULAR_SEARCH: 'ps',
    USER_INPUT: 'ui',
    EMPTY_SEARCH: 'es',
    FAST_SEARCH_BUTTON: 'fsb',
    MAGIC_SEARCH_BUTTON: 'msb'
}

const ANALYTICS_TAGS = {
    SUGGESTION: 'query_suggestion',
    CURRENT_LOCATION: 'current_location',
    SEARCH_HISTORY: 'search_history',
    POPULAR_SEARCH: 'popular_search',
    USER_INPUT: 'user_input',
    EMPTY_SEARCH: 'empty_search',
    FAST_SEARCH_BUTTON: 'fast_search_button',
    POPULAR_LOCATION_MOBILE: 'popular_location_mobile',
    MAGIC_SEARCH_BUTTON: 'magic_search_button'
}

const SEARCH = {
    GALLERY_IMAGE_COUNT: 3,
    TEST_TAG_DOMAIN: '@flowtheroom.com',
    VIEWED_EVENT_BATCH_SIZE: 20,
    HITS_PER_PAGE_BATCH_MOBILE: 6,
    HITS_PER_PAGE_BATCH_WEB: 30,
    HITS_PER_PAGE_SUGGESTION: 20,
    HITS_PER_PAGE_FULL: 100,
    ITEMS_PER_WORKER: 6,
    AROUND_RADIUS_METER: 5000,
    REST_BOOK_TYPES: ['hour', 'session', 'overnight'],
    MEET_WORK_BOOK_TYPES: ['hourly', 'day'],
    PARTY_BOOK_TYPES: ['hourly'],
    INCLUDE_FIELDS: [
        'slug',
        '_id',
        'name',
        'location.area',
        'location.district',
        'location.country',
        'location.city',    // dev ONLY, for verifying exact city search
        'location.state',   // dev ONLY, for verifying exact state search
        '_geoloc',
        '_tags',
        'tags',
        'images',
        'ranking',
        'config',
        'id',
        'top_comments',
        'top_comments_average_rating',
        'status.min_prices',
        'frame_style',
    ],
    INCLUDE_FIELDS_SORT_INDEX: ['id', 'tags', '_id', 'ranking.last_reservation_timestamp'],
    EMPTY_RESULT: {
        searchResults: {
            hits: [],
            found: 0,
            nbPages: 0
        },
        pageResults: {
            hasNext: false,
            nextPage: 0
        },
        workerResults: []
    },
    EVAL_PLACEHOLDER: '_eval()'
}

const EXACT_SEARCH_TYPE = {
    TYPE_EXACT_SPACE_SEARCH: 'space',
    TYPE_EXACT_AREA_SEARCH: 'area',
    TYPE_EXACT_DISTRICT_SEARCH: 'district',
    TYPE_EXACT_META_DISTRICT_SEARCH: 'mdistrict',
    TYPE_EXACT_CITY_SEARCH: 'city',
    TYPE_EXACT_STATE_SEARCH: 'state'
}

const FALLBACK_SEARCH_TYPE = {
    TYPE_ANY_TIME: 'any_time',
    TYPE_ASAP_TIME: 'asap_time',
    TYPE_EXACT_TIME: 'exact_time'
}

const DATE_TIME = {
    ANY_CHECK_IN_TIME: 'any_time',
    ASAP_CHECK_IN_TIME: 'asap_time',
    ASAP_CHECK_IN_TIME_N: 'asap_time_n',
    END_OF_DAY: '23:59',
    MILLIS_ONE_MINUTE: 60000,
    INTERVAL_MINUTE: 15,
    MIDNIGHT_TIME: 'midnight_time',
    MIDNIGHT_TIME_START: '00:00',
    MIDNIGHT_TIME_END: '05:45',
    MORNING_TIME: 'morning_time',
    MORNING_TIME_START: '06:00',
    MORNING_TIME_END: '11:45',
    AFTERNOON_TIME: 'afternoon_time',
    AFTERNOON_TIME_START: '12:00',
    AFTERNOON_TIME_END: '17:45',
    EVENING_TIME: 'evening_time',
    EVENING_TIME_START: '18:00',
    EVENING_TIME_END: '23:45',
    AFTER_X_TIME: 'after_x_time',
    AFTER_MIDNIGHT_TIME: 'after_midnight_time'
}

const VERSIONS = {
    /** 20230926
     * 1. Returned Instant Confirmation and Free Cancellation in space_tags
     */
    WEB_1_0_0: '1.0.0-web',
    /** 20240117
     * 1. Returned parent start time for period session availability
     */
    WEB_1_0_1: '1.0.1-web',
    /** 20230926
     * 1. Returned Instant Confirmation and Free Cancellation in space_tags
     */
    APP_1_0_0: '1.0.0-app',
}

export {
    FETCH_STATE,
    QUERY_SOURCE,
    ANALYTICS_TAGS,
    SEARCH,
    EXACT_SEARCH_TYPE,
    FALLBACK_SEARCH_TYPE,
    DATE_TIME,
    VERSIONS
}
