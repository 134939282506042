<template>
    <transition name="fade">
        <nav class="navbar fixed-bottom navbar-dark bg-dark py-4 px-4">
            <div
                class="d-flex justify-content-between w-100"
                style="pointer-events: auto"
            >
                <div class="text-reg text-light text-15">
                    {{ $t('home.gdrp_warn') }}
                    <NuxtLink
                        :to="`/${region}/${lang}/privacy-policy`"
                        class="text-mid text-15 text-primary"
                    >
                        {{ $t('home.gdrp_more') }}
                    </NuxtLink>
                </div>
                <b-button
                    variant="transparent"
                    aria-label="close button"
                    class="p-0 d-flex"
                    @click="close"
                >
                    <Close class="close-btn" />
                </b-button>
            </div>
        </nav>
    </transition>
</template>

<script>
import Close from '~/components/iconSvg/Close.vue'

export default {
    name: 'GdrpSnackBar',
    components: { Close },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.35s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.close-btn {
    height: 18px;
    width: 18px;
    fill: #ffffff;
}
.close-btn:hover {
    color: #f2bc1f;
}
</style>
