import axios from 'axios'

const create = (baseURL = process.env.API_BASE_URL + '/api/web/') => {
    const CancelToken = axios.CancelToken
    let cancel

    const api = axios.create({
        // base URL is read from the "constructor"
        baseURL,
        // here are some default headers
        headers: {
            'Cache-Control': 'no-cache',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Credentials': true,
            Pragma: 'no-cache',
            Expires: '0'
        },
        // 30 second timeout
        timeout: 30000,
        // retry 3 times if request failed
        retry: 3,
        retryDelay: 3000
    })

    api.interceptors.request.use(
        (config) => {
            // TODO cancel duplicated HTTP requests: should cover all api(an error occurred on details page)
            if (config.url === '/search') {
                if (cancel) {
                    cancel()
                }

                config.cancelToken = new CancelToken(function executor(c) {
                    cancel = c
                })
            }

            return config
        },
        (error) => {
            Promise.reject(error)
        }
    )
    api.interceptors.response.use(
        (config) => {
            return config
        },
        (error) => {
            const { config, message } = error
            if (!config || !config.retry) {
                return Promise.reject(error)
            }
            // retry while Network timeout or Network Error
            if (
                !(
                    message.includes('timeout') ||
                    message.includes('Network Error')
                )
            ) {
                return Promise.reject(error)
            }
            config.retry -= 1
            const delayRetryRequest = new Promise((resolve) => {
                setTimeout(() => {
                    // console.log('retry the request', config.url)
                    resolve()
                }, config.retryDelay || 1000)
            })
            return delayRetryRequest.then(() => api(config))
        }
    )
    const getHomeInfo = (params) => {
        const url = `v4/index/home`
        return api.get(url, {
            params
        })
    }
    const getSubLanding = (urlType, params) => {
        const url = `v4/index/sublanding/${urlType}`
        return api.get(url, {
            params
        })
    }
    const getMagicButtons = (params) => {
        const url = `v4/index/buttons/magic/${params.space_type}`
        return api.get(url, {
            params
        })
    }
    const getSlugList = (params) => {
        const url = 'v4/space/list'
        return api.get(url, {
            params
        })
    }
    const saveCurrentLangRegion = (body, lang, region, group) => {
        let url = `v4/index/setting?lang=${lang}&region=${region}`
        if (group) {
            url += `&group=${group}`
        }
        return api.put(url, body)
    }
    /* TODO: Confirm NOT in use
    // didn't use?
    const getDefaultSetting = (params) => {
        const url = 'v4/index/setting'
        return api.get(url, {
            params
        })
    }
    */
    const getListSearchRes = (params) => {
        const url = `v4/space/${params.space_type}/search`
        return api.get(url, {
            params
        })
    }
    const getSearchBarSetting = (params) => {
        const url = `v4/space/${params.space_type}/available/search`
        return api.get(url, {
            params
        })
    }
    // const getDetailsResgetDetailsRes = (params, spaceType) => {
    //     const url = `v3/space/${spaceType}/details`
    //     return api.get(url, {
    //         params
    //     })
    // }
    const getSpaceDetailsRes = (params, spaceType, slug) => {
        const url = `v4/space/${spaceType}/${slug}/details`
        return api.get(url, {
            params
        })
    }
    const getSpaceReviewDetails = (params, spaceType, slug) => {
        const url = `v4/review/${spaceType}/${slug}/list`
        return api.get(url, { params })
    }
    const getVenueDetailsRes = (params, spaceType, slug, venueType) => {
        const url = `v4/space/${spaceType}/${slug}/venue/${venueType}/details`
        return api.get(url, {
            params
        })
    }
    const getVenueRateDetailsRes = (params, spaceType, slug, venueType) => {
        const url = `v4/reservation/${spaceType}/${slug}/venue/${venueType}/details`
        return api.get(url, {
            params
        })
    }
    const getDetailsBookLengthRes = (params, spaceType, slug) => {
        const url = `v4/reservation/${spaceType}/${slug}/book_type/available`
        return api.get(url, {
            params
        })
    }
    const getDetailsCheckInRes = (params, spaceType, slug) => {
        const url = `v4/reservation/${spaceType}/${slug}/checkin/available`
        return api.get(url, {
            params
        })
    }
    const getDetailsCheckOutRes = (params, spaceType, slug) => {
        const url = `v4/reservation/${spaceType}/${slug}/checkout/available`
        return api.get(url, {
            params
        })
    }
    const getReservationCheckOutDetailsRes = (params, spaceType) => {
        const url = `v4/reservation/${spaceType}/checkout/details`
        return api.get(url, {
            params
        })
    }
    const getDetailsRoomRes = (params, spaceType, slug) => {
        const url = `v4/reservation/${spaceType}/${slug}/venue/available`
        return api.get(url, {
            params
        })
    }

    // const getRefundTerms = (params) => {
    //     const url = 'v3/misc/terms/refund'
    //     return api.get(url, {
    //         params
    //     })
    // }

    // const makeReservation = (params, spaceType) => {
    //     const url = `v3/reservation/${spaceType}/summary`
    //     return api.get(url, {
    //         params
    //     })
    // }
    const rejectAndRefund = (body, lang, region) => {
        const url = `v4/reservation/cancel?lang=${lang}`
        return api.put(url, body)
    }
    // const getPriceStatement = (params, spaceType) => {
    //     const url = `v3/reservation/${spaceType}/price`
    //     return api.get(url, {
    //         params
    //     })
    // }
    const getReservationDetails = (params, bookingId) => {
        const url = `v4/reservation/${bookingId}/details`
        return api.get(url, {
            params
        })
    }

    const getCardList = (params) => {
        const url = 'v4/payment/card/list'
        return api.get(url, {
            params
        })
    }

    const getPaymentMethodList = (spaceType, params) => {
        const url = `v4/payment/${spaceType}/gateway`
        return api.get(url, {
            params
        })
    }

    const adyenPaymentDetails = (body, lang, region) => {
        const url = `v4/payment/adyen/details?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const validateAdyenApplePaySession = (body, lang, region) => {
        const url = `v4/payment/adyen/applepay/session?lang=${lang}&region=${region}`
        return api.post(url, body)
    }
    const checkPaymentStatus = (body, lang, region) => {
        const url = `v4/reservation/status?lang=${lang}&region=${region}`
        return api.put(url, body)
    }
    // const checkMPayPaymentStatus = (params) => {
    //     const url = 'v3/payment/mpay/status'
    //     return api.get(url, {
    //         params
    //     })
    // }
    // const checkPayMePaymentStatus = (params) => {
    //     const url = 'v3/payment/payme/status'
    //     return api.get(url, {
    //         params
    //     })
    // }
    const notCompletedReservationBasicInfo = (params) => {
        const url = 'v4/reservation/basic_info'
        return api.get(url, {
            params
        })
    }

    const postUploadResvProof = (body, lang, region) => {
        const url = `v4/party/proof/upload?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const makePayment = (
        spaceType,
        body,
        lang,
        region,
        isWalkIn,
        device,
        os,
        group
    ) => {
        let url = `v4/payment/${spaceType}/charge?lang=${lang}&region=${region}&is_walkin=${isWalkIn}&device=${device}&os=${os}`
        if (group) {
            url += `&group=${group}`
        }
        return api.post(url, body, { timeout: 60000 })
    }

    const checkBookable = (params) => {
        const url = 'v4/reservation/check/bookable'
        return api.get(url, {
            params
        })
    }

    const checkSameDayDuplicateBookings = (params) => {
        const url = 'v4/reservation/check/duplicates'
        return api.get(url, {
            params
        })
    }

    const checkMemberInput = (params, spaceType) => {
        const url = `v4/reservation/${spaceType}/checkout/info_put`
        return api.get(url, {
            params
        })
    }

    const checkTapNGoCard = (body, lang, region) => {
        const url = `v4/payment/adyen/tapngo/check?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const checkWeWaCard = (body, lang, region) => {
        const url = `v4/payment/adyen/wewa/check?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const checkTrustBankCard = (body, lang, region) => {
        const url = `v4/payment/adyen/trustbank/check?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const checkHSBCCard = (body, lang, region) => {
        const url = `v4/payment/adyen/hsbc/check?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const getMemberDashBroadInfo = (params) => {
        const url = 'v4/member/profile'
        return api.get(url, {
            params
        })
    }

    const getMembershipDetails = (params) => {
        const url = 'v4/member/membership/details'
        return api.get(url, {
            params
        })
    }

    const getMemberMessage = (params) => {
        const url = 'v4/member/message/list'
        return api.get(url, {
            params
        })
    }

    const getMessageContent = (params, messageRecipientId) => {
        const url = `v4/member/message/${messageRecipientId}/content`
        return api.get(url, {
            params
        })
    }

    const getMemberVoucher = (params) => {
        const url = 'v4/member/voucher/list'
        return api.get(url, {
            params
        })
    }

    const getRewardHistory = (params, spaceType) => {
        const url = `v4/member/reward/${spaceType}/history`
        return api.get(url, {
            params
        })
    }

    const hideRewardHistory = (body, lang, region) => {
        const url = `v4/member/reward/history/hide?lang=${lang}&region=${region}`
        return api.put(url, body)
    }

    const getMyBookings = (params, type) => {
        const url = `v4/reservation/${type}/list`
        return api.get(url, {
            params
        })
    }

    const getReferCodeDetails = (params) => {
        const url = 'v4/member/refer_code'
        return api.get(url, {
            params
        })
    }

    const getMemberProfile = (params) => {
        const url = 'v4/member/setting/profile'
        return api.get(url, {
            params
        })
    }
    const updateMemberProfile = (body, lang, region) => {
        const url = `v4/member/setting/profile?lang=${lang}&region=${region}`
        return api.put(url, body)
    }

    const getFlowDollarInfo = (params) => {
        const url = 'v4/member/dollar/history'
        return api.get(url, {
            params
        })
    }

    const getFlowDollarPackageList = (params) => {
        const url = 'v4/payment/dollar/package/list'
        return api.get(url, {
            params
        })
    }

    const topupCredit = (body, lang, region, device, os) => {
        const url = `v4/payment/dollar/topup?lang=${lang}&region=${region}&device=${device}&os=${os}`
        return api.post(url, body)
    }

    const addVoucher = (body, lang, region) => {
        const url = `v4/member/voucher/redeem?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const getMemberCards = (params) => {
        const url = 'v4/payment/card/list'
        return api.get(url, {
            params
        })
    }

    const addCard = (body, lang, region) => {
        const url = `v4/payment/card/add?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const removeCard = (body, lang, region) => {
        const url = `v4/payment/card/disable?lang=${lang}&region=${region}`
        return api.put(url, body)
    }

    const setDefaultCard = (body, lang, region) => {
        const url = `v4/payment/card/default?version=300&os=ios&lang=${lang}&region=${region}`
        return api.put(url, body)
    }

    const isMemberEmailPhoneExist = (params) => {
        const url = 'v4/member/email_phone/valid'
        return api.get(url, {
            params
        })
    }

    const isMemberEmailExist = (params) => {
        const url = 'v4/member/email/valid'
        return api.get(url, {
            params
        })
    }

    const emailSignup = (body, lang, region) => {
        const url = `v4/member/register?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const checkSocialAuthState = (body, lang, region) => {
        const url = `v4/member/register/social/authenticated?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const getAvailableVoucher = (spaceType, params) => {
        const url = `v4/reservation/${spaceType}/checkout/voucher/list`
        return api.get(url, {
            params
        })
    }

    // handle in checkout page
    // const applyVoucher = (spaceType, body, lang, region) => {
    //     const url = `v3/reservation/${spaceType}/voucher?lang=${lang}&region=${region}`
    //     return api.put(url, body)
    // }

    const getPriceUpdate = (params, spaceType) => {
        const url = `v4/reservation/${spaceType}/checkout/price`
        return api.get(url, {
            params
        })
    }

    const sendVerifyCode = (body, lang, region) => {
        const url = `v4/reservation/verify/code?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const verifyVerificationCode = (body, lang, region) => {
        const url = `v4/reservation/verify?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const verifyAndSearchReservation = (body, lang, region) => {
        const url = `v4/reservation/retrieve/verify?lang=${lang}&region=${region}`
        return api.put(url, body)
    }

    const retrieveReservation = (body, lang, region) => {
        const url = `v4/reservation/retrieve?lang=${lang}&region=${region}`
        return api.put(url, body)
    }

    const isInviteCodeValid = (params) => {
        const url = 'v4/member/refer_code/valid'
        return api.get(url, {
            params
        })
    }

    const postMemberSendPhoneNumber = (body, lang, region) => {
        const url = `v4/member/verify/code?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const postMemberVerifyPhoneNumber = (body, lang, region) => {
        const url = `v4/member/verify?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    // support?
    const contactFlow = (body, lang, region) => {
        const url = `v4/support/contact_us?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const activateMember = (body, lang) => {
        const url = `v4/member/register/activate?os=ios&lang=${lang}`
        return api.put(url, body)
    }

    const checkRedeemCode = (body, lang, region) => {
        const url = `v4/member/dollar/redeem?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const getAffiliateSiteInfo = (domain, lang, region) => {
        const url = `v4/misc/referral/site?refer_domain=${encodeURIComponent(
            domain
        )}&lang=${lang}&region=${region}`
        return api.get(url)
    }
    const getFaqContent = (params) => {
        const url = 'v4/support/faq'
        return api.get(url, { params })
    }
    const checkBookingCancelable = (params) => {
        const url = `v4/support/reservation/cancellable`
        return api.get(url, { params })
    }
    const sendEnquiry = (body, lang, region) => {
        const url = `v4/support/enquiry?lang=${lang}&region=${region}`
        return api.post(url, body)
    }
    const getCancellationTerms = (bookingId, params) => {
        const url = `v4/reservation/${bookingId}/cancellation/terms`
        return api.get(url, { params })
    }
    const postPriceGuaranteeProof = (body, lang, region) => {
        const url = `v4/support/proof/upload?lang=${lang}&region=${region}`
        return api.post(url, body)
    }
    const sendPriceGuaranteeEnquiry = (body, lang, region) => {
        const url = `v4/support/enquiry/bpg?lang=${lang}&region=${region}`
        return api.post(url, body)
    }

    const getAvailableEnquiryMethods = (idToken, bookingId, lang, region) => {
        const url = `v4/support/enquiry/reservation?id_token=${idToken}&booking_id=${bookingId}&lang=${lang}&region=${region}`
        return api.get(url)
    }
    const postUploadCancellationProof = (body, lang, region) => {
        const url = `v4/support/proof/upload?lang=${lang}&region=${region}`
        return api.post(url, body)
    }
    const getLocationPageList = (spaceType, params) => {
        const url = `v4/location/${spaceType}/list`
        return api.get(url, { params })
    }
    const getLocationDetails = (spaceType, slug, params) => {
        const url = `v4/location/${spaceType}/${slug}/details`
        return api.get(url, { params })
    }
    const getSpaceMinimumRates = (idToken, spaceType, slug, lang, region) => {
        const url = `v4/misc/${spaceType}/${slug}/price?lang=${lang}&region=${region}&id_token=${idToken}`
        return api.get(url)
    }
    const getWebToAppOffer = (idToken, lang, region) => {
        const url = `v4/offer/web_to_app/details?lang=${lang}&region=${region}&id_token=${idToken}`
        return api.get(url)
    }
    const getCardBrandList = (lang, region, action) => {
        let url = `v4/payment/card/gateway?lang=${lang}&region=${region}`
        if (action) {
            url += `&action=${action}`
        }
        return api.get(url)
    }
    const checkAddCardStatus = (region, lang, idToken, tempId, sequence) => {
        const url = `v4/payment/card/status?region=${region}&lang=${lang}&id_token=${idToken}&temp_id=${tempId}&sequence=${sequence}`
        return api.get(url)
    }

    const getCMSPosts = (type, params) => {
        const url = `v4/misc/cms/posts/${type}`
        return api.get(url, { params })
    }

    const postEmailAddressByOAuthToken = (region, lang, credential) => {
        const url = `v4/member/oauth/access_token?region=${region}&lang=${lang}`
        return api.post(url, {
            sign_in_method: credential.signInMethod,
            access_token: credential.accessToken
        })
    }
    return {
        getHomeInfo,
        getSubLanding,
        getMagicButtons,
        getSlugList,
        // saveCurrentRegion,
        saveCurrentLangRegion,
        getListSearchRes,
        getSearchBarSetting,
        // getDetailsRes,
        getSpaceDetailsRes,
        getSpaceReviewDetails,
        getVenueDetailsRes,
        getVenueRateDetailsRes,
        getDetailsBookLengthRes,
        getDetailsCheckInRes,
        getDetailsCheckOutRes,
        getReservationCheckOutDetailsRes,
        getDetailsRoomRes,
        // getRefundTerms,
        // makeReservation,
        rejectAndRefund,
        // getPriceStatement,
        getCardList,
        getPaymentMethodList,
        adyenPaymentDetails,
        validateAdyenApplePaySession,
        // checkMPayPaymentStatus,
        // checkPayMePaymentStatus,
        checkPaymentStatus,
        notCompletedReservationBasicInfo,
        postUploadResvProof,
        makePayment,
        checkBookable,
        checkSameDayDuplicateBookings,
        checkMemberInput,
        checkTapNGoCard,
        checkWeWaCard,
        checkTrustBankCard,
        checkHSBCCard,
        getReservationDetails,
        getMemberDashBroadInfo,
        getMembershipDetails,
        getMemberMessage,
        getMessageContent,
        getMemberVoucher,
        getRewardHistory,
        hideRewardHistory,
        getMyBookings,
        getReferCodeDetails,
        getMemberProfile,
        updateMemberProfile,
        getFlowDollarInfo,
        getFlowDollarPackageList,
        topupCredit,
        addVoucher,
        getMemberCards,
        addCard,
        removeCard,
        setDefaultCard,
        isMemberEmailPhoneExist,
        isMemberEmailExist,
        checkSocialAuthState,
        emailSignup,
        getAvailableVoucher,
        // applyVoucher,
        getPriceUpdate,
        sendVerifyCode,
        verifyVerificationCode,
        verifyAndSearchReservation,
        retrieveReservation,
        isInviteCodeValid,
        postMemberSendPhoneNumber,
        postMemberVerifyPhoneNumber,
        contactFlow,
        activateMember,
        checkRedeemCode,
        getAffiliateSiteInfo,
        getFaqContent,
        checkBookingCancelable,
        sendEnquiry,
        getCancellationTerms,
        postPriceGuaranteeProof,
        sendPriceGuaranteeEnquiry,
        getAvailableEnquiryMethods,
        postUploadCancellationProof,
        getLocationPageList,
        getLocationDetails,
        getSpaceMinimumRates,
        getWebToAppOffer,
        getCardBrandList,
        checkAddCardStatus,
        getCMSPosts,
        postEmailAddressByOAuthToken
    }
}

const api = create()

export default {
    create,
    api
}
