<template>
    <div>
        <b-container
            fluid
            class="py-3 d-none d-lg-block"
            style="background-color: black;"
        >
            <div
                class="footer-container"
                style="background-color: black; margin-left: auto;margin-right: auto; width: 60%"
            >
                <div
                    class="d-flex footer-bottom d-lg-flex py-5 d-lg-none justify-content-between"
                    style="background-color: black; margin-left: auto;margin-right: auto; border-bottom: 1px solid hsla(0,0%,100%,.1);"
                >
                    <div class="d-flex flex-column">
                        <p
                            class="mx-1 text-white text-16 text-raleway-reg mb-3 font-weight-bold"
                        >
                            {{ $t('footer.explore_more') }}
                        </p>
                        <NuxtLink
                            v-show="isShowStudentDiscountsLink(`${region}-${lang}`)"
                            :to="toLink(studentDiscounts[`${region}-${lang}`])"
                            class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                        >
                            {{ $t('footer.student_discounts') }}
                        </NuxtLink>
                        <NuxtLink
                            v-show="isShowAffiliateLink(`${region}-${lang}`)"
                            :to="toLink(affiliate[`${region}-${lang}`])"
                            class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                        >
                            {{ $t('footer.affiliate') }}
                        </NuxtLink>
                        <NuxtLink
                            v-for="(item, index) in exploreMore"
                            v-show="isShowLink(item.link)"
                            :key="index"
                            :to="toLink(item.link)"
                            class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                        >
                            {{ $t(item.name) }}
                        </NuxtLink>
                    </div>
                    <div>
                        <FooterDistrictLink v-show="isShowLink('district')" />
                        <NuxtLink
                            v-if="miniSite[region]"
                            :to="toLink(miniSite[region].link)"
                            class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0 d-block"
                        >
                            {{ $t(miniSite[region].name) }}
                        </NuxtLink>
                    </div>
                    <div class="d-flex flex-column">
                        <p
                            class="mx-1 text-white text-16 text-raleway-reg mb-3 font-weight-bold"
                        >
                            {{ $t('footer.about_flow') }}
                        </p>
                        <NuxtLink
                            :to="toLink('about')"
                            class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                        >
                            {{ $t('footer.about_us') }}
                        </NuxtLink>
                        <NuxtLink
                            :to="toLink('contact')"
                            class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                        >
                            {{ $t('footer.contact_us') }}
                        </NuxtLink>
                        <NuxtLink
                            :to="toLink('faq')"
                            class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                        >
                            {{ $t('footer.faq') }}
                        </NuxtLink>
                        <NuxtLink
                            :to="toLink('blog')"
                            class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                        >
                            {{ $t('blog.header') }}
                        </NuxtLink>
                        <NuxtLink
                            :to="toLink('app')"
                            class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                        >
                            {{ $t('footer.download_title') }}
                        </NuxtLink>
                        <NuxtLink
                            v-show="lang === 'en'"
                            :to="toLink('sitemap')"
                            class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                        >
                            {{ $t('footer.sitemap') }}
                        </NuxtLink>
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                        <div class="d-flex flex-column">
                            <p
                                class="mx-1 text-white text-16 text-raleway-reg mb-3 font-weight-bold"
                            >
                                {{ $t('footer.terms') }}
                            </p>
                            <NuxtLink
                                :to="toLink('privacy-policy')"
                                class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                                rel="nofollow"
                            >
                                {{ $t('footer.privacy') }}
                            </NuxtLink>
                            <NuxtLink
                                :to="toLink('terms-of-use')"
                                class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                                rel="nofollow"
                            >
                                {{ $t('footer.terms') }}
                            </NuxtLink>
                            <NuxtLink
                                :to="toLink('best-price-guarantee')"
                                class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                                rel="nofollow"
                            >
                                {{ $t('footer.best_price_guarantee') }}
                            </NuxtLink>
                            <a
                                v-show="region === 'vn'"
                                :href="operationRegulations"
                                target="_blank"
                                rel="noreferrer noopener nofollow"
                                aria-label="operationRegulations"
                                class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                            >
                                {{ $t('footer.operation_regulations') }}
                            </a>
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-between">
                        <div v-show="isShowLink('payment-icons')">
                            <p
                                class="text-white text-15 text-raleway-reg mb-3 font-weight-bold"
                            >
                                {{ $t('footer.payment_methods') }}
                            </p>
                            <PaymentChannels class="mt-4" />
                        </div>
                        <div class="d-flex">
                            <a
                                :href="urlLink('facebook')"
                                @click="sendPageTrack(urlLink('facebook'))"
                                class="mr-4"
                                target="_blank"
                                rel="noreferrer noopener nofollow"
                                aria-label="facebook"
                            >
                                <Facebook class="social-media-icon" />
                            </a>
                            <a
                                :href="linkedinUrl"
                                @click="sendPageTrack(linkedinUrl)"
                                class="mr-4"
                                target="_blank"
                                rel="noreferrer noopener nofollow"
                                aria-label="linkedin"
                            >
                                <Linkedin class="social-media-icon" />
                            </a>
                            <a
                                :href="urlLink('instagram')"
                                @click="sendPageTrack(urlLink('instagram'))"
                                class="mr-4"
                                target="_blank"
                                rel="noreferrer noopener nofollow"
                                aria-label="instagram"
                            >
                                <Instagram class="social-media-icon" />
                            </a>
                            <a
                                v-if="region === 'sg' || region === 'my'"
                                :href="urlLink('tiktok')"
                                @click="sendPageTrack(urlLink('tiktok'))"
                                target="_blank"
                                rel="noreferrer noopener nofollow"
                                aria-label="tiktok"
                            >
                                <TikTok class="social-media-icon" />
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    class="my-5 d-flex align-items-center justify-content-center"
                >
                    <ImageContainer
                        :src="flowCircle"
                        fluid
                        class="align-self-center mr-3"
                        alt="flow circle"
                        height="40"
                        width="40"
                    />
                    <div class="d-flex">
                        <div class="d-flex align-items-center">
                            <p
                                class="mx-1 text-14 text-raleway-reg text-center sitemap-link"
                            >
                                {{ 'Copyright ' + currentYear }}
                            </p>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="mx-3 sitemap-link text-18">|</div>
                        </div>
                        <p
                            class="mx-1 text-14 text-raleway-reg text-center d-flex align-items-center sitemap-link"
                        >
                            {{ copyrightName }}
                        </p>
                    </div>
                    <div></div>
                </div>
            </div>
        </b-container>
        <!--            mobile size-->
        <div class="d-lg-none bg-dark py-3 px-4">
            <div class="d-flex mt-3 mb-4 pb-3 px-1">
                <div>
                    <a
                        :href="urlLink('facebook')"
                        @click="sendPageTrack(urlLink('facebook'))"
                        class="icon facebook"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                        aria-label="facebook"
                    >
                        <Facebook class="social-media-icon" />
                    </a>
                    <a
                        :href="linkedinUrl"
                        @click="sendPageTrack(linkedinUrl)"
                        class="icon instagram"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                        aria-label="linkedin"
                    >
                        <Linkedin class="social-media-icon" />
                    </a>
                    <a
                        :href="urlLink('instagram')"
                        @click="sendPageTrack(urlLink('instagram'))"
                        class="icon linkedin"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                        aria-label="instagram"
                    >
                        <Instagram class="social-media-icon" />
                    </a>
                    <a
                        v-if="region === 'sg' || region === 'my'"
                        :href="urlLink('tiktok')"
                        @click="sendPageTrack(urlLink('tiktok'))"
                        class="text-white"
                        target="_blank"
                        rel="noreferrer noopener nofollow"
                        aria-label="tiktok"
                    >
                        <TikTok class="social-media-icon" />
                    </a>
                </div>
            </div>
            <div class="d-flex flex-column mb-4">
                <p
                    class="mx-1 text-white text-16 text-raleway-reg mb-3 font-weight-bold"
                >
                    {{ $t('footer.about_flow') }}
                </p>
                <NuxtLink
                    :to="toLink('about')"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                >
                    {{ $t('footer.about_us') }}
                </NuxtLink>
                <NuxtLink
                    :to="toLink('contact')"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                >
                    {{ $t('footer.contact_us') }}
                </NuxtLink>
                <NuxtLink
                    :to="toLink('faq')"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                >
                    {{ $t('footer.faq') }}
                </NuxtLink>
                <NuxtLink
                    :to="toLink('blog')"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                >
                    {{ $t('blog.header') }}
                </NuxtLink>
                <NuxtLink
                    :to="toLink('app')"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                >
                    {{ $t('footer.download_title') }}
                </NuxtLink>
            </div>
            <div class="d-flex flex-column mb-4">
                <p
                    class="mx-1 text-white text-16 text-raleway-reg mb-3 font-weight-bold"
                >
                    {{ $t('footer.explore_more') }}
                </p>
                <NuxtLink
                    v-show="isShowStudentDiscountsLink(`${region}-${lang}`)"
                    :to="toLink(studentDiscounts[`${region}-${lang}`])"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                >
                    {{ $t('footer.student_discounts') }}
                </NuxtLink>
                <NuxtLink
                    v-show="isShowAffiliateLink(`${region}-${lang}`)"
                    :to="toLink(affiliate[`${region}-${lang}`])"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                >
                    {{ $t('footer.affiliate') }}
                </NuxtLink>
                <NuxtLink
                    v-for="(item, index) in exploreMore"
                    v-show="isShowLink(item.link)"
                    :key="index"
                    :to="toLink(item.link)"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                >
                    {{ $t(item.name) }}
                </NuxtLink>
            </div>
            <div class="mb-4">
                <FooterDistrictLink v-show="isShowLink('district')" />
                <NuxtLink
                    v-if="miniSite[region]"
                    :to="toLink(miniSite[region].link)"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0 d-block"
                >
                    {{ $t(miniSite[region].name) }}
                </NuxtLink>
            </div>
            <div class="d-flex flex-column mb-4">
                <p
                    class="mx-1 text-white text-16 text-raleway-reg mb-3 font-weight-bold"
                >
                    {{ $t('footer.terms') }}
                </p>
                <NuxtLink
                    :to="toLink('privacy-policy')"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                    rel="nofollow"
                >
                    {{ $t('footer.privacy') }}
                </NuxtLink>
                <NuxtLink
                    :to="toLink('terms-of-use')"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                    rel="nofollow"
                >
                    {{ $t('footer.terms') }}
                </NuxtLink>
                <NuxtLink
                    :to="toLink('best-price-guarantee')"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                    rel="nofollow"
                >
                    {{ $t('footer.best_price_guarantee') }}
                </NuxtLink>
                <a
                    v-show="region === 'vn'"
                    :href="operationRegulations"
                    target="_blank"
                    rel="noreferrer noopener nofollow"
                    aria-label="operationRegulations"
                    class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
                >
                    {{ $t('footer.operation_regulations') }}
                </a>
            </div>
            <div v-show="isShowLink('payment-icons')">
                <p
                    class="mx-1 text-white text-16 text-raleway-reg mb-3 font-weight-bold"
                >
                    {{ $t('footer.payment_methods') }}
                </p>
                <PaymentChannels class="mt-4" />
            </div>
            <div v-show="false" class="d-flex">
                <b-btn
                    class="text-white text-14 text-raleway-reg text-button p-0 m-0"
                >
                    {{ $t('footer.site_map') }}
                </b-btn>
            </div>
            <div class="pt-3 mb-4 px-1">
                <p class="sitemap-link text-14 text-raleway-reg text-left">
                    {{ 'Copyright © ' + currentYear }}
                </p>
                <p
                    class="sitemap-link text-14 text-left"
                    style="white-space: pre-line;"
                >
                    {{ copyrightName }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import FooterDistrictLink from '@/components/item/FooterDistrictLink'
import Facebook from '@/components/iconSvg/Facebook'
import Instagram from '@/components/iconSvg/Instagram'
import Linkedin from '@/components/iconSvg/Linkedin'
import dateServices from '@/services/dateServices'
import PaymentChannels from '~/components/footer/PaymentChannels'
import TikTok from '@/components/iconSvg/TikTok'
import ImageContainer from "@/components/ui/ImageContainer.vue";
export default {
    name: 'AppFooter',
    components: {
        ImageContainer,
        TikTok,
        PaymentChannels,
        Linkedin,
        Instagram,
        Facebook,
        FooterDistrictLink
    },
    data() {
        return {
            flowCircle:
                'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/c0594502-74d7-4cb6-3d76-1ff9aaf4ba00/thumbnail',
            operationRegulations:
                'https://flowtheroomcom.sharepoint.com/:b:/s/Flow-external/ERONU906kDxKqovwMOOFEUMBJcIiC9yFuhfSMTU1o3XOEg',
            exploreMore: [
                { link: 'rewards', name: 'header.rewards_program' },
                { link: 'top-up', name: 'footer.top_up' },
                { link: 'invite-friends', name: 'footer.invite_friends' },
                // { link: 'app', name: 'footer.download_title' },
                { link: 'hourly-hotels', name: 'footer.hourly_hotels' },
                { link: 'day-use-hotels', name: 'footer.day_use_hotels' },
                { link: 'budget-hotels', name: 'footer.budget_hotels' },
                { link: 'daytime-hotels', name: 'footer.daytime_hotels' },
                { link: 'daycation', name: 'footer.daycation' },
                { link: 'work-from-hotel', name: 'footer.work_from_hotel' }
            ],
            currentYear: dateServices.getCurrentYear(),
            miniSite: {
                hk: {
                    link: 'airport-hotels',
                    name: 'sub_landing.hk_airport_hotels'
                },
                sg: {
                    link: 'airport-hotels',
                    name: 'sub_landing.sg_airport_hotels'
                }
            },
            affiliate: {
                'hk-en' : 'promotions/flow-affiliate-program',
                'hk-zh' : 'promotions/flow-affiliate-program-zh',
                'sg-en' : 'promotions/flow-affiliate-program-sg-en',
                'my-en' : 'promotions/flow-affiliate-program-my-en',
                'my-ms' : 'promotions/flow-affiliate-program-my-ms'
            },
            studentDiscounts: {
                'hk-en' : 'promotions/university-students-hotel-discounts-hk-en',
                'hk-zh' : 'promotions/university-students-hotel-discounts',
                'sg-en' : 'promotions/university-students-hotel-discounts-sg-en',
                'my-en' : 'promotions/university-students-hotel-discounts-my-en',
            }
        }
    },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        },
        copyrightName() {
            switch (`${this.lang}-${this.region}`) {
                case 'en-sg':
                case 'zh-sg':
                    return 'Flow Entertainment Pte. Ltd. All rights reserved.'
                case 'en-my':
                case 'zh-my':
                case 'ms-my':
                    return (
                        'Flow Entertainment Technology Sdn Bhd\n' +
                        '(Reg. No.: 202101024998).\n' +
                        'All rights reserved.'
                    )
                case 'en-vn':
                    return 'Flow Entertainment Company Limited.\n All rights reserved.'
                case 'vi-vn':
                    return 'Công Ty TNHH Flow Entertainment.\n All rights reserved.'
                default:
                    return (
                        'Flow Travel Technology Limited.\n' +
                        'All rights reserved.'
                    )
            }
        }
    },
    methods: {
        navigate(target) {
            this.$router.push({
                path: `/${this.region}/${this.lang}/${target}`
            })
        },
        toLink(target) {
            return `/${this.region}/${this.lang}/${target}`
        },
        navigateToPrivacy() {
            this.$router.push({
                path: `/${this.region}/${this.lang}/privacy-policy`
            })
        },
        sendPageTrack(url) {
            if (process.client) {
                this.$gtag('event', 'click', {
                    event_category: 'outbound',
                    event_label: url,
                    transport_type: 'beacon'
                })
            }
        },
        urlLink(media) {
            switch (media) {
                case 'facebook':
                    return this.facebookUrl[this.region] || this.facebookUrl.hk
                case 'instagram':
                    return (
                        this.instagramUrl[this.region] || this.instagramUrl.hk
                    )
                case 'tiktok':
                    return this.tiktokUrl[this.region]
                default:
                    return ''
            }
        },
        isShowLink(link) {
            if (link === 'best-price-guarantee') {
                return this.isPriceGuaranteeRegion
            } else if (link === 'hourly-hotels') {
                return this.isHourlyHotels
            } else if (link === 'daytime-hotels') {
                return this.isDaytimeHotels
            } else if (link === 'budget-hotels') {
                return this.isBudgetHotels
            } else if (link === 'daycation') {
                return this.isDaycation
            } else if (link === 'work-from-hotel') {
                return this.isWorkFromHotel
            } else if (link === 'day-use-hotels') {
                return this.isDayUseHotels
            } else if (link === 'top-up') {
                return this.isTopUpRegion
            } else if (link === 'blog') {
                return !this.isHideBlogPosts
            } else if (link === 'app') {
                return !this.isVNTestEnv
            } else if (link === 'district') {
                return this.isDistrictLinks
            } else if (link === 'payment-icons') {
                return this.paramsRegion !== 'vn'
            } else {
                return true
            }
        },
        isShowAffiliateLink(locale) {
            return locale === 'hk-en' || locale === 'hk-zh' || locale === 'sg-en' || locale === 'my-en' || locale === 'my-ms'
        },
        isShowStudentDiscountsLink(locale) {
            return locale === 'hk-en' || locale === 'hk-zh' || locale === 'sg-en' || locale === 'my-en'
        }
    }
}
</script>

<style scoped>
.text-button:hover {
    color: #f2c83d !important;
}
.icon {
    color: white;
    transition-property: color;
    transition-duration: 0.3s;
    cursor: pointer;
    padding: 0;
    margin-right: 20px;
}

.facebook:hover {
    color: #425994;
}
.instagram:hover {
    color: #c53d6a;
}
.linkedin:hover {
    color: #3272ab;
}
@media (max-width: 1700px) {
    .footer-container {
        width: 90% !important;
    }
}
.sitemap-link {
    color: #909090;
    font-family: Inconsolata, HelveticaNeue, Helvetica Neue, sans-serif;
    text-decoration: none;
}
@media (max-width: 991px) {
    .social-media-icon {
        height: 20px;
    }
}
@media (min-width: 992px) {
    .social-media-icon {
        height: 25px;
    }
}
</style>
