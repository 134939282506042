import {
    fpjsPlugin,
    defaultEndpoint,
    defaultScriptUrlPattern
} from '@fingerprintjs/fingerprintjs-pro-vue-v2'
import Vue from 'vue'

Vue.use(fpjsPlugin, {
    loadOptions: {
        apiKey: '2wB7qzMpyoCpsfRSs7J7',
        endpoint: [
            'https://app.flowtheroom.com/8tu6FTy6NTxuA8T4/EPceSL9hohy80Xx7',
            defaultEndpoint
        ],
        scriptUrlPattern: [
            'https://app.flowtheroom.com/8tu6FTy6NTxuA8T4/lxZIsv6oEyqB48FA?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>',
            defaultScriptUrlPattern
        ]
    }
})
