<template>
    <a
        :class="deviceType() !== 'mobile' ? 'disable-hover' : ''"
        :href="appleDlUrl"
        @click="sendOutboundClickTrack(appleDlUrl)"
        aria-label="appleDlUrl"
        target="_blank"
        rel="noreferrer noopener"
        class="border-0"
    >
        <ImageContainer
            :style="`height: ${height}px; width: ${width}px;`"
            class="store-img"
            src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/870b46e6-c5c1-4e5f-1328-010a864ec400/mobile"
            alt="Apple store"
            width="120"
            height="40"
        />
    </a>
</template>
<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'AppleStoreIcon',
    components: {ImageContainer},
    props: {
        height: {
            type: Number,
            default: 45
        },
        width: {
            type: Number
        }
    },
    data() {
        return {
            // appleDlUrl: {
            //     hk:
            //         'https://itunes.apple.com/hk/app/flow-last-minute-hotel-booking/id1257818009',
            //     sg:
            //         'https://apps.apple.com/sg/app/flow-hotel-workspace-by-hour/id1257818009',
            //     mo:
            //         'https://itunes.apple.com/hk/app/flow-last-minute-hotel-booking/id1257818009',
            //     my:
            //         'https://apps.apple.com/my/app/flow-last-minute-hotel-booking/id1257818009',
            //     vn:
            //         'https://apps.apple.com/my/app/flow-last-minute-hotel-booking/id1257818009'
            // }
            appleDlUrl: 'https://link.flowtheroom.com/XkDW/leckko16'
        }
    },
    computed: {
        region() {
            return this.$route.params.region
        }
    },
    methods: {
        sendOutboundClickTrack(url) {
            this.$gtag('event', 'click', {
                event_category: 'outbound',
                event_label: url,
                transport_type: 'beacon'
            })
        }
    }
}
</script>
<style scoped>
.store-img {
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
}
.store-img {
    width: auto;
}
</style>
