import { addHours, format, roundToNearestMinutes, parse, eachMinuteOfInterval } from 'date-fns'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import { DATE_TIME, QUERY_SOURCE, ANALYTICS_TAGS } from '../constants/index'

const getDateByFormat = (date, displayDay = true) => {
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yyyy = date.getFullYear()
    if (displayDay) {
        return yyyy + '-' + mm + '-' + dd
    } else {
        return yyyy + '-' + mm
    }
}

const getToday = () => {
    const today = new Date()
    return getDateByFormat(today)
}

const getTomorrow = () => {
    const today = new Date()
    today.setDate(today.getDate() + 1)
    return getDateByFormat(today)
}

const getCurrentStartInterval = (intervalMinute, region) => {
    const nowDate = new Date()

    const startIntervalCeil = roundToNearestMinutes(nowDate, {
        nearestTo: intervalMinute,
        roundingMethod: 'ceil'
    })
    const startIntervalTrunc = roundToNearestMinutes(nowDate, {
        nearestTo: intervalMinute,
        roundingMethod: 'trunc'
    })
    return startIntervalTrunc.valueOf() > nowDate.valueOf()
        ? startIntervalTrunc
        : startIntervalCeil
}

const getAsapMinuteInterval = (region) => {
    return format(getCurrentStartInterval(15, region), 'HH:mm')
}

const parseSearchTimeValue = (date, time, region) => {
    if (date === getToday()) {
        if (time === DATE_TIME.ASAP_CHECK_IN_TIME || time === DATE_TIME.ANY_CHECK_IN_TIME) {
            return getAsapMinuteInterval(region)
        } else {
            return time
        }
    } else if (time === DATE_TIME.ANY_CHECK_IN_TIME) {
        return '00:00'
    } else if (time === DATE_TIME.ASAP_CHECK_IN_TIME) {
        return getAsapMinuteInterval(region)
    } else {
        return time
    }
}

export const getTimezoneByRegion = (region) => {
    const map = {
        hk: 'Asia/Hong_Kong',
        mo: 'Asia/Macau',
        sg: 'Asia/Singapore',
        my: 'Asia/Kuala_Lumpur',
        vn: 'Asia/Saigon'
    }
    return region ? map[region] : 'UTC'
}

const getOffsetByRegion = (region) => {
    const map = {
        hk: '+08:00',
        mo: '+08:00',
        sg: '+08:00',
        my: '+08:00',
        vn: '+07:00'
    }
    return region ? map[region] : '+00:00'
}

const getTimestampFromDateTime = (selectedDate, selectedTime, region) => {
    const offset = getOffsetByRegion(region)
    const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm xxx'
    if (selectedDate === getToday()) {
        if (selectedTime.length === 0) {
            return getCurrentStartInterval(15, region).valueOf()
        }
        return parse(
            `${selectedDate} ${selectedTime} ${offset}`,
            DATE_TIME_FORMAT,
            new Date()
        ).valueOf()
    }
    if (selectedTime.length === 0) {
        return parse(
            `${selectedDate} 00:00 ${offset}`,
            DATE_TIME_FORMAT,
            new Date()
        ).valueOf()
    }
    return parse(
        `${selectedDate} ${selectedTime} ${offset}`,
        DATE_TIME_FORMAT,
        new Date()
    ).valueOf()
}

const getDateTimeFromTimestamp = (timestamp, region) => {
    const timezone = getTimezoneByRegion(region)
    return formatInTimeZone(timestamp, timezone, 'yyyy-MM-dd HH:mm')
}

const getTimeFromTimestamp = (timestamp, region) => {
    const timezone = getTimezoneByRegion(region)
    return formatInTimeZone(timestamp, timezone, 'HH:mm')
}

const mapToQuerySourceTags = (type) => {
    if (type === QUERY_SOURCE.SUGGESTION) return ANALYTICS_TAGS.SUGGESTION
    if (type === QUERY_SOURCE.CURRENT_LOCATION) return ANALYTICS_TAGS.CURRENT_LOCATION
    if (type === QUERY_SOURCE.SEARCH_HISTORY) return ANALYTICS_TAGS.SEARCH_HISTORY
    if (type === QUERY_SOURCE.POPULAR_SEARCH) return ANALYTICS_TAGS.POPULAR_SEARCH
    if (type === QUERY_SOURCE.USER_INPUT) return ANALYTICS_TAGS.USER_INPUT
    if (type === QUERY_SOURCE.EMPTY_SEARCH) return ANALYTICS_TAGS.EMPTY_SEARCH
    if (type === QUERY_SOURCE.FAST_SEARCH_BUTTON) return ANALYTICS_TAGS.FAST_SEARCH_BUTTON
    if (type === QUERY_SOURCE.MAGIC_SEARCH_BUTTON) return ANALYTICS_TAGS.MAGIC_SEARCH_BUTTON
    return ANALYTICS_TAGS.EMPTY_SEARCH
}

const toRad = (value) => {
    return (value * Math.PI) / 180
}

const calcDirectDistKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371 // km
    const dLat = toRad(lat2 - lat1)
    const dLon = toRad(lon2 - lon1)
    lat1 = toRad(lat1)
    lat2 = toRad(lat2)

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(lat1) *
        Math.cos(lat2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return R * c
}

const isNonHotelSpaceType = (spaceType) => {
    return spaceType === 'meet' ||
        spaceType === 'work' ||
        spaceType === 'party'
}

const isPeriodTimeFilter = (time) => {
    return time?.includes(DATE_TIME.MIDNIGHT_TIME) ||
        time?.includes(DATE_TIME.MORNING_TIME) ||
        time?.includes(DATE_TIME.AFTERNOON_TIME) ||
        time?.includes(DATE_TIME.EVENING_TIME) ||
        time?.includes(DATE_TIME.AFTER_X_TIME) ||
        time?.includes(DATE_TIME.ASAP_CHECK_IN_TIME_N) ||
        time?.includes(DATE_TIME.AFTER_MIDNIGHT_TIME)
}

const computeIntervalsByPeriod = (date, time, region) => {
    let startMinute, endMinute
    let midnightIntervals = [],
        morningIntervals = [],
        afternoonIntervals = [],
        eveningIntervals = [],
        afterXIntervals = [],
        asapIntervals = [],
        afterMidnightIntervals = []
    if (time.includes(DATE_TIME.MIDNIGHT_TIME)) {
        startMinute = getTimestampFromDateTime(
            date,
            DATE_TIME.MIDNIGHT_TIME_START,
            region
        )
        endMinute = getTimestampFromDateTime(
            date,
            DATE_TIME.MIDNIGHT_TIME_END,
            region
        )
        midnightIntervals = [startMinute, endMinute]
        // midnightIntervals = eachMinuteOfInterval(
        //     {
        //         start: startMinute,
        //         end: endMinute
        //     },
        //     {
        //         step: DATE_TIME.INTERVAL_MINUTE
        //     }
        // )
    }
    if (time.includes(DATE_TIME.MORNING_TIME)) {
        startMinute = getTimestampFromDateTime(
            date,
            DATE_TIME.MORNING_TIME_START,
            region
        )
        endMinute = getTimestampFromDateTime(
            date,
            DATE_TIME.MORNING_TIME_END,
            region
        )
        morningIntervals = [startMinute, endMinute]
        // morningIntervals = eachMinuteOfInterval(
        //     {
        //         start: startMinute,
        //         end: endMinute
        //     },
        //     {
        //         step: DATE_TIME.INTERVAL_MINUTE
        //     }
        // )
    }
    if (time.includes(DATE_TIME.AFTERNOON_TIME)) {
        startMinute = getTimestampFromDateTime(
            date,
            DATE_TIME.AFTERNOON_TIME_START,
            region
        )
        endMinute = getTimestampFromDateTime(
            date,
            DATE_TIME.AFTERNOON_TIME_END,
            region
        )
        afternoonIntervals = [startMinute, endMinute]
        // afternoonIntervals = eachMinuteOfInterval(
        //     {
        //         start: startMinute,
        //         end: endMinute
        //     },
        //     {
        //         step: DATE_TIME.INTERVAL_MINUTE
        //     }
        // )
    }
    if (time.includes(DATE_TIME.EVENING_TIME)) {
        startMinute = getTimestampFromDateTime(
            date,
            DATE_TIME.EVENING_TIME_START,
            region
        )
        endMinute = getTimestampFromDateTime(
            date,
            DATE_TIME.EVENING_TIME_END,
            region
        )
        eveningIntervals = [startMinute, endMinute]
        // eveningIntervals = eachMinuteOfInterval(
        //     {
        //         start: startMinute,
        //         end: endMinute
        //     },
        //     {
        //         step: DATE_TIME.INTERVAL_MINUTE
        //     }
        // )
    }
    if (time.includes(DATE_TIME.AFTER_X_TIME)) {
        // TODO: Replace hardcoded hour from endpoint
        startMinute = getTimestampFromDateTime(
            date,
            '14:00',
            region
        )
        endMinute = getTimestampFromDateTime(
            date,
            DATE_TIME.EVENING_TIME_END,
            region
        )
        afterXIntervals = [startMinute, endMinute]
        // afterXIntervals = eachMinuteOfInterval(
        //     {
        //         start: startMinute,
        //         end: endMinute
        //     },
        //     {
        //         step: DATE_TIME.INTERVAL_MINUTE
        //     }
        // )
    }
    if (time.includes(DATE_TIME.ASAP_CHECK_IN_TIME_N)) {
        startMinute = getCurrentStartInterval(15, region).valueOf()
        endMinute = addHours(startMinute, 2)
        if (startMinute < endMinute) {
            asapIntervals = [startMinute, endMinute]
            // asapIntervals = eachMinuteOfInterval(
            //     {
            //         start: startMinute,
            //         end: endMinute
            //     },
            //     {
            //         step: DATE_TIME.INTERVAL_MINUTE
            //     }
            // ).slice(0, 8)
        }
    }
    if (time.includes(DATE_TIME.AFTER_MIDNIGHT_TIME)) {
        startMinute = getTimestampFromDateTime(
            date,
            DATE_TIME.MIDNIGHT_TIME_START,
            region
        )
        endMinute = getTimestampFromDateTime(
            date,
            DATE_TIME.MIDNIGHT_TIME_END,
            region
        )
        afterMidnightIntervals = [startMinute, endMinute]
        // afterMidnightIntervals = eachMinuteOfInterval(
        //     {
        //         start: startMinute,
        //         end: endMinute
        //     },
        //     {
        //         step: DATE_TIME.INTERVAL_MINUTE
        //     }
        // )
    }
    let daycationIntervals =  [
        ...midnightIntervals,
        ...morningIntervals,
        ...afternoonIntervals,
        ...eveningIntervals
    ].map((i) => i.valueOf() / DATE_TIME.MILLIS_ONE_MINUTE)
    let overnightIntervals = [
        ...afterXIntervals,
        ...asapIntervals,
        ...afterMidnightIntervals
    ].map((i) => i.valueOf() / DATE_TIME.MILLIS_ONE_MINUTE)
    daycationIntervals = [...new Set(daycationIntervals)].sort()
    overnightIntervals = [...new Set(overnightIntervals)].sort()
    // DEBUG: interval range check
    // console.log('computeIntervalsByPeriod, daycationIntervals, ', daycationIntervals)
    return {
        hour: daycationIntervals,
        session: daycationIntervals,
        overnight: overnightIntervals
    }
}

const computeBookTypeByPeriod = (time) => {
    let bookTypes = []
    const timePeriods = time.split(',')
    if (
        timePeriods.includes(DATE_TIME.MIDNIGHT_TIME) ||
        timePeriods.includes(DATE_TIME.MORNING_TIME) ||
        timePeriods.includes(DATE_TIME.AFTERNOON_TIME) ||
        timePeriods.includes(DATE_TIME.EVENING_TIME)
    ) {
        bookTypes.push('hour')
        bookTypes.push('session')
    }
    if (
        timePeriods.includes(DATE_TIME.ASAP_CHECK_IN_TIME_N) ||
        timePeriods.includes(DATE_TIME.AFTER_X_TIME) ||
        timePeriods.includes(DATE_TIME.AFTER_MIDNIGHT_TIME)
    ) {
        bookTypes.push('overnight')
    }
    return bookTypes.join(',')
}

/**
 * @param now, timestamp
 * @param today, 2024-01-01
 * @param region, hk | mo | my | sg | vn
 */
const isMidnight = (now, today, region) => {
    const start = getTimestampFromDateTime(
        today,
        DATE_TIME.MIDNIGHT_TIME_START,
        region
    )
    const end = getTimestampFromDateTime(
        today,
        DATE_TIME.MIDNIGHT_TIME_END,
        region
    )
    return now >= start && now < end
}

const isMorning = (now, today, region) => {
    const start = getTimestampFromDateTime(
        today,
        DATE_TIME.MIDNIGHT_TIME_END,
        region
    )
    const end = getTimestampFromDateTime(
        today,
        DATE_TIME.MORNING_TIME_END,
        region
    )
    return now >= start && now < end
}

const isAfternoon = (now, today, region) => {
    const start = getTimestampFromDateTime(
        today,
        DATE_TIME.MORNING_TIME_END,
        region
    )
    const end = getTimestampFromDateTime(
        today,
        DATE_TIME.AFTERNOON_TIME_END,
        region
    )
    return now >= start && now < end
}

const isEvening = (now, today, region) => {
    const start = getTimestampFromDateTime(
        today,
        DATE_TIME.AFTERNOON_TIME_END,
        region
    )
    const end = getTimestampFromDateTime(
        today,
        DATE_TIME.END_OF_DAY,
        region
    )
    return now >= start && now < end
}

const isBefore8pm = (now, today, region) => {
    const end = getTimestampFromDateTime(
        today,
        '20:00',
        region
    )
    return now < end
}

const isAfterLastInterval = (now, today, region) => {
    const end = getTimestampFromDateTime(
        today,
        DATE_TIME.EVENING_TIME_END,
        region
    )
    return now > end
}

const intersection = (a, b) => {
    const setA = new Set(a)
    return b.filter(value => setA.has(value))
}

/**
 * @param selectedOptions, ['midnight_time', 'morning_time']
 * @param now, current timestamp
 * @param today, '2024-01-01'
 * @param selectedDate, '2024-01-02'
 * @param region, 'hk' | 'mo' | 'my' | 'sg' | 'vn'
 * @returns (string)[]
 */
const computeConflictPeriodOptions = (selectedOptions, now, today, selectedDate, region) => {
    const DAYCATIONS = [DATE_TIME.MIDNIGHT_TIME, DATE_TIME.MORNING_TIME, DATE_TIME.AFTERNOON_TIME, DATE_TIME.EVENING_TIME]
    const OVERNIGHTS = [DATE_TIME.ASAP_CHECK_IN_TIME_N, DATE_TIME.AFTER_X_TIME, DATE_TIME.AFTER_MIDNIGHT_TIME]
    const isTodaySelected = selectedDate === today
    let conflictingOvernights = []
    if (selectedOptions.length >= 2) {
        return [...DAYCATIONS, ...OVERNIGHTS]
            .filter(_ => _ !== selectedOptions[0] && _ !== selectedOptions[1])
    }
    if (selectedOptions.length === 1 && OVERNIGHTS.includes(selectedOptions[0])) {
        conflictingOvernights = [...OVERNIGHTS].filter(_ => _ !== selectedOptions[0])
    }
    if (isTodaySelected && (isMidnight(now, today, region) || isMorning(now, today, region))) {
        if (
            selectedOptions.includes(DATE_TIME.MIDNIGHT_TIME) ||
            selectedOptions.includes(DATE_TIME.ASAP_CHECK_IN_TIME_N) ||
            selectedOptions.includes(DATE_TIME.AFTER_MIDNIGHT_TIME)
        ) {
            const RESULT =  [
                DATE_TIME.MORNING_TIME, DATE_TIME.AFTERNOON_TIME, DATE_TIME.EVENING_TIME, DATE_TIME.AFTER_X_TIME,
                ...conflictingOvernights
            ]
            return [... new Set(RESULT)]
        }
        if (
            selectedOptions.includes(DATE_TIME.MORNING_TIME) ||
            selectedOptions.includes(DATE_TIME.AFTERNOON_TIME) ||
            selectedOptions.includes(DATE_TIME.EVENING_TIME) ||
            selectedOptions.includes(DATE_TIME.AFTER_X_TIME)
        ) {
            const RESULT = [
                DATE_TIME.MIDNIGHT_TIME, DATE_TIME.ASAP_CHECK_IN_TIME_N, DATE_TIME.AFTER_MIDNIGHT_TIME,
                ...conflictingOvernights
            ]
            return [... new Set(RESULT)]
        }
    }
    /* Handled by isPeriodButtonDisabled */
    // if (isTodaySelected && isAfternoon(now, today, region)) {}
    if (isTodaySelected && isEvening(now, today, region)) {
        if (
            selectedOptions.includes(DATE_TIME.EVENING_TIME) ||
            selectedOptions.includes(DATE_TIME.ASAP_CHECK_IN_TIME_N)
        ) {
            const RESULT = [
                DATE_TIME.MIDNIGHT_TIME, DATE_TIME.AFTER_MIDNIGHT_TIME,
                ...conflictingOvernights
            ]
            return [... new Set(RESULT)]
        }
        if (
            selectedOptions.includes(DATE_TIME.MIDNIGHT_TIME) ||
            selectedOptions.includes(DATE_TIME.AFTER_MIDNIGHT_TIME)
        ) {
            const RESULT = [
                DATE_TIME.EVENING_TIME, DATE_TIME.ASAP_CHECK_IN_TIME_N,
                ...conflictingOvernights
            ]
            return [... new Set(RESULT)]
        }
    }
    /* Handled by isPeriodButtonDisabled */
    // if (isTodaySelected && isEvening(now, today, region) && !isBefore8pm(now, today, region)) {}
    return [...conflictingOvernights]
}

const validatePeriodOptions = (options, toBeAdded, now, today, region, selectedDate) => {
    // const DAYCATIONS = [DATE_TIME.MIDNIGHT_TIME, DATE_TIME.MORNING_TIME, DATE_TIME.AFTERNOON_TIME, DATE_TIME.EVENING_TIME]
    const OVERNIGHTS = [DATE_TIME.ASAP_CHECK_IN_TIME_N, DATE_TIME.AFTER_X_TIME, DATE_TIME.AFTER_MIDNIGHT_TIME]
    const isTodaySelected = selectedDate === today
    let newOptions = options
    if (options.length >= 2) {
        options.pop()
    }
    if (intersection(options, OVERNIGHTS).length > 0 && OVERNIGHTS.includes(toBeAdded)) {
        options.pop()
    }
    if (isTodaySelected && isMidnight(now, today, region)) {
        const midnightRule = (
                options.includes(DATE_TIME.MIDNIGHT_TIME) ||
                options.includes(DATE_TIME.ASAP_CHECK_IN_TIME_N) ||
                options.includes(DATE_TIME.AFTER_MIDNIGHT_TIME)
            ) && [DATE_TIME.MORNING_TIME, DATE_TIME.AFTERNOON_TIME, DATE_TIME.EVENING_TIME, DATE_TIME.AFTER_X_TIME]
                .includes(toBeAdded)
        if (midnightRule) {
            options.pop()
        } else if (!midnightRule) {
            options.pop()
        }
    }
    if (isEvening(now, today, region) && !isBefore8pm(now, today, region) && isTodaySelected) {
        const eveningRule1 = (
                options.includes(DATE_TIME.MIDNIGHT_TIME) ||
                options.includes(DATE_TIME.AFTER_MIDNIGHT_TIME)
            ) && [DATE_TIME.EVENING_TIME, DATE_TIME.AFTER_X_TIME].includes(toBeAdded)
        const eveningRule2 = (
                options.includes(DATE_TIME.EVENING_TIME) ||
                options.includes(DATE_TIME.AFTER_X_TIME)
            ) && [DATE_TIME.MIDNIGHT_TIME, DATE_TIME.AFTER_MIDNIGHT_TIME].includes(toBeAdded)
        if (eveningRule1 || eveningRule2) {
            options.pop()
        }
    }
    newOptions.push(toBeAdded)
    return newOptions
}

/**
 * @param type, 'midnight_time' | 'morning_time' | 'afternoon_time'
 * @param region, 'hk' | 'mo' | 'my' | 'sg' | 'vn
 * @param selectedDate, '2024-01-01'
 * @returns Boolean
 */
const isPeriodButtonDisabled = (type, region, selectedDate) => {
    const now = new Date().valueOf()
    const today = getToday()
    const isTodaySelected = selectedDate === today
    const isTmrSelected = selectedDate === getTomorrow()
    if (isTodaySelected) {
        if (isMidnight(now, today, region)) {
            const DISABLED = {
                [DATE_TIME.MIDNIGHT_TIME]: false,
                [DATE_TIME.AFTER_MIDNIGHT_TIME]: false,
                [DATE_TIME.MORNING_TIME]: false,
                [DATE_TIME.AFTERNOON_TIME]: false,
                [DATE_TIME.EVENING_TIME]: false,
                [DATE_TIME.ASAP_CHECK_IN_TIME_N]: false,
                [DATE_TIME.AFTER_X_TIME]: false
            }
            return DISABLED[type]
        }
        if (isMorning(now, today, region)) {
            const DISABLED = {
                [DATE_TIME.MIDNIGHT_TIME]: true,
                [DATE_TIME.AFTER_MIDNIGHT_TIME]: true,
                [DATE_TIME.MORNING_TIME]: false,
                [DATE_TIME.AFTERNOON_TIME]: false,
                [DATE_TIME.EVENING_TIME]: false,
                [DATE_TIME.ASAP_CHECK_IN_TIME_N]: true,
                [DATE_TIME.AFTER_X_TIME]: false
            }
            return DISABLED[type]
        }
        if (isAfternoon(now, today, region)) {
            const DISABLED = {
                [DATE_TIME.MIDNIGHT_TIME]: true,
                [DATE_TIME.AFTER_MIDNIGHT_TIME]: true,
                [DATE_TIME.MORNING_TIME]: true,
                [DATE_TIME.AFTERNOON_TIME]: false,
                [DATE_TIME.EVENING_TIME]: false,
                [DATE_TIME.ASAP_CHECK_IN_TIME_N]: true,
                [DATE_TIME.AFTER_X_TIME]: false
            }
            return DISABLED[type]
        }
        if (isEvening(now, today, region) && isBefore8pm(now, today, region)) {
            const DISABLED = {
                [DATE_TIME.MIDNIGHT_TIME]: true,
                [DATE_TIME.AFTER_MIDNIGHT_TIME]: true,
                [DATE_TIME.MORNING_TIME]: true,
                [DATE_TIME.AFTERNOON_TIME]: true,
                [DATE_TIME.EVENING_TIME]: false,
                [DATE_TIME.ASAP_CHECK_IN_TIME_N]: false,
                [DATE_TIME.AFTER_X_TIME]: true
            }
            return DISABLED[type]
        }
        if (isEvening(now, today, region) && !isBefore8pm(now, today, region)) {
            const DISABLED = {
                [DATE_TIME.MIDNIGHT_TIME]: false,
                [DATE_TIME.AFTER_MIDNIGHT_TIME]: false,
                [DATE_TIME.MORNING_TIME]: true,
                [DATE_TIME.AFTERNOON_TIME]: true,
                [DATE_TIME.EVENING_TIME]: isAfterLastInterval(now, today, region),
                [DATE_TIME.ASAP_CHECK_IN_TIME_N]: false,
                [DATE_TIME.AFTER_X_TIME]: true
            }
            return DISABLED[type]
        }
    }

    const DISABLED = {
        [DATE_TIME.MIDNIGHT_TIME]: false,
        [DATE_TIME.AFTER_MIDNIGHT_TIME]: !isTmrSelected,
        [DATE_TIME.MORNING_TIME]: false,
        [DATE_TIME.AFTERNOON_TIME]: false,
        [DATE_TIME.EVENING_TIME]: false,
        [DATE_TIME.ASAP_CHECK_IN_TIME_N]: true,
        [DATE_TIME.AFTER_X_TIME]: false
    }
    return DISABLED[type]
}

const isAutoTmrDate = (selectedDate, region) => {
    const now = new Date().valueOf()
    const today = getToday()
    return !isBefore8pm(now, today, region) && selectedDate === today
}

const insertTmrDateIfAfter8 = (period, selectedDate, region) => {
    return (period === DATE_TIME.MIDNIGHT_TIME || period === DATE_TIME.AFTER_MIDNIGHT_TIME) &&
        isAutoTmrDate(selectedDate, region)
            ? `${getTomorrow()} `
            : ''
}

const sortedDaycationPeriods = (selectedDate, region, periods) => {
    if (isAutoTmrDate(selectedDate, region)) {
        const first = DATE_TIME.EVENING_TIME
        return periods.filter(_ => _.type === 'daycation')
            .sort((x, y) => {
                return x.value === first ? -1 : y.value === first ? 1 : 0
            })
    }
    return periods.filter(_ => _.type === 'daycation')
}

const mapTimePeriodToTrackingValue = (period) => {
    if (period === DATE_TIME.MIDNIGHT_TIME) {
        return `midnight time(${DATE_TIME.MIDNIGHT_TIME_START}-${DATE_TIME.MIDNIGHT_TIME_END})`
    } else if (period === DATE_TIME.MORNING_TIME) {
        return `morning time(${DATE_TIME.MORNING_TIME_START}-${DATE_TIME.MORNING_TIME_END})`
    } else if (period === DATE_TIME.AFTERNOON_TIME) {
        return `afternoon time(${DATE_TIME.AFTERNOON_TIME_START}-${DATE_TIME.AFTERNOON_TIME_END})`
    } else if (period === DATE_TIME.EVENING_TIME) {
        return `evening time(${DATE_TIME.EVENING_TIME_START}-${DATE_TIME.EVENING_TIME_END})`
    } else if (period === DATE_TIME.AFTER_X_TIME) {
        return 'after x pm'
    } else if (period === DATE_TIME.ASAP_CHECK_IN_TIME_N) {
        return 'as soon as possible'
    } else if (period === DATE_TIME.AFTER_MIDNIGHT_TIME) {
        return 'after midnight'
    } else if (period === DATE_TIME.ANY_CHECK_IN_TIME) {
        return 'any time'
    } else {
        return period
    }
}

export {
    parseSearchTimeValue,
    getTimestampFromDateTime,
    getDateTimeFromTimestamp,
    getTimeFromTimestamp,
    getCurrentStartInterval,
    getToday,
    mapToQuerySourceTags,
    calcDirectDistKm,
    isNonHotelSpaceType,
    computeIntervalsByPeriod,
    isPeriodTimeFilter,
    computeBookTypeByPeriod,
    isMidnight,
    isMorning,
    isAfternoon,
    isEvening,
    isBefore8pm,
    validatePeriodOptions,
    computeConflictPeriodOptions,
    isPeriodButtonDisabled,
    insertTmrDateIfAfter8,
    sortedDaycationPeriods,
    mapTimePeriodToTrackingValue
}
