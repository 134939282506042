<template>
    <div
        v-if="exploreDaycations[region]?.length > 0"
        class="d-flex flex-column"
    >
        <p
            class="mx-1 text-white text-16 text-raleway-reg mb-3 font-weight-bold"
        >
            {{
                $t('footer.explore_daycations_title', { country: regionName() })
            }}
        </p>
        <NuxtLink
            v-for="(item, index) in exploreDaycations[region]"
            :key="index"
            :to="toLink(item.link, index)"
            class="sitemap-link text-15 text-raleway-reg text-button py-2 px-1 m-0"
        >
            {{ $t('footer.daycation_place', { location: $t(item.name) }) }}
        </NuxtLink>
    </div>
</template>
<script>
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'FooterDistrictLink',
    data() {
        return {
            exploreDaycations: {
                hk: [
                    { link: 'mongkok', name: 'district_name.mongkok' },
                    { link: 'kwun-tong', name: 'district_name.kwun_tong' },
                    {
                        link: 'wan-chai',
                        name: 'district_name.wan_chai'
                    },
                    {
                        link: 'causeway-bay',
                        name: 'district_name.causeway_bay'
                    },
                    { link: 'tsuen-wan', name: 'district_name.tsuen_wan' },
                    { link: 'sha-tin', name: 'district_name.sha_tin' }
                ],
                sg: [
                    { link: 'orchard', name: 'district_name.0rchard' },
                    {
                        link: 'fort-canning',
                        name: 'district_name.fort_canning'
                    },
                    {
                        link: 'bencoolen',
                        name: 'district_name.bencoolen'
                    },
                    {
                        link: 'bugis',
                        name: 'district_name.bugis'
                    },
                    { link: 'changi', name: 'district_name.changi' },
                    { link: 'geylang', name: 'district_name.geylang' },
                    {
                        link: 'chinatown-sg',
                        name: 'district_name.chinatown_sg'
                    },
                    { link: 'clarke-quay', name: 'district_name.clarke_quay' },
                    { link: 'little-india', name: 'district_name.little_india' }
                ],
                my: [
                    {
                        link: 'bukit-bintang',
                        name: 'district_name.bukit_bintang'
                    },
                    {
                        link: 'kuala-lumpur-city-centre',
                        name: 'district_name.klcc'
                    },
                    {
                        link: 'subang-jaya',
                        name: 'district_name.subang_jaya_bandar_sunway'
                    },
                    {
                        link: 'petaling-jaya',
                        name: 'district_name.petaling_jaya'
                    },
                    {
                        link: 'bangsar',
                        name: 'district_name.mid_valleybangsar_bangsar'
                    },
                    // Disable due to no hotels
                    // { link: 'kl-sentral', name: 'district_name.kl_sentral' },
                    { link: 'chinatown-kl', name: 'district_name.chinatown_kl' }
                ]
            }
        }
    },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        }
    },
    methods: {
        toLink(target, position) {
            const source = getGAEventName(this.landingPageName, 'FOOTER', 'EXPLORE_DAYCATIONS', position, target)
            return {
                name: 'region-locale-space-location-keyword',
                params: {
                    space: 'hotel',
                    source,
                    location: target,
                    keyword: 'maps'
                }
            }
        }
    }
}
</script>
<style>
.sitemap-link {
    color: #909090;
    font-family: Inconsolata, HelveticaNeue, Helvetica Neue, sans-serif;
    text-decoration: none !important;
}
.text-button:hover {
    color: #f2c83d !important;
}
</style>
