import axios from 'axios'

const state = () => ({
    list: [],
    searchResult: 'im empty'
})

const getters = {
    searchResult: (state) => {
        return state.searchResult
    }
}

const action = {
    search: ({ commit }) => {
        axios.get('http://api.tvmaze.com/search/shows?q=new%20girl').then(
            (response) => {
                commit('search', response.data)
                commit('SET_USER_ID', 'search')
            },
            () => {
                // console.log(error)
            }
        )
    }
}

const mutations = {
    add(state, text) {
        state.list.push({
            text,
            done: false
        })
    },
    remove(state, { todo }) {
        state.list.splice(state.list.indexOf(todo), 1)
    },
    toggle(state, todo) {
        todo.done = !todo.done
    }
}

export default {
    state,
    getters,
    action,
    mutations
}
