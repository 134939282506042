import Api from '@/services/api'
import {
    FULL_WIDTH,
    DEFAULT_IMAGE_HEIGHT,
    DEFAULT_IMAGE_WIDTH
} from '@/utils/spaceOffer'

const api = Api.api

const state = () => ({
    isFetchingSpaceSlug: false,
    isEmptySlugResults: false,
    isFetchingSpaceOffers: false,
    isEmptyOfferResults: false
})

const getters = {
    isFetchingSpaceSlug: (state) => {
        return state.isFetchingSpaceSlug
    },
    isEmptySlugResults: (state) => {
        return state.isEmptySlugResults
    },
    isFetchingSpaceOffers: (state) => {
        return state.isFetchingSpaceOffers
    },
    isEmptyOfferResults: (state) => {
        return state.isEmptyOfferResults
    }
}

const mutations = {
    SET_IS_FETCHING_SPACE_SLUG: (state, payload) => {
        state.isFetchingSpaceSlug = payload.isFetching
    },
    SET_IS_EMPTY_SLUG_RESULTS: (state, payload) => {
        state.isEmptySlugResults = payload.isEmptySlugResults
    },
    SET_IS_FETCHING_SPACE_OFFERS: (state, payload) => {
        state.isFetchingSpaceOffers = payload.isFetching
    },
    SET_IS_EMPTY_OFFER_RESULTS: (state, payload) => {
        state.isEmptyOfferResults = payload.isEmptyOfferResults
    }
}

const actions = {
    GET_SPACE_OFFERS: async (context, payload) => {
        const spaceSlugs = payload.spaceSlugs.split(',')
        context.commit('SET_IS_FETCHING_SPACE_OFFERS', { isFetching: true })
        context.commit('SET_IS_EMPTY_OFFER_RESULTS', {
            isEmptyOfferResults: false
        })
        try {
            const spaceOffersRes = await Promise.all(
                spaceSlugs.map((slug) =>
                    api
                        .getSpaceMinimumRates(
                            payload.idToken,
                            payload.spaceType,
                            slug,
                            payload.lang,
                            payload.region
                        )
                        .catch((err) => err)
                )
            )
            const spaceOffers = spaceOffersRes
                .filter((res) => !(res instanceof Error))
                .filter((res) => res.status === 200)
                .map((res) => {
                    const offerData = res.data.Data
                    offerData.tags = res.data.Data?.tags.join('|') ?? ''
                    offerData.additionalInfo = ''
                    offerData.featuredDesc = ''
                    offerData.rates.min_price = `${offerData?.rates?.currency} ${offerData?.rates?.min_price}`
                    offerData.rates.original_price = `${offerData?.rates?.currency} ${offerData?.rates?.original_price}`
                    offerData.layout = FULL_WIDTH
                    offerData.generatedCard = ''
                    offerData.spaceName = payload.spaceName
                    offerData.height = DEFAULT_IMAGE_HEIGHT
                    offerData.width = DEFAULT_IMAGE_WIDTH
                    return offerData
                })
            context.commit('SET_IS_EMPTY_OFFER_RESULTS', {
                isEmptyOfferResults: spaceOffers.length <= 0
            })
            return { spaceOffers }
        } catch (error) {
            context.commit('SET_IS_EMPTY_OFFER_RESULTS', {
                isEmptyOfferResults: true
            })
            return { spaceOffers: [] }
        } finally {
            context.commit('SET_IS_FETCHING_SPACE_OFFERS', {
                isFetching: false
            })
        }
    }
    /*
    GET_SPACE_SLUG: async (context, payload) => {
        context.commit('SET_IS_FETCHING_SPACE_SLUG', { isFetching: true })
        context.commit('SET_IS_EMPTY_SLUG_RESULTS', {
            isEmptySlugResults: false
        })
        const searchIndex = computeSearchIndex(
            payload.region,
            payload.spaceType
        )
        try {
            const results = await executeSearchForSlug(searchIndex, payload.q)
            context.commit('SET_IS_EMPTY_SLUG_RESULTS', {
                isEmptySlugResults: results.found <= 0
            })
            return { hits: results.hits }
        } catch (error) {
            context.commit('SET_IS_EMPTY_SLUG_RESULTS', {
                isEmptySlugResults: true
            })
            return { hits: [] }
        } finally {
            context.commit('SET_IS_FETCHING_SPACE_SLUG', { isFetching: false })
        }
    }
    */
}

export default {
    state,
    getters,
    mutations,
    actions
}
