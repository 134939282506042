import _cloneDeep from 'lodash/cloneDeep'
import _find from 'lodash/find'
import _remove from 'lodash/remove'

const key = {
    language: 'language',
    locale: 'locale',
    region: 'region',
    gdrpAccepted: 'gdrpAccepted',
    bannerDisplayed: 'bannerDisplayed',
    override: 'override',
    liked: 'liked',
    countdown: 'countdown',
    webToAppBannerCountdown: 'webToAppBannerCountdown',
    bookingId: 'bookingId'
}

function get(key) {
    if (process.client) {
        return localStorage.getItem(key)
    }
}

function set(key, value) {
    return localStorage.setItem(key, value)
}

function remove(key) {
    return localStorage.removeItem(key)
}

function removeAll() {
    return localStorage.clear()
}

function setLanguage(value = 'en') {
    set(key.language, value)
}

function setLocale(value) {
    set(key.locale, value)
}

function setRegion(value) {
    set(key.region, value)
}

function setGdrpAccepted() {
    set(key.gdrpAccepted, true)
}

function setBannerDisplayed() {
    set(key.bannerDisplayed, true)
}

function setWebToAppBannerCountdown(countDown) {
    set(key.webToAppBannerCountdown, countDown)
}

function setLiked(id) {
    if (get(key.liked)) {
        const list = _cloneDeep(JSON.parse(get(key.liked)).list)

        if (_find(list, (i) => i === id)) {
            _remove(list, (item) => item === id)
        } else {
            list.push(id)
        }
        const json = { list }
        set(key.liked, JSON.stringify(json))
    } else {
        const json = { list: [id] }
        set(key.liked, JSON.stringify(json))
    }
}

function setIsOverride() {
    set(key.override, true)
}

function setCountdown(countDown) {
    set(key.countdown, countDown)
}

function setBookingId(bookingId) {
    set(key.bookingId, bookingId)
}

export default {
    key,
    get,
    set,
    remove,
    removeAll,
    setLocale,
    setLanguage,
    setRegion,
    setGdrpAccepted,
    setBannerDisplayed,
    setLiked,
    setIsOverride,
    setCountdown,
    setWebToAppBannerCountdown,
    setBookingId
}
