// let format = {
//     precision: number,
//     separator: string,
//     decimal: string,
//     format: [number],
//     symbol: string,
//     pattern: string
// }

// const format = {
//     precision: 0,
//     separator: ", ",
//     decimal: ".",
//     format: [1, 2, 3],
//     symbol: "₫",
//     pattern: "#!",
// }

// const prices = 2500.12

import _isEmpty from 'lodash/isEmpty'

const formatted = (amount, symbol, pattern) => {
    const str = pattern.toString().replace('!', symbol)
    return str.replace('#', amount)
}

const isNegNum = (amount) => {
    return amount.toString().includes('-')
}

const removeMinusSign = (amount) => {
    const isNeg = isNegNum(amount)
    return isNeg ? amount.toString().replace('-', '') : amount.toString()
}

const rounded = (amount, precision) => {
    const amt = parseFloat(amount) ?? -1
    if (precision === 0) {
        return amt
    } else {
        return (amt?.toFixed(precision) * 1).toString()
    }
}

const show = (price, format = {}, isDecimal = false) => {
    try {
        if (price !== null && !_isEmpty(format)) {
            let newPrice = Number.parseFloat(price)
            if (isNaN(newPrice)) {
                throw Error
            }
            if (!Number.isInteger(newPrice)) {
                newPrice = rounded(price, format.precision)
            }
            const isNeg = isNegNum(price)
            const strPrice = removeMinusSign(newPrice)

            const { integer, decimal } = split(strPrice)
            const revFormatArr = format.grouping.slice().reverse()
            const splitStringArr = integer.split('').reverse()
            const finalPriceArr = integer.split('').reverse()

            let addCount = revFormatArr[0]
            let loopCount = revFormatArr[0]
            let formatIndex = 0
            const offset = 1

            splitStringArr.map((i, idx) => {
                if (
                    (idx + 1) % loopCount === 0 &&
                    idx !== splitStringArr.length - 1
                ) {
                    finalPriceArr.splice(addCount, 0, format.separator)

                    if (revFormatArr[formatIndex + 1] !== undefined) {
                        formatIndex++
                    }

                    loopCount = loopCount + revFormatArr[formatIndex]
                    addCount = addCount + revFormatArr[formatIndex] + offset
                }
            })

            const pat = isNeg ? format?.pattern?.neg : format?.pattern?.pos
            const dec = decimal ? format.decimal + decimal : ''
            const finalPrice = finalPriceArr.reverse().join('') + dec

            return formatted(finalPrice, format.symbol, pat)
        } else if (!_isEmpty(format)) {
            return format.symbol
        } else {
            return '-'
        }
    } catch (err) {
        return '-'
    }
}

const split = (amount, separator = '.') => {
    const arr = amount.split(separator)
    return {
        integer: arr[0],
        decimal: arr[1]
    }
}

export default {
    formatted,
    isNegNum,
    removeMinusSign,
    rounded,
    split,
    show
}
