import analytics from '@/services/analytics'

function sendSearch(spaceType, date, searchTerm) {
    const params = {
        content_category: spaceType,
        date,
        query: searchTerm
    }
    sendEventCode('Search', params)
}

function sendViewContent(btn, slug, name, spaceType, startDate, endDate) {
    const params = {
        content_category: spaceType,
        view_button_type: btn,
        content_name: name,
        property_ID: slug,
        content_id: slug,
        start_date: startDate,
        end_date: endDate,
        content_type: 'product'
    }
    sendEventCode('ViewContent', params)
}

function sendInitiateCheckout(params) {
    const p = {
        content_category: params.spaceType,
        content_id: params.slug,
        content_name: params.name,
        check_in_time: params.checkInTime,
        check_out_time: params.checkOutTime,
        date: params.date,
        duration_type: params.bookType,
        end_date: params.endDate,
        property_ID: params.slug,
        purchase_option_ID: params.venue,
        purchase_option_name: params.venueName,
        start_date: params.startDate
    }
    sendEventCode('InitiateCheckout', p)
}

function sendCompletePayment(params) {
    const InitiateCheckoutParams = {
        content_category: params.bookType,
        content_id: params.slug,
        content_name: params.name,
        check_in_time: params.startTime,
        check_out_time: params.endTime,
        date: params.date,
        duration_type: params.durationType,
        end_date: params.endDate,
        property_ID: params.slug,
        purchase_option_ID: params.venueType,
        purchase_option_name: params.venueName,
        start_date: params.startDate
    }
    const additionalParams = {
        content_type: 'product',
        currency: params.currency,
        discount: params.discount,
        paid_amount: params.paidAmount,
        payment_method: params.paymentMethod,
        value: params.originalAmount,
        voucher_name: analytics.getVoucherName(params.statement),
        reserve_ID: params.bookingId
    }
    const p = { ...InitiateCheckoutParams, ...additionalParams }
    sendEventCode('CompletePayment', p)
}
function sendAddPaymentInfo(day) {
    const params = {
        date_after_registration: day
    }
    sendEventCode('AddPaymentInfo', params)
}
function sendAddToWishlist(name, slug, spaceType, startDate) {
    const params = {
        content_category: spaceType,
        content_id: slug,
        content_name: name,
        property_ID: slug,
        start_date: startDate,
        end_date: '',
        content_type: 'product'
    }
    sendEventCode('AddToWishlist', params)
}

function sendCompleteRegistration(method) {
    const params = {
        method
    }
    sendEventCode('CompleteRegistration', params)
}
function sendEventCode(event, params) {
    // console.log('Tiktok Pixel:', event, params)
    window.ttq.track(event, params)
}

export default {
    sendSearch,
    sendViewContent,
    sendInitiateCheckout,
    sendCompletePayment,
    sendAddPaymentInfo,
    sendAddToWishlist,
    sendCompleteRegistration
}
