<template>
    <NuxtLink :to="destination" class="link-container">
        <div class="d-flex mx-3 my-4 py-2 item-container align-items-center">
            <div class="p-0 ml-2 mr-3">
                <div
                    class="icon-container d-flex align-items-center justify-content-center"
                >
                    <ImageContainer
                        v-if="iconUrl.length > 0"
                        :src="iconUrl"
                        :alt="title"
                        class="icon"
                        height="20"
                        width="20"
                    />
                    <BookSvg v-else-if="'book'" style="fill: #f2bc1f;" />
                </div>
            </div>
            <div>
                <p
                    class="text text-16 text-020202 text-s-bold p-0"
                    style="color: #0a0a0a;"
                >
                    {{ title }}
                </p>
                <!--                <p class="text text-020202">{{ description }}</p>-->
            </div>
        </div>
    </NuxtLink>
</template>

<script>
import BookSvg from '@/components/iconSvg/BookSvg.vue'
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'NavigationItem',
    components: {ImageContainer, BookSvg },
    props: {
        iconUrl: {
            type: String,
            required: false,
            default: ''
        },
        title: {
            type: String,
            required: true,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        destination: {
            type: String,
            required: true,
            default: ''
        },
        materialIcon: {
            type: String,
            required: false,
            default: ''
        }
    }
}
</script>

<style scoped>
.link-container {
    color: inherit;
    text-decoration: none;
}
.item-container {
    border-radius: 10px;
}
.item-container:hover {
    background-color: #fff4d6;
}
.icon-container {
    position: relative;
    background: #fffbf0;
    border: 1px solid #fff0c9;
    width: 36px;
    height: 36px;
    border-radius: 10px;
}
.icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
</style>
