<template>
    <ImageContainer
        :loading="loading"
        src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/93a03ebb-69d9-450c-9d06-b4ad14868f00/public"
        alt="Get App QR Code"
        width="370"
        height="370"
    />
</template>
<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'GetAppQRCode',
    components: {ImageContainer},
    props: {
        loading: {
            type: String,
            default: 'lazy'
        }
    }
}
</script>
