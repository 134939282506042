import _concat from 'lodash/concat'
import _ from 'lodash'
import { parse } from 'node-html-parser'
import wpBlogApi from '../services/wpBlogApi'
// import blog from '../services/blog'

const state = () => ({
    homePosts: [],
    promotionPosts: [],
    fullList: {},
    authorPostsList: {},
    tagPostsList: {},
    postDetails: {},
    author: {
        jsonld: {},
        linkTags: [],
        metaTags: []
    },
    tag: {
        jsonld: {},
        linkTags: [],
        metaTags: []
    },
    settings: {},
    postDetailsMeta: {
        jsonld: {},
        linkTags: [],
        metaTags: []
    },
    promotionPostsList: {},
    promotionMeta: {
        jsonld: {},
        linkTags: [],
        metaTags: []
    },
    promotionDetails: {},
    promotionDetailsMeta: {
        jsonld: {},
        linkTags: [],
        metaTags: []
    }
})

const getters = {
    getHomePosts: (state) => {
        return state.homePosts
    },
    getPromotionPosts: (state) => {
        return state.promotionPosts
    },
    getFullList: (state) => {
        return state.fullList
    },
    getAuthorPostsList: (state) => {
        return state.authorPostsList
    },
    getTagPostsList: (state) => {
        return state.tagPostsList
    },
    getPostDetails: (state) => {
        return state.postDetails
    },
    getAuthor: (state) => {
        return state.author
    },
    getTag: (state) => {
        return state.tag
    },
    getSettings: (state) => {
        return state.settings
    },
    getPostDetailsMeta: (state) => {
        return state.postDetailsMeta
    },
    getPromotionPostsList: (state) => {
        return state.promotionPostsList
    },
    getPromotionMeta: (state) => {
        return state.promotionMeta
    },
    getPromotionDetails: (state) => {
        return state.promotionDetails
    },
    getPromotionDetailsMeta: (state) => {
        return state.promotionDetailsMeta
    }
}

const mutations = {
    SET_HOME_POSTS: (state, payload) => {
        state.homePosts = payload.posts
    },
    SET_PROMOTION_POSTS: (state, payload) => {
        state.promotionPosts = payload.posts
    },
    SET_FULL_LIST: (state, payload) => {
        /*
        if (payload.meta.pagination.page <= 1) {
            state.fullList = {
                posts: payload,
                meta: payload.meta
            }
        } else {
            state.fullList = state.fullList = {
                posts: _concat(state.fullList.posts, payload),
                meta: payload.meta
            }
        }
        */
        if (payload.pagination.currentPage <= 1) {
            state.fullList = {
                posts: payload.posts,
                pagination: payload.pagination
            }
        } else {
            state.fullList = {
                posts: _concat(state.fullList.posts, payload.posts),
                pagination: payload.pagination
            }
        }
    },
    SET_AUTHOR_POST_LIST: (state, payload) => {
        /*
        if (payload.meta.pagination.page <= 1) {
            state.authorPostsList = {
                posts: payload,
                meta: payload.meta
            }
        } else {
            state.authorPostsList = state.authorPostsList = {
                posts: _concat(state.authorPostsList.posts, payload),
                meta: payload.meta
            }
        }
        */
        if (payload.pagination.currentPage <= 1) {
            state.authorPostsList = {
                posts: payload.posts,
                pagination: payload.pagination,
                author: payload.author
            }
        } else {
            state.authorPostsList = {
                posts: _concat(state.authorPostsList.posts, payload.posts),
                pagination: payload.pagination,
                author: payload.author
            }
        }
    },
    SET_TAG_POST_LIST: (state, payload) => {
        /*
        if (payload.meta.pagination.page <= 1) {
            state.tagPostsList = {
                posts: payload,
                meta: payload.meta
            }
        } else {
            state.tagPostsList = state.tagPostsList = {
                posts: _concat(state.tagPostsList.posts, payload),
                meta: payload.meta
            }
        }
        */
        if (payload.pagination.currentPage <= 1) {
            state.tagPostsList = {
                posts: payload.posts,
                pagination: payload.pagination,
                tag: payload.tag
            }
        } else {
            state.tagPostsList = {
                posts: _concat(state.tagPostsList.posts, payload.posts),
                pagination: payload.pagination,
                tag: payload.tag
            }
        }
    },
    SET_POST_DETAILS: (state, payload) => {
        const result = payload
        result.html = sanitizeImageVideoTags(result)
        state.postDetails = result
    },
    SET_AUTHOR: (state, payload) => {
        state.author = payload
    },
    SET_TAG: (state, payload) => {
        state.tag = payload
    },
    SET_SETTINGS: (state, payload) => {
        state.settings = payload
    },
    SET_POST_DETAILS_META: (state, payload) => {
        state.postDetailsMeta = payload
    },
    SET_PROMOTION_POST_LIST: (state, payload) => {
        if (payload.pagination.currentPage <= 1) {
            state.promotionPostsList = {
                posts: payload.posts,
                pagination: payload.pagination,
                tag: payload.tag
            }
        } else {
            state.promotionPostsList = {
                posts: _concat(state.promotionPostsList.posts, payload.posts),
                pagination: payload.pagination,
                tag: payload.tag
            }
        }
    },
    SET_PROMOTION_META: (state, payload) => {
        state.promotionMeta = payload
    },
    SET_PROMOTION_DETAILS: (state, payload) => {
        const result = payload
        result.html = sanitizeImageVideoTags(result)
        state.promotionDetails = result
    },
    SET_PROMOTION_DETAILS_META: (state, payload) => {
        state.promotionDetailsMeta = payload
    }
}

const actions = {
    setHomePosts: (context, payload) => {
        return new Promise((resolve, reject) => {
            const finalQuery = computeFinalQueryWithStaticId(payload.filter)
            fetchPosts({
                ...payload,
                queryParams: finalQuery
            }).then((postsData) => {
                if (
                    postsData.posts.length > 0 ||
                    postsData.pagination.totalPages === 0
                ) {
                    context.commit('SET_HOME_POSTS', {
                        posts: postsData.posts,
                        pagination: postsData.pagination
                    })
                }
                resolve()
            })
        })
    },
    setPromotionPosts: (context, payload) => {
        return new Promise((resolve, reject) => {
            createLocaleQuery(
                payload.filter.region,
                payload.filter.lang,
                payload.filter.excludeCategories,
                payload.filter.tagsIds
            )
                .then((result) =>
                    fetchPosts({
                        ...payload,
                        queryParams: result.finalQuery
                    })
                )
                .then((postsData) => {
                    if (
                        postsData.posts.length > 0 ||
                        postsData.pagination.totalPages === 0
                    ) {
                        context.commit('SET_PROMOTION_POSTS', {
                            posts: postsData.posts,
                            pagination: postsData.pagination
                        })
                    }
                    resolve()
                })
        })
    },
    setPostsFullList: (context, payload) => {
        return new Promise((resolve, reject) => {
            /*
            blog.api.posts
                .browse(payload)
                .then((posts) => {
                    resolve(posts.meta.pagination)
                    if (posts.length > 0) {
                        context.commit('SET_FULL_LIST', posts)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
            */
            createLocaleQuery(
                payload.filter.region,
                payload.filter.lang,
                payload.filter.excludeCategories,
                [],
                [],
                payload.filter.excludeTags
            )
                .then((result) =>
                    fetchPosts({
                        ...payload,
                        queryParams: result.finalQuery
                    })
                )
                .then((postsData) => {
                    if (
                        postsData.posts.length > 0 ||
                        postsData.pagination.totalPages === 0
                    ) {
                        context.commit('SET_FULL_LIST', {
                            posts: postsData.posts,
                            pagination: postsData.pagination
                        })
                    }
                    resolve(postsData.pagination)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setAuthorPostsList: (context, payload) => {
        return new Promise((resolve, reject) => {
            /*
            blog.api.posts
                .browse(payload)
                .then((posts) => {
                    resolve(posts.meta.pagination)
                    if (posts.length > 0) {
                        context.commit('SET_AUTHOR_POST_LIST', posts)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
             */
            const authorId = payload.filter.authorId
            createLocaleQuery(
                payload.filter.region,
                payload.filter.lang,
                payload.filter.excludeCategories,
                [],
                [authorId]
            )
                .then((result) => {
                    if (result.slugToIdDict[authorId] === undefined) {
                        throw new Error(wpBlogApi.ERROR_AUTHOR_NOT_FOUND)
                    } else {
                        return fetchPosts({
                            ...payload,
                            queryParams: result.finalQuery
                        })
                    }
                })
                .then((postsData) => {
                    if (
                        postsData.posts.length > 0 ||
                        postsData.pagination.totalPages === 0
                    ) {
                        context.commit('SET_AUTHOR_POST_LIST', {
                            posts: postsData.posts,
                            pagination: postsData.pagination,
                            author: postsData.posts.find(
                                (post) =>
                                    post.primary_author.slug ===
                                    payload.filter.authorId
                            )?.primary_author
                        })
                    }
                    resolve(postsData.pagination)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setTagPostsList: (context, payload) => {
        return new Promise((resolve, reject) => {
            /*
            blog.api.posts
                .browse(payload)
                .then((posts) => {
                    resolve(posts.meta.pagination)
                    if (posts.length > 0) {
                        context.commit('SET_TAG_POST_LIST', posts)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
             */
            const tagId = payload.filter.tagId
            createLocaleQuery(
                payload.filter.region,
                payload.filter.lang,
                payload.filter.excludeCategories,
                [tagId],
                []
            )
                .then((result) => {
                    if (result.slugToIdDict[tagId] === undefined) {
                        throw new Error(wpBlogApi.ERROR_TAG_NOT_FOUND)
                    } else {
                        return fetchPosts({
                            ...payload,
                            queryParams: result.finalQuery
                        })
                    }
                })
                .then((postsData) => {
                    if (
                        postsData.posts.length > 0 ||
                        postsData.pagination.totalPages === 0
                    ) {
                        context.commit('SET_TAG_POST_LIST', {
                            posts: postsData.posts,
                            pagination: postsData.pagination,
                            tag: postsData.posts
                                .map((post) => post.tags)
                                .flat()
                                .find(
                                    (tag) =>
                                        decodeURIComponent(tag.slug) === tagId
                                )
                        })
                    }
                    resolve(postsData.pagination)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setPostDetails: (context, payload) => {
        return new Promise((resolve, reject) => {
            /*
            blog.api.posts
                .read(payload.params, { formats: payload.formats })
                .then((details) => {
                    context.commit('SET_POST_DETAILS', details)
                    resolve(true)
                })
                .catch((error) => {
                    reject(error.message)
                })
             */
            wpBlogApi.api
                .getPostDetails(payload)
                .then((res) => res.data)
                .then((posts) => {
                    let postDetails
                    if (posts.length > 0) {
                        postDetails = posts[0]
                    } else {
                        throw new Error(wpBlogApi.ERROR_POST_NOT_FOUND)
                    }
                    const normalizedPostDetails = mapToNormalizedPost(
                        postDetails
                    )

                    normalizedPostDetails.html =
                        normalizedPostDetails.content.rendered
                    delete normalizedPostDetails.content

                    context.commit('SET_POST_DETAILS', normalizedPostDetails)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setAuthor: (context, payload) => {
        return new Promise((resolve, reject) => {
            /*
            blog.api.authors
                .read(payload)
                .then((author) => {
                    if (author) {
                        context.commit('SET_AUTHOR', author)
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
             */
            const authorLink = context.getters.getAuthorPostsList?.author?.link
            if (authorLink) {
                fetchMeta(authorLink, payload, false)
                    .then((meta) => {
                        context.commit('SET_AUTHOR', meta)
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } else {
                context.commit('SET_AUTHOR', {
                    jsonld: {},
                    linkTags: [],
                    metaTags: []
                })
                resolve()
            }
        })
    },
    setTag: (context, payload) => {
        return new Promise((resolve, reject) => {
            /*
            blog.api.tags
                .read(payload)
                .then((tag) => {
                    if (tag) {
                        context.commit('SET_TAG', tag)
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
             */
            const tagLink = context.getters.getTagPostsList?.tag?.link
            if (tagLink) {
                fetchMeta(tagLink, payload, false)
                    .then((meta) => {
                        context.commit('SET_TAG', meta)
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } else {
                context.commit('SET_TAG', {
                    jsonld: {},
                    linkTags: [],
                    metaTags: []
                })
                resolve()
            }
        })
    },
    setSettings: (context, payload) => {
        return new Promise((resolve, reject) => {
            /*
            blog.api.settings
                .browse()
                .then((settings) => {
                    if (settings) {
                        context.commit('SET_SETTINGS', settings)
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
             */
            fetchMeta(`${wpBlogApi.BASE_URL}/index.php`, payload, false)
                .then((meta) => {
                    context.commit('SET_SETTINGS', meta)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setPostDetailsMeta: (context, payload) => {
        return new Promise((resolve, reject) => {
            fetchMeta(context.getters.getPostDetails.link, payload, false)
                .then((meta) => {
                    context.commit('SET_POST_DETAILS_META', meta)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setPromotionPostsList: (context, payload) => {
        return new Promise((resolve, reject) => {
            const tagId = payload.filter.tagId
            if (tagId) {
                createLocaleQuery(
                    payload.filter.region,
                    payload.filter.lang,
                    payload.filter.excludeCategories,
                    [tagId],
                    []
                )
                    .then((result) =>
                        fetchPosts({
                            ...payload,
                            queryParams: result.finalQuery
                        })
                    )
                    .then((postsData) => {
                        if (
                            postsData.posts.length > 0 ||
                            postsData.pagination.totalPages === 0
                        ) {
                            context.commit('SET_PROMOTION_POST_LIST', {
                                posts: postsData.posts,
                                pagination: postsData.pagination,
                                tag: postsData.posts
                                    .map((post) => post.tags)
                                    .flat()
                                    .find(
                                        (tag) =>
                                            decodeURIComponent(tag.slug) ===
                                            tagId
                                    )
                            })
                        }
                        resolve(postsData.pagination)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } else {
                const emptyPagination = {
                    next: null,
                    totalPages: 0,
                    currentPage: 0
                }
                context.commit('SET_PROMOTION_POST_LIST', {
                    posts: [],
                    pagination: emptyPagination,
                    tag: null
                })
                resolve(emptyPagination)
            }
        })
    },
    setPromotionMeta: (context, payload) => {
        return new Promise((resolve, reject) => {
            const tagLink = context.getters.getPromotionPostsList?.tag?.link
            if (tagLink) {
                fetchMeta(tagLink, payload, true)
                    .then((meta) => {
                        context.commit('SET_PROMOTION_META', meta)
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            } else {
                context.commit('SET_PROMOTION_META', {
                    jsonld: {},
                    linkTags: [],
                    metaTags: []
                })
                resolve()
            }
        })
    },
    setPromotionDetails: (context, payload) => {
        return new Promise((resolve, reject) => {
            wpBlogApi.api
                .getPostDetails(payload)
                .then((res) => res.data)
                .then((posts) => {
                    let postDetails
                    if (posts.length > 0) {
                        postDetails = posts[0]
                    } else {
                        throw new Error(wpBlogApi.ERROR_POST_NOT_FOUND)
                    }
                    const normalizedPostDetails = mapToNormalizedPost(
                        postDetails
                    )

                    normalizedPostDetails.html =
                        normalizedPostDetails.content.rendered
                    delete normalizedPostDetails.content

                    context.commit(
                        'SET_PROMOTION_DETAILS',
                        normalizedPostDetails
                    )
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setPromotionDetailsMeta: (context, payload) => {
        return new Promise((resolve, reject) => {
            fetchMeta(context.getters.getPromotionDetails.link, payload, true)
                .then((meta) => {
                    context.commit('SET_PROMOTION_DETAILS_META', meta)
                    resolve()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

const rewriteUrl = (stringRewrite, pageUrl, route, isPromotion) => {
    const REWRITE_DOMAIN = 'https://app.flowtheroom.com'
    let newUrl
    // Media url
    if (stringRewrite.includes('/wp-content')) {
        newUrl = stringRewrite
    }
    // Author url
    else if (stringRewrite.includes('/author')) {
        newUrl = stringRewrite
            .replace(/\/$/, '')
            .replace(
                `${wpBlogApi.BASE_URL}/author`,
                `${REWRITE_DOMAIN}/${route.region}/${route.locale}/blog/author`
            )
    }
    // Post url
    else if (stringRewrite.includes(pageUrl)) {
        newUrl = stringRewrite.replace(
            pageUrl,
            isPromotion
                ? `${REWRITE_DOMAIN}/${route.region}/${route.locale}/promotions/${route.id}/`
                : `${REWRITE_DOMAIN}/${route.region}/${route.locale}/blog/${route.id}/`
        )
    } else if (stringRewrite.includes(wpBlogApi.BASE_URL)) {
        newUrl = stringRewrite.replace(/[^#]*/, REWRITE_DOMAIN + '/')
    } else {
        newUrl = stringRewrite
    }
    return newUrl
}

const rewriteMetaTags = (stringRewrite, pageUrl, route, isPromotion) => {
    const isValidUrl = URL_REGEX.test(stringRewrite)
    if (isValidUrl) {
        return rewriteUrl(stringRewrite, pageUrl, route, isPromotion)
    }
    return stringRewrite
}

const rewriteJsonld = (jsonld, pageUrl, route, isPromotion) => {
    const urls = jsonld.replace('\\', '').match(URL_REGEX)
    let rewriteUrls = urls.map((urlString) => {
        const newUrl = rewriteUrl(urlString, pageUrl, route, isPromotion)

        return {
            url: urlString,
            newUrl
        }
    })
    rewriteUrls = _.uniqBy(rewriteUrls, 'url')
    let rewriteJsonld = jsonld
    rewriteUrls.map((_) => {
        rewriteJsonld = rewriteJsonld.replace(
            new RegExp(_.url + '*?(?=")', 'g'),
            _.newUrl
        )
    })
    return rewriteJsonld
}

const fetchMeta = (url, route, isPromotion) => {
    return new Promise((resolve, reject) => {
        wpBlogApi.api
            .getHeadMeta(url)
            .then((res) => res.data)
            .then((responseData) => {
                const root = parse(responseData.head)
                const metaTags = root
                    .querySelectorAll('meta')
                    .map((meta) => ({
                        name:
                            meta.getAttribute('name') ??
                            meta.getAttribute('property'),
                        content: rewriteMetaTags(
                            meta.getAttribute('content'),
                            url,
                            route,
                            isPromotion
                        )
                    }))
                    .map((tag) => {
                        if (tag.name === 'robots') {
                            tag.name = ''
                            tag.content = ''
                        }
                        return tag
                    })
                const linkTags = root.querySelectorAll('link').map((link) => ({
                    rel: link.getAttribute('rel'),
                    href: rewriteMetaTags(
                        link.getAttribute('href'),
                        url,
                        route,
                        isPromotion
                    )
                }))

                const scriptTag = root.querySelector('script')

                const webLocaleHyphen = `${
                    route.locale
                }-${route.region.toUpperCase()}`

                const localizedScriptTag = JSON.stringify(
                    JSON.parse(scriptTag.innerHTML)
                )
                    .replace(
                        /"inLanguage":"en_US"/g,
                        `"inLanguage":"${webLocaleHyphen}"`
                    )
                    .replace(
                        /"inLanguage":"en-US"/g,
                        `"inLanguage":"${webLocaleHyphen}"`
                    )
                const jsonld = JSON.parse(
                    rewriteJsonld(localizedScriptTag, url, route, isPromotion)
                )
                // Rewrite Locale
                const webLocale = `${
                    route.locale
                }_${route.region.toUpperCase()}`
                const LOCALE_US = 'en_US'
                const LOCALE_US_HYPHEN = 'en-US'
                metaTags.map((tag) => {
                    if (
                        tag.content === LOCALE_US ||
                        tag.content === LOCALE_US_HYPHEN
                    ) {
                        tag.content = webLocale
                    }
                })
                resolve({
                    metaTags,
                    jsonld,
                    linkTags
                })
            })
    })
}

const fetchPosts = (payload) => {
    return new Promise((resolve) => {
        wpBlogApi.api
            .getPosts(payload)
            .then((res) => res.data)
            .then((responseData) => {
                const posts = responseData.body
                const normalizedPosts = posts.map((post) =>
                    mapToNormalizedPost(post)
                )
                const pagination = mapToPagination(
                    responseData.headers,
                    payload.page
                )
                resolve({
                    posts: normalizedPosts,
                    pagination
                })
            })
    })
}

const createLocaleQuery = (
    regionIds,
    langIds,
    excludeCategories,
    tagIds = [],
    authorIds = [],
    excludeTags = []
) => {
    return new Promise((resolve) => {
        Promise.all([
            wpBlogApi.api.getCategories(
                regionIds.concat(excludeCategories).join(',')
            ),
            wpBlogApi.api.getTags(
                langIds
                    .concat(tagIds)
                    .concat(excludeTags)
                    .join(',')
            ),
            authorIds?.length > 0
                ? wpBlogApi.api.getAuthors(authorIds.join(','))
                : null
        ]).then((results) => {
            const idResults = results
                .flatMap((_) => _?.data)
                .filter((_) => _ !== undefined)
                .map((_) => ({
                    [decodeURI(_.slug)]: _.id
                }))
            const slugToIdDict = Object.assign({}, ...idResults)

            const regionQuery = regionIds.map((slug) => slugToIdDict[slug])
            const excludeCategoriesQuery = excludeCategories.map(
                (slug) => slugToIdDict[slug]
            )
            const tagQuery = langIds
                .concat(tagIds)
                .map((slug) => slugToIdDict[slug])
            const authorQuery = authorIds.map((slug) => slugToIdDict[slug])
            const excludeTagsQuery = excludeTags.map(
                (slug) => slugToIdDict[slug]
            )

            const finalQuery =
                `&categories[terms]=${regionQuery.join(',')}` +
                `&categories[operator]=OR` +
                `&tags[terms]=${tagQuery.join(',')}` +
                `&tags[operator]=AND` +
                (excludeCategoriesQuery.length > 0
                    ? `&categories_exclude=${excludeCategoriesQuery.join(',')}`
                    : '') +
                (authorIds.length > 0
                    ? `&author=${authorQuery.join(',')}`
                    : '') +
                (excludeTagsQuery.length > 0
                    ? `&tags_exclude=${excludeTagsQuery.join(',')}`
                    : '')
            resolve({ finalQuery, slugToIdDict })
        })
    })
}

const mapToNormalizedPost = (post) => {
    // Status
    post.visibility = post.status === 'publish' ? 'public' : undefined
    delete post.status

    // Date
    post.published_at = post.date
    delete post.date

    post.updated_at = post.modified
    delete post.modified

    // Title
    if (post.title?.rendered) {
        post.title = post.title.rendered
        post.title = post.title
            .replace(/&amp;/g, '&')
            .replace(/&#038;/g, '&')
            .replace(/&#8216;/g, "'")
            .replace(/&#8217;/g, "'")
            .replace(/&#8211;/g, '-')
    }
    // Excerpt
    if (post.excerpt?.rendered) {
        post.excerpt = post.excerpt.rendered
    }
    // Author
    if (post._embedded?.author && post._embedded.author.length > 0) {
        post.primary_author = {
            id: post._embedded?.author[0].id,
            name: post._embedded?.author[0].name,
            slug: post._embedded?.author[0].slug,
            profile_image: null,
            bio: post._embedded?.author[0].description,
            link: post._embedded?.author[0].link
        }
    }
    // Feature Media
    if (
        post._embedded['wp:featuredmedia'] &&
        post._embedded['wp:featuredmedia'].length > 0
    ) {
        post.feature_image =
            // eslint-disable-next-line
            post._embedded['wp:featuredmedia'][0]?.media_details?.sizes?.full?.source_url
    }
    // Categories
    if (post._embedded['wp:term'] && post._embedded['wp:term'].length >= 1) {
        post.categories = post._embedded['wp:term'][0].map((category) => ({
            id: category.id,
            name: category.name,
            slug: category.slug
        }))
    }
    // Tags
    if (post._embedded['wp:term'] && post._embedded['wp:term'].length >= 1) {
        post.tags = post._embedded['wp:term'][1].map((tag) => ({
            id: tag.id,
            name: tag.name,
            slug: tag.slug,
            link: tag._links?.self[0]?.href,
            visibility: tag.slug.startsWith('hash-') ? 'private' : 'public'
        }))
    }
    return post
}

const mapToPagination = (responseHeaders, currentPage) => ({
    next:
        currentPage < responseHeaders['X-WP-TotalPages']
            ? currentPage + 1
            : null,
    totalPages: responseHeaders['X-WP-TotalPages'],
    currentPage
})

const sanitizeImageVideoTags = (result) => {
    let res = result.html
    const cards = res.match(/<figure.+?figure>/g)
    const iframes = res.match(/<iframe.+?>/g)
    const promoCodeButtons = res.match(
        /<button style="display:none" class="promo-code-button".+?<\/button>/g
    )
    if (promoCodeButtons) {
        const visibleButtons = promoCodeButtons.map((button) => {
            button = button.replace(/style="[^"]*"/, '')
            return button
        })
        promoCodeButtons.forEach((button, index) => {
            res = res.replace(button, visibleButtons[index])
        })
    }
    // card = picture
    if (cards) {
        const updatedImages = cards.map((card) => {
            const isVideo = card.match(/kg-embed-card/g)
            const isImage =
                card.match(/wp-image/g) ||
                card.match(/wp-block-image/g) ||
                card.match(/kg-image-card/g)
            if (isVideo) {
                const mobileImages = card.replace(
                    'width="200" height="113"',
                    'width="100%" height="203" class="d-sm-none d-xs-block"'
                )
                const deskTopImages = card.replace(
                    'width="200" height="113"',
                    'width="100%" height="401" class="d-none d-sm-block"'
                )
                return mobileImages + deskTopImages
            } else if (isImage) {
                const img = card.match(/<img([\w\W]+?)\/?>/g)
                const hasClassAttr = img[0]?.includes('class="')
                let updatedImage
                if (hasClassAttr) {
                    const imgClass = /class="([^"]*)/.exec(img)[1]
                    updatedImage =
                        img.length > 0
                            ? img[0].replace(
                                  /class="[^"]*"/,
                                  'class="' + imgClass + ' w-100 h-100"'
                              )
                            : ''
                } else {
                    updatedImage =
                        img.length > 0
                            ? img[0].replace(/\/?>/, 'class="w-100 h-100" />')
                            : ''
                }
                return card.replace(/<img([^>]*[^/])\/?>/g, updatedImage)
            }
        })
        cards.forEach((card, index) => {
            res = res.replace(card, updatedImages[index])
        })
    }
    // iframes = map / video
    if (iframes) {
        const updatedIframes = iframes.map((iframe) => {
            return iframe.replace('>', ' class="w-100">')
        })
        iframes.forEach((iframe, index) => {
            res = res.replace(iframe, updatedIframes[index])
        })
    }
    return res
}

const computeFinalQueryWithStaticId = (filter) => {
    const exCatIDs =
        filter.excludeCategoryIDs.length > 0
            ? `&categories_exclude=${filter.excludeCategoryIDs}`
            : ''
    const exTagIDs =
        filter.excludeTagIDs.length > 0
            ? `&tags_exclude=${filter.excludeTagIDs}`
            : ''
    return (
        `&categories[terms]=${filter.categoryIDs}` +
        `&categories[operator]=OR` +
        `&tags[terms]=${filter.tagIDs}` +
        `&tags[operator]=AND` +
        exCatIDs +
        exTagIDs
    )
}

const URL_REGEX = new RegExp(
    'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)',
    'g'
)

export default {
    state,
    getters,
    actions,
    mutations
}
