export const ADMIN_DOMAIN = '@flowtheroom.com'
export const COLUMN_LEFT = 'COLUMN_LEFT'
export const COLUMN_RIGHT = 'COLUMN_RIGHT'
export const FULL_WIDTH = 'FULL_WIDTH'
export const DEFAULT_IMAGE_HEIGHT = 168.75
export const DEFAULT_IMAGE_WIDTH = 300

export const renderSpaceCard = (offer, region, lang, selectedThumbnail) => {
    const detailsUrl = computeDetailsUrl(offer, region, lang)
    const image = renderImage(offer, detailsUrl, selectedThumbnail)
    const nameLocation = renderNameLocation(offer, detailsUrl)
    const tags = renderTags(offer)
    const reviews = renderReviews(offer)
    const additionalInfo = renderAdditionalInfo(offer)
    const featuredDesc = renderFeaturedDesc(offer)
    const price = renderPrice(offer, lang)
    /**
     *     console.log('utils/spaceOffer, renderSpaceCard, ', offer)
     *     console.log('utils/spaceOffer, imageContent, ', image)
     *     console.log('utils/spaceOffer, nameLocation, ', nameLocation)
     *     console.log('utils/spaceOffer, tags, ', tags)
     *     console.log('utils/spaceOffer, reviews, ', reviews)
     *     console.log('utils/spaceOffer, additionalInfo, ', additionalInfo)
     *     console.log('utils/spaceOffer, featuredDesc, ', featuredDesc)
     *     console.log('utils/spaceOffer, price, ', price)
     */
    let containerStyle = `font-family: 'Heiti SC','Microsoft Yahei',Arial !important; border-radius: 30px !important; box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2) !important; letter-spacing: normal !important; overflow: hidden !important; transition: 0.3s !important; width: ${offer.width}px !important;`
    const textContainer = 'padding: 0 4px 8px 4px !important;'
    const contentContainerNode = document.createElement('div')
    if (offer.layout === COLUMN_LEFT) {
        containerStyle += ' margin-right: 4px !important;'
    } else if (offer.layout === COLUMN_RIGHT) {
        containerStyle += ' margin-left: 4px !important;'
    }
    contentContainerNode.setAttribute('style', containerStyle)
    const textContainerNode = document.createElement('div')
    textContainerNode.setAttribute('style', textContainer)
    textContainerNode.appendChild(nameLocation)
    if (tags) textContainerNode.appendChild(tags)
    if (reviews) textContainerNode.appendChild(reviews)
    if (additionalInfo) textContainerNode.appendChild(additionalInfo)
    if (featuredDesc) textContainerNode.appendChild(featuredDesc)
    if (price) textContainerNode.appendChild(price)

    contentContainerNode.appendChild(image)
    contentContainerNode.appendChild(textContainerNode)
    if (offer.layout === FULL_WIDTH || offer.layout === COLUMN_RIGHT) {
        return format(contentContainerNode.outerHTML)
    } else {
        const leftColumnContainerNode = document.createElement('div')
        leftColumnContainerNode.setAttribute(
            'style',
            'display: flex !important; flex-direction: row !important;'
        )
        leftColumnContainerNode.appendChild(contentContainerNode)
        leftColumnContainerNode.appendChild(
            document.createComment('      Right Column Goes Here      ')
        )
        return format(leftColumnContainerNode.outerHTML)
    }
}

const renderImage = (offer, detailsUrl, selectedThumbnail) => {
    const imageLinkNode = document.createElement('a')
    imageLinkNode.setAttribute('href', detailsUrl)
    imageLinkNode.setAttribute('target', '_blank')
    imageLinkNode.setAttribute('rel', 'noopener')

    const imageNode = document.createElement('img')
    imageNode.setAttribute('src', selectedThumbnail ?? offer.defaultThumbnail)
    imageNode.setAttribute('alt', offer.name)
    imageNode.setAttribute(
        'style',
        `width: ${offer.width}px !important; height: ${offer.height}px !important;`
    )

    imageLinkNode.appendChild(imageNode)
    return imageLinkNode
}

const renderNameLocation = (offer, detailsUrl) => {
    const rgbBlack = '#333333'
    const rgbGrey = '#B8B8B8'
    const nameNode = document.createElement('p')
    nameNode.setAttribute(
        'style',
        `color: ${rgbBlack} !important; font-size: 16px !important; margin: 0 !important; padding: 0 !important;`
    )
    nameNode.appendChild(document.createTextNode(offer.name))

    const locationNode = document.createElement('p')
    locationNode.setAttribute('style', `color: ${rgbGrey} !important; font-size: 14px !important;`)
    locationNode.appendChild(document.createTextNode(`📍${offer.area}`))

    const linkNode = document.createElement('a')
    linkNode.setAttribute('href', detailsUrl)
    linkNode.setAttribute('style', 'text-decoration: none !important;')
    linkNode.setAttribute('target', '_blank')
    linkNode.setAttribute('rel', 'noopener')

    const nameLocationNode = document.createElement('div')
    nameLocationNode.setAttribute(
        'style',
        'display: flex !important; flex-direction: row !important; align-items: center !important; justify-content: space-between !important;'
    )
    nameLocationNode.appendChild(nameNode)
    nameLocationNode.appendChild(locationNode)

    if (offer.layout === FULL_WIDTH) {
        linkNode.appendChild(nameLocationNode)
    } else {
        linkNode.appendChild(nameNode)
        linkNode.appendChild(locationNode)
    }

    return linkNode
}

const renderTags = (offer) => {
    if (offer.tags.trim().length <= 0) {
        return undefined
    }
    const rgbPrimary = '#F2BC1F'
    const basicStyle =
        'border-radius: 4px !important; font-size: 14px !important; font-weight: bold !important; padding: 4px 8px !important; text-transform: uppercase !important; margin-right: 4px !important;'
    const mainStyle = `background-color: ${rgbPrimary} !important; color: white !important;`
    const subStyle = `border-color: ${rgbPrimary} !important; border-style: solid !important; border-width: 1px !important; color: ${rgbPrimary} !important;`

    const containerNode = document.createElement('div')
    containerNode.setAttribute(
        'style',
        'display: flex !important; flex-direction: row !important; flex-wrap: wrap !important;'
    )
    offer.tags.split('|').forEach((tag, index) => {
        const tagStyle = basicStyle + (index % 2 ? subStyle : mainStyle)
        const spanNode = document.createElement('span')
        spanNode.setAttribute('style', tagStyle)
        spanNode.appendChild(document.createTextNode(tag))
        containerNode.appendChild(spanNode)
    })
    return containerNode
}

const renderReviews = (offer) => {
    if (!offer.review?.average_score && !offer.review?.total_review) {
        return undefined
    }
    const rgbGrey = '#B8B8B8'
    const rgbPrimary = '#F2BC1F'
    const containerNode = document.createElement('div')
    containerNode.setAttribute(
        'style',
        'align-items: center !important; display: flex !important; flex-direction: row !important; margin-top: 8px !important;'
    )
    const starNode = document.createElement('span')
    starNode.setAttribute('style', `font-size: 20px !important; color:${rgbPrimary} !important;`)
    starNode.appendChild(document.createTextNode('★'))

    const scoreNode = document.createElement('span')
    scoreNode.setAttribute(
        'style',
        `color: ${rgbPrimary} !important; font-size: 18px !important; font-weight: bold !important; text-transform: uppercase !important;`
    )
    scoreNode.appendChild(document.createTextNode(offer.review.average_score))

    const reviewCountNode = document.createElement('span')
    reviewCountNode.setAttribute(
        'style',
        `color: ${rgbGrey} !important; font-size: 14px !important; text-transform: uppercase !important; white-space: pre !important;`
    )
    reviewCountNode.appendChild(
        document.createTextNode(` (${offer.review.total_review})`)
    )

    containerNode.appendChild(starNode)
    containerNode.appendChild(scoreNode)
    containerNode.appendChild(reviewCountNode)
    return containerNode
}

const renderAdditionalInfo = (offer) => {
    if (offer.additionalInfo.trim().length <= 0) {
        return undefined
    }
    const rgbRed = '#FF6633'
    const rgbYellow = '#FDF2D2'
    const containerNode = document.createElement('div')
    containerNode.setAttribute(
        'style',
        `align-items: center !important; background-color: ${rgbYellow} !important; color: ${rgbRed} !important; display: inline-flex !important; flex-direction: row !important; margin-top: 16px !important;`
    )

    const hashTagNode = document.createElement('span')
    hashTagNode.setAttribute('style', `font-size: 20px !important; padding: 4px 8px !important;`)
    hashTagNode.appendChild(document.createTextNode('🏷'))

    const infoNode = document.createElement('span')
    infoNode.setAttribute(
        'style',
        `background-color: ${rgbYellow} !important; font-size: 14px !important; padding: 4px 8px !important;`
    )
    infoNode.appendChild(document.createTextNode(offer.additionalInfo))

    containerNode.appendChild(hashTagNode)
    containerNode.appendChild(infoNode)

    return containerNode
}

const renderFeaturedDesc = (offer) => {
    if (offer.featuredDesc.trim().length <= 0) {
        return undefined
    }
    const listNode = document.createElement('ul')
    listNode.setAttribute(
        'style',
        'font-size: 14px !important; margin-top: 8px !important; padding-left: 16px !important; padding-right: 4px !important;'
    )

    offer.featuredDesc.split('\n').forEach((feature) => {
        const itemNode = document.createElement('li')
        itemNode.appendChild(document.createTextNode(feature))
        listNode.appendChild(itemNode)
    })
    return listNode
}

const renderPrice = (offer, lang) => {
    // TODO: confirm discount price display format
    const rgbGrey = '#B8B8B8'
    const containerNode = document.createElement('div')
    containerNode.setAttribute('style', 'margin-top: 16px !important;')

    const originalPriceNode = document.createElement('span')
    originalPriceNode.setAttribute(
        'style',
        `color: ${rgbGrey} !important; font-size: 14px !important; text-decoration: line-through !important;`
    )
    originalPriceNode.appendChild(
        document.createTextNode(offer.rates.original_price)
    )

    const discountContainerNode = document.createElement('div')
    discountContainerNode.setAttribute(
        'style',
        'align-items: baseline !important; display: flex !important; flex-direction: row !important;'
    )
    const fromTranslation = {
        en: 'From ',
        zh: ' 起',
        ms: 'Dari ',
        vi: 'From '
    }
    const fromNode = document.createElement('span')
    fromNode.setAttribute('style', 'font-size: 12px !important; white-space:pre !important;')
    fromNode.appendChild(
        document.createTextNode(fromTranslation[lang])
    )

    const priceNode = document.createElement('span')
    priceNode.setAttribute(
        'style',
        'font-size: 16px !important; font-weight: bold !important; white-space: pre !important;'
    )
    priceNode.appendChild(document.createTextNode(offer.rates.min_price))
    const hourTranslation = {
        en: 'hrs',
        zh: '小時',
        ms: ' jam',
        vi: 'hrs'
    }
    const hourNode = document.createElement('span')
    hourNode.setAttribute('style', 'font-size: 12px !important; white-space:pre !important;')
    hourNode.appendChild(
        document.createTextNode(
            ` / ${offer.rates.booking_hr} ${hourTranslation[lang]}`
        )
    )

    if (lang === 'en' || lang === 'ms') {
        discountContainerNode.appendChild(fromNode)
        discountContainerNode.appendChild(priceNode)
    } else {
        discountContainerNode.appendChild(priceNode)
        discountContainerNode.appendChild(fromNode)
    }
    discountContainerNode.appendChild(hourNode)

    if (offer.rates?.original_price?.length > 0) {
        containerNode.appendChild(originalPriceNode)
    }
    containerNode.appendChild(discountContainerNode)
    return containerNode
}

const computeDetailsUrl = (offer, region, lang) => {
    return `https://app.flowtheroom.com/${region}/${lang}/${offer.slug}/${offer.spaceName}`
}

const format = (html) => {
    const tab = '\t'
    let result = ''
    let indent = ''

    html.split(/>\s*</).forEach(function(element) {
        if (element.match(/^\/\w/)) {
            indent = indent.substring(tab.length)
        }

        result += indent + '<' + element + '>\r\n'

        if (element.match(/^<?\w[^>]*[^/]$/) && !element.startsWith('input')) {
            indent += tab
        }
    })

    return result.substring(1, result.length - 3)
}
