import axios from 'axios'

const create = (baseURL = process.env.API_NEXT_BASE_URL) => {
    const api = axios.create({
        baseURL,
        headers: {},
        // 30 second timeout
        timeout: 30000,
        // retry 3 times if request failed
        retry: 3,
        retryDelay: 3000
    })
    api.interceptors.request.use(
        (config) => {
            return config
        },
        (error) => {
            Promise.reject(error)
        }
    )
    api.interceptors.response.use(
        (config) => {
            return config
        },
        (error) => {
            const { config, message } = error
            if (!config || !config.retry) {
                return Promise.reject(error)
            }
            // retry while Network timeout or Network Error
            if (
                !(
                    message.includes('timeout') ||
                    message.includes('Network Error')
                )
            ) {
                return Promise.reject(error)
            }
            config.retry -= 1
            const delayRetryRequest = new Promise((resolve) => {
                setTimeout(() => {
                    // console.log('retry the request', config.url)
                    resolve()
                }, config.retryDelay || 1000)
            })
            return delayRetryRequest.then(() => api(config))
        }
    )

    const sendVerifyCode = (body, lang, region, device) => {
        const url = `v1/reservation/verify/code?lang=${lang}&region=${region}&platform=web&device=${device}`
        return api.post(url, body, { retry: 0 })
    }

    const getRegionList = (payload) => {
        const url = 'v1/index/region'
        const params = {
            ...payload,
            platform: 'web'
        }
        return api.get(url, {
            params
        })
    }
    const getSearchBarSetting = (spaceType, params) => {
        if (!spaceType) {
            spaceType = 'rest'
        }
        const url = `v1/space/${spaceType}/search/options`
        return api.get(url, {
            params
        })
    }
    const getSearchConfig = (spaceType, params) => {
        if (!spaceType) {
            spaceType = 'rest'
        }
        const url = `v1/space/${spaceType}/search/options/lite`
        return api.get(url, {
            params
        })
    }
    const getSpaceTypeInfo = (payload) => {
        const url = 'v1/index/space_type'
        const params = payload
        if (params.id_token) {
            delete params.id_token
        }
        params.platform = 'web'
        return api.get(url, {
            params
        })
    }
    const getDealsList = (payload) => {
        const url = 'v1/index/deals'
        const params = payload
        params.platform = 'web'
        return api.get(url, {
            params
        })
    }
    const postButtonListWorker = (body) => {
        const url = 'v1/list/btn'
        return api.post(url, body, { retry: 1 })
    }
    const getPaymentIcons = (payload) => {
        const url = `v1/index/payments/icon`
        const params = payload
        params.platform = 'web'
        return api.get(url, { params })
    }
    const getDefaultRegionSetting = (payload) => {
        const url = '/v1/index/setting'
        const params = payload
        params.platform = 'web'
        return api.get(url, { params })
    }
    return {
        sendVerifyCode,
        getRegionList,
        getSearchBarSetting,
        getSearchConfig,
        getSpaceTypeInfo,
        getDealsList,
        postButtonListWorker,
        getPaymentIcons,
        getDefaultRegionSetting,
    }
}

const api = create()
const appApi = create(process.env.LIST_BTN_BASE_URL)

export default {
    create,
    api,
    appApi
}
