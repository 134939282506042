const ALLOWED_REGION = ['hk', 'sg', 'mo', 'my', 'vn']
const ALLOWED_LANG = ['en', 'zh', 'ms', 'vi']
const getRegionNameForLocationSeo = (region, lang) => {
    if (lang === 'en' || lang === 'my') {
        if (region === 'hk') return 'Hong Kong'
        if (region === 'mo') return 'Macau'
        if (region === 'my') return 'Malaysia'
        if (region === 'sg') return 'Singapore'
        if (region === 'vn') return 'Vietnam'
        return 'Hong Kong'
    }
    if (lang === 'vi') {
        if (region === 'hk') return 'Hồng Kông'
        if (region === 'mo') return 'Ma Cao'
        if (region === 'my') return 'Malaysia'
        if (region === 'sg') return 'Singapore'
        if (region === 'vn') return 'Việt Nam'
        return 'Hong Kong'
    }
    if (lang === 'zh' && (region === 'sg' || region === 'ms')) {
        if (region === 'hk') return '香港'
        if (region === 'mo') return '澳门'
        if (region === 'my') return '马来西亚'
        if (region === 'sg') return '新加坡'
        if (region === 'vn') return '越南'
        return '香港'
    }
    if (lang === 'zh' && (region === 'hk' || region === 'mo')) {
        if (region === 'hk') return '香港'
        if (region === 'mo') return '澳門'
        if (region === 'my') return '馬來西亞'
        if (region === 'sg') return '新加坡'
        if (region === 'vn') return '越南'
        return '香港'
    }
    return 'Hong Kong'
}

const getLocaleForAirwallexElement = (region, lang) => {
    if ((region === 'hk' || region === 'mo') && lang === 'zh') {
        return 'zh-HK'
    }
    if (lang === 'zh') {
        return 'zh'
    }
    return 'en'
}

const LAT_RANGE = {
    hk: [22.140989, 22.576423],
    mo: [22.108309, 22.217935],
    my: [0.814521, 7.638546],
    sg: [1.147018, 1.486902],
    vn: [8.335429, 23.764563]
}

const LNG_RANGE = {
    hk: [113.812886, 114.422628],
    mo: [113.523803, 113.602895],
    my: [99.134996, 119.54599],
    sg: [103.568864, 104.128532],
    vn: [101.758374, 109.696141]
}

const isLatValid = (region, lat) => {
    const range = LAT_RANGE[region]
    return lat >= range[0] && lat <= range[1]
}

const isLngValid = (region, lng) => {
    const range = LNG_RANGE[region]
    return lng >= range[0] && lng <= range[1]
}

const isWithinRegion = (region, lat, lng) => {
    return isLatValid(region, lat) && isLngValid(region, lng)
}

const REGION_LANG_MAPPING = {
    hk: ['en', 'zh'],
    sg: ['en', 'zh'],
    mo: ['en', 'zh'],
    my: ['en', 'zh', 'ms'],
    vn: ['en', 'vi']
}

const SPACE_REGION_MAPPING = {
    hotel: ['hk', 'sg', 'mo', 'my', 'vn'],
    party: ['hk'],
    'co-working': ['hk'],
    meeting: ['hk']
}

const BLOG_REGION_LANG_MAPPING = {
    hk: ['en', 'zh'],
    sg: ['en', 'zh'],
    mo: ['en', 'zh'],
    my: ['en', 'zh', 'ms'],
    vn: ['en', 'vi']
}

const PROMOTIONS_REGION_LANG_MAPPING = {
    hk: ['en', 'zh'],
    sg: ['en', 'zh'],
    mo: [],
    my: ['en', 'zh', 'ms'],
    vn: []
}

const TOP_UP_REGION_LANG_MAPPING = {
    hk: ['en', 'zh'],
    sg: ['en', 'zh'],
    mo: ['en', 'zh'],
    my: ['en', 'zh', 'ms'],
    vn: []
}

const isBlogValid = (region, lang) => {
    return BLOG_REGION_LANG_MAPPING[region].includes(lang)
}

const isPromotionsValid = (region, lang) => {
    return PROMOTIONS_REGION_LANG_MAPPING[region].includes(lang)
}

export {
    ALLOWED_REGION,
    ALLOWED_LANG,
    getRegionNameForLocationSeo,
    getLocaleForAirwallexElement,
    isWithinRegion,
    REGION_LANG_MAPPING,
    SPACE_REGION_MAPPING,
    BLOG_REGION_LANG_MAPPING,
    PROMOTIONS_REGION_LANG_MAPPING,
    TOP_UP_REGION_LANG_MAPPING,
    isBlogValid,
    isPromotionsValid
}
