import config from '@/utils/config'
import { initializeApp, getApps } from 'firebase/app'
import { getAuth } from 'firebase/auth'

const fbApps = getApps()
let fbApp
if (!fbApps.length) {
    fbApp = initializeApp(config.FIREBASE_CONFIG)
} else {
    fbApp = fbApps[0]
}

export const firebaseApp = fbApp
export const firebaseAuth = getAuth(fbApp)
