<template>
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 59 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3 2.68527H56"
            stroke="white"
            stroke-width="5"
            stroke-linecap="square"
        />
        <path
            d="M3 20.5879H56"
            stroke="white"
            stroke-width="5"
            stroke-linecap="square"
        />
        <path
            d="M3 38.4905H56"
            stroke="white"
            stroke-width="5"
            stroke-linecap="square"
        />
    </svg>
</template>

<script>
export default {
    name: 'MenuSvg'
}
</script>

<style scoped></style>
