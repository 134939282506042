const state = () => ({
    isSigned: null,
    isPhoneAuthPassed: false,
    // uid: '',
    idToken: ''
})

const getters = {
    isSigned: (state) => {
        return state.isSigned
    },
    isPhoneAuthPassed: (state) => {
        return state.isPhoneAuthPassed
    },
    // getUid: (state) => {
    //     return state.uid
    // },
    idToken: (state) => {
        return state.idToken
    }
}

const mutations = {
    resetSignIn: (state, payload) => {
        state.isSigned = null
    },
    setFirebaseInfo: (state, payload) => {
        state.isSigned = !payload.isAnonymous
        // state.uid = payload.uid
    },
    setIsPhoneAuthPassed: (state, payload) => {
        state.isPhoneAuthPassed = payload.state === 2
    },
    setUserSigned: (state, payload) => {
        state.isSigned = true
    },
    setIdToken: (state, payload) => {
        state.idToken = payload
    },
    resetAllState: (state, payload) => {
        state.isSigned = false
        state.isPhoneAuthPassed = false
        state.uid = ''
    }
}

const actions = {
    resetSignIn: (context, payload) => {
        context.commit('resetSignIn', payload)
    },
    setFirebaseInfo: (context, payload) => {
        context.commit('setFirebaseInfo', payload)
    },
    setIsPhoneAuthPassed: (context, payload) => {
        context.commit('setIsPhoneAuthPassed', payload)
    },
    setUserPhoneAuthPassed: (context) => {
        context.commit('setIsPhoneAuthPassed', { state: 2 })
    },
    setUserSigned: (context, payload) => {
        context.commit('setUserSigned')
        context.commit('setIsPhoneAuthPassed', { state: 2 })
    },
    setIdToken: (context, payload) => {
        context.commit('setIdToken', payload)
    },
    resetAllState: (context) => {
        context.commit('resetAllState')
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
