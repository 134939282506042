<template>
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        height="48px"
        width="48px"
        x="0"
        y="0"
        viewBox="0 0 48 48"
    >
        <style type="text/css">
            .st12 {
                fill: #ffffff;
            }
        </style>
        <g>
            <g>
                <path
                    class="st12"
                    d="M25.1,1.8c0.7,0.1,1.4,0.2,2,0.3c5.7,1.4,9.4,4.8,11.3,10.3c0.5,1.3,0.7,2.7,0.7,4.2c0,0.3,0.1,0.4,0.4,0.5
                    c2.1,0.4,3.5,1.9,3.8,4c0.1,0.4,0.1,0.9,0.1,1.3c0,2.4,0,4.9,0,7.3c0,0.8-0.1,1.6-0.4,2.4c-0.6,1.6-1.8,2.4-3.4,2.7
                    c-0.3,0.1-0.4,0.2-0.4,0.5c-0.1,1.6-0.4,3.1-1.1,4.6c-1,1.9-2.6,3-4.6,3.5c-1.3,0.4-2.7,0.5-4.1,0.6c-0.3,0-0.4,0.1-0.4,0.4
                    c0,1-0.7,1.8-1.8,1.8c-1.7,0-3.4,0-5.2,0c-1,0-1.7-0.7-1.8-1.7c0-0.9,0-1.8,0-2.7c0-1,0.7-1.7,1.7-1.7c1.7,0,3.5,0,5.2,0
                    c1.1,0,1.8,0.7,1.8,1.8c0,0.3,0.1,0.4,0.4,0.4c1.5-0.1,3-0.2,4.4-0.8c1.9-0.7,3-2.2,3.5-4.2c0.2-0.7,0.2-1.4,0.3-2.2
                    c0-0.2,0-0.3-0.2-0.3c-0.4,0-0.8,0-1.3,0c-0.5,0-0.9-0.3-0.9-0.8c0-0.2,0-0.4,0-0.6c0-5.1,0-10.2,0-15.4c0-0.2,0-0.4,0.1-0.6
                    c0.1-0.4,0.3-0.6,0.7-0.6c0.5,0,0.9,0,1.4,0c0.2,0,0.3-0.1,0.3-0.3c-0.1-2.3-0.7-4.5-1.9-6.5c-1.7-2.8-4.1-4.8-7.1-5.9
                    c-2.2-0.8-4.5-1-6.9-0.6c-3.2,0.6-5.9,2.1-8,4.6c-1.6,1.9-2.6,4-2.9,6.4c-0.1,0.6-0.1,1.3-0.2,1.9c0,0.3,0.1,0.3,0.3,0.3
                    c0.3,0,0.6,0,1,0c0.9,0,1.2,0.3,1.2,1.2c0,0.9,0,1.9,0,2.8c0,4.3,0,8.5,0,12.8c0,0.9-0.3,1.2-1.2,1.2c-0.9,0-1.7,0-2.6,0
                    c-2.4-0.1-4.1-1.5-4.5-3.8c-0.1-0.5-0.1-0.9-0.1-1.4c0-2.5,0-5.1,0-7.6c0-0.9,0.1-1.9,0.6-2.7c0.7-1.3,1.8-2,3.3-2.3
                    c0.3-0.1,0.4-0.1,0.4-0.5c0.2-4.3,1.8-7.9,4.9-10.8c1.6-1.5,3.6-2.6,5.7-3.3c1-0.3,2-0.5,3-0.6c0.1,0,0.3,0,0.4-0.1
                    C23.6,1.8,24.4,1.8,25.1,1.8z M11.2,25.9c0-2.3,0-4.7,0-7c0-0.3-0.1-0.4-0.4-0.4c-0.7,0-1.4,0-2,0.1c-1.3,0.1-2.2,1-2.5,2.2
                    c-0.1,0.4-0.1,0.9-0.1,1.3c0,2.5,0,5.1,0,7.6c0,2,0.8,3.1,2.5,3.4c0.7,0.1,1.4,0.1,2.1,0.1c0.4,0,0.5-0.1,0.5-0.5
                    C11.2,30.5,11.2,28.2,11.2,25.9z M36.8,25.9c0,2.3,0,4.7,0,7c0,0.3,0,0.4,0.3,0.4c0.5,0,1,0,1.4,0c2-0.1,3.1-1.2,3.2-3.2
                    c0-1,0-2,0-2.9c0-1.7,0-3.4,0-5.1c0-1.9-0.8-2.9-2.4-3.3c-0.7-0.2-1.5-0.1-2.2-0.1c-0.4,0-0.4,0.1-0.4,0.4
                    C36.8,21.3,36.8,23.6,36.8,25.9z M24.6,41.8c-0.8,0-1.6,0-2.4,0c-0.2,0-0.3,0.1-0.3,0.3c0,0.7,0,1.4,0,2c0,0.3,0.1,0.4,0.4,0.4
                    c1.5,0,3,0,4.5,0c0.3,0,0.4-0.1,0.4-0.4c0-0.7,0-1.3,0-2c0-0.3-0.1-0.4-0.4-0.4C26.1,41.8,25.4,41.8,24.6,41.8z"
                />
            </g>
        </g>
        <g>
            <path
                class="st12"
                d="M25.1,1.8c0.7,0.1,1.4,0.2,2,0.3c5.7,1.4,9.4,4.8,11.3,10.3c0.5,1.3,0.7,2.7,0.7,4.2c0,0.3,0.1,0.4,0.4,0.5
                c2.1,0.4,3.5,1.9,3.8,4c0.1,0.4,0.1,0.9,0.1,1.3c0,2.4,0,4.9,0,7.3c0,0.8-0.1,1.6-0.4,2.4c-0.6,1.6-1.8,2.4-3.4,2.7
                c-0.3,0.1-0.4,0.2-0.4,0.5c-0.1,1.6-0.4,3.1-1.1,4.6c-1,1.9-2.6,3-4.6,3.5c-1.3,0.4-2.7,0.5-4.1,0.6c-0.3,0-0.4,0.1-0.4,0.4
                c0,1-0.7,1.8-1.8,1.8c-1.7,0-3.4,0-5.2,0c-1,0-1.7-0.7-1.8-1.7c0-0.9,0-1.8,0-2.7c0-1,0.7-1.7,1.7-1.7c1.7,0,3.5,0,5.2,0
                c1.1,0,1.8,0.7,1.8,1.8c0,0.3,0.1,0.4,0.4,0.4c1.5-0.1,3-0.2,4.4-0.8c1.9-0.7,3-2.2,3.5-4.2c0.2-0.7,0.2-1.4,0.3-2.2
                c0-0.2,0-0.3-0.2-0.3c-0.4,0-0.8,0-1.3,0c-0.5,0-0.9-0.3-0.9-0.8c0-0.2,0-0.4,0-0.6c0-5.1,0-10.2,0-15.4c0-0.2,0-0.4,0.1-0.6
                c0.1-0.4,0.3-0.6,0.7-0.6c0.5,0,0.9,0,1.4,0c0.2,0,0.3-0.1,0.3-0.3c-0.1-2.3-0.7-4.5-1.9-6.5c-1.7-2.8-4.1-4.8-7.1-5.9
                c-2.2-0.8-4.5-1-6.9-0.6c-3.2,0.6-5.9,2.1-8,4.6c-1.6,1.9-2.6,4-2.9,6.4c-0.1,0.6-0.1,1.3-0.2,1.9c0,0.3,0.1,0.3,0.3,0.3
                c0.3,0,0.6,0,1,0c0.9,0,1.2,0.3,1.2,1.2c0,0.9,0,1.9,0,2.8c0,4.3,0,8.5,0,12.8c0,0.9-0.3,1.2-1.2,1.2c-0.9,0-1.7,0-2.6,0
                c-2.4-0.1-4.1-1.5-4.5-3.8c-0.1-0.5-0.1-0.9-0.1-1.4c0-2.5,0-5.1,0-7.6c0-0.9,0.1-1.9,0.6-2.7c0.7-1.3,1.8-2,3.3-2.3
                c0.3-0.1,0.4-0.1,0.4-0.5c0.2-4.3,1.8-7.9,4.9-10.8c1.6-1.5,3.6-2.6,5.7-3.3c1-0.3,2-0.5,3-0.6c0.1,0,0.3,0,0.4-0.1
                C23.6,1.8,24.4,1.8,25.1,1.8z M11.2,25.9c0-2.3,0-4.7,0-7c0-0.3-0.1-0.4-0.4-0.4c-0.7,0-1.4,0-2,0.1c-1.3,0.1-2.2,1-2.5,2.2
                c-0.1,0.4-0.1,0.9-0.1,1.3c0,2.5,0,5.1,0,7.6c0,2,0.8,3.1,2.5,3.4c0.7,0.1,1.4,0.1,2.1,0.1c0.4,0,0.5-0.1,0.5-0.5
                C11.2,30.5,11.2,28.2,11.2,25.9z M36.8,25.9c0,2.3,0,4.7,0,7c0,0.3,0,0.4,0.3,0.4c0.5,0,1,0,1.4,0c2-0.1,3.1-1.2,3.2-3.2
                c0-1,0-2,0-2.9c0-1.7,0-3.4,0-5.1c0-1.9-0.8-2.9-2.4-3.3c-0.7-0.2-1.5-0.1-2.2-0.1c-0.4,0-0.4,0.1-0.4,0.4
                C36.8,21.3,36.8,23.6,36.8,25.9z M24.6,41.8c-0.8,0-1.6,0-2.4,0c-0.2,0-0.3,0.1-0.3,0.3c0,0.7,0,1.4,0,2c0,0.3,0.1,0.4,0.4,0.4
                c1.5,0,3,0,4.5,0c0.3,0,0.4-0.1,0.4-0.4c0-0.7,0-1.3,0-2c0-0.3-0.1-0.4-0.4-0.4C26.1,41.8,25.4,41.8,24.6,41.8z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Chatbot'
}
</script>

<style scoped></style>
