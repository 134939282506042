import Typesense from 'typesense'
import { addHours, compareAsc, differenceInDays, eachMinuteOfInterval, parseISO } from 'date-fns'
import { DATE_TIME, FETCH_STATE, SEARCH, FALLBACK_SEARCH_TYPE } from '../constants'
import {
    calcDirectDistKm,
    getCurrentStartInterval,
    getDateTimeFromTimestamp,
    getTimestampFromDateTime,
    getTimeFromTimestamp,
    getTimezoneByRegion,
    getToday,
    parseSearchTimeValue,
    mapToQuerySourceTags,
    isNonHotelSpaceType,
    computeIntervalsByPeriod,
    isPeriodTimeFilter,
    computeBookTypeByPeriod
} from '../utils'

export let SEARCH_CLIENT = process.env.TYPESENSE_ENV === 'DEV'
    ? new Typesense.Client({
        nodes: [
            { 'host': `${process.env.TYPESENSE_CLUSTER_ID}-1.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
        ],
        apiKey: process.env.TYPESENSE_API_KEY,
        connectionTimeoutSeconds: 2,
    })
    : new Typesense.Client({
        nearestNode: { 'host': `${process.env.TYPESENSE_CLUSTER_ID}.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
        nodes: [
            { 'host': `${process.env.TYPESENSE_CLUSTER_ID}-1.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
            { 'host': `${process.env.TYPESENSE_CLUSTER_ID}-2.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
            { 'host': `${process.env.TYPESENSE_CLUSTER_ID}-3.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
        ],
        apiKey: process.env.TYPESENSE_API_KEY,
        connectionTimeoutSeconds: 2,
    })

export let CUSTOM_HOST_CLIENT = process.env.TYPESENSE_ENV === 'DEV'
    ? new Typesense.Client({
        nodes: [
            { 'host': `${process.env.TYPESENSE_SEARCH_HOST}`, 'port': '443', 'protocol': 'https' },
            { 'host': `${process.env.TYPESENSE_CLUSTER_ID}-1.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
        ],
        apiKey: process.env.TYPESENSE_API_KEY,
        additionalHeaders: {
            'X-Typesense-Cluster-Id': process.env.TYPESENSE_CLUSTER_ID,
        },
        connectionTimeoutSeconds: 2,
    })
    : new Typesense.Client({
        nearestNode: { 'host': `${process.env.TYPESENSE_SEARCH_HOST}`, 'port': '443', 'protocol': 'https' },
        nodes: [
            { 'host': `${process.env.TYPESENSE_CLUSTER_ID}.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
            { 'host': `${process.env.TYPESENSE_CLUSTER_ID}-1.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
            { 'host': `${process.env.TYPESENSE_CLUSTER_ID}-2.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
            { 'host': `${process.env.TYPESENSE_CLUSTER_ID}-3.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
        ],
        apiKey: process.env.TYPESENSE_API_KEY,
        additionalHeaders: {
            'X-Typesense-Cluster-Id': process.env.TYPESENSE_CLUSTER_ID,
        },
        connectionTimeoutSeconds: 2,
    })

/**
 * @param apiKey
 * @param clusterId
 * @param env DEV || PROD
 */
const initClient = (apiKey, clusterId, env) => {
    return env === 'DEV'
        ? new Typesense.Client({
            nodes: [
                { 'host': `${clusterId}-1.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
            ],
            apiKey,
            connectionTimeoutSeconds: 2,
        })
        : new Typesense.Client({
            nearestNode: { 'host': `${clusterId}.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
            nodes: [
                { 'host': `${clusterId}-1.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
                { 'host': `${clusterId}-2.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
                { 'host': `${clusterId}-3.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
            ],
            apiKey,
            connectionTimeoutSeconds: 2,
        })
}

/**
 * @param apiKey
 * @param clusterId
 * @param env DEV || PROD
 * @param customHost
 */
const initCustomHostClient = (apiKey, clusterId, env, customHost) => {
    return env === 'DEV'
        ? new Typesense.Client({
            nodes: [
                { 'host': `${customHost}`, 'port': '443', 'protocol': 'https' },
                { 'host': `${clusterId}-1.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
            ],
            apiKey,
            connectionTimeoutSeconds: 2,
        })
        : new Typesense.Client({
            nearestNode: { 'host': `${customHost}`, 'port': '443', 'protocol': 'https' },
            nodes: [
                { 'host': `${clusterId}.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
                { 'host': `${clusterId}-1.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
                { 'host': `${clusterId}-2.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
                { 'host': `${clusterId}-3.a1.typesense.net`, 'port': '443', 'protocol': 'https' },
            ],
            apiKey,
            additionalHeaders: {
                'X-Typesense-Cluster-Id': clusterId,
            },
            connectionTimeoutSeconds: 2,
        })
}

/**
 * @param region
 * @param spaceTypeId 1 = rest, 2 = work, 3 = meet, 5 = party
 * @param env DEV || PROD
 * @returns {`space_${string}_${*}_${string}`}
 */
export const computeSearchIndex = (region, spaceTypeId, env) => {
    return `space_${region.toUpperCase()}_${spaceTypeId}_${env}`
}

export const computeDateDiffSearchIndex = (region, spaceTypeId, env, searchDate) => {
    if (false && (region === 'hk' || region === 'my') && spaceTypeId === 1) {
        let dateDiff = differenceInDays(
            getTimestampFromDateTime(
                getToday(),
                '00:00',
                region
            ),
            getTimestampFromDateTime(
                searchDate,
                '00:00',
                region
            )
        )
        dateDiff = Math.abs(dateDiff)
        return `space_${region.toUpperCase()}_${spaceTypeId}_replica_ranking_peers_price_diffs_${dateDiff}_desc_${env}`
    } else {
        return computeSearchIndex(region, spaceTypeId, env)
    }
}

export const computeSuggestionIndex = (region, spaceTypeId, env) => {
    return `space_external_query_suggestions_${region.toUpperCase()}_${spaceTypeId}_${env}`
}

/** Example Payload
 * isExactResults: show exact search results or not
 * isPriceQuery
 * date, required
 * time, required
 * region, required
 * lang
 * starRanking
 * theme
 * bookType
 * aroundLatLng
 * currFetchState
 * spaceType: rest, work, meet, party
 * spaceTypeNum
 * query
 * page
 * isTest
 * platform
 * numPpl
 * exactSpace, providing the lat, lng for nearby space search
 * area
 * district
 * metaDistrict
 * querySource,
 * analyticsTags
 * userToken
 * isOverride
 * isCurrentIndexFetched,
 * workerResults
 * priceMin
 * priceMax
 * pageSize
 * locationFilter, WEB ONLY
 * isAnyAvailability, WEB ONLY
 * attrRetrieve, overwrite the default retrieving attributes
 * isCustomHost, fetch hits from custom host, fallback with algolia
 */

const searchIndex = async (payload) => {
    if (payload.currFetchState === FETCH_STATE.DONE) {
        const result = SEARCH.EMPTY_RESULT
        result.pageResults.nextState = FETCH_STATE.DONE
        return result
    }
    const numericTime = parseSearchTimeValue(
        payload.date,
        isPeriodTimeFilter(payload.time) ? DATE_TIME.ANY_CHECK_IN_TIME : payload.time,
        payload.region
    )

    /** startMinute: user selected start date & time, for locating start interval */
    let startMinute = getTimestampFromDateTime(
        payload.date,
        numericTime,
        payload.region
    )
    const currentTimestamp = getCurrentStartInterval(15, payload.region).valueOf()
    if (startMinute < currentTimestamp) {
        startMinute = currentTimestamp
    }

    /** endMinute: 23:59 of user selected date, for locating end interval */
    const endMinute = getTimestampFromDateTime(
        payload.date,
        DATE_TIME.END_OF_DAY,
        payload.region
    )
    /** Algolia filter */
    let starFilter, themeFilter, intervalFilter, latLngFilter, showStatusFilter, searchParams

    /** Star Ranking Filter */
    if (payload.starRanking) {
        starFilter = computeStarRankingFilter(
            'ranking.star_rating',
            payload.starRanking
        )
    }
    /** Theme Filter */
    if (payload.theme) {
        themeFilter = computeAlgoliaFilter('tags.slug', payload.theme)
    }
    /** Interval Filter */
    if (payload.isAnyAvailability === true) {
        intervalFilter = undefined
    } else {
        intervalFilter = computeIntervalFilter(
            payload,
            startMinute,
            endMinute,
            false,
            false
        )
    }
    /** Current Location Filter */
    if (payload.aroundLatLng) {
        const latLng = payload.aroundLatLng.split(',')
        if (latLng.length === 2) {
            const lat = parseFloat(latLng[0])
            const lng = parseFloat(latLng[1])
            latLngFilter = `${lat}, ${lng}`
        }
    }
    /** Show Status Filter */
    if (payload.showStatus) {
        showStatusFilter = computeAlgoliaFilter('config.show.status', payload.showStatus)
    }
    if (payload.currFetchState === FETCH_STATE.SPACE) {
        payload.priceMin = undefined
        payload.priceMax = undefined
        searchParams = computeSearchParams(payload)
    } else if (payload.currFetchState === FETCH_STATE.NEARBY) {
        payload.query = '*'
        const exactSpace = payload.exactSpace
        const lat = parseFloat(exactSpace._geoloc[0])
        const lng = parseFloat(exactSpace._geoloc[1])
        searchParams = computeSearchParams({
            ...payload,
            intervalFilter,
            latLngFilter: `${lat}, ${lng}`
        })
    } else if (payload.currFetchState === FETCH_STATE.AREA) {
        payload.query = '*'
        let areaDistrictFilter
        if (payload.area) {
            areaDistrictFilter = `location.area.id:=${payload.area}`
        }
        searchParams = computeSearchParams({
            ...payload,
            areaDistrictFilter,
            optionalIntervalFilter: computeIntervalFilter(
                payload,
                startMinute,
                endMinute,
                false,
                false
            )
        })
    } else if (payload.currFetchState === FETCH_STATE.DISTRICT) {
        payload.query = '*'
        let areaDistrictFilter
        if (payload.district) {
            areaDistrictFilter = `location.district.id:=${payload.district}`
        }
        searchParams = computeSearchParams({
            ...payload,
            areaDistrictFilter,
            optionalIntervalFilter: computeIntervalFilter(
                payload,
                startMinute,
                endMinute,
                false,
                false
            )
        })
    } else if (payload.currFetchState === FETCH_STATE.META_DISTRICT) {
        payload.query = '*'
        let areaDistrictFilter
        if (payload.metaDistrict) {
            areaDistrictFilter = `location.meta_district.id:=${payload.metaDistrict}`
        }
        searchParams = computeSearchParams({
            ...payload,
            areaDistrictFilter,
            optionalIntervalFilter: computeIntervalFilter(
                payload,
                startMinute,
                endMinute,
                false,
                false
            )
        })
    } else if (payload.currFetchState === FETCH_STATE.CITY) {
        payload.query = '*'
        let areaDistrictFilter
        if (payload.city) {
            areaDistrictFilter = `location.city.code:=${payload.city}`
        }
        searchParams = computeSearchParams({
            ...payload,
            areaDistrictFilter
        })
    } else if (payload.currFetchState === FETCH_STATE.STATE) {
        payload.query = '*'
        let areaDistrictFilter
        if (payload.state) {
            areaDistrictFilter = `location.state.code:=${payload.state}`
        }
        searchParams = computeSearchParams({
            ...payload,
            areaDistrictFilter
        })
    } else if (payload.currFetchState === FETCH_STATE.AVAILABLE) {
        searchParams = computeSearchParams({
            ...payload,
            query: payload.isExactResults ? '*' : payload.query,
            starFilter,
            themeFilter,
            intervalFilter,
            showStatusFilter,
            latLngFilter: payload.isExactResults ? undefined : latLngFilter
        })
    } else if (
        payload.currFetchState === FETCH_STATE.ALTERNATIVE ||
        payload.currFetchState === FETCH_STATE.UNAVAILABLE ||
        payload.currFetchState === FETCH_STATE.FALLBACK_ANYTIME
    ) {
        payload.time = DATE_TIME.ANY_CHECK_IN_TIME
        payload.bookType = undefined
        payload.query = '*'
        intervalFilter = computeIntervalFilter(
            payload,
            startMinute,
            endMinute,
            payload.currFetchState === FETCH_STATE.UNAVAILABLE,
            false
        )
        searchParams = computeSearchParams({
            ...payload,
            intervalFilter
        })
    } else if (payload.currFetchState === FETCH_STATE.FALLBACK_EXACT_TIME) {
        payload.bookType = undefined
        payload.query = '*'
        intervalFilter = computeIntervalFilter(
            payload,
            startMinute,
            endMinute,
            false,
            false
        )
        searchParams = computeSearchParams({
            ...payload,
            intervalFilter
        })
    } else if (payload.currFetchState === FETCH_STATE.FALLBACK_ASAP_TIME) {
        payload.time = DATE_TIME.ASAP_CHECK_IN_TIME
        payload.bookType = undefined
        payload.query = '*'
        intervalFilter = computeIntervalFilter(
            payload,
            startMinute,
            endMinute,
            false,
            false
        )
        searchParams = computeSearchParams({
            ...payload,
            intervalFilter
        })
    } else {
        searchParams = computeSearchParams({
            ...payload,
            intervalFilter
        })
    }
    if (payload.isPriceQuery) {
        let workerResults = payload.workerResults
        if (!payload.isCurrentIndexFetched) {
            if (
                payload.currFetchState === FETCH_STATE.ALTERNATIVE ||
                payload.currFetchState === FETCH_STATE.UNAVAILABLE
            ) {
                payload.priceMin = undefined
                payload.priceMax = undefined
            }
            let indexResults = await fullSearch(payload, searchParams)
            if (payload.currFetchState === FETCH_STATE.SPACE) {
                const exactResults = indexResults.hits.filter(_ => _._id === payload.suggestionId)
                if (indexResults.found <= 0 || exactResults.length <= 0) {
                    indexResults = {
                        hits: [],
                        found: 0,
                        page: 0
                    }
                } else {
                    indexResults.hits = exactResults.slice(0, 1)
                    indexResults.found = 1
                }
            }
            const workerIdentifier = indexResults.hits.map(mapToWorkerIdentifier)
            workerResults = await fetchAdditionalInfo(
                {
                    ...payload,
                    workerHits: workerIdentifier.slice(),
                    startMinute,
                    endMinute
                },
                payload.kvBaseUrl
            )
            workerResults.results = workerResults.results.filter((_) => {
                if (payload.priceMin || payload.priceMax) {
                    return !_.isNotFound
                }
                return true
            })
            workerResults = workerResults.results
        }
        let perPage = payload.pageSize
        if (!payload.pageSize) {
            perPage =
                payload.platform === 'web'
                    ? SEARCH.HITS_PER_PAGE_BATCH_WEB
                    : SEARCH.HITS_PER_PAGE_BATCH_MOBILE
        }
        const hasNext = workerResults.length >= perPage
        if (workerResults.length > 0) {
            payload.query = '*'
            const idFilter = `id:=[${workerResults.slice(0, perPage).map((_) => _.objectID)}]`
            const idSearchParams = computeSearchParams({
                ...payload,
                page: 1,
                idFilter
            })
            const searchResults = await paginatedSearch(
                payload,
                idSearchParams,
                startMinute,
                endMinute
            )
            const pageResults = computePriceQueryPagination(
                hasNext,
                searchResults.page,
                payload.currFetchState
            )
            return {
                searchResults,
                pageResults,
                workerResults
            }
        } else {
            // Handle NO worker results and trigger fallback search
            return SEARCH.EMPTY_RESULT
        }
    } else {
        const searchResults = await paginatedSearch(
            payload,
            searchParams,
            startMinute,
            endMinute
        )
        const exactResults = searchResults.hits.filter(_ => _._id === payload.suggestionId)
        if (
            payload.currFetchState === FETCH_STATE.SPACE &&
            searchResults.found > 0 &&
            exactResults.length > 0
        ) {
            searchResults.hits = exactResults.slice(0, 1)
            searchResults.found = 1
        }
        const pageResults = computePagination(
            {
                page: searchResults.page,
                totalPage: Math.ceil(searchResults.found / searchResults.request_params.per_page)
            },
            payload.currFetchState
        )
        return {
            searchResults,
            pageResults,
            startMinute,
            endMinute
        }
    }
}

const paginatedSearch = async (
    payload,
    searchParams,
    startMinute,
    endMinute
) => {
    searchParams.queryParameters = {
        region: payload.region
    }
    const results = await executeAlgoliaSearch(payload.algoliaIndex, searchParams, payload.isCustomHost)
    // payload.spaceType = 'rest'
    if (results.found > 0) {
        results.hits = results.hits.map((hit) =>
            mapToGallery(
                hit,
                results.queryID,
                payload.spaceType,
                payload.bookType,
                startMinute,
                endMinute,
                payload.region,
                payload.date,
                payload.time
            )
        )
        if (payload.exactSpace && payload.currFetchState === FETCH_STATE.NEARBY) {
            results.hits.map((hit) => mapToNearbyDistance(payload.exactSpace, hit))
        }
    }
    return results
}

const computePriceQueryPagination = (hasNext, page, currFetchState) => {
    let nextPage = page
    let isCurrentIndexFetched = true
    let nextState = currFetchState
    if (!hasNext) {
        nextState = computeNextState(currFetchState)
        if (currFetchState !== nextState) {
            hasNext = true
            nextPage = 1
            isCurrentIndexFetched = false
        }
    }
    return {
        nextState,
        hasNext,
        nextPage,
        isCurrentIndexFetched
    }
}

const fullSearch = async (payload, searchParams) => {
    let currPage = 1
    let totalPage = 2

    let results
    let currentHits = []
    while (currPage < totalPage) {
        results = await executeAlgoliaSearch(payload.algoliaIndex, searchParams, payload.isCustomHost)
        currentHits = [...currentHits, ...results.hits]
        currPage += 1
        searchParams.page = currPage
        totalPage = Math.ceil(results.found / results.request_params.per_page)
        if (currPage >= totalPage) {
            break
        }
    }
    results.hits = currentHits.map((hit) => {
        hit.status = {}
        return hit
    })
    return results
}

const computePagination = (results, currFetchState) => {
    let nextPage = results.page + 1
    let hasNext = nextPage < results.totalPage
    let nextState
    if (!hasNext) {
        nextState = computeNextState(currFetchState)
        if (currFetchState !== nextState) {
            hasNext = true
            nextPage = 1
        }
    } else {
        nextState = currFetchState
    }
    return {
        hasNext,
        nextPage,
        nextState
    }
}

const fetchAdditionalInfo = async (
    payload,
    kvBaseUrl = process.env.CF_KV_BASE_URL
) => {
    const timezone = getTimezoneByRegion(payload.region)
    /** kvStartMinute: 00:00 of user selected date, for computing KV identifier */
    const kvStartMinute = getTimestampFromDateTime(
        payload.date,
        '00:00',
        payload.region
    )
    const currentTimestamp = new Date().valueOf()
    const pageSize = payload.pageSize ?? SEARCH.ITEMS_PER_WORKER
    if (payload.currFetchState === FETCH_STATE.SPACE) {
        payload.priceMin = undefined
        payload.priceMax = undefined
    } else if (
        payload.currFetchState === FETCH_STATE.ALTERNATIVE ||
        payload.currFetchState === FETCH_STATE.UNAVAILABLE
    ) {
        payload.bookType = undefined
    }
    const params = {
        lang: payload.lang,
        region: payload.region,
        platform: payload.platform,
        spaceType: payload.spaceType,
        spaceTypeNum: payload.spaceTypeNum,
        priceMin: payload.priceMin,
        priceMax: payload.priceMax,
        numPpl: payload.numPpl,
        startMinute: payload.startMinute,
        endMinute: payload.endMinute,
        kvStartMinute,
        currentTimestamp,
        timezone,
        bookType: payload.bookType,
        selectedDate: payload.date,
        workerHits: payload.workerHits.splice(
            0,
            payload.isPriceQuery ? payload.workerHits.length : pageSize
        ),
        isOverride: payload.isOverride,
        s_price: payload.s_price,
        s_price_per_hour: payload.s_price_per_hour,
        version: payload.version,
        time: payload.time
    }
    try {
        const res = await fetch(`${kvBaseUrl}/v1/list/btn`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(params)
        })
        if (res.status === 200) {
            const resJson = await res.json()
            return {
                success: true,
                results: resJson
            }
        } else {
            return {
                success: false,
                results: []
            }
        }
    } catch (error) {
        return {
            success: false,
            results: []
        }
    }
}

const computeAlgoliaFilter = (field, value) => {
    return `${field}:=[${value}]`
}

const computeStarRankingFilter = (field, value) => {
    return value
        .split(',')
        .map((item, index) => (index === 0 ? '' : ' || ') + `${field}:${item} || ${field}:${item}.5`)
        .join('')
}

export const computeIntervalFilter = (
    payload,
    startMinute,
    endMinute,
    isFetchUnavailable,
    isOptionalFilter
) => {
    let searchIntervals
    let bookType = payload.bookType
    const isPeriod = isPeriodTimeFilter(payload.time)
    if (isPeriod) {
        bookType = computeBookTypeByPeriod(payload.time)
        searchIntervals = computeIntervalsByPeriod(
            payload.date,
            payload.time,
            payload.region
        )
    } else {
        const fullIntervals = [startMinute, endMinute]
            .map((i) => i.valueOf() / DATE_TIME.MILLIS_ONE_MINUTE)
        // const fullIntervals = eachMinuteOfInterval(
        //     {
        //         start: startMinute,
        //         end: endMinute
        //     },
        //     {
        //         step: DATE_TIME.INTERVAL_MINUTE
        //     }
        // ).map((i) => i.valueOf() / DATE_TIME.MILLIS_ONE_MINUTE)

        let availableIntervals = fullIntervals
        if (payload.time === DATE_TIME.ASAP_CHECK_IN_TIME) {
            availableIntervals = [startMinute, addHours(startMinute, 2)]
                .map((i) => i.valueOf() / DATE_TIME.MILLIS_ONE_MINUTE)
        } else if (payload.time === DATE_TIME.ANY_CHECK_IN_TIME || !payload.time) {
            // no-op, keep the fullIntervals
        } else {
            availableIntervals = [startMinute, addHours(startMinute, 1)]
                .map((i) => i.valueOf() / DATE_TIME.MILLIS_ONE_MINUTE)
        }
        const intervals = isFetchUnavailable
            ? fullIntervals
            : availableIntervals
        searchIntervals = {
            hour: intervals,
            session: intervals,
            overnight: intervals,
            hourly: intervals,
            day: intervals
        }
    }
    /**
     * searchIntervals = {
     *  hour: [28414650],
     *  session: [28414650],
     *  overnight: [28414650],
     *  hourly: [28414650],
     *  day: [28414650]
     * }
     */
    /**
     * isFetchUnavailable = TRUE, status.availabilities.hour:!=${i} &&
     * isFetchUnavailable = FALSE, status.availabilities.hour:${i} ||
     */
    let availabilityFilters = []
    if (isOptionalFilter && payload.spaceType === 'rest') {
        return mapToOptionalAvailabilityFilter(
            SEARCH.REST_BOOK_TYPES,
            bookType,
            searchIntervals
        )
            .filter((i) => !!i)
    } else if (payload.spaceType === 'rest') {
        availabilityFilters = mapToAvailabilityFilter(
            SEARCH.REST_BOOK_TYPES,
            bookType,
            searchIntervals,
            isFetchUnavailable
        )
    } else if (payload.spaceType === 'work' || payload.spaceType === 'meet') {
        availabilityFilters = mapToAvailabilityFilter(
            SEARCH.MEET_WORK_BOOK_TYPES,
            bookType,
            searchIntervals,
            isFetchUnavailable
        )
    } else if (payload.spaceType === 'party') {
        availabilityFilters = mapToAvailabilityFilter(
            SEARCH.PARTY_BOOK_TYPES,
            bookType,
            searchIntervals,
            isFetchUnavailable
        )
    }
    const joinOperator = isFetchUnavailable ? ' && ' : ' || '
    return availabilityFilters
        .filter((i) => !!i)
        .map((i) => `(${i})`)
        .join(joinOperator)
}

const mapToAvailabilityFilter = (
    BOOK_TYPES,
    selectedBookTypes,
    searchIntervals,
    isFetchUnavailable
) => {
    const negateOperator = isFetchUnavailable ? '!= ' : ''
    const succeedOperator = isFetchUnavailable ? ' && ' : ' || '
    return BOOK_TYPES.map((type) => {
        if (!selectedBookTypes || selectedBookTypes.includes(type)) {
            return `status.availabilities.${type}:${negateOperator}[${searchIntervals[type].join('..')}]`
            // return searchIntervals[type]
            //     .map((i, index) =>
            //         index === searchIntervals[type].length - 1
            //             ? `status.availabilities.${type}:${negateOperator}${i}`
            //             : `status.availabilities.${type}:${negateOperator}${i}${succeedOperator}`
            //     )
            //     .join('')
        }
        return undefined
    })
}

const mapToOptionalAvailabilityFilter = (
    BOOK_TYPES,
    selectedBookTypes,
    searchIntervals,
) => {
    return BOOK_TYPES.flatMap((type) => {
        if (!selectedBookTypes || selectedBookTypes.includes(type)) {
            return `status.availabilities.${type}:=[${searchIntervals[type].join('..')}]`
            // return searchIntervals[type]
            //     .map((i) => `status.availabilities.${type}:${i}`)
        }
        return undefined
    })
}

/** Example Filters
 * page
 * platform
 * isTest
 * algoliaLang
 * starFilter
 * themeFilter
 * intervalFilter
 * latLngFilter
 * idFilter
 * areaDistrictFilter
 * locationFilter
 * dealsFilter
 * query
 */
const computeSearchParams = (payload) => {
    const searchParams = {
        page: payload.page,
        // analytics: !payload.isSkipAnalytics, TODO: remove / migrate
        // clickAnalytics: !payload.isSkipAnalytics, TODO: remove / migrate
        include_fields:
            payload.attrRetrieve ??
            computeIncludeFields(
                payload.currFetchState,
                payload.isPriceQuery,
                payload.idFilter !== undefined,
                payload.spaceType
            ),
        per_page: computeBatchSize(
            payload.platform,
            payload.isPriceQuery,
            payload.pageSize
        ),
        // enablePersonalization: !payload.isSkipAnalytics, TODO: remove / migrate
    }
    /*
    if (payload.userToken) {
        searchParams.userToken = payload.userToken
    }
    const _analyticsTags = payload.analyticsTags?.slice()
    if (payload.querySource) {
        const querySourceTag = mapToQuerySourceTags(payload.querySource)
        _analyticsTags?.push(querySourceTag)
    }
    searchParams.analyticsTags = payload.isSkipAnalytics ? [] : _analyticsTags TODO: remove / migrate
    */
    const customEvals = []
    const isAppOrWebShow =
        payload.platform === 'web' ? 'is_web_show' : 'is_app_show'
    let filtersQuery
    if (payload.isTest) {
        filtersQuery = `(_tags:=[live, test]) && config.show.${isAppOrWebShow}:=true`
    } else {
        filtersQuery = `_tags:=live && config.show.${isAppOrWebShow}:=true`
    }
    if (payload.starFilter) {
        filtersQuery += ` && (${payload.starFilter})`
    }
    if (payload.themeFilter) {
        filtersQuery += ` && (${payload.themeFilter})`
    }
    if (payload.intervalFilter) {
        filtersQuery += ` && (${payload.intervalFilter})`
    }
    if (payload.showStatusFilter) {
        filtersQuery += ` && (${payload.showStatusFilter})`
    } else {
        filtersQuery += ` && (config.show.status:!=UNLISTED)`
    }
    if (payload.idFilter) {
        filtersQuery += ` && (${payload.idFilter})`
    }
    if (payload.areaDistrictFilter) {
        filtersQuery += ` && (${payload.areaDistrictFilter})`
    }
    if (payload.locationFilter) {
        filtersQuery += ` && ((location.area.name.${payload.algoliaLang}:='${payload.locationFilter}' || location.city.name.${payload.algoliaLang}:='${payload.locationFilter}'))`
    }
    if (payload.tagsFilter) {
        filtersQuery += ` && ((tags.slug:=${payload.tagsFilter}))`
    }
    if (payload.swimmingPoolFilter) {
        filtersQuery += ` && ((amenities.name.${payload.algoliaLang}:='${payload.swimmingPoolFilter[0]}' || amenities.name.${payload.algoliaLang}:='${payload.swimmingPoolFilter[1]}'))`
    }
    if (payload.fitnessCenterFilter) {
        filtersQuery += ` && ((amenities.name.${payload.algoliaLang}:='${payload.fitnessCenterFilter}'))`
    }
    if (payload.latLngFilter) {
        filtersQuery += ` && _geoloc:(${payload.latLngFilter}, ${(payload.aroundRadius ?? SEARCH.AROUND_RADIUS_METER) / 1000} km)`
    }
    /** Group Filter (for MY cities) */
    if (payload.group) {
        const groupFilter = computeAlgoliaFilter('location.group.slug', payload.group)
        filtersQuery += ` && (${groupFilter})`
    }
    if (payload.s_star_rating) {
        customEvals.push(computeStarRankingEval(payload.s_star_rating === 'htl'))
    }
    /*
    if (payload.s_user_rating) {
        TODO: Typesense user rating sort
    }
    */
    // Search Config
    if (payload.searchConfig?.query_by) {
        searchParams.query_by = payload.searchConfig.query_by
    }
    if (payload.searchConfig?.sort_by_order) {
        const sortBy = payload.searchConfig?.sort_by_order
        if (payload.optionalIntervalFilter) {
            customEvals.push(`(${payload.optionalIntervalFilter}):8`)
        }
        const sortEval = computeSortEvalExpression(
            payload.searchConfig?.sort_by_eval ?? [],
            customEvals
        )
        const evalIndex = sortBy.indexOf(SEARCH.EVAL_PLACEHOLDER)
        if (payload.latLngFilter) {
            sortBy[evalIndex] = `_geoloc(${payload.latLngFilter}):asc`
        } else {
            sortBy[evalIndex] = sortEval
        }
        searchParams.sort_by = sortBy.join(',')
    }
    if (payload.override_tags) {
        searchParams.override_tags = payload.override_tags
    }
    searchParams.filter_by = filtersQuery
    return {
        ...searchParams,
        q: (payload.query === '' || payload.query === undefined) ? '*' : payload.query
    }
}

const executeAlgoliaSearch = async (indexName, searchParams, isCustomHost) => {
    let client
    if (isCustomHost) {
        client = CUSTOM_HOST_CLIENT
    } else {
        client = SEARCH_CLIENT
    }
    const commonParams = searchParams.queryParameters
    if (searchParams.include_fields) {
        searchParams.include_fields = searchParams.include_fields.join(',')
    }
    delete searchParams.queryParameters
    const results = await client.multiSearch.perform({
        searches: [
            {
                collection: indexName,
                ...searchParams,
            }
        ]
    }, commonParams)
    let firstResult = results.results[0]
    firstResult.hits = firstResult.hits.map((_) => _.document)
    return firstResult
}

const computeBatchSize = (platform, isPriceQuery, perPage) => {
    if (isPriceQuery) {
        return SEARCH.HITS_PER_PAGE_FULL
    } else if (platform === 'web') {
        return perPage ?? SEARCH.HITS_PER_PAGE_BATCH_WEB
    } else {
        return perPage ?? SEARCH.HITS_PER_PAGE_BATCH_MOBILE
    }
}

const computeIncludeFields = (
    currFetchState,
    isPriceQuery,
    isIdFilter,
    spaceType
) => {
    if (isPriceQuery && !isIdFilter) {
        return SEARCH.INCLUDE_FIELDS_SORT_INDEX
    } else if (
        currFetchState === FETCH_STATE.AVAILABLE ||
        currFetchState === FETCH_STATE.ALTERNATIVE
    ) {
        return isNonHotelSpaceType(spaceType)
            ? [...SEARCH.INCLUDE_FIELDS, 'status.availabilities.hourly']
            : [...SEARCH.INCLUDE_FIELDS, 'status.availabilities']
    } else {
        return [...SEARCH.INCLUDE_FIELDS, 'status']
    }
}

const mapToGallery = (
    hit,
    queryID,
    spaceType,
    bookTypesString,
    startMinute,
    endMinute,
    region,
    date,
    time
) => {
    const images = hit.images.slice(0, SEARCH.GALLERY_IMAGE_COUNT)
    let isAvailableByBookType
    let next_available
    let _bookTypesString = bookTypesString
    if (_bookTypesString === undefined && time && isPeriodTimeFilter(time)) {
        _bookTypesString = periodTimeToBookTypeString(time)
    }
    if (hit.status?.availabilities) {
        next_available = computeNextAvailableTime(
            hit.status.availabilities,
            spaceType,
            _bookTypesString,
            endMinute,
            region
        )
        if (startMinute && endMinute) {
            const MILLIS_ONE_MINUTE = 60000
            const _startMinute = startMinute / MILLIS_ONE_MINUTE
            const _endMinute = endMinute / MILLIS_ONE_MINUTE
            const hourInterval = hit.status.availabilities?.hour
                ?.filter((i) => i >= _startMinute)
                ?.filter((i) => i <= _endMinute)
            const sessionInterval = hit.status.availabilities?.session
                ?.filter((i) => i >= _startMinute)
                ?.filter((i) => i <= _endMinute)
            const overnightInterval = hit.status.availabilities?.overnight
                ?.filter((i) => i >= _startMinute)
                ?.filter((i) => i <= _endMinute)
            const hourlyInterval = hit.status.availabilities?.hourly
                ?.filter((i) => i >= _startMinute)
                ?.filter((i) => i <= _endMinute)
            const dayInterval = hit.status.availabilities?.day
                ?.filter((i) => i >= _startMinute)
                ?.filter((i) => i <= _endMinute)
            const bookTypes = _bookTypesString?.split(',') ?? []
            if (spaceType === 'rest') {
                if (bookTypes.length === 1 && bookTypes.includes('hour')) {
                    isAvailableByBookType = hourInterval?.length > 0
                } else if (bookTypes.length === 1 && bookTypes.includes('session')) {
                    isAvailableByBookType = sessionInterval?.length > 0
                } else if (bookTypes.length === 1 && bookTypes.includes('overnight')) {
                    isAvailableByBookType = overnightInterval?.length > 0
                } else if (
                    bookTypes.length === 2 &&
                    bookTypes.includes('hour') &&
                    bookTypes.includes('session')
                ) {
                    isAvailableByBookType = hourInterval?.length > 0 || sessionInterval?.length > 0
                } else if (
                    bookTypes.length === 2 &&
                    bookTypes.includes('hour') &&
                    bookTypes.includes('overnight')
                ) {
                    isAvailableByBookType = hourInterval?.length > 0 || overnightInterval?.length > 0
                } else if (
                    bookTypes.length === 2 &&
                    bookTypes.includes('session') &&
                    bookTypes.includes('overnight')
                ) {
                    isAvailableByBookType = sessionInterval?.length > 0 || overnightInterval?.length > 0
                } else {
                    isAvailableByBookType =
                        hourInterval?.length > 0 ||
                        sessionInterval?.length > 0 ||
                        overnightInterval?.length > 0
                }
            } else if (spaceType === 'work' || spaceType === 'meet') {
                if (bookTypes.length === 1 && bookTypes.includes('hourly')) {
                    isAvailableByBookType = hourlyInterval?.length > 0
                } else if (bookTypes.length === 1 && bookTypes.includes('day')) {
                    isAvailableByBookType = dayInterval?.length > 0
                } else {
                    isAvailableByBookType = hourlyInterval?.length > 0 || dayInterval?.length > 0
                }
            } else if (spaceType === 'party') {
                isAvailableByBookType = hourlyInterval?.length > 0
            } else {
                isAvailableByBookType =
                    hourInterval?.length > 0 ||
                    sessionInterval?.length > 0 ||
                    overnightInterval?.length > 0 ||
                    hourlyInterval?.length > 0 ||
                    dayInterval?.length > 0
            }
            if (isNonHotelSpaceType(spaceType) && hit.status?.availabilities?.hourly) {
                const MIDNIGHT_RANGE = {
                    start: new Date(date + ' 00:00:00').valueOf() / DATE_TIME.MILLIS_ONE_MINUTE,
                    end: Math.floor(new Date(date + ' 05:59:59').valueOf() / DATE_TIME.MILLIS_ONE_MINUTE)
                }
                const MORNING_RANGE = {
                    start: new Date(date + ' 06:00:00').valueOf() / DATE_TIME.MILLIS_ONE_MINUTE,
                    end: Math.floor(new Date(date + ' 11:59:59').valueOf() / DATE_TIME.MILLIS_ONE_MINUTE)
                }
                const AFTERNOON_RANGE = {
                    start: new Date(date + ' 12:00:00').valueOf() / DATE_TIME.MILLIS_ONE_MINUTE,
                    end: Math.floor(new Date(date + ' 17:59:59').valueOf() / DATE_TIME.MILLIS_ONE_MINUTE)
                }
                const EVENING_RANGE = {
                    start: new Date(date + ' 18:00:00').valueOf() / DATE_TIME.MILLIS_ONE_MINUTE,
                    end: Math.floor(new Date(date + ' 23:59:59').valueOf() / DATE_TIME.MILLIS_ONE_MINUTE)
                }
                const midnightRange = hit.status?.availabilities?.hourly
                    .filter(interval => MIDNIGHT_RANGE.start <= interval && interval <= MIDNIGHT_RANGE.end)
                let midnightTime
                if (midnightRange.length > 1) {
                    midnightTime = {
                        start: getTimeFromTimestamp(
                            midnightRange[0] * DATE_TIME.MILLIS_ONE_MINUTE, region
                        ),
                        end: getTimeFromTimestamp(
                            midnightRange[midnightRange.length - 1] * DATE_TIME.MILLIS_ONE_MINUTE, region
                        )
                    }
                }
                const morningRange = hit.status?.availabilities?.hourly
                    .filter(interval => MORNING_RANGE.start <= interval && interval <= MORNING_RANGE.end)
                let morningTime
                if (morningRange.length > 1) {
                    morningTime = {
                        start: getTimeFromTimestamp(
                            morningRange[0] * DATE_TIME.MILLIS_ONE_MINUTE, region
                        ),
                        end: getTimeFromTimestamp(
                            morningRange[morningRange.length - 1] * DATE_TIME.MILLIS_ONE_MINUTE, region
                        )
                    }
                }
                const afternoonRange = hit.status?.availabilities?.hourly
                    .filter(interval => AFTERNOON_RANGE.start <= interval && interval <= AFTERNOON_RANGE.end)
                let afternoonTime
                if (afternoonRange.length > 1) {
                    afternoonTime = {
                        start: getTimeFromTimestamp(
                            afternoonRange[0] * DATE_TIME.MILLIS_ONE_MINUTE, region
                        ),
                        end: getTimeFromTimestamp(
                            afternoonRange[afternoonRange.length - 1] * DATE_TIME.MILLIS_ONE_MINUTE, region
                        )
                    }
                }
                const eveningRange = hit.status?.availabilities?.hourly
                    .filter(interval => EVENING_RANGE.start <= interval && interval <= EVENING_RANGE.end)
                let eveningTime
                if (eveningRange.length > 1) {
                    eveningTime = {
                        start: getTimeFromTimestamp(
                            eveningRange[0] * DATE_TIME.MILLIS_ONE_MINUTE, region
                        ),
                        end: getTimeFromTimestamp(
                            eveningRange[eveningRange.length - 1] * DATE_TIME.MILLIS_ONE_MINUTE, region
                        )
                    }
                }
                hit.otherTimes = {
                    midnightTime,
                    morningTime,
                    afternoonTime,
                    eveningTime
                }
            }
        }
    }

    const status = hit.status ?? {}
    return {
        ...hit,
        images,
        queryID,
        next_available,
        isAvailableByBookType,
        status
    }
}

const mapToNearbyDistance = (exactSpace, hit) => {
    const lat1 = exactSpace._geoloc[0]
    const lng1 = exactSpace._geoloc[1]
    const lat2 = hit._geoloc[0]
    const lng2 = hit._geoloc[1]
    if (lat1 && lng1 && lat2 && lng2) {
        hit.nearbyDistance = calcDirectDistKm(lat1, lng1, lat2, lng2)
    }
    return hit
}

const computeNextAvailableTime = (
    availabilities,
    spaceType,
    bookTypesString,
    endMinute,
    region
) => {
    // const endMinuteInInterval = endMinute / DATE_TIME.MILLIS_ONE_MINUTE
    const bookTypes = bookTypesString?.split(',') ?? []
    let nextAvailable
    const nowValue = Date.now() / DATE_TIME.MILLIS_ONE_MINUTE
    if (spaceType === 'rest') {
        const hourAvails = availabilities.hour
            ?.filter(_ => _ > nowValue)
            ?.sort((a, b) => a - b)
        const sessionAvails = availabilities.session
            ?.filter(_ => _ > nowValue)
            ?.sort((a, b) => a - b)
        const overnightAvails = availabilities.overnight
            ?.filter(_ => _ > nowValue)
            ?.sort((a, b) => a - b)
        let nextHour = ' '
        let nextSession = ''
        let nextOvernight = ''
        if (hourAvails) nextHour = hourAvails[0]
        if (sessionAvails) nextSession = sessionAvails[0]
        if (overnightAvails) nextOvernight = overnightAvails[0]
        if (bookTypes.length === 1 && bookTypes.includes('hour')) {
            nextAvailable = nextHour
        } else if (bookTypes.length === 1 && bookTypes.includes('session')) {
            nextAvailable = nextSession
        } else if (bookTypes.length === 1 && bookTypes.includes('overnight')) {
            nextAvailable = nextOvernight
        } else if (
            bookTypes.length === 2 &&
            bookTypes.includes('hour') &&
            bookTypes.includes('session')
        ) {
            nextAvailable = Math.min(
                ...[nextHour, nextSession].filter(Number.isFinite)
            )
        } else if (
            bookTypes.length === 2 &&
            bookTypes.includes('hour') &&
            bookTypes.includes('overnight')
        ) {
            nextAvailable = Math.min(
                ...[nextHour, nextOvernight].filter(Number.isFinite)
            )
        } else if (
            bookTypes.length === 2 &&
            bookTypes.includes('session') &&
            bookTypes.includes('overnight')
        ) {
            nextAvailable = Math.min(
                ...[nextSession, nextOvernight].filter(Number.isFinite)
            )
        } else {
            nextAvailable = Math.min(
                ...[nextHour, nextSession, nextOvernight].filter(Number.isFinite)
            )
        }
    } else if (spaceType === 'work' || spaceType === 'meet') {
        const hourlyAvails = availabilities.hourly
            ?.filter(_ => _ > nowValue)
            ?.sort((a, b) => a - b)
        const dayAvails = availabilities.day
            ?.filter(_ => _ > nowValue)
            ?.sort((a, b) => a - b)
        let nextHourly = ' '
        let nextDay = ''
        if (hourlyAvails) nextHourly = hourlyAvails[0]
        if (dayAvails) nextDay = dayAvails[0]
        if (bookTypes.length === 1 && bookTypes.includes('hourly')) {
            nextAvailable = nextHourly
        } else if (bookTypes.length === 1 && bookTypes.includes('day')) {
            nextAvailable = nextDay
        } else {
            nextAvailable = Math.min(...[nextHourly, nextDay].filter(Number.isFinite))
        }
    } else if (spaceType === 'party') {
        const hourlyAvails = availabilities.hourly
            ?.filter(_ => _ > nowValue)
            ?.sort((a, b) => a - b)
        nextAvailable = hourlyAvails[0]
    }
    return nextAvailable > 0 && isFinite(nextAvailable)
        ? getDateTimeFromTimestamp(
            nextAvailable * DATE_TIME.MILLIS_ONE_MINUTE,
            region
        )
        : undefined
}

const mapToWorkerIdentifier = (hit) => {
    return {
        objectID: hit.id,
        tags: hit.tags ?? [],
        last_reservation_timestamp: hit.ranking?.last_reservation_timestamp
    }
}

const computeNextState = (currFetchState) => {
    let nextState = currFetchState
    if (currFetchState === FETCH_STATE.SPACE) {
        nextState = FETCH_STATE.NEARBY
    } else if (currFetchState === FETCH_STATE.NEARBY) {
        nextState = FETCH_STATE.AVAILABLE
    } else if (currFetchState === FETCH_STATE.AREA) {
        nextState = FETCH_STATE.DISTRICT
    } else if (currFetchState === FETCH_STATE.DISTRICT) {
        nextState = FETCH_STATE.AVAILABLE
    } else if (currFetchState === FETCH_STATE.META_DISTRICT) {
        nextState = FETCH_STATE.AVAILABLE
    } else if (currFetchState === FETCH_STATE.CITY) {
        nextState = FETCH_STATE.AVAILABLE
    } else if (currFetchState === FETCH_STATE.STATE) {
        nextState = FETCH_STATE.AVAILABLE
    } else if (currFetchState === FETCH_STATE.AVAILABLE) {
        nextState = FETCH_STATE.ALTERNATIVE
    } else if (currFetchState === FETCH_STATE.ALTERNATIVE) {
        nextState = FETCH_STATE.UNAVAILABLE
    } else if (currFetchState === FETCH_STATE.UNAVAILABLE) {
        nextState = FETCH_STATE.DONE
    } else if (currFetchState === FETCH_STATE.FALLBACK_EXACT_TIME) {
        nextState = FETCH_STATE.FALLBACK_ASAP_TIME
    } else if (currFetchState === FETCH_STATE.FALLBACK_ASAP_TIME) {
        nextState = FETCH_STATE.FALLBACK_ANYTIME
    } else if (currFetchState === FETCH_STATE.FALLBACK_ANYTIME) {
        nextState = FETCH_STATE.UNAVAILABLE
    }
    return nextState
}

const searchSuggestionIndex = async (payload) => {
    const searchParams = {
        page: 1,
        per_page: SEARCH.HITS_PER_PAGE_SUGGESTION,
        // analytics: true, TODO: remove / migrate
        // clickAnalytics: true, TODO: remove / migrate
        filter_by: `id:=[${payload.suggestionIDs}]`,
        queryParameters: {
            region: payload.region,
            query_suggestion: true
        }
    }
    const searchPayload = {
        ...searchParams,
        q: '*',
    }
    return await executeAlgoliaSearch(payload.algoliaIndex, searchPayload, true)
}

const compareSpaceByAvailability = (a, b) => {
    return b.isAvailable - a.isAvailable
}

const compareSpaceByNextAvailable = (a, b) => {
    if (b.isAvailable !== a.isAvailable) {
        return b.isAvailable - a.isAvailable
    }
    if (a.next_available && b.next_available) {
        return compareAsc(parseISO(a.next_available), parseISO(b.next_available))
    }
    if (a.next_available && !b.next_available) {
        return -1
    }
    if (a.next_available && !b.next_available) {
        return 1
    }
    return 0
}

const computeFallbackSearchType = (time) => {
    if (time === DATE_TIME.ANY_CHECK_IN_TIME) {
        return FALLBACK_SEARCH_TYPE.TYPE_ANY_TIME
    }
    if (time === DATE_TIME.ASAP_CHECK_IN_TIME) {
        return FALLBACK_SEARCH_TYPE.TYPE_ASAP_TIME
    }
    return FALLBACK_SEARCH_TYPE.TYPE_EXACT_TIME
}

const computeStarRankingEval = (isHighToLow) => {
    return (
        isHighToLow
            ? ['(ranking.star_rating:5):29',
            '(ranking.star_rating:4.5):28',
            '(ranking.star_rating:4):27',
            '(ranking.star_rating:3.5):26',
            '(ranking.star_rating:3):25',
            '(ranking.star_rating:2.5):24',
            '(ranking.star_rating:2):23',
            '(ranking.star_rating:1.5):22',
            '(ranking.star_rating:1):21']
            : ['(ranking.star_rating:1):29',
            '(ranking.star_rating:1.5):28',
            '(ranking.star_rating:2):27',
            '(ranking.star_rating:2.5):26',
            '(ranking.star_rating:3):25',
            '(ranking.star_rating:3.5):24',
            '(ranking.star_rating:4):23',
            '(ranking.star_rating:4.5):22',
            '(ranking.star_rating:5):21']
    )
        .join(',')
}

const computeOptionalUserRatingFilter = (isHighToLow = true) => {
    const MIN_RATING = 0.1
    const MAX_RATING = 5.0
    let filter = []
    for (let i = MAX_RATING; i >= MIN_RATING; i -= 0.1) {
        const currIndex = i.toFixed(1)
        // filter += `top_comments_average_rating:${currIndex}<score=${currIndex * 10}>`
        // filter += currIndex === MIN_RATING.toFixed(1) ? '' : ' || '
        filter.push(`top_comments_average_rating:${currIndex}<score=${currIndex * 10}>`)
    }
    // High to Low / Low to High
    // if (isHighToLow) {
    //     for (let i = MAX_RATING; i <= MIN_RATING; i -= 0.1) {
    //         const currIndex = i.toFixed(1)
    //         filter += `top_comments_average_rating:${currIndex}<score=${currIndex * 10}>`
    //         filter += currIndex === MIN_RATING.toFixed(1) ? '' : ' || '
    //     }
    // } else {
    //     for (let i = MIN_RATING; i <= MAX_RATING; i += 0.1) {
    //         const currIndex = i.toFixed(1)
    //         filter += `top_comments_average_rating:${currIndex}<score=${currIndex * 10}>`
    //         filter += currIndex === MAX_RATING.toFixed(1) ? '' : ' || '
    //     }
    // }
    return filter
}

/** Example Payload
 * query
 * algoliaLang
 * group
 */
const computeSuggestionFilter = (payload) => {
    const langFilter = payload.query.trim().length <= 0
        ? `lang:=${payload.algoliaLang}`
        : ''
    let groupFilter
    if (payload.region === 'my') {
        groupFilter = payload.group ? `location_groups:=[${payload.group}]` : ''
    } else {
        groupFilter = 'location_groups:=[ALL]'
    }
    if (langFilter && groupFilter) {
        return `${langFilter} && ${groupFilter}`
    } else if (langFilter && !groupFilter) {
        return langFilter
    } else {
        return groupFilter
    }
}

const periodTimeToBookTypeString = (time) => {
    const bookTypes = []
    if (
        time.includes(DATE_TIME.MIDNIGHT_TIME) ||
        time.includes(DATE_TIME.MORNING_TIME) ||
        time.includes(DATE_TIME.AFTERNOON_TIME) ||
        time.includes(DATE_TIME.EVENING_TIME)
    ) {
        bookTypes.push('hour')
        bookTypes.push('session')
    }
    if (
        time.includes(DATE_TIME.ASAP_CHECK_IN_TIME_N)  ||
        time.includes(DATE_TIME.AFTER_X_TIME) ||
        time.includes(DATE_TIME.AFTER_MIDNIGHT_TIME)
    ) {
        bookTypes.push('overnight')
    }
    return bookTypes.join(',')
}

const computeTimeByFetchState = (fetchState, time) => {
    if (
        fetchState === FETCH_STATE.ALTERNATIVE ||
        fetchState === FETCH_STATE.UNAVAILABLE ||
        fetchState === FETCH_STATE.FALLBACK_ANYTIME
    ) {
        return DATE_TIME.ANY_CHECK_IN_TIME
    }
    return time
}

const computeSortEvalExpression = (defaultEval, customEvals) => {
    let evalList = [
        ...defaultEval,
        ...customEvals,
    ]
    return `_eval([${evalList.join(',')}]):desc`
}

export {
    searchIndex,
    searchSuggestionIndex,
    fetchAdditionalInfo,
    mapToWorkerIdentifier,
    initClient,
    initCustomHostClient,
    compareSpaceByAvailability,
    compareSpaceByNextAvailable,
    computeFallbackSearchType,
    computeSuggestionFilter,
    computeTimeByFetchState,
    executeAlgoliaSearch
}
