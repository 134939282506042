<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div @click.stop class="d-flex justify-content-center">
                        <div class="bg-light content">
                            <div class="d-flex justify-content-end pt-2 pr-2">
                                <b-button
                                    @click="close"
                                    class="close-btn bg-transparent border-0 text-292929 text-ikonoMini-4"
                                >
                                    
                                </b-button>
                            </div>
                            <div
                                style="min-width: 300px"
                                class="content-container py-4 px-4"
                            >
                                <p class="text-14 text-bold text-606060 mr-2">
                                    {{ $t('member.phone_auth') }}
                                </p>
                                <div class="mb-3 d-inline-flex">
                                    <p
                                        class="text-12 text-mid text-606060 mr-1"
                                    >
                                        {{ $t('member.phone_auth_dis') }}
                                    </p>
                                    <b-button
                                        @click="signOut"
                                        variant="transparent"
                                        class="p-0 text-12 text-bold text-primary"
                                        style="text-decoration: underline;"
                                    >
                                        {{ $t('signout') }}
                                    </b-button>
                                </div>
                                <div class="mb-3">
                                    <p
                                        class="text-12 text-bold text-606060 mb-2"
                                    >
                                        {{ $t('member.phone') }}
                                    </p>
                                    <div class="d-flex mb-2">
                                        <b-dropdown
                                            :disabled="signup.count > 0"
                                            :class="
                                                signup.count > 0
                                                    ? 'input-field-disable'
                                                    : 'input-field'
                                            "
                                            lazy
                                            no-flip
                                            class="p-0 m-0 phone-code-dropdown flex-fill"
                                            style="border: 1px solid #ced4da; border-radius: 0.25rem;"
                                            variant="transparent"
                                        >
                                            <template #button-content>
                                                <span
                                                    class="text-14 text-mid text-606060"
                                                >
                                                    {{ signup.phoneCode.code }}
                                                </span>
                                            </template>
                                            <b-dropdown-item
                                                v-for="(item,
                                                index) in countryCode"
                                                :key="index"
                                                @click="signup.phoneCode = item"
                                                class="mb-2"
                                            >
                                                <p class="text-14 text-mid">
                                                    {{
                                                        $store.state.locale ===
                                                        'en'
                                                            ? item.English
                                                            : item.Hongkong
                                                    }}
                                                </p>
                                                <p class="text-16 text-s-bold">
                                                    {{ item.code }}
                                                </p>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                        <b-form-input
                                            v-model="signup.phone"
                                            :disabled="signup.count > 0"
                                            :class="
                                                signup.count > 0
                                                    ? 'input-field-disable'
                                                    : 'input-field'
                                            "
                                            class="ml-2"
                                            type="tel"
                                        ></b-form-input>
                                    </div>
                                    <b-button
                                        :disabled="
                                            isSubmitting ||
                                                signup.phone === '' ||
                                                signup.count > 0
                                        "
                                        @click="sendSms"
                                        variant="primary"
                                        style="white-space: nowrap;"
                                        class="text-14 text-mid text-light container-fluid m-0"
                                    >
                                        <div
                                            v-if="
                                                isSubmitting || signup.count > 0
                                            "
                                            class="d-flex justify-content-center align-items-center"
                                        >
                                            <b-spinner
                                                variant="light"
                                                class="mr-2"
                                                small
                                            ></b-spinner>
                                            <p v-if="signup.count > 0">
                                                {{ signup.count }}
                                            </p>
                                        </div>
                                        <p
                                            v-if="
                                                !isSubmitting &&
                                                    signup.count <= 0
                                            "
                                        >
                                            {{ $t('member.get_v_code') }}
                                        </p>
                                    </b-button>
                                </div>
                                <div class="mb-3">
                                    <p
                                        class="text-12 text-bold text-606060 mb-2"
                                    >
                                        {{ $t('member.v_code') }}
                                    </p>
                                    <b-form-input
                                        v-model="signup.passcode"
                                        :disabled="!signup.smsSent"
                                        :class="
                                            !signup.smsSent
                                                ? 'input-field-disable'
                                                : 'input-field'
                                        "
                                    />
                                </div>
                                <b-button
                                    :disabled="
                                        signup.passcode === '' &&
                                            !signup.vidSending
                                    "
                                    @click="verifyPhoneAndSubmit"
                                    variant="primary"
                                    class="container-fluid m-0 mb-5"
                                >
                                    <b-spinner
                                        v-if="signup.vidSending"
                                        variant="light"
                                        class="mr-2"
                                        small
                                    ></b-spinner>
                                    <p
                                        v-if="!signup.vidSending"
                                        class="text-14 text-mid text-606060 text-light"
                                    >
                                        {{ $t('signup') }}
                                    </p>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="phone-auth-recaptcha-container"
                data-badge="inline"
                style="position: absolute; bottom: 0; right: 0;"
            ></div>
        </div>
    </transition>
</template>

<script>
import _sortBy from 'lodash/sortBy'
import firebase from '../../services/firebase'
import countryCode from '~/assets/json/country-code'
import Api from '~/services/api'

const api = Api.create()

export default {
    name: 'MemberPhoneAuth',
    data() {
        return {
            isSubmitting: false,
            countryCode: [],
            signup: {
                phone: '',
                phoneCode: {
                    English: 'Hong Kong',
                    China: '香港',
                    Taiwan: '香港',
                    Hongkong: '香港',
                    code: '+852'
                },
                passcode: '',
                count: -1,
                verificationId: '',
                smsSent: false,
                vidSending: false
            },
            option: {
                mode: 'native',
                vuescroll: {
                    sizeStrategy: 'number'
                },
                bar: {
                    showDelay: 2000
                },
                scrollPanel: {
                    scrollingX: false,
                    scrollingY: true,
                    speed: 300
                }
            }
        }
    },
    computed: {
        getSortedCountryCode() {
            const list = _sortBy(countryCode, 'English')
            const sortedList = _sortBy(list, (item) => {
                if (item.ISO3 === 'HKG') {
                    return 0
                } else if (item.ISO3 === 'SGP') {
                    return 0
                } else if (item.ISO3 === 'MAC') {
                    return 0
                } else if (item.ISO3 === 'TWN') {
                    return 0
                } else if (item.ISO3 === 'MYS') {
                    return 0
                } else if (item.ISO3 === 'VNM') {
                    return 0
                } else {
                    return 1
                }
            })
            return sortedList
        },
        lang() {
            return this.$route.params.locale
        }
    },
    mounted() {
        this.countryCode = this.getSortedCountryCode
        this.hideSlideBar(true)
        window.recaptchaVerifier = firebase.setReCAPTCHA(
            'phone-auth-recaptcha-container',
            {
                size: 'invisible'
            }
        )
    },
    methods: {
        sendSms() {
            const appVerifier = window.recaptchaVerifier
            const phoneNum = this.signup.phone
            const countryCode = this.signup.phoneCode.code

            this.isSubmitting = true

            firebase
                .sendPhoneNumber(countryCode + phoneNum, appVerifier)
                .then((res) => {
                    if (res.verificationId) {
                        this.setSmsResendTimer()
                        this.signup.verificationId = res.verificationId
                    } else {
                        throw new Error('flow/error')
                    }
                })
                .finally(() => {
                    this.isSubmitting = false
                })
                .catch((err) => {
                    if (err.code === 'auth/invalid-phone-number') {
                        switch (err.message) {
                            case 'TOO_SHORT':
                                this.showOkModal(this.$t('err_phone_too_short'))
                                break
                            case 'TOO_LONG':
                                this.showOkModal(this.$t('err_phone_too_long'))
                                break
                            default:
                                this.showOkModal(this.$t('err_phone_invalid'))
                        }
                    } else if (err.code === 'auth/invalid-email') {
                        this.showOkModal(this.$t('err_auth_invalid_email'))
                    } else {
                        let msg = ''
                        switch (err.message) {
                            case 'flow/email_exists':
                                msg = this.$t('err_email_exists')
                                break
                            case 'flow/error':
                                msg = this.$t('err_occurred')
                                break
                            default:
                                msg = err.message
                                break
                        }
                        this.showOkModal(msg)
                    }
                })
        },
        async verifyPhoneAndSubmit() {
            const passcode = this.signup.passcode
            const vid = this.signup.verificationId
            const dialCode = this.signup.phoneCode.code
            const phone = this.signup.phone
            this.signup.vidSending = true
            const visitorData = await this.$fpjs.getVisitorData({
                extendedResult: true
            })
            firebase
                .socialVerifySMSCode(vid, passcode)
                .then((res) => {
                    if (res) {
                        return firebase.getAnonymousIdToken()
                    } else {
                        throw new Error('flow/error')
                    }
                })
                .then((res) => {
                    const body = {
                        id_token: res.user.accessToken,
                        dial_code: dialCode,
                        phone,
                        fingerprint: visitorData.visitorId
                    }
                    return api.activateMember(body, this.lang)
                })
                .then((res) => {
                    if (res.data.Success) {
                        this.$store.dispatch('member/setUserPhoneAuthPassed')
                        this.showOkModal(
                            this.$t('member.register_success'),
                            'dismissModal'
                        )
                    } else {
                        throw new Error('flow/error')
                    }
                })
                .finally(() => {
                    this.signup.vidSending = false
                })
                .catch((err) => {
                    let message = this.$t('err_occurred')
                    if (err.code === 'auth/invalid-verification-code') {
                        message = this.$t('err_invalid_verification_code')
                    }
                    if (err.code === 'auth/credential-already-in-use') {
                        message = this.$t('err_phone_number_exists')
                    }
                    this.showOkModal(message)
                })
        },
        setSmsResendTimer() {
            const countDown = 60
            this.signup.count = countDown
            this.signup.smsSent = true
            this.emailTimer = setInterval(() => {
                this.signup.count = this.signup.count - 1
                if (this.signup.count <= 0) {
                    clearInterval(this.emailTimer)
                }
            }, 1000)
        },
        close() {
            this.hideSlideBar(false)
            this.$emit('close')
        },
        hideSlideBar(hide) {
            if (hide) {
                document.documentElement.style.overflow = 'hidden'
            } else {
                document.documentElement.style.overflow = 'auto'
            }
        },
        showOkModal(msg = '', action = '') {
            this.$bvModal
                .msgBoxOk(msg, {
                    okTitle: this.$t('ok'),
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                    contentClass: 'justify-content-center text-center',
                    footerClass: 'justify-content-center',
                    okVariant: 'dark',
                    size: 'sm',
                    buttonSize: 'sm',
                    centered: true
                })
                .then(() => {
                    if (action !== '') {
                        switch (action) {
                            case 'dismissModal':
                                this.close()
                                break
                        }
                    }
                })
        },
        signOut() {
            this.$bvModal
                .msgBoxConfirm(this.$t('member.logout_modal_content'), {
                    title: this.$t('signout'),
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'light',
                    okTitle: this.$t('signout'),
                    cancelVariant: 'light',
                    cancelTitle: this.$t('close'),
                    hideHeaderClose: true,
                    centered: true
                })
                .then((value) => {
                    if (value) {
                        firebase.signOut()
                        this.close()
                    }
                })
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 1038;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: table;
    transition: opacity 0.3s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-container {
    transition: all 300ms;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
}
.content {
    position: relative;
    border-radius: 10px;
}
.close-btn {
    font-size: 22px;
    position: absolute;
    right: -50px;
    top: 0;
    color: #ffffff;
    transform: rotate(45deg);
}
.slide-fade-enter-active .content-container {
}
.slide-fade-leave-active .content-container {
}
.slide-fade-enter, .slide-fade-leave-to .content-container
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
.phone-code-dropdown >>> .dropdown-menu {
    max-height: 25vh;
    max-width: 80vw;
    overflow-y: auto;
}
@media (max-width: 575.98px) {
    .content {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
    }
    .close-btn {
        font-size: 22px;
        position: unset;
        right: 0;
        top: 0;
        color: #505050;
        transform: rotate(45deg);
    }
}
</style>
