<template>
    <b-button
        @click="$emit('handleBtnClick')"
        variant="transparent"
        class="text-777777 px-0 button-active p-0 text-btn"
        style="text-underline-offset: 4px; white-space: nowrap;"
    >
        <slot />
    </b-button>
</template>
<script>
export default {
    name: 'TextButton',
    components: {}
}
</script>
<style>
.text-btn {
    text-decoration: underline;
}
@media (min-width: 992px) {
    .text-btn:hover {
        text-decoration: underline;
        color: #9d9d9d !important;
    }
}
</style>
