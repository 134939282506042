function validate(type, input) {
    // eslint-disable-next-line no-useless-escape
    const symbolsRegex = /[-!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/
    const numericRegex = /^\d+$/
    const englishRegex = /^[a-z A-Z]{1,}$/
    const vietnameseRegex = /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]+$/
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
    const passwordRegex = /^.{6,24}$/
    const nullRegex = /^.{1,}$/

    switch (type) {
        case this.type.email:
            return emailRegex.test(input)
        case this.type.english:
            return englishRegex.test(input)
        case this.type.vietnamese:
            return vietnameseRegex.test(input)
        case this.type.numeric:
            return numericRegex.test(input)
        case this.type.symbols:
            return symbolsRegex.test(input)
        case this.type.password:
            return passwordRegex.test(input)
        case this.type.checkNull:
            return nullRegex.test(input)
    }
}

const type = {
    email: 'email',
    english: 'english',
    vietnamese: 'vietnamese',
    numeric: 'numeric',
    symbols: 'symbols',
    checkNull: 'checkNull',
    password: 'password'
}

export default {
    type,
    validate
}
