<template>
    <a
        :class="deviceType() !== 'mobile' ? 'disable-hover' : ''"
        :href="googleDlUrl"
        @click="sendOutboundClickTrack(googleDlUrl)"
        aria-label="googleDlUrl"
        target="_blank"
        rel="noreferrer noopener"
        class="border-0"
    >
        <ImageContainer
            :style="`height: ${height}px; width: ${width}px;`"
            class="store-img"
            src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/baae91d9-9270-494d-886d-d82673214b00/mobile"
            alt="Google Play"
            width="564"
            height="170"
        />
    </a>
</template>
<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'GooglePlayIcon',
    components: {ImageContainer},
    props: {
        height: {
            type: Number,
            default: 45
        },
        width: {
            type: Number
        }
    },
    data() {
        return {
            // googleDlUrl: {
            //     hk:
            //         'https://play.google.com/store/apps/details?id=com.flowtheroom.userapp',
            //     sg:
            //         'https://play.google.com/store/apps/details?id=com.flowtheroom.userapp&gl=SG',
            //     mo:
            //         'https://play.google.com/store/apps/details?id=com.flowtheroom.userapp',
            //     my:
            //         'https://play.google.com/store/apps/details?id=com.flowtheroom.userapp',
            //     vn:
            //         'https://play.google.com/store/apps/details?id=com.flowtheroom.userapp'
            // }
            googleDlUrl: 'https://link.flowtheroom.com/XkDW/leckko16'
        }
    },
    computed: {
        region() {
            return this.$route.params.region
        }
    },
    methods: {
        sendOutboundClickTrack(url) {
            this.$gtag('event', 'click', {
                event_category: 'outbound',
                event_label: url,
                transport_type: 'beacon'
            })
        }
    }
}
</script>
<style scoped>
.store-img {
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
}
.store-img {
    height: 45px;
    width: auto;
}
</style>
