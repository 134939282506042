<template>
    <b-button
        :class="`${btnColor}-btn`"
        @click="$emit('handleBtnClick')"
        aria-label="close button"
        variant="transparent"
        class="position-relative p-0 border-0 circle-close-btn"
    >
        <Close :class="`${btnColor}-btn`" class="cross" />
    </b-button>
</template>
<script>
import Close from '~/components/iconSvg/Close'
export default {
    name: 'CircleCloseBtn',
    components: { Close },
    props: {
        btnColor: {
            type: String,
            default: 'black'
        }
    }
}
</script>
<style scoped>
.cross {
    height: 18px;
    width: 18px;
    position: absolute;
}
.circle-close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 28px;
    height: 28px;
}
@media (max-width: 991px) {
    .circle-close-btn {
        width: 24px;
        height: 24px;
    }
}
.black-btn {
    fill: #ffffff;
    background-color: black;
}
.grey-btn {
    fill: #000000;
    background-color: #f7f7f7;
}
.transparent-btn {
    fill: #ffffff;
    background-color: #d5b856;
}
</style>
