import NextApi from '@/services/nextApi'

const nextApi = NextApi.api

const state = () => ({
    isLocaleModalVisible: false,
    localeModalMode: MODAL_MODE.LOCALE,
    detectedRegion: undefined,
    detectedGroup: undefined,
    prefGroup: undefined,
    periodModalDate: undefined,
    periodModalServiceType: undefined
})

const getters = {
    isLocaleModalVisible: (state) => {
        return state.isLocaleModalVisible
    },
    localeModalMode: (state) => {
        return state.localeModalMode
    },
    detectedRegion: (state) => {
        return state.detectedRegion
    },
    detectedGroup: (state) => {
        return state.detectedGroup
    },
    prefGroup: (state) => {
        return state.prefGroup
    },
    periodModalDate: (state) => {
        return state.periodModalDate
    },
    periodModalServiceType: (state) => {
        return state.periodModalServiceType
    }
}

const mutations = {
    COMMIT_LOCALE_MODAL_VISIBLE(state, payload) {
        state.isLocaleModalVisible = payload.isVisible
        state.localeModalMode = payload.mode ?? MODAL_MODE.LOCALE
    },
    SET_DEFAULT_REGION_SETTING(state, payload) {
        state.detectedRegion = payload.region
        state.detectedGroup = payload.groups
    },
    SET_PREF_GROUP(state, payload) {
        state.prefGroup = payload.group
    },
    SET_PERIOD_MODAL_DATE(state, payload) {
        state.periodModalDate = payload.date
    },
    SET_PERIOD_MODAL_SERVICE_TYPE(state, payload) {
        state.periodModalServiceType = payload.type
    }
}

const actions = {
    SET_LOCALE_MODAL_VISIBLE: (context, payload) => {
        context.commit('COMMIT_LOCALE_MODAL_VISIBLE', payload)
    },
    GET_DEFAULT_REGION_SETTING: async (context, payload) => {
        try {
            const res = await nextApi.getDefaultRegionSetting(payload)
            if (res.data.Success) {
                context.commit('SET_DEFAULT_REGION_SETTING', res.data.Data)
            }
        } catch (error) {
            // no-op
        }
    },
    REMOVE_DEFAULT_REGION_SETTING: (context) => {
        context.commit('SET_DEFAULT_REGION_SETTING', {
            region: undefined,
            groups: undefined
        })
    },
    UPDATE_PREF_GROUP: (context, payload) => {
        context.commit('SET_PREF_GROUP', payload)
    },
    UPDATE_PERIOD_MODAL_DATE: (context, payload) => {
        context.commit('SET_PERIOD_MODAL_DATE', payload)
    },
    UPDATE_PERIOD_MODAL_SERVICE_TYPE:(context, payload) => {
        context.commit('SET_PERIOD_MODAL_SERVICE_TYPE', payload)
    }
}

const MODAL_MODE = {
    LOCALE: 'LOCALE',
    GROUP: 'GROUP'
}

export { state, getters, mutations, actions, MODAL_MODE }
