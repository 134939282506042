var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"d-flex justify-content-center",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"bg-light content"},[_c('div',{staticClass:"d-flex justify-content-end pt-2 pr-2"},[_c('b-button',{staticClass:"close-btn bg-transparent border-0 text-292929 text-ikonoMini-4",on:{"click":_vm.close}},[_vm._v("\n                                \n                            ")])],1),_vm._v(" "),_c('div',{staticClass:"content-container py-4 px-4",staticStyle:{"min-width":"300px"}},[_c('p',{staticClass:"text-14 text-bold text-606060 mr-2"},[_vm._v("\n                                "+_vm._s(_vm.$t('member.phone_auth'))+"\n                            ")]),_vm._v(" "),_c('div',{staticClass:"mb-3 d-inline-flex"},[_c('p',{staticClass:"text-12 text-mid text-606060 mr-1"},[_vm._v("\n                                    "+_vm._s(_vm.$t('member.phone_auth_dis'))+"\n                                ")]),_vm._v(" "),_c('b-button',{staticClass:"p-0 text-12 text-bold text-primary",staticStyle:{"text-decoration":"underline"},attrs:{"variant":"transparent"},on:{"click":_vm.signOut}},[_vm._v("\n                                    "+_vm._s(_vm.$t('signout'))+"\n                                ")])],1),_vm._v(" "),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"text-12 text-bold text-606060 mb-2"},[_vm._v("\n                                    "+_vm._s(_vm.$t('member.phone'))+"\n                                ")]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2"},[_c('b-dropdown',{staticClass:"p-0 m-0 phone-code-dropdown flex-fill",class:_vm.signup.count > 0
                                                ? 'input-field-disable'
                                                : 'input-field',staticStyle:{"border":"1px solid #ced4da","border-radius":"0.25rem"},attrs:{"disabled":_vm.signup.count > 0,"lazy":"","no-flip":"","variant":"transparent"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"text-14 text-mid text-606060"},[_vm._v("\n                                                "+_vm._s(_vm.signup.phoneCode.code)+"\n                                            ")])]},proxy:true}])},[_vm._v(" "),_vm._l((_vm.countryCode),function(item,index){return _c('b-dropdown-item',{key:index,staticClass:"mb-2",on:{"click":function($event){_vm.signup.phoneCode = item}}},[_c('p',{staticClass:"text-14 text-mid"},[_vm._v("\n                                                "+_vm._s(_vm.$store.state.locale ===
                                                    'en'
                                                        ? item.English
                                                        : item.Hongkong)+"\n                                            ")]),_vm._v(" "),_c('p',{staticClass:"text-16 text-s-bold"},[_vm._v("\n                                                "+_vm._s(item.code)+"\n                                            ")])])})],2),_vm._v(" "),_c('b-form-input',{staticClass:"ml-2",class:_vm.signup.count > 0
                                                ? 'input-field-disable'
                                                : 'input-field',attrs:{"disabled":_vm.signup.count > 0,"type":"tel"},model:{value:(_vm.signup.phone),callback:function ($$v) {_vm.$set(_vm.signup, "phone", $$v)},expression:"signup.phone"}})],1),_vm._v(" "),_c('b-button',{staticClass:"text-14 text-mid text-light container-fluid m-0",staticStyle:{"white-space":"nowrap"},attrs:{"disabled":_vm.isSubmitting ||
                                            _vm.signup.phone === '' ||
                                            _vm.signup.count > 0,"variant":"primary"},on:{"click":_vm.sendSms}},[(
                                            _vm.isSubmitting || _vm.signup.count > 0
                                        )?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('b-spinner',{staticClass:"mr-2",attrs:{"variant":"light","small":""}}),_vm._v(" "),(_vm.signup.count > 0)?_c('p',[_vm._v("\n                                            "+_vm._s(_vm.signup.count)+"\n                                        ")]):_vm._e()],1):_vm._e(),_vm._v(" "),(
                                            !_vm.isSubmitting &&
                                                _vm.signup.count <= 0
                                        )?_c('p',[_vm._v("\n                                        "+_vm._s(_vm.$t('member.get_v_code'))+"\n                                    ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"mb-3"},[_c('p',{staticClass:"text-12 text-bold text-606060 mb-2"},[_vm._v("\n                                    "+_vm._s(_vm.$t('member.v_code'))+"\n                                ")]),_vm._v(" "),_c('b-form-input',{class:!_vm.signup.smsSent
                                            ? 'input-field-disable'
                                            : 'input-field',attrs:{"disabled":!_vm.signup.smsSent},model:{value:(_vm.signup.passcode),callback:function ($$v) {_vm.$set(_vm.signup, "passcode", $$v)},expression:"signup.passcode"}})],1),_vm._v(" "),_c('b-button',{staticClass:"container-fluid m-0 mb-5",attrs:{"disabled":_vm.signup.passcode === '' &&
                                        !_vm.signup.vidSending,"variant":"primary"},on:{"click":_vm.verifyPhoneAndSubmit}},[(_vm.signup.vidSending)?_c('b-spinner',{staticClass:"mr-2",attrs:{"variant":"light","small":""}}):_vm._e(),_vm._v(" "),(!_vm.signup.vidSending)?_c('p',{staticClass:"text-14 text-mid text-606060 text-light"},[_vm._v("\n                                    "+_vm._s(_vm.$t('signup'))+"\n                                ")]):_vm._e()],1)],1)])])])]),_vm._v(" "),_c('div',{staticStyle:{"position":"absolute","bottom":"0","right":"0"},attrs:{"id":"phone-auth-recaptcha-container","data-badge":"inline"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }