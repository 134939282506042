<template>
    <div class="fixed-bottom assistant-btn">
        <div
            @click="onFloatingButtonClicked"
            class="fab-container"
            style="cursor:pointer; pointer-events:auto;"
        >
            <div class="wrapper shadow">
                <div class="fab-content">
                    <Chatbot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Chatbot from '@/components/iconSvg/Chatbot'

export default {
    name: 'AssistantButton',
    components: { Chatbot },
    methods: {
        onFloatingButtonClicked() {
            this.$emit('onFloatingButtonClicked')
        }
    }
}
</script>

<style scoped>
.fab-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    user-select: none;
}
.wrapper {
    position: relative;
    height: 70px;
    width: 70px;
    background-color: #ffc000;
    border-radius: 50%;
    z-index: 2;
}
.wrapper::before {
    content: ' ';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 35px;
    width: 35px;
    background-color: inherit;
    border-radius: 0 0 10px 0;
    z-index: -1;
}
.fab-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.assistant-btn {
    position: relative;
    bottom: 20px;
    margin-left: auto;
    margin-right: 0;
}
</style>
