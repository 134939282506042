<template>
    <div>
        <AppHeader v-if="!hideHeader" />
        <client-only>
            <div v-if="isVNTestEnv" class="beta-ribbon p-3 text-14 text-mid">
                {{ $t('website_beta_message') }}
            </div>
        </client-only>
        <nuxt style="min-height: 100vh" />
        <AppFooter v-if="!hideFooter" />
        <AssistantButton
            v-if="isHomePage"
            @onFloatingButtonClicked="navigateToContactUs"
        />
        <div class="fixed-bottom">
            <WebToAppBanner
                v-if="!isHideBanner"
                @close="closeWebToAppBanner"
                class="position-relative"
            />
            <GdrpSnackBar v-if="!hideCookies" @close="closeGdrpBar" />
        </div>
        <client-only>
            <OpenExternalBrowserGuide v-if="isTiktokInAppBrowser" />
        </client-only>
    </div>
</template>
<script>
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth'
import Vue from 'vue'
import GdrpSnackBar from '../components/GdrpSnackBar'
import storage from '../services/storage'
import Api from '../services/api'
import analysis from '@/services/analysis'
import global from '~/mixins/global'
import version from '~/static/version.json'
import AssistantButton from '@/components/home/AssistantButton'
import WebToAppBanner from '~/components/WebToAppBanner'
import AppHeader from '~/components/Header'
import AppFooter from '~/components/Footer'
import OpenExternalBrowserGuide from '@/components/ui/OpenExternalBrowserGuide.vue'
import firebaseService from '@/services/firebase'
import { MODAL_MODE } from '@/store/general'
import { firebaseAuth } from '@/plugins/firebase'
import { getMetaLink } from '@/utils/metaData'
Vue.mixin(global)

export default {
    components: {
        OpenExternalBrowserGuide,
        AppFooter,
        AppHeader,
        WebToAppBanner,
        GdrpSnackBar,
        AssistantButton
    },
    data() {
        return {
            hideHeader: false,
            hideFooter: false,
            hideCookies: true,
            hideBanner: true
            // redirect: true
        }
    },
    head() {
        const routeName = this.$route.name
        const fullPath = this.$route.fullPath
        const routePath = this.$route.path
        const regex = /\?q=([^&]+)&es_type=space/
        const isSearchPage = regex.exec(fullPath)
        const canonicalPath = isSearchPage ? fullPath : routePath
        const isRemoveHreflang =
            routeName === 'region-locale-blog-id' ||
            routeName === 'region-locale-blog-tag-id' ||
            routeName === 'region-locale-blog-author-id' ||
            routeName === 'region-locale-promotions-id' ||
            isSearchPage
        const defaultLinks = [
            {
                hid: 'canonical',
                rel: 'canonical',
                href: `https://app.flowtheroom.com${canonicalPath}`
            }
        ]
        const meta = [
            {
                hid: 'og:locale',
                property: 'og:locale',
                content: this.lang + '_' + this.region?.toUpperCase()
            }
        ]
        // disallow dynamic links with query being indexed
        const isDisallowIndex = Object.keys(this.$route.query).length !== 0
        if (isDisallowIndex) {
            meta.push({
                hid: 'robots',
                name: 'robots',
                content: 'noindex, nofollow'
            })
        }
        // const isRegionHreflang =
        //     this.$route.name === 'region-locale-spaceName-space' ||
        //     this.$route.name === 'region-locale-space-location-keyword' ||
        //     isSearchPage

        // const regionList = isRegionHreflang
        //     ? [this.region]
        //     : this.$store.state.regions

        const link =
            isRemoveHreflang ?
            defaultLinks :
            [...defaultLinks, ...getMetaLink(this.$route)]

        return {
            htmlAttrs: {
                lang: this.lang + '-' + this.region
            },
            link,
            meta
        }
    },
    computed: {
        getRoute() {
            return this.$route
        },
        lang() {
            return this.$route.params.locale || this.$store.state.locale
        },
        region() {
            return this.$route.params.region || this.$store.state.region
        },
        isHideBanner() {
            return (
                this.hideBanner ||
                this.isOrderCheckout ||
                this.isVNTestEnv ||
                this.globalIsSigned
            )
        },
        isTiktokInAppBrowser() {
            if (process.client && !this.isDev) {
                return (
                    (navigator.userAgent.includes('Bytedance') ||
                        navigator.userAgent.includes('ByteFullLocale')) &&
                    this.osType() === 'android'
                )
            }
            return false
        },
        isDev() {
            return process.env.MODE === 'DEV'
        },
        detectedGroup() {
            return this.$store.getters['general/detectedGroup']
        },
        isLocaleModalVisible(){
            // return (
            //     this.paramsRegion === 'my' &&
            //     this.detectedGroup === null &&
            //     (!this.paramsGroup || !this.isLocationGroupValid) &&
            //     (
            //         this.$route.name === 'region-locale' ||
            //         this.$route.name === 'region-locale-space'
            //     ) &&
            //     !(this.$route.query?.q && this.$route.query?.es_type)
            // )
            return false
        }
    },
    watch: {
        getRoute(newVal) {
            if (newVal.name === 'region-locale-order-checkout') {
                this.hideHeader = true
                this.hideFooter = true
            } else if (
                newVal.name === 'region-locale-privacy-policy' &&
                newVal.query.hide === '1'
            ) {
                this.hideHeader = true
                this.hideFooter = true
            } else if (newVal.name === 'region-locale-spaceName-space') {
                this.hideHeader = false
                this.hideFooter = false
            } else {
                this.hideHeader = false
                this.hideFooter = false
            }
            if (newVal.name === 'index') {
                this.setGdrp()
            } else {
                this.hideCookies = true
            }
            this.setLocaleModalVisible()

        },
        detectedGroup: {
            immediate: true,
            async handler(newVal) {
                if (
                    this.paramsRegion === 'my' &&
                    newVal &&
                    (!this.paramsGroup || !this.isLocationGroupValid)
                ) {
                    await this.$router.push({
                        path: this.$route.path,
                        query: {
                            ...this.$route.query,
                            group: newVal
                        },
                        replace: true
                    })
                }
            }
        }
    },
    mounted() {
        this.setOverride()
        this.setUtmSourceToCookies()
        this.$store.watch((state) => state.api.getDataFail, this.handleError, {
            immediate: true
        })
        // this.$store.watch((state) => state.member.isSigned, this.setRegion, {
        //     immediate: false
        // })
        this.setOnAuthStateChanged()
        this.setGdrp()
        this.setWebToAppBanner()
        // this.handleRedirect()
        this.setLanguageAndRegion()
        // this.setLocate()
        this.setMobileModalHeight()
        this.checkWebToAppBannerCountdown()
        // this.getVisitorData()
        // this.hardRefresh()
        this.$store.watch(
            (state) => state.member.isSigned,
            this.getWebToAppOffer,
            {
                immediate: false
            }
        )
        this.getWebToAppOffer()
        this.setLocaleModalVisible()
    },
    created() {
        this.hideNav()
    },
    methods: {
        hideNav() {
            if (
                this.$route.name === 'spinner' ||
                this.$route.name === 'api-reservation-payout-provider-fail' ||
                this.$route.name ===
                    'api-reservation-payout-provider-success' ||
                this.$route.name ===
                    'api-reservation-payout-provider-web-fail' ||
                this.$route.name ===
                    'api-reservation-payout-provider-web-success' ||
                this.$route.name === 'api-reservation-payout-tapngo' ||
                this.$route.name === 'redirect'
            ) {
                this.hideHeader = true
                this.hideFooter = true
                this.hideCookies = true
            }
        },
        // handleRedirect() {
        //     const route = this.$router.currentRoute.fullPath
        //     const paymeSuccess = '/api/reservation/payout/payme/success'
        //     const paymeFail = '/api/reservation/payout/payme/fail'
        //     const isSpinner = '/spinner'
        //     const isTapAndGo = '/api/reservation/payout/tapngo'
        //     if (
        //         route === paymeSuccess ||
        //         route === paymeFail ||
        //         route === isSpinner ||
        //         route === isTapAndGo
        //     ) {
        //         this.redirect = false
        //     }
        // },
        handleError() {
            const failed = this.$store.getters['api/getDataFail']
            if (failed) {
                const error = this.$store.getters['api/getError']
                // console.log(error)
                this.$nuxt.error(error)
            }
        },
        setGdrp() {
            if (!storage.get(storage.key.gdrpAccepted) && this.$route.name !== 'spinner') {
                this.showGdrpBar()
            }
        },
        setWebToAppBanner() {
            if (!storage.get(storage.key.bannerDisplayed)) {
                this.showWebToAppBanner()
            }
        },
        showGdrpBar() {
            this.hideCookies = false
        },
        closeGdrpBar() {
            this.hideCookies = true
            storage.setGdrpAccepted()
        },
        showWebToAppBanner() {
            this.hideBanner = false
        },
        closeWebToAppBanner() {
            this.hideBanner = true
            storage.setBannerDisplayed()
            this.setWebToAppBannerCountdown()
        },
        setLanguageAndRegion() {
            const lang = this.$route.params.locale
            const region = this.$route.params.region
            this.$store.commit('setLanguage', lang)
            this.$store.commit('setRegion', region)
            if ((region === 'sg' || region === 'my') && lang === 'zh') {
                this.$i18n.locale = 'cn'
            } else {
                this.$i18n.locale = lang
            }
        },
        // async setRegion() {
        //     try {
        //         let idToken = ''
        //         let region = ''
        //         const isSigned = this.$store.getters['member/isSigned']
        //         if (isSigned) {
        //             idToken = await firebase.auth().currentUser.getIdToken()
        //             const obj = {
        //                 id_token: idToken,
        //                 lang: this.parsedLang
        //             }
        //             const res = await this.$store.dispatch(
        //                 'api/GET_DEFAULT_SETTING',
        //                 obj
        //             )
        //             if (res) {
        //                 if (res.region) {
        //                     region = res.region
        //                     await storage.setRegion(region)
        //                     await this.$store.commit('setRegion', region)
        //                 } else {
        //                     region = this.region
        //                 }
        //                 const route = this.$router.currentRoute.fullPath
        //                 const path = '/' + region + route.slice(3)
        //                 await this.$router.push({
        //                     path
        //                 })
        //             }
        //         }
        //     } catch (err) {
        //         this.$nuxt.error(err)
        //         this.$sentry.captureException(err)
        //     }
        // },
        // setLocate() {
        //     let locale = 'HK'
        //
        //     if (storage.get(storage.key.locale)) {
        //         locale = storage.get(storage.key.locale)
        //     }
        //
        //     storage.setLocale(locale)
        // },
        async setOnAuthStateChanged() {
            await onAuthStateChanged(firebaseAuth, async (user) => {
                const sentryUser = { ip_address: "{{auto}}" }
                if (!user) {
                    await this.$store.dispatch('member/resetSignIn')
                    await signInAnonymously(firebaseAuth)
                    const id = await firebaseService.getUserId()
                    if (id) {
                        sentryUser.id = id
                    }
                    this.$sentry.setUser(sentryUser)
                } else if (!user.isAnonymous) {
                    // sign in with user
                    await this.checkMember(user)
                    if (user?.uid) {
                        sentryUser.id = user.uid
                    }
                    if (user?.email) {
                        sentryUser.email = user.email
                    }
                    this.$sentry.setUser(sentryUser)
                    await this.resetCountdown()
                } else {
                    // sign in anonymously
                    if (user?.uid) {
                        sentryUser.id = user.uid
                    }
                    this.$sentry.setUser(sentryUser)
                    await this.$store.dispatch('member/resetAllState')
                }
            })
        },
        async checkMember(user) {
            const api = Api.api
            try {
                if (user.email) {
                    const obj = {
                        lang: this.$store.state.locale,
                        email: user.email
                    }
                    const res = await api.isMemberEmailExist(obj)
                    if (res.data.Success && res.data.Data.state !== null) {
                        await this.$store.dispatch(
                            'member/setIsPhoneAuthPassed',
                            res.data.Data
                        )
                        await this.$store.dispatch(
                            'member/setFirebaseInfo',
                            user
                        )
                    } else {
                        await this.$store.dispatch('member/resetAllState')
                    }
                } else {
                    await firebaseService.signOut()
                    await this.$store.dispatch('member/resetAllState')
                }
            } catch (err) {
                await this.$store.dispatch('member/resetAllState')
            }
        },
        setMobileModalHeight() {
            // set default window height
            document.documentElement.style.setProperty(
                '--vh',
                `${window.innerHeight}px`
            )
            // resize window height
            window.addEventListener('resize', () => {
                document.documentElement.style.setProperty(
                    '--vh',
                    `${window.innerHeight}px`
                )
            })
        },
        hardRefresh() {
            if (version.version !== '1.0.1') {
                window.location.reload(true)
            }
        },
        navigateToContactUs() {
            const routeData = this.$router.resolve({
                path: `/${this.region}/${this.lang}/contact`
            })
            if (process.client) {
                const finalUrl = this.appendOverrideParamIfNeeded(
                    routeData.href
                )
                window.open(finalUrl, '_blank')
            }
            analysis.sendChatbotButtonClicked()
        },
        setOverride() {
            if (
                !storage.get(storage.key.override) &&
                this.$route.query.override === 'true'
            ) {
                storage.setIsOverride()
            } else if (
                storage.get(storage.key.override) &&
                this.$route.query.override !== 'true'
            ) {
                let path = this.$route.fullPath
                if (Object.keys(this.$route.query).length !== 0) {
                    path += '&override=true'
                } else {
                    path += '?override=true'
                }
                this.$router.push(path)
            }
        },
        setUtmSourceToCookies() {
            const query = this.$route.query
            let utmSource = ''
            let transactionId = ''
            // add and replace old affiliate
            if (query?.utm_source === 'shopback') {
                transactionId = query?.transaction_id
                utmSource = 'shopback'
                this.$cookies.remove('txid')
            } else if (query?.txId) {
                transactionId = query?.txId
                utmSource = 'txid'
                this.$cookies.remove('shopback')
            }
            // set cookies and expire after one week
            if (utmSource && transactionId) {
                this.$cookies.set(utmSource, transactionId, {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 7
                })
                // remove txId and utm source query from url
                const { utm_source, transaction_id, txId, ...newQuery } = query
                this.$router.push({
                    name: this.$route.name,
                    params: this.$route.params,
                    query: newQuery
                })
            }
        },
        resetCountdown() {
            storage.remove('countdown')
        },
        setWebToAppBannerCountdown() {
            storage.setWebToAppBannerCountdown(Date.now())
        },
        checkWebToAppBannerCountdown() {
            if (storage.get(storage.key.bannerDisplayed)) {
                const initialTimestamp = storage.get(
                    storage.key.webToAppBannerCountdown
                )
                const isDisplayBanner =
                    initialTimestamp - Date.now() + 86400 * 1000 < 0
                // restore banner after 1 day / 86400 second
                if (isDisplayBanner) storage.remove('bannerDisplayed')
            }
        },
        async getWebToAppOffer() {
            try {
                const idToken = await this.getIdToken()
                const isGetOffer =
                    idToken &&
                    this.paramsRegion &&
                    this.paramsLang &&
                    !this.hideBanner
                if (isGetOffer) {
                    await this.$store.dispatch('api/GET_WEB_TO_APP_OFFER', {
                        region: this.paramsRegion,
                        lang: this.paramsLang,
                        idToken
                    })
                }
            } catch (error) {
                alert(this.$t('err_occurred'))
            }
        },
        async setLocaleModalVisible(){
            if (this.isLocaleModalVisible) {
                await this.$store.dispatch('general/SET_LOCALE_MODAL_VISIBLE', {
                    isVisible: true,
                    mode: MODAL_MODE.LOCALE
                })
            }
        }
        // async getVisitorData() {
        //     const visitorData = await this.$fpjs.getVisitorData({
        //         extendedResult: true
        //     })
        //     console.log(visitorData)
        //     // // Do something with visitorData
        // }
    }
}
</script>
<style>
html {
    font-size: 14px;
    word-spacing: 1px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    --palette-yellow: #fdb93e;
    --palette-primary-yellow: #765821;
    --palette-gold: #ffc000;
}
body {
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, 'Helvetica Neue', Arial, sans-serif;
}
html.is-locked body {
    /* want to fix the height to the window height */
    height: calc(var(--vh) - 1px);

    /* want to block all overflowing content */
    overflow: hidden;

    /* want to exclude padding from the height */
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: border-box;
    margin: 0;
}
.padding-top-50 {
    padding-top: 50px;
}
.padding-bottom-50 {
    padding-bottom: 50px;
}
.text-s-thin {
    font-weight: 300;
}
.text-reg {
    font-weight: 400;
}
.text-mid {
    font-weight: 500;
}
.text-s-bold {
    font-weight: 600;
}
.text-bold {
    font-weight: 700;
}
.text-raleway-reg {
    font-family: 'Poppins', sans-serif !important;
}
.text-ikonoMini-4 {
    font-family: 'IkonoMiniL-4';
}
.text-606060 {
    color: #606060;
}
.text-d1d1d1 {
    color: #d1d1d1;
}
.text-909090 {
    color: #909090;
}
.text-888888 {
    color: #888888;
}
.text-d5d5d5 {
    color: #d5d5d5;
}
.text-292929 {
    color: #292929;
}
.text-9d9d9d {
    color: #9d9d9d;
}
.text-0a0a0a {
    color: #0a0a0a;
}
.text-3e3e3e {
    color: #3e3e3e;
}
.text-747a81 {
    color: #747a81;
}
.text-a6a6a6 {
    color: #a6a6a6;
}
.text-404040 {
    color: #404040;
}
.text-959595 {
    color: #959595;
}
.text-3c3c3c {
    color: #3c3c3c;
}
.text-f2371f {
    color: #f2371f;
}
.text-707070 {
    color: #707070;
}
.text-313131 {
    color: #313131;
}
.text-020202 {
    color: #020202;
}
.text-b5b3ab {
    color: #b5b3ab;
}
.text-777777 {
    color: #777777;
}
.text-f44336 {
    color: #f44336;
}
.text-b1aa97 {
    color: #b1aa97;
}
.text-d3d4d8 {
    color: #d3d4d8;
}
.text-e29110 {
    color: #e29110;
}
.text-transparent {
    color: rgba(0, 0, 0, 0);
}
.text-6d6d6d {
    color: #6d6d6d;
}
.text-797979 {
    color: #797979;
}
.text-ffc000 {
    color: #ffc000;
}
.text-868686 {
    color: #868686;
}
.text-1e1e1e {
    color: #1e1e1e;
}
.text-a9a9a9 {
    color: #a9a9a9;
}
.text-afafaf {
    color: #afafaf;
}
.bg-afafaf {
    background-color: #afafaf;
}
.bg-1e1e1e {
    background-color: #1e1e1e;
}
.text-f66e32 {
    color: #f66e32;
}
.bg-f66e32 {
    background-color: #f66e32;
}
.bg-ffc6ac {
    background-color: #ffc6ac;
}
.text-838383 {
    color: #838383;
}
.bg-fff2cc {
    background-color: #fff2cc;
}
.bg-f3f3f5 {
    background-color: #f3f3f5;
}
.bg-f3f2f0 {
    background-color: #f3f2f0;
}
.bg-ffc000 {
    background-color: #ffc000;
}
.bg-fff8e8 {
    background-color: #fff8e8;
}
.text-765821 {
    color: #765821;
}
p {
    margin: 0;
    padding: 0;
}
.button-box-shadow {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.book-btn {
    width: 100%;
    border-radius: 8px;
    color: var(--light) !important;
}
.book-btn:active {
    transform: scale(0.98);
}
.btn-active-shrink:active {
    transform: scale(0.98);
}
.disabled-book-btn {
    width: 100%;
    border-radius: 8px;
    background-color: #d3d4d8 !important;
    color: white !important;
    border-color: #d3d4d8 !important;
}
.text-back-btn {
    color: black;
    transform: rotate(90deg);
    font-weight: bold;
}
.text-forward-btn {
    transform: rotate(270deg);
}
.content-container {
    max-width: 1200px;
    height: auto;
    margin: 0 auto;
}
.button-active:active {
    filter: opacity(0.8);
}
.dark-button-active:active {
    filter: brightness(70%);
}
.fomo-space-tag {
    border-radius: 4px;
}
.fomo-last-booked {
    padding: 0.2rem 0.8rem;
    font-size: 12px;
}
.fomo-last-room {
    padding: 0.15rem 0.5rem;
    font-size: 10px;
}
.fomo-room-tag {
    padding: 2px 11px 2px 5px;
    font-size: 11px;
}
.fomo-tag-concave {
    border-radius: 5px 0 0 5px;
    display: flex;
}
.fomo-tag-concave:after,
.fomo-tag-concave:before {
    content: '';
    position: absolute;
    height: 20px;
    width: 0;
    right: -10px;
    border-style: solid;
    padding-right: 2px;
    border-color: #8b1af5 transparent #8b1af5 #8b1af5;
}

.fomo-tag-concave:before {
    bottom: 0;
    border-radius: 2px;
    border-width: 0 10px 12px 0;
}
.fomo-tag-concave:after {
    top: 0;
    border-radius: 2px;
    border-width: 12px 10px 0 0;
}
.fomo-tag-purple {
    background: #8b1af5;
    color: white !important;
}
.feature-tag-yellow {
    background: var(--palette-yellow);
    color: white !important;
}
.feature-tag {
    display: flex;
    align-items: center;
}
.global-space-tag {
    border-radius: 20px;
    padding: 4px 13px;
    font-size: 13px;
}
q {
    quotes: '“' '”' '‘' '’';
}
q::before {
    content: open-quote;
}
q::after {
    content: close-quote;
}
@media (max-width: 576px) {
    button:active {
        filter: opacity(0.6);
    }
}
/*input[type='text'],*/
/*input[type='number'],*/
/*input[type='email'],*/
/*textarea {*/
/*    font-size: 15px;*/
/*}*/
@media (min-width: 1200px) {
    .text-42 {
        font-size: 42px;
    }
    .text-36 {
        font-size: 36px;
    }
    .text-34 {
        font-size: 34px;
    }
    .text-32 {
        font-size: 32px;
    }
    .text-28 {
        font-size: 28px;
    }
    .text-27 {
        font-size: 27px;
    }
    .text-26 {
        font-size: 26px;
    }
    .text-25 {
        font-size: 25px;
    }
    .text-24 {
        font-size: 24px;
    }
    .text-23 {
        font-size: 23px;
    }
    .text-22 {
        font-size: 22px;
    }
    .text-21 {
        font-size: 21px;
    }
    .text-20 {
        font-size: 20px;
    }
    .text-19 {
        font-size: 19px;
    }
    .text-18 {
        font-size: 18px;
    }
    .text-17 {
        font-size: 17px;
    }
    .text-16 {
        font-size: 16px;
    }
    .text-15 {
        font-size: 15px;
    }
    .text-14 {
        font-size: 14px;
    }
    .text-13 {
        font-size: 13px;
    }
    .text-12 {
        font-size: 12px;
    }
    .text-11 {
        font-size: 11px;
    }
    .text-10 {
        font-size: 10px;
    }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
    .text-42 {
        font-size: 41px;
    }
    .text-36 {
        font-size: 35px;
    }
    .text-34 {
        font-size: 33px;
    }
    .text-32 {
        font-size: 31px;
    }
    .text-28 {
        font-size: 27px;
    }
    .text-27 {
        font-size: 26px;
    }
    .text-26 {
        font-size: 25px;
    }
    .text-25 {
        font-size: 24px;
    }
    .text-24 {
        font-size: 23px;
    }
    .text-23 {
        font-size: 22px;
    }
    .text-22 {
        font-size: 21px;
    }
    .text-21 {
        font-size: 20px;
    }
    .text-20 {
        font-size: 19px;
    }
    .text-19 {
        font-size: 18px;
    }
    .text-18 {
        font-size: 17px;
    }
    .text-17 {
        font-size: 16px;
    }
    .text-16 {
        font-size: 15px;
    }
    .text-15 {
        font-size: 14px;
    }
    .text-14 {
        font-size: 13px;
    }
    .text-13 {
        font-size: 12px;
    }
    .text-12 {
        font-size: 11px;
    }
    .text-11 {
        font-size: 10px;
    }
    .text-10 {
        font-size: 9px;
    }
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .text-42 {
        font-size: 41px;
    }
    .text-36 {
        font-size: 35px;
    }
    .text-34 {
        font-size: 33px;
    }
    .text-32 {
        font-size: 31px;
    }
    .text-28 {
        font-size: 27px;
    }
    .text-27 {
        font-size: 26px;
    }
    .text-26 {
        font-size: 25px;
    }
    .text-25 {
        font-size: 24px;
    }
    .text-24 {
        font-size: 23px;
    }
    .text-23 {
        font-size: 22px;
    }
    .text-22 {
        font-size: 21px;
    }
    .text-21 {
        font-size: 20px;
    }
    .text-20 {
        font-size: 19px;
    }
    .text-19 {
        font-size: 18px;
    }
    .text-18 {
        font-size: 17px;
    }
    .text-17 {
        font-size: 16px;
    }
    .text-16 {
        font-size: 15px;
    }
    .text-15 {
        font-size: 14px;
    }
    .text-14 {
        font-size: 13px;
    }
    .text-13 {
        font-size: 12px;
    }
    .text-12 {
        font-size: 11px;
    }
    .text-11 {
        font-size: 10px;
    }
    .text-10 {
        font-size: 9px;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .text-42 {
        font-size: 40px;
    }
    .text-36 {
        font-size: 34px;
    }
    .text-34 {
        font-size: 32px;
    }
    .text-32 {
        font-size: 30px;
    }
    .text-28 {
        font-size: 26px;
    }
    .text-27 {
        font-size: 25px;
    }
    .text-26 {
        font-size: 24px;
    }
    .text-25 {
        font-size: 23px;
    }
    .text-24 {
        font-size: 22px;
    }
    .text-23 {
        font-size: 21px;
    }
    .text-22 {
        font-size: 20px;
    }
    .text-21 {
        font-size: 19px;
    }
    .text-20 {
        font-size: 18px;
    }
    .text-19 {
        font-size: 17px;
    }
    .text-18 {
        font-size: 16px;
    }
    .text-17 {
        font-size: 15px;
    }
    .text-16 {
        font-size: 14px;
    }
    .text-15 {
        font-size: 13px;
    }
    .text-14 {
        font-size: 12px;
    }
    .text-13 {
        font-size: 11px;
    }
    .text-12 {
        font-size: 10px;
    }
    .text-11 {
        font-size: 9px;
    }
    .text-10 {
        font-size: 8px;
    }
}
@media (min-width: 350px) and (max-width: 575.98px) {
    .text-42 {
        font-size: 34px;
    }
    .text-36 {
        font-size: 33px;
    }
    .text-34 {
        font-size: 28px;
    }
    .text-32 {
        font-size: 27px;
    }
    .text-28 {
        font-size: 26px;
    }
    .text-27 {
        font-size: 25px;
    }
    .text-26 {
        font-size: 24px;
    }
    .text-25 {
        font-size: 23px;
    }
    .text-24 {
        font-size: 22px;
    }
    .text-23 {
        font-size: 21px;
    }
    .text-22 {
        font-size: 20px;
    }
    .text-21 {
        font-size: 19px;
    }
    .text-20 {
        font-size: 18px;
    }
    .text-19 {
        font-size: 17px;
    }
    .text-18 {
        font-size: 16px;
    }
    .text-17 {
        font-size: 15px;
    }
    .text-16 {
        font-size: 14px;
    }
    .text-15 {
        font-size: 13px;
    }
    .text-14 {
        font-size: 12px;
    }
    .text-13 {
        font-size: 11px;
    }
    .text-12 {
        font-size: 10px;
    }
    .text-11 {
        font-size: 9px;
    }
    .text-10 {
        font-size: 8px;
    }
}
@media (max-width: 349.98px) {
    .text-42 {
        font-size: 28px;
    }
    .text-36 {
        font-size: 27px;
    }
    .text-34 {
        font-size: 26px;
    }
    .text-32 {
        font-size: 26px;
    }
    .text-28 {
        font-size: 25px;
    }
    .text-27 {
        font-size: 24px;
    }
    .text-26 {
        font-size: 23px;
    }
    .text-25 {
        font-size: 22px;
    }
    .text-24 {
        font-size: 21px;
    }
    .text-23 {
        font-size: 20px;
    }
    .text-22 {
        font-size: 19px;
    }
    .text-21 {
        font-size: 18px;
    }
    .text-20 {
        font-size: 17px;
    }
    .text-19 {
        font-size: 16px;
    }
    .text-18 {
        font-size: 15px;
    }
    .text-17 {
        font-size: 14px;
    }
    .text-16 {
        font-size: 13px;
    }
    .text-15 {
        font-size: 12px;
    }
    .text-14 {
        font-size: 11px;
    }
    .text-13 {
        font-size: 10px;
    }
    .text-12 {
        font-size: 9px;
    }
    .text-11 {
        font-size: 8px;
    }
    .text-10 {
        font-size: 7px;
    }
}
.icon-14 {
    width: 14px;
    height: 14px;
}
.icon-18 {
    width: 18px;
    height: 18px;
}
/*::-webkit-scrollbar {*/
/*    width: 0; !* Remove scrollbar space *!*/
/*    background: transparent; !* Optional: just make scrollbar invisible *!*/
/*}*/
.form-control::-webkit-input-placeholder {
    color: #747a81;
    font-size: 15px;
}
* {
    touch-action: manipulation;
}

.beta-ribbon {
    background-color: #3d3d3d;
    text-align: center;
    color: white;
}
.skeleton-text {
    border-radius: 2px;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.hide-overflow-text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}
.disable-hover{
    pointer-events: none !important;
}
h1,
h2,
h3 {
    margin: 0;
    font-weight: normal;
    line-height: unset;
}
pre {
    margin: 0;
    font-family: inherit;
    white-space: pre-line;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (min-width: 992px) {
    .card-item:hover {
        transform: translateY(-6px);
        transition: all 0.25s ease;
        cursor: pointer;
    }
    .button-hover:hover {
        filter: opacity(0.6);
    }
}
.button-active-filter:active {
    filter: opacity(0.8);
}
.card-shadow {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
}
.round-image-swiper-wrapper {
    /*fix Overflow hidden + round corners not working on Safari bug*/
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    position: relative;
}
.image-placeholder {
    background-color: #e1e1e1;
    aspect-ratio: 16/9;
}
@media (min-width: 1228px) {
    .main-list-container,
    .home-column {
        width: 1200px;
        margin: auto;
    }
}
@media (max-width: 1227px) {
    .home-column {
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
    }
}
</style>
