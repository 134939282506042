import {
    signInWithEmailAndPassword as _signInWithEmailAndPassword,
    signInWithPopup as _signInWithPopup,
    linkWithCredential as _linkWithCredential,
    fetchSignInMethodsForEmail as _fetchSignInMethodsForEmail,
    sendPasswordResetEmail as _sendPasswordResetEmail,
    getIdToken as _getIdToken,
    signInAnonymously as _signInAnonymously,
    reauthenticateWithCredential as _reauthenticateWithCredential,
    updateEmail as _updateEmail,
    updatePassword as _updatePassword,
    signInWithPhoneNumber as _signInWithPhoneNumber,
    signOut as _signOut,
    useDeviceLanguage,
    EmailAuthProvider,
    FacebookAuthProvider,
    GoogleAuthProvider,
    OAuthProvider,
    PhoneAuthProvider,
    RecaptchaVerifier
} from 'firebase/auth'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { firebaseAuth, firebaseApp } from '@/plugins/firebase'


function signInWithEmailAndPassword(obj) {
    return _signInWithEmailAndPassword(firebaseAuth, obj.email, obj.password)
}

function signInWithPopup(provider) {
    return _signInWithPopup(firebaseAuth, provider)
}

function createUserWithEmail(email, password) {
    const credential = getCredentialByEmail(email, password)
    return _linkWithCredential(firebaseAuth.currentUser, credential)
}

function getFacebookProvider() {
    return new FacebookAuthProvider()
}

function getFacebookCredential(accessToken) {
    return FacebookAuthProvider.credential(accessToken)
}

function getGoogleProvider() {
    return new GoogleAuthProvider()
}

function getGoogleCredential(idToken, accessToken) {
    return GoogleAuthProvider.credential(idToken, accessToken)
}

function getAppleProvider() {
    return new OAuthProvider('apple.com')
}

function getAppleCredential(accessToken) {
    return new OAuthProvider('apple.com').credential(accessToken)
}

function fetchSignInMethodsForEmail(email) {
    return _fetchSignInMethodsForEmail(firebaseAuth, email)
}

function isEmailAvailable(email) {
    return new Promise((resolve, reject) => {
        _fetchSignInMethodsForEmail(firebaseAuth, email)
            .then((signInMethods) => {
                // console.log(signInMethods)
                // if "signInMethods" size equal to 0, means email not registered
                if (signInMethods.length === 0) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
            .catch(() => {
                resolve(false)
            })
    })
}

function resetPassword(email, lang = 'en') {
    return new Promise((resolve, reject) => {
        firebaseAuth.languageCode = lang
        _sendPasswordResetEmail(firebaseAuth, email)
            .then(() => {
                resolve(true)
            })
            .catch(() => {
                resolve(false)
            })
    })
}
function getIdToken(isRefreshToken = false) {
    return _getIdToken(firebaseAuth.currentUser, isRefreshToken)
}
function getUserId() {
    return firebaseAuth.currentUser?.uid || null
}
function isAnonymous() {
    return firebaseAuth.currentUser?.isAnonymous
}
function getAnonymousIdToken() {
    return _signInAnonymously(firebaseAuth)
}
function getCredentialByEmail(email, password) {
    return EmailAuthProvider.credential(email, password)
}

function reauthenticateWithCredential(credential) {
    return _reauthenticateWithCredential(firebaseAuth.currentUser, credential)
}

function updatePassword(newPassword) {
    return _updatePassword(firebaseAuth.currentUser, newPassword)
}

function updateEmail(newEmail) {
    return _updateEmail(firebaseAuth.currentUser, newEmail)
}

function sendPhoneNumber(phoneNum, verifier) {
    return _signInWithPhoneNumber(firebaseAuth, phoneNum, verifier)
}

function verifySMSCode(verificationId, verificationCode) {
    const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
    )
    // PAT: function "signInWithCredential" creates a new user, which causes the member ID mismatch issue
    // return firebase.auth().signInWithCredential(credential)
    return _linkWithCredential(firebaseAuth.currentUser, credential)
}

function socialVerifySMSCode(verificationId, verificationCode) {
    const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
    )
    return _linkWithCredential(firebaseAuth.currentUser, credential)
}

function setReCAPTCHA(id, setting) {
    useDeviceLanguage(firebaseAuth)
    return new RecaptchaVerifier(firebaseAuth, id, setting)
}

function signOut() {
    return _signOut(firebaseAuth)
}

function linkWithCredential(credential) {
    return _linkWithCredential(firebaseAuth.currentUser, credential)
}

function sendAnalyticsLogEvent(name, params = null) {
    const fbAnalytics = getAnalytics(firebaseApp)
    if (params === null) {
        return logEvent(fbAnalytics, name)
    } else {
        return logEvent(fbAnalytics, name, params)
    }
}

export default {
    signInWithEmailAndPassword,
    signInWithPopup,
    getGoogleProvider,
    getGoogleCredential,
    getFacebookProvider,
    getFacebookCredential,
    getAppleProvider,
    getAppleCredential,
    signOut,
    isEmailAvailable,
    resetPassword,
    getIdToken,
    getUserId,
    isAnonymous,
    getAnonymousIdToken,
    getCredentialByEmail,
    updatePassword,
    updateEmail,
    reauthenticateWithCredential,
    sendPhoneNumber,
    verifySMSCode,
    socialVerifySMSCode,
    setReCAPTCHA,
    createUserWithEmail,
    fetchSignInMethodsForEmail,
    linkWithCredential,
    sendAnalyticsLogEvent
}
