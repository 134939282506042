<template>
    <transition name="modal">
        <div class="modal-mask">
            <div @click="checkPathBeforeClose" class="modal-wrapper">
                <div class="modal-container">
                    <div class="d-flex justify-content-center">
                        <div @click.stop class="bg-light content modal-height">
                            <div
                                class="content-container py-sm-4 px-3 d-flex flex-column justify-content-between h-100 overflow-auto"
                            >
                                <div>
                                    <div class="d-flex justify-content-end">
                                        <b-button
                                            v-if="isLangRegionValid"
                                            @click="checkPathBeforeClose"
                                            class="close-btn bg-transparent border-0 text-292929 text-ikonoMini-4 px-0"
                                        >
                                            
                                        </b-button>
                                    </div>
                                    <ItemTitle class="my-4">
                                        {{ $t('header.display_settings') }}
                                    </ItemTitle>
                                    <!--Current Country / Region-->
                                    <b-dropdown
                                        :disabled="isChangeRegionDisabled"
                                        variant="transparent"
                                        class="dropdown-toggle text-center icon w-100 dropdown mb-3"
                                        down
                                        no-caret
                                    >
                                        <template #button-content>
                                            <b-button
                                                :class="{
                                                    'disable-hover': isChangeRegionDisabled
                                                }"
                                                class="py-2 px-3 select-date-button flex-fill text-606060 check-btn w-100 d-flex justify-content-between align-items-center"
                                                variant="btn-sm"
                                            >
                                                <div class="text-left">
                                                    <div class="text-13 mb-1 ">
                                                        {{
                                                            $t('header.region')
                                                        }}
                                                    </div>
                                                    <div
                                                        class="text-16 text-mid"
                                                    >
                                                        {{ currentRegionText }}
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="
                                                        !isChangeRegionDisabled
                                                    "
                                                    class="text-11 text-ikonoMini-4 text-center"
                                                    style="font-weight: 500"
                                                >
                                                    
                                                </div>
                                            </b-button>
                                        </template>
                                        <div class="mb-2 text-center w-100">
                                            <b-dropdown-item
                                                v-for="(item,
                                                index) in regionList"
                                                :key="`region_${index}`"
                                                @click="
                                                    setRegion(item.country_code)
                                                "
                                                class="w-100"
                                            >
                                                <div class="">
                                                    <p
                                                        :class="
                                                            selectedRegion ===
                                                            item.country_code
                                                                ? 'text-primary'
                                                                : 'text-dark'
                                                        "
                                                        class="icon text-left"
                                                    >
                                                        {{ item.region_translated[apiLang] }}
                                                    </p>
                                                </div>
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                    <!--Current Area / City-->
                                    <b-dropdown
                                        v-if="groupList.length > 0"
                                        :key="groupDropdownUpdateKey"
                                        variant="transparent"
                                        class="dropdown-toggle text-center icon w-100 dropdown mb-3"
                                        down
                                        no-caret
                                    >
                                        <template #button-content>
                                            <b-button
                                                class="py-2 px-3 select-date-button flex-fill text-606060 check-btn w-100 d-flex justify-content-between align-items-center"
                                                variant="btn-sm"
                                            >
                                                <div class="text-left">
                                                    <div class="text-13 mb-1 ">
                                                        {{
                                                            $t(
                                                                'header.current_area'
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        class="text-16 text-mid"
                                                    >
                                                        {{ currentGroupText }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="text-11 text-ikonoMini-4 text-center"
                                                    style="font-weight: 500"
                                                >
                                                    
                                                </div>
                                            </b-button>
                                        </template>
                                        <div class="mb-2 text-center w-100">
                                            <b-dropdown-item
                                                v-for="(item,
                                                index) in groupList"
                                                :key="`area_${index}`"
                                                @click="setGroup(item.slug)"
                                                class="w-100"
                                            >
                                                <div class="">
                                                    <p
                                                        :class="
                                                            selectedGroup ===
                                                            item.slug
                                                                ? 'text-primary'
                                                                : 'text-dark'
                                                        "
                                                        class="icon text-left"
                                                    >
                                                        {{ item.name_translated[apiLang] }}
                                                    </p>
                                                </div>
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                    <!--Display Language-->
                                    <b-dropdown
                                        v-if="!isGroupModalMode"
                                        variant="transparent"
                                        class="mr-0 dropdown-toggle text-center icon p-0 w-100 dropdown mb-5"
                                        down
                                        no-caret
                                    >
                                        <template #button-content>
                                            <b-button
                                                class="py-2 px-3 select-date-button flex-fill text-606060 check-btn w-100 d-flex justify-content-between align-items-center"
                                                variant="btn-sm"
                                            >
                                                <div class="text-left">
                                                    <div class="text-13 mb-1">
                                                        {{
                                                            $t(
                                                                'header.language'
                                                            )
                                                        }}
                                                    </div>
                                                    <div
                                                        class="text-16 text-mid"
                                                    >
                                                        {{ currentLocaleText }}
                                                    </div>
                                                </div>
                                                <div
                                                    class="text-11 text-ikonoMini-4 text-center"
                                                    style="font-weight: 500"
                                                >
                                                    
                                                </div>
                                            </b-button>
                                        </template>
                                        <div class="mb-2 text-center w-100">
                                            <b-dropdown-item
                                                v-for="(item,
                                                index) in localeList()"
                                                :key="index"
                                                @click="setLang(item.locate)"
                                                class="w-100"
                                            >
                                                <div class="">
                                                    <p
                                                        :class="
                                                            selectedLang ===
                                                            item.locate
                                                                ? 'text-primary'
                                                                : 'text-dark'
                                                        "
                                                        class="icon text-left"
                                                    >
                                                        {{ item.text }}
                                                    </p>
                                                </div>
                                            </b-dropdown-item>
                                        </div>
                                    </b-dropdown>
                                </div>
                                <BookButton
                                    :is-disable="isSaveButtonDisabled"
                                    :book-button-text="$t('save')"
                                    :button-class="'btn-yellow'"
                                    @buttonClicked="saveSettings"
                                    class="mb-3"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import _filter from 'lodash/filter'
import ItemTitle from '@/components/item/ItemTitle'
import BookButton from '@/components/item/BookButton'
import { MODAL_MODE } from '@/store/general'
import { getAlgoliaLang } from '@/services/algoliaApi'

export default {
    name: 'LangRegion',
    components: { BookButton, ItemTitle },
    props: {
        isLangRegionValid: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selectedLang: '',
            selectedRegion: '',
            selectedGroup: '',
            selectedRegionObj: undefined,
            groupDropdownUpdateKey: 50
        }
    },
    computed: {
        currentLocaleText() {
            const lang = this.selectedLang ? this.selectedLang : this.paramsLang
            const locale = _filter(this.localeList(), ['locate', lang])
            if (locale.length > 0) {
                return locale[0].text
            } else {
                return ''
            }
        },
        currentRegionText() {
            const region = this.selectedRegion
                ? this.selectedRegion
                : this.paramsRegion
            const currentRegion = _filter(this.regionList, [
                'country_code',
                region
            ])
            if (currentRegion.length > 0) {
                this.selectedRegionObj = currentRegion[0]
                return currentRegion[0].region_translated[this.apiLang]
            } else {
                return ''
            }
        },
        currentGroupText() {
            const group = this.selectedGroup
                ? this.selectedGroup
                : this.paramsGroup
            if (
                this.selectedRegionObj &&
                this.selectedRegionObj.groups?.length > 0
            ) {
                const currentGroup = _filter(this.selectedRegionObj.groups, [
                    'slug',
                    group
                ])
                if (currentGroup.length <= 0) {
                    return this.translatedGroupPlaceholder
                }
                return currentGroup?.[0]?.name_translated[this.apiLang]
            } else {
                return this.translatedGroupPlaceholder
            }
        },
        groupList() {
            const region = this.selectedRegion
                ? this.selectedRegion
                : this.paramsRegion
            const currentRegion = _filter(this.regionList, [
                'country_code',
                region
            ])
            if (currentRegion.length <= 0) {
                return []
            }
            return currentRegion[0].groups
        },
        localeModalMode() {
            return this.$store.getters['general/localeModalMode']
        },
        isGroupModalMode() {
            return this.localeModalMode === MODAL_MODE.GROUP
        },
        isChangeRegionDisabled() {
            return (
                this.isGroupModalMode &&
                this.$route.name === 'region-locale-space'
            )
        },
        isSaveButtonDisabled() {
            if (this.selectedRegion === 'my') {
                return !this.selectedGroup && !this.paramsGroup
            }
            return false
        },
        apiLang() {
            const region = this.selectedRegion
                ? this.selectedRegion
                : this.paramsRegion
            const lang = this.selectedLang
                ? this.selectedLang
                : this.paramsLang
            return getAlgoliaLang(region, lang)
        },
        translatedGroupPlaceholder() {
            const translation = {
                en: this.$t('header.current_area_placeholder', 'en'),
                ms: this.$t('header.current_area_placeholder', 'ms'),
                sc: this.$t('header.current_area_placeholder', 'cn'),
                tc: this.$t('header.current_area_placeholder', 'zh'),
                vi: this.$t('header.current_area_placeholder', 'vi')
            }
            return translation[this.apiLang]
        }
    },
    mounted() {
        this.hiddenScrollBar()
        this.setDefaultSetting()
    },
    destroyed() {
        this.addScrollBar()
    },
    methods: {
        setDefaultSetting() {
            this.selectedLang = this.paramsLang
                ? this.paramsLang
                : this.$store.state.locale
            this.selectedRegion = this.paramsRegion
                ? this.paramsRegion
                : this.$store.state.region
        },
        localeList() {
            const localeList = [{ text: 'English', locate: 'en' }]
            if (this.selectedRegion === 'sg') {
                localeList.push({ text: '简体中文', locate: 'zh' })
            } else if (this.selectedRegion === 'my') {
                localeList.push({ text: '简体中文', locate: 'zh' })
                localeList.push({ text: 'Bahasa Melayu', locate: 'ms' })
            } else if (this.selectedRegion === 'vn') {
                localeList.push({ text: 'Tiếng Việt', locate: 'vi' })
            } else {
                localeList.push({ text: '繁體中文', locate: 'zh' })
            }
            return localeList
        },
        saveSettings() {
            this.$emit(
                'changeLangRegion',
                this.selectedLang,
                this.selectedRegion,
                this.selectedGroup
            )
            this.close()
        },
        setLang(lang) {
            this.selectedLang = lang
            this.groupDropdownUpdateKey += 1
        },
        setRegion(region) {
            this.selectedRegion = region
            if (!this.currentLocaleText) {
                this.setLang('en')
            }
        },
        setGroup(group) {
            this.selectedGroup = group
        },
        checkPathBeforeClose() {
            if (this.paramsRegion === 'my') {
                if (this.paramsGroup && this.isLangRegionValid) {
                    this.close()
                }
            } else if (this.isLangRegionValid) {
                this.close()
            }
        },
        close() {
            this.$emit('close')
            this.$emit('closeNavbar')
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 1060;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: table;
    transition: opacity 0.3s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-container {
    transition: all 300ms;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
}
.content {
    position: relative;
    border-radius: 10px;
}
.close-btn {
    font-size: 22px;
    position: absolute;
    right: -50px;
    top: 0;
    color: #ffffff;
    transform: rotate(45deg);
}
.slide-fade-enter-active .content-container {
}
.slide-fade-leave-active .content-container {
}
.slide-fade-enter, .slide-fade-leave-to .content-container
    /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
}
.slide-fadeeeeeee-enter-active,
.slide-fadeeeeeee-leave-active {
    transition: opacity 10s;
}
.slide-fadeeeeeee-enter, .slide-fadeeeeeee-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.icon {
    color: var(--light);
    padding: 0px !important;
}
.check-btn-selected {
    color: var(--primary);
    border-color: var(--primary);
}
.check-btn {
    color: #606060;
    border-color: #e1e1e1;
}
.select-date-button {
    border-width: 1px;
    border-radius: 0.5rem;
    width: 33%;
    border-style: solid;
}
.select-date-button:hover {
    border-style: solid;
    border-width: 1px;
    border-color: #f2bc1f;
}
.dropdown-toggle {
    padding: 0px !important;
}
.content-container {
    width: 550px;
    min-height: 550px;
}
@media (max-width: 575.98px) {
    .content {
        height: 100vh;
        width: 100vw;
        border-radius: 0;
    }
    .close-btn {
        font-size: 22px;
        position: unset;
        right: 0;
        top: 0;
        color: #505050;
        transform: rotate(45deg);
    }
    .content-container {
        width: auto;
    }
    /*Get window height*/
    .modal-height {
        height: var(--vh);
    }
}
.dropdown >>> button {
    padding: 0;
}
.dropdown >>> .dropdown-menu {
    width: 100%;
    padding-bottom: 0;
}
.dropdown-toggle::after {
    content: none;
}
</style>
