import dateServices from './dateServices'
import {DATE_TIME} from "@/lib-flow-main-search/src/constants";
import common from "@/services/common";

function getBookType(type) {
    switch (type) {
        case 'hotel':
            return 'Rest'
        case 'co-working':
            return 'Work'
        case 'meeting':
            return 'Meet'
        case 'staycation':
            return 'Staycation'
        case 'party':
            return 'Party'
        default:
            return ''
    }
}

function getDate(date) {
    const diffDay = dateServices.getDiffDay(date)
    switch (diffDay) {
        case 0:
            return 'today'
        case 1:
            return 'tomorrow'
        default:
            return diffDay + ' days'
    }
}

function getDateCount(date) {
    const diffDay = dateServices.getDiffDay(date)
    return diffDay + ' days'
}

function getTime(time) {
    const newTime = new Date(time)
    const hour =
        newTime.getHours() < 10 ? '0' + newTime.getHours() : newTime.getHours()
    const min =
        newTime.getMinutes() < 10
            ? '0' + newTime.getMinutes()
            : newTime.getMinutes()

    return hour + ':' + min
}

function getVoucherName(statement) {
    let voucherName = ''
    statement.map((i) => {
        if (i.type === 'discount_fixed' || i.type === 'discount_removable') {
            voucherName = i.desc
        }
    })
    return voucherName
}

function convertToGAName(filter) {
    if (!filter) return null
    const arr = filter.split('-').map((item) => {
        return common.firstLetterToUpper(item)
    })
    return arr.join('_')
}

function getGASourceName(filter) {
    if (!filter) return null
    const arr = filter.split('-').map((item) => {
        return common.firstLetterToUpper(item)
    })
    return arr.join('_')
}

export default {
    getBookType,
    getDate,
    getDateCount,
    getTime,
    getVoucherName,
    convertToGAName,
    getGASourceName
}
