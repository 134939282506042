import axios from 'axios'

async function getImageAltFromImgUrl(imageUrl, lang, alt) {
    try {
        const response = await axios.get(imageUrl)
        if (response) {
            const xImgCaption = response.headers['x-image-caption']
            const imgAltLangObj = JSON.parse(decodeURIComponent(atob(xImgCaption)))
            return imgAltLangObj[lang]
        }
    } catch (error) {

    }
}

function atob(str) {
    if (process.client) {
        // Browser environment
        return window.atob(str)
    } else {
        // Node.js environment
        return Buffer.from(str, 'base64').toString('binary')
    }
}
export default {
    getImageAltFromImgUrl
}
