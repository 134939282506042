<template>
    <p
        class="text-20 ml-4 mt-2 text-s-bold text-020202 py-2 border-bottom"
        style="border-bottom: 1px solid #ebebeb"
    >
        <slot />
    </p>
</template>
<script>
export default {
    name: 'NavigationHeaderTitle'
}
</script>
