var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper",on:{"click":_vm.checkPathBeforeClose}},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"bg-light content modal-height",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"content-container py-sm-4 px-3 d-flex flex-column justify-content-between h-100 overflow-auto"},[_c('div',[_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.isLangRegionValid)?_c('b-button',{staticClass:"close-btn bg-transparent border-0 text-292929 text-ikonoMini-4 px-0",on:{"click":_vm.checkPathBeforeClose}},[_vm._v("\n                                        \n                                    ")]):_vm._e()],1),_vm._v(" "),_c('ItemTitle',{staticClass:"my-4"},[_vm._v("\n                                    "+_vm._s(_vm.$t('header.display_settings'))+"\n                                ")]),_vm._v(" "),_c('b-dropdown',{staticClass:"dropdown-toggle text-center icon w-100 dropdown mb-3",attrs:{"disabled":_vm.isChangeRegionDisabled,"variant":"transparent","down":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-button',{staticClass:"py-2 px-3 select-date-button flex-fill text-606060 check-btn w-100 d-flex justify-content-between align-items-center",class:{
                                                'disable-hover': _vm.isChangeRegionDisabled
                                            },attrs:{"variant":"btn-sm"}},[_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"text-13 mb-1"},[_vm._v("\n                                                    "+_vm._s(_vm.$t('header.region'))+"\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"text-16 text-mid"},[_vm._v("\n                                                    "+_vm._s(_vm.currentRegionText)+"\n                                                ")])]),_vm._v(" "),(
                                                    !_vm.isChangeRegionDisabled
                                                )?_c('div',{staticClass:"text-11 text-ikonoMini-4 text-center",staticStyle:{"font-weight":"500"}},[_vm._v("\n                                                \n                                            ")]):_vm._e()])]},proxy:true}])},[_vm._v(" "),_c('div',{staticClass:"mb-2 text-center w-100"},_vm._l((_vm.regionList),function(item,index){return _c('b-dropdown-item',{key:`region_${index}`,staticClass:"w-100",on:{"click":function($event){return _vm.setRegion(item.country_code)}}},[_c('div',{},[_c('p',{staticClass:"icon text-left",class:_vm.selectedRegion ===
                                                        item.country_code
                                                            ? 'text-primary'
                                                            : 'text-dark'},[_vm._v("\n                                                    "+_vm._s(item.region_translated[_vm.apiLang])+"\n                                                ")])])])}),1)]),_vm._v(" "),(_vm.groupList.length > 0)?_c('b-dropdown',{key:_vm.groupDropdownUpdateKey,staticClass:"dropdown-toggle text-center icon w-100 dropdown mb-3",attrs:{"variant":"transparent","down":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-button',{staticClass:"py-2 px-3 select-date-button flex-fill text-606060 check-btn w-100 d-flex justify-content-between align-items-center",attrs:{"variant":"btn-sm"}},[_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"text-13 mb-1"},[_vm._v("\n                                                    "+_vm._s(_vm.$t(
                                                            'header.current_area'
                                                        ))+"\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"text-16 text-mid"},[_vm._v("\n                                                    "+_vm._s(_vm.currentGroupText)+"\n                                                ")])]),_vm._v(" "),_c('div',{staticClass:"text-11 text-ikonoMini-4 text-center",staticStyle:{"font-weight":"500"}},[_vm._v("\n                                                \n                                            ")])])]},proxy:true}],null,false,737914686)},[_vm._v(" "),_c('div',{staticClass:"mb-2 text-center w-100"},_vm._l((_vm.groupList),function(item,index){return _c('b-dropdown-item',{key:`area_${index}`,staticClass:"w-100",on:{"click":function($event){return _vm.setGroup(item.slug)}}},[_c('div',{},[_c('p',{staticClass:"icon text-left",class:_vm.selectedGroup ===
                                                        item.slug
                                                            ? 'text-primary'
                                                            : 'text-dark'},[_vm._v("\n                                                    "+_vm._s(item.name_translated[_vm.apiLang])+"\n                                                ")])])])}),1)]):_vm._e(),_vm._v(" "),(!_vm.isGroupModalMode)?_c('b-dropdown',{staticClass:"mr-0 dropdown-toggle text-center icon p-0 w-100 dropdown mb-5",attrs:{"variant":"transparent","down":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-button',{staticClass:"py-2 px-3 select-date-button flex-fill text-606060 check-btn w-100 d-flex justify-content-between align-items-center",attrs:{"variant":"btn-sm"}},[_c('div',{staticClass:"text-left"},[_c('div',{staticClass:"text-13 mb-1"},[_vm._v("\n                                                    "+_vm._s(_vm.$t(
                                                            'header.language'
                                                        ))+"\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"text-16 text-mid"},[_vm._v("\n                                                    "+_vm._s(_vm.currentLocaleText)+"\n                                                ")])]),_vm._v(" "),_c('div',{staticClass:"text-11 text-ikonoMini-4 text-center",staticStyle:{"font-weight":"500"}},[_vm._v("\n                                                \n                                            ")])])]},proxy:true}],null,false,214630490)},[_vm._v(" "),_c('div',{staticClass:"mb-2 text-center w-100"},_vm._l((_vm.localeList()),function(item,index){return _c('b-dropdown-item',{key:index,staticClass:"w-100",on:{"click":function($event){return _vm.setLang(item.locate)}}},[_c('div',{},[_c('p',{staticClass:"icon text-left",class:_vm.selectedLang ===
                                                        item.locate
                                                            ? 'text-primary'
                                                            : 'text-dark'},[_vm._v("\n                                                    "+_vm._s(item.text)+"\n                                                ")])])])}),1)]):_vm._e()],1),_vm._v(" "),_c('BookButton',{staticClass:"mb-3",attrs:{"is-disable":_vm.isSaveButtonDisabled,"book-button-text":_vm.$t('save'),"button-class":'btn-yellow'},on:{"buttonClicked":_vm.saveSettings}})],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }