<template>
    <b-modal
        id="modal-services-selector"
        size="lg"
        hide-header
        hide-footer
        centered
        class="d-flex"
        no-close-on-backdrop
    >
        <div class="pt-4 pb-w">
            <ItemTitle>{{ $t('modal_services.title') }}</ItemTitle>
            <b-container fluid>
                <b-row class="text-center">
                    <b-col
                        class="col-lg-4 col-md-6 col-12 mb-md-5 mb-sm-5 mb-5"
                    >
                        <b-img-lazy
                            class="image"
                            src="~/assets/images/home/btn-img-rest.png"
                        ></b-img-lazy>
                        <p class="mt-3 mb-4  text-s-bold text-18 text-404040">
                            {{ $t('modal_services.rest') }}
                        </p>
                        <b-button
                            @click="navigateToList('1')"
                            class="btn-primary px-3 py-1"
                            style="color: white; border-radius: 50px;"
                            variant="btn-xs"
                            type="submit"
                        >
                            <div class="d-flex">
                                <p class="mr-2 text-s-bold text-14 text-white">
                                    {{ $t('modal_services.explore') }}
                                </p>
                                <p
                                    class="text-12 text-ikonoMini-4 text-light align-self-center"
                                >
                                    
                                </p>
                            </div>
                        </b-button>
                    </b-col>
                    <b-col
                        class="col-lg-4 col-md-6 col-12 mb-md-5 mb-sm-5 mb-5"
                    >
                        <b-img-lazy
                            class="image"
                            src="~/assets/images/home/btn-img-work.png"
                        >
                        </b-img-lazy>
                        <p class="mt-3 mb-4  text-s-bold text-18 text-404040">
                            {{ $t('modal_services.work') }}
                        </p>
                        <b-button
                            @click="navigateToList('2')"
                            class="btn-primary px-3 py-1"
                            style="color: white; border-radius: 50px;"
                            variant="btn-xs"
                            type="submit"
                        >
                            <div class="d-flex">
                                <p class="mr-2 text-s-bold text-14 text-white">
                                    {{ $t('modal_services.explore') }}
                                </p>
                                <p
                                    class="text-12 text-ikonoMini-4 text-light align-self-center"
                                >
                                    
                                </p>
                            </div>
                        </b-button>
                    </b-col>
                    <b-col
                        class="col-lg-4 col-md-12 col-12 mb-md-5 mb-sm-5 mb-5"
                    >
                        <!--                        <b-img-lazy-->
                        <!--                            class="image"-->
                        <!--                            src="~/assets/images/home/btn-img-meet.png"-->
                        <!--                        >-->
                        <!--                        </b-img-lazy>-->
                        <p class="mt-3 mb-4 text-s-bold text-18 text-404040">
                            {{ $t('modal_services.meet') }}
                        </p>
                        <b-button
                            @click="navigateToList('3')"
                            class="btn-primary px-3 py-1"
                            style="color: white; border-radius: 50px;"
                            variant="btn-xs"
                            type="submit"
                        >
                            <div class="d-flex">
                                <p class="mr-2 text-s-bold text-14 text-white">
                                    {{ $t('modal_services.explore') }}
                                </p>
                                <p
                                    class="text-12 text-ikonoMini-4 text-light align-self-center"
                                >
                                    
                                </p>
                            </div>
                        </b-button>
                    </b-col>
                </b-row>
            </b-container>
            <b-button
                @click="close()"
                class="close-btn bg-transparent border-0"
            >
                <p
                    class="text-24 text-ikonoMini-4 text-light align-self-center"
                >
                    
                </p>
            </b-button>
        </div>
    </b-modal>
</template>

<script>
import dateServices from '../../services/dateServices'
import ItemTitle from '@/components/item/ItemTitle'

export default {
    name: 'ServicesSelector',
    components: { ItemTitle },
    computed: {
        getToday() {
            return dateServices.getToday()
        }
    },
    methods: {
        close() {
            this.$root.$emit('bv::hide::modal', 'modal-services-selector')
        },
        navigateToList(services) {
            this.close()
            let space = ''
            switch (services) {
                case 1:
                    space = 'hotel'
                    break
                case 2:
                    space = 'co-working'
                    break
                case 3:
                    space = 'meeting'
                    break
                case 4:
                    space = 'staycation'
                    break
                default:
                    space = 'hotel'
            }
            this.$router.push({
                path: `/${this.region}/${this.lang}/${space}?date=${this.getToday}`
            })
        },
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        }
    }
}
</script>

<style scoped>
.image {
    width: auto;
    height: 150px;
}
.close-btn {
    position: absolute;
    right: -60px;
    top: 0;
    transform: rotate(45deg);
}
@media (max-width: 767.98px) {
    .close-btn {
        position: absolute;
        right: 0;
        top: 0;
        transform: rotate(45deg);
    }
    .close-btn p {
        color: rgb(0, 0, 0) !important;
    }
}
</style>
