import {
    REGION_LANG_MAPPING,
    ALLOWED_REGION,
    SPACE_REGION_MAPPING,
    BLOG_REGION_LANG_MAPPING,
    PROMOTIONS_REGION_LANG_MAPPING, TOP_UP_REGION_LANG_MAPPING
} from '@/utils/locale'

const getMetaLink = (route) => {
    const routePath = route.path
    const routeName = route.name
    const region = route.params.region
    const space = route.params.space
    const tail = routePath.slice(6)
    const miniSiteSlug = routeName.replace('region-locale-', '');
    const localeList = getLocaleList(miniSiteSlug, region, space)
    let link = []
    localeList.forEach((locale)=> {
        const localeArr = locale.split('-')
        const lang = localeArr[0]
        const region = localeArr[1]
        const href = `https://app.flowtheroom.com/${region}/${lang}${tail}`
        const hreflang = lang + '-' + region?.toUpperCase()
        link.push({
            rel: 'alternate',
            hreflang,
            href
        })
    })
    return link
}

const getLocaleList = (miniSiteSlug, region, space) => {
    switch (miniSiteSlug) {
        case 'hourly-hotels':
            return ['zh-hk', 'en-sg', 'en-my']
        case 'daytime-hotels':
            return ['zh-hk']
        case 'airport-hotels':
            return ['zh-hk', 'en-hk', 'en-sg', 'zh-sg']
        case 'work-from-hotel':
        case 'daycation':
            return ['en-sg', 'en-my']
        case 'day-use-hotels':
        case 'budget-hotels':
            return ['en-sg']
        case 'dayuse-vs-flow':
            return ['en-sg', 'zh-sg', 'en-hk', 'zh-hk']
        case 'spaceName-space':
        case 'space-location-keyword':
            return getDefaultLocaleList(region)
        case 'space':
            return getDefaultLocaleList('', space)
        case 'blog':
            return getDefaultLocaleList('', '', miniSiteSlug)
        case 'promotions':
            return getDefaultLocaleList('', '', miniSiteSlug)
        case 'top-up':
            return getDefaultLocaleList('', '', miniSiteSlug)
        default:
            return getDefaultLocaleList()
    }
}

const getDefaultLocaleList = (region, space, miniSiteSlug) => {
    const regionList = getRegionList(region, space)
    const defaultLocaleList = []
    regionList.forEach((region) => {
        const langList = getLangList(region, miniSiteSlug)
        langList.forEach((lang) => {
            defaultLocaleList.push(lang + '-' + region)
        })
    })
    return defaultLocaleList
}

const getRegionList = (region, space) => {
    return space ? SPACE_REGION_MAPPING[space]
            : region
            ? [region]
            : ALLOWED_REGION
}

const getLangList = (region, miniSiteSlug) => {
    return miniSiteSlug === 'blog' ? BLOG_REGION_LANG_MAPPING[region]
        : miniSiteSlug === 'promotions'
        ? PROMOTIONS_REGION_LANG_MAPPING[region]
        : miniSiteSlug === 'top-up'
        ? TOP_UP_REGION_LANG_MAPPING[region]
        : REGION_LANG_MAPPING[region]
}

export {
    getMetaLink
}
