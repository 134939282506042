import _filter from 'lodash/filter'
import { en, vi, zh } from 'vuejs-datepicker/dist/locale'
import { computeSearchIndex } from '@/lib-flow-main-search/src/search'
import inputValidation from '@/services/inputValidation'
import firebase from '~/services/firebase'
import currencyServices from '@/services/currencyFormat'
import { getAlgoliaLang, getAllFiltersValue, getSpaceTypeId } from '@/services/algoliaApi'
import dateServices from '@/services/dateServices'
import { DATE_TIME } from '@/lib-flow-main-search/src/constants'
import { getRegionNameForLocationSeo, isBlogValid } from '@/utils/locale'

export default {
    data() {
        return {
            instagramUrl: {
                hk: 'https://www.instagram.com/flowhk',
                sg: 'https://www.instagram.com/flowsgp/',
                my: 'https://www.instagram.com/flow_msia/',
                vn: 'https://www.instagram.com/flow_vn/'
            },
            facebookUrl: {
                hk: 'https://www.facebook.com/flowhongkong',
                sg: 'https://www.facebook.com/flowsgp/',
                my: 'https://www.facebook.com/flowinmsia',
                vn: 'https://www.facebook.com/FlowVietnam'
            },
            linkedinUrl: 'https://www.linkedin.com/company/flowhk/',
            tiktokUrl: {
                sg: 'https://www.tiktok.com/@flowsgp',
                my: 'https://www.tiktok.com/@flow_msia'
            },
            GATEWAY_ADYEN: 'gateway1',
            GATEWAY_AIRWALLEX: 'gateway2'
        }
    },
    validate({ store, route, error }) {
        const region = route.params.region
        const lang = route.params.locale
        if (region && lang) {
            const isLocaleValid = store.state.localeRegion[region]?.includes(
                lang
            )
            if (!isLocaleValid) {
                throw error({
                    statusCode: 404,
                    message: 'This page could not be found.'
                })
            }
        }
        return true
    },
    computed: {
        isBypassPhoneRegister() {
            const region = this.$route.params.region
            const regionList = this.$store.getters['api/regionList']
            const currentRegion = _filter(regionList, ['country_code', region])
            let bypassState = 0
            if (currentRegion.length > 0) {
                bypassState = currentRegion[0].reg_skip_phone
            }
            return bypassState > 0
        },
        isOverride() {
            return this.$route.query.override
        },
        isVNTestEnv() {
            const isBlogPage = this.$route.name?.includes('region-locale-blog')
            const region = this.$route.params.region
            return !this.isOverride && region === 'vn' && !isBlogPage
        },
        paramsRegion() {
            return this.$route.params.region
        },
        paramsLang() {
            return this.$route.params.locale
        },
        paramsGroup() {
            return this.$route.query.group
        },
        paramsLangRegion() {
            return this.paramsLang + '-' + this.paramsRegion
        },
        globalSlug() {
            return this.$route.params.spaceName
        },
        globalLocationSlug() {
            switch (this.paramsRegion) {
                case 'hk':
                    return 'hong-kong'
                case 'mo':
                    return 'macau'
                case 'sg':
                    return 'singapore'
                case 'my':
                    return 'malaysia'
                case 'vi':
                    return 'vietnam'
                default:
                    return 'hong-kong'
            }
        },
        paramsSpace() {
            return this.$route.params.space
        },
        postAffiliateProTrackerObject() {
            return {
                id: 'pap_x2s6df8d',
                src: 'https://affiliate.flowtheroom.com/scripts/j886m5',
                type: 'text/javascript',
                defer: true,
                callback: () => {
                    try {
                        // eslint-disable-next-line no-undef
                        PostAffTracker.setAccountId('default1')
                        // eslint-disable-next-line no-undef
                        PostAffTracker.track()
                    } catch (err) {}
                }
            }
        },
        postAffiliateProTracker() {
            return {
                script: [this.postAffiliateProTrackerObject]
            }
        },
        isPriceGuaranteeRegion() {
            const region = this.$route.params.region
            return (
                region === 'hk' ||
                region === 'mo' ||
                region === 'sg' ||
                region === 'my'
            )
        },
        isHourlyHotels() {
            switch (this.paramsLangRegion) {
                case 'zh-hk':
                case 'en-sg':
                case 'en-my':
                    return true
                default:
                    return false
            }
        },
        isBudgetHotels() {
            return this.paramsLangRegion === 'en-sg'
        },
        isDayUseHotels() {
            return this.paramsLangRegion === 'en-sg'
        },
        isDaytimeHotels() {
            return this.paramsLangRegion === 'zh-hk'
        },
        isDistrictLinks() {
            switch (this.paramsRegion) {
                case 'hk':
                case 'sg':
                case 'my':
                    return true
                default:
                    return false
            }
        },
        isDaycation() {
            switch (this.paramsLangRegion) {
                case 'en-sg':
                case 'en-my':
                    return true
                default:
                    return false
            }
        },
        isWorkFromHotel() {
            switch (this.paramsLangRegion) {
                case 'en-sg':
                case 'en-my':
                    return true
                default:
                    return false
            }
        },
        isTopUpRegion() {
            const region = this.$route.params.region
            return (
                region === 'hk' ||
                region === 'mo' ||
                region === 'sg' ||
                region === 'my'
            )
        },
        isAirportHotels() {
            switch (this.paramsRegion) {
                case 'sg':
                case 'hk':
                    return true
                default:
                    return false
            }
        },
        globalAlgoliaLang() {
            return getAlgoliaLang(this.paramsRegion, this.paramsLang)
        },
        globalIsSigned() {
            return this.$store.getters['member/isSigned']
        },
        isOrderCheckout() {
            return this.$route.name === 'region-locale-order-checkout'
        },
        isAirportHotelsPage() {
            return this.$route.name === 'region-locale-airport-hotels'
        },
        isMiniSite() {
            return (
                this.$route.name === 'region-locale-day-use-hotels' ||
                this.$route.name === 'region-locale-hourly-hotels' ||
                this.$route.name === 'region-locale-budget-hotels' ||
                this.$route.name === 'region-locale-daycation' ||
                this.$route.name === 'region-locale-work-from-hotel' ||
                this.$route.name === 'region-locale-airport-hotels'
            )
        },
        isHomePage() {
            return this.$route.name === 'region-locale'
        },
        isDetailsPage() {
            return this.$route.name === 'region-locale-spaceName-space'
        },
        isLocationPage() {
            return this.$route.name === 'region-locale-space-location-keyword'
        },
        isListingPage() {
            return this.$route.name === 'region-locale-space'
        },
        isDayuseVsFlowPage() {
            return this.$route.name === 'region-locale-dayuse-vs-flow'
        },
        routePathTail() {
            const fullPath = this.$route.path
            const array = fullPath.split('/')
            const hasSlug =
                (fullPath.match(new RegExp('/', 'g')) || []).length > 2
            return array.length > 0 && hasSlug
                ? array[array.length - 1]
                : 'landing'
        },
        isDateQueryEmpty() {
            return !this.$route.query.date
        },
        isHideBlogPosts() {
            return !isBlogValid(this.paramsRegion, this.paramsLang)
        },
        regionList() {
            return this.$store.getters['api/regionList']
        },
        currentRegionObj() {
            const region = this.paramsRegion
            const currentRegion = _filter(this.regionList, [
                'country_code',
                region
            ])
            let selectedRegionObj
            if (currentRegion.length > 0) {
                selectedRegionObj = currentRegion[0]
            }
            return selectedRegionObj
        },
        isLocationGroupValid() {
            const group = this.paramsGroup
            if (
                this.currentRegionObj &&
                this.currentRegionObj.groups?.length > 0
            ) {
                const currentArea = _filter(this.currentRegionObj.groups, [
                    'slug',
                    group
                ])
                return currentArea.length > 0
            }
            return false
        },
        isSSR() {
            return process.server
        },
        isBlockGooglebotFromButton() {
            return this.$store.state.isBlockGooglebotFromButton
        },
        // update function name later
        landingPageName() {
            return this.isHomePage
                ? 'HOME'
                : this.isMiniSite
                ? 'SUB_LANDING'
                : this.isListingPage
                ? 'LISTING'
                : this.isLocationPage
                ? 'LOCATION'
                : this.isDetailsPage
                ? 'DETAILS'
                : this.isDayuseVsFlowPage
                ? 'DAYUSE_VS_FLOW'
                : 'OTHER'
        }
    },
    methods: {
        async getIdToken() {
            let idToken
            const isSigned = this.$store.getters['member/isSigned']
            if (isSigned !== null) {
                idToken = await firebase.getIdToken()
            }
            return idToken
        },
        deviceType() {
            let deviceType = ''
            if (this.$device.isDesktop) {
                deviceType = 'desktop'
            } else if (this.$device.isTablet) {
                deviceType = 'tablet'
            } else if (this.$device.isMobile) {
                deviceType = 'mobile'
            }
            return deviceType
        },
        globalSpaceType(space) {
            let type = ''
            switch (space) {
                case 'hotel':
                    type = 'rest'
                    break
                case 'co-working':
                    type = 'work'
                    break
                case 'meeting':
                    type = 'meet'
                    break
                case 'staycation':
                    type = 'staycation'
                    break
                case 'party':
                    type = 'party'
                    break
            }
            return type
        },
        globalSpace(service = this.paramsSpace) {
            let space = ''
            switch (service) {
                case 'rest':
                    space = 'hotel'
                    break
                case 'work':
                    space = 'co-working'
                    break
                case 'meet':
                    space = 'meeting'
                    break
                case 'staycation':
                    space = 'staycation'
                    break
                case 'party':
                    space = 'party'
                    break
            }
            return space
        },
        globalSpaceTypeNum(space) {
            let type = ''
            switch (space) {
                case 'hotel':
                    type = '1'
                    break
                case 'co-working':
                    type = '2'
                    break
                case 'meeting':
                    type = '3'
                    break
                case 'staycation':
                    type = '4'
                    break
                case 'party':
                    type = '5'
                    break
                default:
                    type = '1'
            }
            return type
        },
        getSpaceTypeByNum(space) {
            let spaceType = ''
            switch (space) {
                case 1:
                    spaceType = 'rest'
                    break
                case 2:
                    spaceType = 'work'
                    break
                case 3:
                    spaceType = 'meet'
                    break
                case 4:
                    spaceType = 'staycation'
                    break
                case 5:
                    spaceType = 'party'
                    break
                default:
                    spaceType = 'rest'
            }
            return spaceType
        },
        getGlobalSpaceTypeByNum(space) {
            let spaceType = ''
            switch (space) {
                case 1:
                    spaceType = 'hotel'
                    break
                case 2:
                    spaceType = 'co-working'
                    break
                case 3:
                    spaceType = 'meeting'
                    break
                case 4:
                    spaceType = 'staycation'
                    break
                case 5:
                    spaceType = 'party'
                    break
                default:
                    spaceType = 'hotel'
            }
            return spaceType
        },
        isGlobalHotel(space) {
            return space === 'hotel' || space === 'staycation'
        },
        osType() {
            // const isIos =
            //     [
            //         'iPad Simulator',
            //         'iPhone Simulator',
            //         'iPod Simulator',
            //         'iPad',
            //         'iPhone',
            //         'iPod'
            //     ].includes(navigator.platform) ||
            //     // iPad on iOS 13 detection
            //     (navigator.userAgent.includes('Mac') &&
            //         'ontouchend' in document)
            const isIos =
                this.$device.isMacOS ||
                this.$device.isIos ||
                this.$device.isSafari
            if (isIos) {
                return 'ios'
            } else {
                return 'android'
            }
        },
        searchAnalyticsTags() {
            const analyticsTags = ['web']
            // Device Tag
            if (this.$device.isDesktop) {
                analyticsTags.push('desktop')
            } else if (this.$device.isTablet) {
                analyticsTags.push('tablet')
            } else if (this.$device.isMobile) {
                analyticsTags.push('mobile')
            } else {
                analyticsTags.push('other_device')
            }
            // OS Tag
            if (this.$device.isAndroid) {
                analyticsTags.push('android')
            } else if (this.$device.isIos) {
                analyticsTags.push('ios')
            } else if (this.$device.isWindows) {
                analyticsTags.push('windows')
            } else if (this.$device.isMacOS) {
                analyticsTags.push('mac')
            } else {
                analyticsTags.push('other_os')
            }
            // Browser Tag
            if (this.$device.isChrome) {
                analyticsTags.push('chrome')
            } else if (this.$device.isEdge) {
                analyticsTags.push('edge')
            } else if (this.$device.isFirefox) {
                analyticsTags.push('firefox')
            } else if (this.$device.isSafari) {
                analyticsTags.push('safari')
            } else if (this.$device.isCrawler) {
                analyticsTags.push('crawler')
            } else {
                analyticsTags.push('other_browser')
            }
            // Region Tag
            analyticsTags.push(this.$route.params.region)
            // Language Tag
            analyticsTags.push(this.$route.params.locale)
            // Guest / User Tag
            const isSigned = this.$store.getters['member/isSigned']
            if (isSigned) {
                analyticsTags.push('user')
            } else {
                analyticsTags.push('guest')
            }
            return analyticsTags
        },
        isLangDisplay(langDisplay) {
            const lang = this.$route.params.locale
            const region = this.$route.params.region
            switch (langDisplay) {
                case 'en':
                    return lang === 'en' || lang !== 'zh'
                case 'zh':
                    return lang === 'zh' && (region === 'hk' || region === 'mo')
                case 'cn':
                    return lang === 'zh' && (region === 'sg' || region === 'my')
                default:
                    return false
            }
        },
        isNameValid(name) {
            const region = this.$route.params.region
            const isEnglish = inputValidation.validate(
                inputValidation.type.english,
                name
            )
            const isVietnamese = inputValidation.validate(
                inputValidation.type.vietnamese,
                name
            )
            return region !== 'vn' ? isEnglish : isEnglish || isVietnamese
        },
        switchDefaultLang(lang, region) {
            let locales = ['en', 'zh']
            switch (region) {
                case 'my':
                    locales = ['en', 'zh', 'ms']
                    break
                case 'vn':
                    locales = ['en', 'vi']
                    break
            }
            if (locales.includes(lang)) {
                return lang
            } else {
                return 'en'
            }
        },
        confirmPopUpModal(
            msg = '',
            tit = '',
            okTitle = this.$t('ok'),
            cancelTitle = this.$t('cancel')
        ) {
            return this.$bvModal.msgBoxConfirm(msg, {
                title: tit,
                size: 'md',
                buttonSize: 'md',
                okVariant: 'dark',
                okTitle,
                cancelVariant: 'primary',
                cancelTitle,
                hideHeaderClose: true,
                centered: true,
                noCloseOnBackdrop: true,
                noCloseOnEsc: false
            })
        },
        okPopUpModal(msg = this.$t('err_occurred'), tit = '', mainMessage = '') {
            const h = this.$createElement
            const subtitleVNode = h('p', { class: ['mt-3'] }, msg)
            const messageVNode = h('p', { class: ['mt-3'] }, mainMessage)
            const arr = [subtitleVNode]
            if (mainMessage){
                arr.push(messageVNode)
            }
            return this.$bvModal.msgBoxOk( arr, {
                title: tit,
                okTitle: this.$t('ok'),
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                contentClass: 'justify-content-center text-center',
                footerClass: 'justify-content-center',
                okVariant: 'dark',
                size: 'sm',
                buttonSize: 'sm',
                centered: true
            })
        },
        hiddenScrollBar() {
            document.documentElement.style.overflow = 'hidden'
            if (this.$device.isDesktop) {
                document.documentElement.style.setProperty(
                    'margin-right',
                    '15px'
                )
            }
        },
        addScrollBar() {
            document.documentElement.style.overflow = 'auto'
            document.documentElement.style.setProperty('margin-right', '0')
        },
        navigateRoute(target) {
            this.$router.push({
                path: `/${this.$route.params.region}/${this.$route.params.locale}${target}`
            })
        },
        regionName() {
            const region = this.$route.params.region
            const regionList = this.$store.getters['api/regionList']
            const currentRegion = _filter(regionList, ['country_code', region])
            if (currentRegion.length > 0) {
                return currentRegion[0].region
            } else {
                return 'Hong Kong'
            }
        },
        countryCode() {
            const region = this.$route.params.region
            const regionList = this.$store.getters['api/regionList']
            const currentRegion = _filter(regionList, ['country_code', region])
            if (currentRegion.length > 0) {
                return currentRegion[0].country_code?.toUpperCase()
            } else {
                return 'HK'
            }
        },
        langName() {
            const region = this.$route.params.region
            const lang = this.$route.params.locale
            if (lang === 'en') {
                return this.$t('lang.english')
            }
            if (lang === 'ms') {
                return this.$t('lang.malay')
            }
            if (lang === 'vi') {
                return this.$t('lang.vietnamese')
            }
            if ((region === 'hk' || region === 'mo') && lang === 'zh') {
                return this.$t('lang.trad_chinese')
            }
            if ((region === 'sg' || region === 'my') && lang === 'zh') {
                return this.$t('lang.simp_chinese')
            }
            return this.$t('lang.english')
        },
        /** Added Global suffix to differentiate the isMobileScreenSize() in BookingInfoView */
        isMobileScreenSizeGlobal() {
            // https://stackoverflow.com/a/8876069
            const width = Math.max(
                document.documentElement.clientWidth,
                window.innerWidth || 0
            )
            return width <= 991
        },
        hasQueryParam(url, field) {
            if (url.includes('?' + field + '=')) return true
            else if (url.includes('&' + field + '=')) return true
            return false
        },
        appendOverrideParamIfNeeded(url) {
            let newUrl = url
            if (this.isOverride && !this.hasQueryParam(url, 'override')) {
                const separator = newUrl.includes('?') ? '&' : '?'
                newUrl = newUrl + separator + 'override=true'
            }
            if (this.paramsGroup && !this.hasQueryParam(url, 'group')) {
                const separator = newUrl.includes('?') ? '&' : '?'
                newUrl = newUrl + separator + `group=${this.paramsGroup}`
            }
            return newUrl
        },
        tagText(daySpaceStatus) {
            switch (daySpaceStatus) {
                case 'AVAILABLE':
                    return this.$t('list.sold_out')
                case 'FULL':
                    return this.$t('list.sold_out')
                case 'CLOSED':
                    return this.$t('list.closed')
                case 'STOPPED':
                    return this.$t('list.unavailable')
                case 'QUARANTINE':
                    return this.$t('list.unavailable')
                default:
                    return ''
            }
        },
        getDatePickerLocale() {
            const locale = this.$store.state.locale
            if (locale === 'zh') {
                return zh
            } else if (locale === 'vi') {
                return vi
            } else {
                return en
            }
        },
        getDisplayPrice(price = 0, currencyRegion) {
            const region = currencyRegion ?? this.$route.params.region
            const regionList = this.$store.getters['api/regionList']
            const currentRegion = _filter(regionList, ['country_code', region])
            return currencyServices.show(
                price,
                currentRegion[0].currency.format
            )
        },
        globalNavigateToDetails(
            params,
            space = 'hotel',
            date = dateServices.getDateByFormat(new Date())
        ) {
            let path = `/${this.paramsRegion}/${this.paramsLang}/${params.slug}/${space}?date=${date}`
            if (this.numPpl > 0 && this.space === 'party') {
                path += `&numPpl=${this.numPpl}`
            }
            if (params.book_type) {
                path += `&booklength=${params.book_type}`
            }
            if (params.start_value) {
                path += `&start=${params.start_value}`
            }
            if (params.end_value) {
                path += `&end=${params.end_value}`
            }
            if (params.queryID) {
                path += `&aID=${params.queryID}`
            }
            if (this.algoliaIndex) {
                path += `&index=${this.algoliaIndex}`
            }
            if (params.objectID) {
                path += `&bID=${params.objectID}`
            }
            if (
                params.timeFrame?.length > 0 ||
                params.starRating?.length > 0 ||
                params.theme?.length > 0
            ) {
                path += `&filters=${getAllFiltersValue(
                    params.theme,
                    params.starRating,
                    params.timeFrame
                ).join(',')}`
            }
            if (params.btn) {
                path += `&viewBtn=${params.btn}`
            }
            if (params.bookId) {
                path += `&bookId=${params.bookId}`
            }
            path = this.appendOverrideParamIfNeeded(path)
            const route = this.$router.resolve({ path })
            window.open(route.href, '_blank')
        },
        getSpaceText(toLowerCase = false, plural = false) {
            let text = ''
            switch (this.paramsSpace) {
                case 'hotel':
                    text = plural
                        ? this.$t('list.hotels')
                        : this.$t('list.hotel')
                    break
                case 'co-working':
                    text = plural
                        ? this.$t('list.work_spaces')
                        : this.$t('list.work_space')
                    break
                case 'meeting':
                    text = plural
                        ? this.$t('list.meeting_rooms')
                        : this.$t('list.meeting_room')
                    break
                case 'party':
                    text = plural
                        ? this.$t('list.parties')
                        : this.$t('list.party')
                    break
                case 'staycation':
                    text = plural
                        ? this.$t('list.staycation')
                        : this.$t('list.staycation')
                    break
                default:
                    text = this.$t('list.hotels')
                    break
            }
            text = toLowerCase ? text.toLowerCase() : text
            return text
        },
        getLocationHeader(meta, name, space, localeKeyword) {
            const keyword = this.$t(`keywords.${localeKeyword}`)
            const obj = {
                location: name,
                country: name !== this.regionName() ? this.regionName() : '',
                keyword
            }
            const spaceTypeNum = this.globalSpaceTypeNum(space)
            return this.$t(`location.${meta}_${spaceTypeNum}`, obj)
        },
        getPopularFilterParams(space, query) {
            const spaceType = this.globalSpaceType(space)
            return {
                page: 1,
                space_type: spaceType,
                lang: this.paramsLang,
                region: this.paramsRegion,
                device: this.deviceType(),
                algoliaIndex: computeSearchIndex(
                    this.paramsRegion,
                    getSpaceTypeId(spaceType),
                    process.env.TYPESENSE_ENV
                ),
                algoliaLang: this.globalAlgoliaLang,
                space_type_num: this.globalSpaceTypeNum(space),
                s_default: 1,
                q: query,
                isTestTag: false,
                type: this.type,
                isOverride: this.isOverride,
                analyticsTags: this.searchAnalyticsTags()
            }
        },
        resizeImage(image, tail, target) {
            return image?.replace(tail, target)
        },
        eventBtnName(name, tail) {
            let eventName = name
            if (this.routePathTail) eventName += ' ' + this.routePathTail
            if (tail) eventName += ' ' + tail
            return eventName
        },
        checkElementVisible(id) {
            const elm = document.getElementById(id)
            if (elm) {
                const rect = elm.getBoundingClientRect()
                const viewHeight = Math.max(
                    document.documentElement.clientHeight,
                    window.innerHeight
                )
                return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
            }
            return false
        },
        globalSendPageTrack(params, prevRoute) {
            if (process.client) {
                if (prevRoute && prevRoute !== '/') {
                    params.page_referrer = prevRoute
                }
                params.page_path = this.$route.path
                params['allow_enhanced_conversions'] = true
                this.$gtag('config', process.env.FIREBASE_M_ID, params)
                this.$gtag('config', process.env.FIREBASE_DR_ID, params)
                this.$gtag('config', process.env.FIREBASE_UA_ID, params)
            }
        },
        async globalSendEnhancedConversionsScript() {
            if (process.client && this.globalIsSigned) {
                const idToken = await firebase.getIdToken()
                const obj = {
                    id_token: idToken,
                    lang: this.paramsLang,
                    region: this.paramsRegion
                }
                const res = await this.$store.dispatch(
                    'api/GET_MEMBER_DASH_BROAD_INFO',
                    obj
                )
                const memberInfo = res.data?.Data?.member
                const userData = {
                    "email": memberInfo.email.toLowerCase(),
                    "phone_number": memberInfo.dial_code + memberInfo.phone,
                    "address": {
                        "first_name": memberInfo.given_name,
                        "last_name": memberInfo.surname,
                        // "city":'yourCityVariable',
                        "region": getRegionNameForLocationSeo(
                            this.paramsRegion,
                            'en'
                        ),
                        // "postal_code": 'yourPostalCodeVariable',
                        "country": this.paramsRegion.toUpperCase()
                    }
                }
                this.$gtag('set', 'user_data', userData)
            }
        },
        getMessageForAwxError(awxCode) {
            const awxToErrorCode = {
                '3ds_not_supported': 'NOT_SUPPORTED',
                amount_above_limit: 'EXCEED_AMOUNT',
                amount_below_limit: 'GENERIC',
                authentication_declined: '3DS_NOT_AUTHORIZED',
                card_brand_not_supported: 'FLOW_NOT_SUPPORT',
                configuration_error: 'GENERIC',
                currency_not_supported: 'NOT_SUPPORTED',
                duplicate_request: 'GENERIC',
                frequency_above_limit: 'EXCEED_NUMBER_OF_TXN',
                invalid_status_for_operation: 'GENERIC',
                issuer_declined: 'AIRWALLEX_CANCEL',
                issuer_unavailable: 'ISSUER_UNAVAILABLE',
                no_3ds_liability_shift: 'GENERIC',
                operation_not_supported: 'GENERIC',
                payment_method_not_allowed: 'GENERIC',
                provider_declined: 'GENERIC',
                provider_unavailable: 'GENERIC',
                quote_expired: 'GENERIC',
                rejected_by_routing_rules: 'GENERIC',
                resource_already_exists: 'GENERIC',
                resource_not_found: 'GENERIC',
                risk_declined: 'REFUSE/BLOCKED/FRAUD',
                suspended_from_online_payments: 'GENERIC',
                validation_error: 'GENERIC'
            }
            const errorMessage = {
                GENERIC: 'generic',
                EXPIRE_CARD: 'expire_card',
                INCORRECT_CVC: 'incorrect_cvc',
                INVALID_CARD_NUMBER: 'invalid_card_number',
                AIRWALLEX_REFUSE: 'airwallex_refuse',
                'REFUSE/BLOCKED/FRAUD': 'refuse_blocked_fraud',
                INVALID_AMOUNT: 'invalid_amount',
                ISSUER_UNAVAILABLE: 'issuer_unavailable',
                NOT_SUPPORTED: 'not_supported',
                '3DS_NOT_AUTHORIZED': '3ds_not_authorized',
                INSUFFICIENT_FUNDS: 'insufficient_funds',
                AIRWALLEX_CANCEL: 'airwallex_cancel',
                CUSTOMER_CANCEL: 'customer_cancel',
                EXCEED_AMOUNT: 'exceed_amount',
                EXCEED_NUMBER_OF_TXN: 'exceed_number_of_txn',
                FLOW_NOT_SUPPORT: 'flow_not_support'
            }
            const errorCode = awxToErrorCode[awxCode]
            if (!errorCode) {
                return errorMessage.GENERIC
            }
            return errorMessage[errorCode]
        },
        getTagStyleByType(type) {
            switch (type) {
                case 'fomo_last_booked':
                    return 'fomo-space-tag fomo-tag-purple fomo-last-booked'
                case 'fomo_limited_availability':
                case 'fomo_last_room':
                    return 'fomo-space-tag fomo-tag-purple fomo-last-room'
                case 'feature':
                    return 'fomo-space-tag feature-tag-yellow fomo-last-booked'
                default:
                    return 'feature-tag'
            }
        },
        paramsToJsonObject(entries) {
            const result = {}
            for(const [key, value] of entries) {
                result[key] = value
            }
            return result
        },
        globalComputeLinkToDetails(slug, space = 'hotel') {
            return `/${this.paramsRegion}/${this.paramsLang}/${slug}/${space}`
        },
        mapToTimePeriodTranslations(period, translations) {
            const isMobile = this.deviceType() === 'mobile'
            const daycationRemark = `(${this.$t('search_bar.daycation')})`
            const overnightRemark = `(${this.$t('search_bar.overnight')})`
            const periodText = translations[period]
            if (period === DATE_TIME.MIDNIGHT_TIME) {
                return `${periodText} ${isMobile ? '' : daycationRemark}`
            }
            if (period === DATE_TIME.MORNING_TIME) {
                return `${periodText} ${isMobile ? '' : daycationRemark}`
            }
            if (period === DATE_TIME.AFTERNOON_TIME) {
                return `${periodText} ${isMobile ? '' : daycationRemark}`
            }
            if (period === DATE_TIME.EVENING_TIME) {
                return `${periodText} ${isMobile ? '' : daycationRemark}`
            }
            if (period === DATE_TIME.ASAP_CHECK_IN_TIME_N) {
                return `${periodText} ${isMobile ? '' : overnightRemark}`
            }
            if (period === DATE_TIME.AFTER_MIDNIGHT_TIME) {
                return `${periodText} ${isMobile ? '' : overnightRemark}`
            }
            if (period === DATE_TIME.AFTER_X_TIME) {
                return `${periodText} ${isMobile ? '' : overnightRemark}`
            }
            return ''
        },
        globalCopyText(text) {
            this.$copyText(text)
            this.globalShowToast()
        },
        globalShowToast() {
            this.$bvToast.toast(this.$t('copied_to_clipboard'), {
                noCloseButton: true,
                autoHideDelay: 4000,
                appendToast: true
            })
        }
    }
}
