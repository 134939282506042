<template>
    <div :style="isFixedTop ? 'margin-bottom: 63.5px;' : ''">
        <b-navbar
            :class="isFixedTop ? 'fixed-top' : ''"
            class="navbar navbar-dark bg-dark px-3 py-2 nav-container"
            toggleable="false"
            type="light"
            variant="fade"
        >
            <b-navbar-brand
                @click="onNavigationItemClicked"
                class="mr-xl-4 mr-lg-2 mr-md-0 hidden-md text-center"
            >
                <div class="d-flex flex-row" style="width: 58px; height: 27px;">
                    <client-only>
                        <NuxtLink
                            :to="
                                `/${$store.state.region}/${$store.state.locale}`
                            "
                            rel="nofollow"
                            class="flow-logo align-self-center"
                        >
                            <ImageContainer
                                :src="
                                    isVNTestEnv
                                        ? 'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/0f79514d-6625-4812-7645-207e6c462800/mobile'
                                        : 'https://cdn.flowtheroom.com/qOh2lp-0AGWeBP68yRH5aQ/72d672d0-b270-4ce9-19a6-3d6b8f17bd00/mobile'
                                "
                                :width="isVNTestEnv ? '658' : '300'"
                                :height="isVNTestEnv ? '309' : '139'"
                                class="logo mx-0 my-2 w-auto"
                                alt="Flow logo"
                            />
                        </NuxtLink>
                    </client-only>
                    <ImageContainer
                        v-if="affiliateLogo !== undefined"
                        :src="affiliateLogo"
                        class="logo-separator my-0 position-relative"
                        alt="affiliate logo"
                    />
                </div>
            </b-navbar-brand>
            <div class="w-auto flex-grow-1 d-none d-lg-block" />
            <ul class="mb-0 p-0 d-none d-lg-block">
                <b-nav-item-dropdown
                    v-if="isDisplayHourlyHotels"
                    class="mr-xl-4 mr-lg-2 mr-0 dropdown-toggle text-center icon"
                    style="list-style-type: none;"
                    right
                    no-caret
                >
                    <template #button-content>
                        <b-button
                            variant="transparent"
                            aria-label="Find a hotel"
                            class="textBtn text text-s-bold text-13 p-0"
                        >
                            {{ $t('header.find_hotel') }}
                        </b-button>
                    </template>
                    <div class="mb-2 text-center">
                        <b-dropdown-item
                            v-for="(item, index) in list[lang + '-' + region]"
                            :key="`region_${index}`"
                            @click="() => navigate(item.value)"
                        >
                            <p
                                :class="
                                    pathName === item.value
                                        ? 'text-primary'
                                        : 'text-dark'
                                "
                                class="icon text-primary"
                            >
                                {{ item.text }}
                            </p>
                        </b-dropdown-item>
                    </div>
                </b-nav-item-dropdown>
            </ul>
            <NuxtLink
                :to="toLink('/favourite')"
                class="d-none d-lg-block textBtn text text-s-bold text-13 text-center mx-4"
            >
                <div @click="onNavigationItemClicked">
                    {{ $t('header.my_list') }}
                </div>
            </NuxtLink>
            <client-only>
                <NuxtLink
                    v-if="!isVNTestEnv"
                    :to="toLink('/app')"
                    class="d-none d-lg-block  textBtn text text-s-bold text-13 text-center mx-4"
                >
                    <div @click="onNavigationItemClicked">
                        {{ $t('header.app') }}
                    </div>
                </NuxtLink>
            </client-only>
            <b-button
                @click="onMoreItemsClicked"
                class="d-none d-lg-inline-flex textBtn text text-s-bold text-13 mx-4"
                style="border: none;"
            >
                {{ $t('header.explore_more') }}
                <ChevronDownSvg />
            </b-button>
            <div class="w-auto flex-grow-1 d-none d-lg-block" />
            <div
                @click="openRegionModal"
                class="region-btn py-2 px-3"
                style="cursor: pointer"
            >
                <div class="d-flex region-btn-wrapper px-1 py-1">
                    <LocaleGlobeSvg class="region-btn-svg ml-1" />
                    <div class="text-mid ml-3 mr-2 region-text">
                        <div class="text-15 d-none d-lg-block">
                            {{ regionName() }} - {{ langName() }}
                        </div>
                        <div class="text-15 d-block d-lg-none">
                            {{ countryCode() }} - {{ langName() }}
                        </div>
                    </div>
                    <ChevronDownSvg class="region-btn-svg" />
                </div>
            </div>
            <b-button
                :disabled="isSigned === null"
                @click="
                    () => {
                        !isSigned ? openLoginModal() : navigateToDashboard()
                        onNavigationItemClicked()
                    }
                "
                class="d-none d-lg-block"
            >
                <div class="d-flex">
                    <ProfileSvg v-if="isSigned" class="signInBtn-icon" />
                    <div
                        v-else
                        class="signInBtn text-14 text-bold-bold text-uppercase text-9d9d9d"
                    >
                        {{ $t('signin') }}
                    </div>
                </div>
            </b-button>
            <b-button
                @click="isNavbarCollapseOpen = !isNavbarCollapseOpen"
                aria-label="nav menu"
                class="d-md-block d-lg-none px-0"
                style="padding: 12px"
                variant="transparent"
            >
                <div class="d-flex align-items-center">
                    <MenuSvg />
                    <ProfileSvg class="ml-1" />
                </div>
            </b-button>
            <b-navbar-nav
                v-if="isNavbarCollapseOpen"
                class="align-items-center w-sm-100 w-lg-50"
                style="max-height: 75vh;"
            >
                <div class="row w-100">
                    <div
                        class="col-12 d-md-block d-lg-none align-items-center py-2"
                    >
                        <b-button
                            :disabled="isSigned === null"
                            @click="
                                () => {
                                    !isSigned
                                        ? openLoginModal()
                                        : navigateToDashboard()
                                    onNavigationItemClicked()
                                }
                            "
                            class="textBtn text text-s-bold text-13 p-0 w-100"
                            variant="transparent"
                        >
                            <div
                                class="d-flex align-items-center justify-content-center w-auto"
                            >
                                <ProfileSvg class="menu-profile-icon" />
                                <p class="text-dark ml-2">
                                    {{
                                        isSigned
                                            ? $t('member.my_profile')
                                            : $t('signin')
                                    }}
                                </p>
                            </div>
                        </b-button>
                        <b-nav-item-dropdown
                            v-if="isDisplayHourlyHotels"
                            class="dropdown-toggle text-center icon"
                            right
                            no-caret
                        >
                            <template #button-content>
                                <b-button
                                    variant="transparent"
                                    aria-label="Find a hotel"
                                    class="textBtn text text-s-bold text-13 p-0"
                                    style="color: #020202"
                                >
                                    {{ $t('header.find_hotel') }}
                                </b-button>
                            </template>
                            <div class="mb-2 text-center">
                                <b-dropdown-item
                                    v-for="(item, index) in list[
                                        lang + '-' + region
                                    ]"
                                    :key="`region_${index}`"
                                    @click="() => navigate(item.value)"
                                >
                                    <p
                                        :class="
                                            pathName === item.value
                                                ? 'text-primary'
                                                : 'text-dark'
                                        "
                                        class="icon text-primary"
                                    >
                                        {{ item.text }}
                                    </p>
                                </b-dropdown-item>
                            </div>
                        </b-nav-item-dropdown>
                        <client-only>
                            <NuxtLink
                                v-if="!isVNTestEnv"
                                :to="toLink('/app')"
                                class="textBtn text text-s-bold text-13 text-center"
                                style="color: #020202"
                            >
                                <div
                                    @click="onNavigationItemClicked"
                                    class="mb-2"
                                >
                                    {{ $t('header.app') }}
                                </div>
                            </NuxtLink>
                        </client-only>
                        <NuxtLink
                            :to="toLink('/favourite')"
                            class="textBtn text text-s-bold text-13 text-020202 text-center"
                            style="color: #020202"
                        >
                            <div @click="onNavigationItemClicked">
                                {{ $t('header.my_list') }}
                            </div>
                        </NuxtLink>
                    </div>
                    <div class="col-12 col-lg-6">
                        <NavigationHeaderTitle>
                            {{ $t('header.useful_information') }}
                        </NavigationHeaderTitle>
                        <NavigationItem
                            v-if="!isHideBlogPosts"
                            :title="$t('header.blog')"
                            :destination="toLink('/blog')"
                            :description="$t('header.blog_description')"
                            @click.native="onNavigationItemClicked"
                            material-icon="book"
                        />
                        <NavigationItem
                            :title="$t('header.rewards_program')"
                            :destination="toLink('/rewards')"
                            :description="
                                $t('header.rewards_program_description')
                            "
                            @click.native="onNavigationItemClicked"
                            icon-url="
                            https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/781b36ab-eed8-4f4f-b0ec-b4c0a511ac00/edm
                        "
                        />
                        <NavigationItem
                            v-if="region !== 'vn'"
                            :title="$t('header.flow_dollar')"
                            :destination="toLink('/top-up')"
                            :description="$t('header.flow_dollar_description')"
                            @click.native="onNavigationItemClicked"
                            icon-url="
                            https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/3f13025f-0138-4969-7576-1459aaf34100/edm
                        "
                        />
                    </div>
                    <div class="col-12 col-lg-6">
                        <NavigationHeaderTitle>
                            {{ $t('header.about_flow') }}
                        </NavigationHeaderTitle>
                        <NavigationItem
                            :title="$t('header.about')"
                            :destination="toLink('/about')"
                            :description="$t('header.about_us_description')"
                            @click.native="onNavigationItemClicked"
                            icon-url="
                            https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/dfc6f3e2-eb00-4335-61de-bf4c6b8c4700/edm
                        "
                        />
                        <NavigationItem
                            :title="$t('header.contact')"
                            :destination="toLink('/contact')"
                            @click.native="onNavigationItemClicked"
                            icon-url="
                            https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/17cd27ff-9b37-4664-b304-617c37268f00/edm
                        "
                        />
                        <NavigationItem
                            :title="$t('header.faq')"
                            :destination="toLink('/faq')"
                            @click.native="onNavigationItemClicked"
                            icon-url="
                            https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/c6675cd3-7e9d-47c8-d710-d8fdc536fe00/edm
                        "
                        />
                    </div>
                </div>
            </b-navbar-nav>
        </b-navbar>
        <transition name="fade">
            <div
                v-if="isNavbarCollapseOpen"
                @click="onNavbarBackdropPressed"
                class="navbar-backdrop"
            ></div>
        </transition>
        <MemberLogin v-if="isLoginModalVisible" @close="closeLoginModal" />
        <LangRegion
            v-if="isLocaleModalVisible"
            :is-lang-region-valid="isLangRegionValid"
            @close="closeRegionModal"
            @changeLangRegion="changeLangRegion"
            @closeNavbar="closeNavbar"
        />
        <MemberPhoneAuth
            v-if="isPhoneAuthModalVisible"
            @close="closePhoneAuthModal"
        />
        <ServicesSelector />
    </div>
</template>

<script>
import _find from 'lodash/find'
import storage from '../services/storage'
import firebase from '../services/firebase'
import MemberLogin from './modal/MemberLogin'
import ServicesSelector from './modal/ServicesSelector'
import MemberPhoneAuth from './modal/MemberPhoneAuth'
import LangRegion from '@/components/modal/LangRegion'
import NavigationHeaderTitle from '@/components/ui/NavigationHeaderTitle'
import NavigationItem from '@/components/ui/NavigationItem'
import Api from '@/services/api'
import ChevronDownSvg from '@/components/iconSvg/ChevronDownSvg'
import LocaleGlobeSvg from '@/components/iconSvg/LocaleGlobeSvg'
import MenuSvg from '@/components/iconSvg/MenuSvg'
import ProfileSvg from '@/components/iconSvg/ProfileSvg'
import { isBlogValid, isPromotionsValid } from '@/utils/locale'
import ImageContainer from "@/components/ui/ImageContainer.vue";
const api = Api.create()

export default {
    name: 'AppHeader',
    components: {
        ImageContainer,
        LangRegion,
        MemberPhoneAuth,
        ServicesSelector,
        MemberLogin,
        NavigationHeaderTitle,
        NavigationItem,
        ChevronDownSvg,
        LocaleGlobeSvg,
        MenuSvg,
        ProfileSvg
    },
    data() {
        return {
            show: false,
            variants: [
                'primary',
                'secondary',
                'success',
                'warning',
                'danger',
                'info',
                'light',
                'dark'
            ],
            headerBgVariant: 'dark',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'warning',
            footerTextVariant: 'dark',
            hideLang: false,
            hideBookBtn: true,
            isLoginModalVisible: false,
            isPhoneAuthModalVisible: false,
            isNavbarCollapseOpen: false,
            isFixedTop: false,
            blogRouteName: [
                'region-locale-blog',
                'region-locale-blog-id',
                'region-locale-blog-author-id',
                'region-locale-blog-tag-id'
            ],
            promotionRouteName: [
                'region-locale-promotions',
                'region-locale-promotions-id'
            ],
            subLandingRouteName: [
                'region-locale-day-use-hotels',
                'region-locale-hourly-hotels',
                'region-locale-budget-hotels',
                'region-locale-daycation',
                'region-locale-work-from-hotel'
            ],
            flowLogo:
                'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/b8579619-f6be-49e1-1c47-b89fa1691a00/mobile',
            selected: null,
            options: [
                { value: null, text: 'Language' },
                { value: 'en', text: 'English' },
                { value: 'zh', text: '繁體中文' }
            ],
            list: {
                'en-sg': [
                    { value: 'hourly-hotels', text: 'Hourly Hotels' },
                    { value: 'day-use-hotels', text: 'Day use hotels' },
                    { value: 'budget-hotels', text: 'Budget Hotels' },
                    { value: 'daycation', text: 'Daycation' },
                    { value: 'work-from-hotel', text: 'Work from Hotel' }
                ],
                'en-my': [
                    { value: 'hourly-hotels', text: 'Hourly Hotels' },
                    { value: 'daycation', text: 'Daycation' },
                    { value: 'work-from-hotel', text: 'Work from Hotel' }
                ],
                'zh-hk': [
                    { value: 'hourly-hotels', text: '時鐘酒店' },
                    { value: 'daytime-hotels', text: '日間酒店' }
                ]
            },
            isLangRegionValid: true,
            affiliateLogo: undefined
        }
    },
    async fetch() {
        await this.getRegionList(this.lang)
        await this.getDefaultRegionSetting()
    },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        },
        storeRegion() {
            return this.$store.state.region
        },
        storeLang() {
            return this.$store.state.locale
        },
        isSigned() {
            return this.$store.getters['member/isSigned']
        },
        isVerified() {
            return this.$store.getters['member/isPhoneAuthPassed']
        },
        pathName() {
            return this.$route.path.split('/').pop()
        },
        isDisplayHourlyHotels() {
            const currentLang = this.lang + '-' + this.region
            switch (currentLang) {
                case 'zh-hk':
                case 'en-sg':
                case 'en-my':
                    return true
                default:
                    return false
            }
        },
        isLocaleModalVisible() {
            return this.$store.getters['general/isLocaleModalVisible']
        },
        isHideLocaleBtn() {
            return this.$route.name === 'region-locale'
        }
    },
    watch: {
        isNavbarCollapseOpen(newVal, oldVal) {
            if (newVal) {
                this.isFixedTop = true
            } else {
                setTimeout(() => {
                    this.isFixedTop = false
                }, 400)
            }
        }
    },
    async mounted() {
        // this.hideButton(this.$router.currentRoute.name)
        this.isInvited()
        this.getRegionList(this.lang)
        this.checkLangRegion()
        this.showAffiliateLogo()
        await this.getDefaultRegionSetting()
    },
    methods: {
        async changeLangRegion(lang, region, group) {
            try {
                await storage.setLanguage(lang)
                await storage.setRegion(region)
                await this.$store.commit('setLanguage', lang)
                await this.$store.commit('setRegion', region)
                await this.$store.dispatch(
                    'general/REMOVE_DEFAULT_REGION_SETTING'
                )
                await this.saveCurrentLangRegion(lang, region, group)
                let route
                const urlParts = this.$router.currentRoute.fullPath.split('?')
                if (urlParts.length > 1) {
                    const params = new URLSearchParams(urlParts[1])
                    params.delete('group')
                    route = urlParts[0] + '?' + params.toString()
                } else {
                    route = this.$router.currentRoute.fullPath
                }
                let path = ''
                const tail = this.$route.name === 'region-locale' ? '' : '/'
                if (this.isLangRegionValid) {
                    path = route.replace(
                        `/${this.region}/${this.lang}${tail}`,
                        `/${region}/${lang}${tail}`
                    )
                } else {
                    this.isLangRegionValid = true
                    path = `/${region}/${lang}`
                }
                if (
                    this.blogRouteName.includes(
                        this.$router.currentRoute.name
                    ) &&
                    (this.region !== region || this.lang !== lang)
                ) {
                    // redirect to blog index page when user change region / lang
                    path = `/${region}/${lang}`
                    if (isBlogValid(region, lang)) {
                        path += '/blog'
                    }
                } else if (
                    this.promotionRouteName.includes(
                        this.$router.currentRoute.name
                    ) &&
                    (this.region !== region || this.lang !== lang)
                ) {
                    // redirect to promotion index page when user change region / lang
                    path = `/${region}/${lang}`
                    if (isPromotionsValid(region, lang)) {
                        path += '/promotions'
                    }
                } else if (
                    this.$router.currentRoute.name ===
                        'region-locale-spaceName-space' &&
                    this.region !== region
                ) {
                    // redirect to home page when change region in details page
                    path = `/${region}/${lang}`
                } else if (
                    this.subLandingRouteName.includes(
                        this.$router.currentRoute.name
                    )
                ) {
                    // redirect to home page when change region or lang in sublanding page
                    path = `/${region}/${lang}`
                } else if (
                    this.$router.currentRoute.name === 'region-locale-top-up' &&
                    region === 'vn'
                ) {
                    // redirect to home page when change region to vi in top up page
                    path = `/${region}/${lang}`
                } else if (
                    this.$route.params.keyword &&
                    this.region !== region
                ) {
                    // redirect to home page when change region in location page
                    path = `/${region}/${lang}`
                } else if (
                    this.$router.currentRoute.name === 'region-locale-space' &&
                    this.region !== region
                ) {
                    // redirect to valid space when change region in space page
                    path = await this.getPathWithValidSpace(region, lang)
                } else if (
                    this.$router.currentRoute.name ===
                        'region-locale-dayuse-vs-flow' &&
                    region !== 'hk' && region !== 'sg'
                ) {
                    // redirect to home page when change region in dayuse compare page
                    path = `/${region}/${lang}`
                }
                await this.$store.dispatch('algoliaSearch/RESET_DEALS')
                const isNavigateWithGroup = region === 'my' && !!group
                await this.$router.push({
                    path,
                    query: {
                        group: isNavigateWithGroup ? group : undefined
                    }
                })
                if (isNavigateWithGroup) {
                    await this.$store.dispatch('general/UPDATE_PREF_GROUP', {
                        group
                    })
                }
                await this.getRegionList(lang)
                await this.getDefaultRegionSetting(region, lang)
                await this.getSearchBarSetting()
            } catch (error) {
                this.$sentry.captureException(error)
                alert(this.$t('err_occurred'))
            }
        },
        async getRegionList(lang) {
            const params = {
                lang,
                region: this.region
            }
            await this.$store.dispatch('api/GET_REGION_LIST', params)
        },
        async getDefaultRegionSetting(
            region = this.paramsRegion,
            lang = this.paramsLang
        ) {
            const params = {
                lang,
                region
            }
            await this.$store.dispatch(
                'general/GET_DEFAULT_REGION_SETTING',
                params
            )
        },
        getSearchBarSetting(spaceType = 'rest') {
            return this.$store.dispatch('api/GET_SEARCH_BAR_SETTING', {
                space_type: spaceType,
                region: this.paramsRegion,
                lang: this.paramsLang,
                group: this.paramsGroup,
                device: this.deviceType()
            })
        },
        toLink(target) {
            return `/${this.storeRegion}/${this.storeLang}${target}`
        },
        navigate(page) {
            this.isNavbarCollapseOpen = false
            this.$router.push({
                path: `/${this.region}/${this.lang}/${page}`
            })
        },
        // hideButton(currentRoute) {
        //     this.hideLang = false
        //     this.hideBookBtn = false
        //
        //     switch (currentRoute) {
        //         case 'list':
        //             this.hideBookBtn = true
        //             break
        //         case 'details':
        //             this.hideBookBtn = true
        //             this.hideLang = false
        //             break
        //         case 'checkout':
        //             this.hideBookBtn = true
        //             this.hideLang = true
        //             break
        //         case 'complete':
        //             this.hideBookBtn = true
        //             this.hideLang = true
        //             break
        //     }
        // },
        openLoginModal() {
            this.isLoginModalVisible = true
        },
        closeLoginModal() {
            this.isLoginModalVisible = false
        },
        openRegionModal() {
            this.$store.dispatch('general/SET_LOCALE_MODAL_VISIBLE', {
                isVisible: true
            })
        },
        closeRegionModal() {
            this.$store.dispatch('general/SET_LOCALE_MODAL_VISIBLE', {
                isVisible: false
            })
        },
        closeNavbar() {
            this.isNavbarCollapseOpen = false
        },
        isInvited() {
            if (this.$route.query.code != null) {
                this.isLoginModalVisible = true
            }
        },
        openPhoneAuthModal() {
            this.isPhoneAuthModalVisible = true
        },
        closePhoneAuthModal() {
            this.isPhoneAuthModalVisible = false
        },
        navigateToDashboard() {
            if (this.isSigned && this.isVerified) {
                this.$router.push({
                    path: `/${this.region}/${this.lang}/user/account`
                })
            } else if (this.isSigned && !this.isVerified) {
                // open phone auth modal
                this.openPhoneAuthModal()
            }
        },
        async saveCurrentLangRegion(lang, region, group) {
            if (this.isSigned) {
                await api.saveCurrentLangRegion(
                    { id_token: await firebase.getIdToken() },
                    lang,
                    region,
                    group
                )
            }
        },
        checkLangRegion() {
            const fullPath = this.$route.fullPath
            if (fullPath === '/' || fullPath === '') {
                this.isLangRegionValid = false
                this.openRegionModal()
            }
        },
        async showAffiliateLogo() {
            const site = this.$cookies.get('affiliate-site')
            if (site !== undefined && site.length > 0) {
                this.affiliateLogo = await this.$store.dispatch(
                    'api/GET_AFFILIATE_SITE_INFO',
                    {
                        domain: site,
                        region: this.region,
                        lang: this.lang
                    }
                )
            }
        },
        async getPathWithValidSpace(region, lang) {
            const spaceTypeInfo = await this.$store.dispatch(
                'api/GET_SPACE_TYPE_INFO',
                {
                    lang,
                    region,
                    all: 0
                }
            )
            const isSpaceTypeValid = _find(spaceTypeInfo, (item) => {
                return item.space_type === this.globalSpaceType(this.space)
            })
            const tailPath = isSpaceTypeValid
                ? `${this.space}?${this.$route.fullPath.split('?')[1]}`
                : `hotel?date=${this.$router.currentRoute.query.date}`
            return `/${region}/${lang}/${tailPath}`
        },
        onMoreItemsClicked() {
            this.isNavbarCollapseOpen = !this.isNavbarCollapseOpen
        },
        onNavigationItemClicked() {
            this.isNavbarCollapseOpen = false
        },
        onNavbarBackdropPressed() {
            this.isNavbarCollapseOpen = false
        }
    }
}
</script>

<style scoped>
.logo {
    height: 27px;
    min-width: 58px;
}
.text {
    letter-spacing: 1px;
    color: var(--light);
}
.textBtn {
    font-size: 15px;
    text-decoration: none;
}
.icon {
    color: var(--light);
}
.icon:active {
    color: var(--light);
}
.menu-profile-icon {
    fill: #020202;
}
.signInBtn:hover,
.signInBtn-icon:hover {
    color: #f2c83d;
    fill: #f2c83d;
}
.signInBtn-icon {
    color: #ffffff;
}
/*.vl {*/
/*    height: 33px;*/
/*    border-left-color: #9d9d9d;*/
/*    border-style: solid;*/
/*    border-width: 1px;*/
/*}*/
.dropdown-toggle::after {
    display: none;
}
.navbar-backdrop {
    z-index: 1029;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.region-btn-wrapper {
    border-radius: 22px;
    border: 2px solid white;
    color: white;
    align-items: center;
    display: flex;
}
.region-btn:hover >>> .region-btn-svg {
    fill: #f2c83d;
}
.region-btn:hover >>> .region-text {
    color: #f2c83d;
}
.region-btn:hover >>> .region-btn-wrapper {
    border-color: #f2c83d;
}
.logo-separator {
    height: 27px;
    width: auto;
    left: 4px;
    border-left: solid 2px #ffffff;
    padding-left: 4px;
}
.navbar-nav {
    background-color: #ffffff;
    position: absolute;
    width: 80vw;
    max-height: 90vh;
    left: 50%;
    top: 70px;
    transform: translate(-50%);
    border-radius: 20px;
    overflow-y: auto;
}
.menu-button {
    padding: 0;
    border: none;
    background: none;
}
.dropdown >>> .dropdown-menu {
    border: 0;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12) !important;
}
@media (min-width: 0px) {
    .w-sm-100 {
        width: 100% !important;
    }
}
@media (min-width: 992px) {
    .w-lg-50 {
        width: 50% !important;
    }
}
@media (min-width: 1440px) {
    .nav-container {
        padding-left: 6rem !important;
        padding-right: 6rem !important;
    }
}
</style>
