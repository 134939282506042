<template>
    <b-btn
        :disabled="isLoading || isDisable"
        :class="[buttonClass + ' ' + buttonPadding + ' ' + `text-${fontSize}`]"
        @click="buttonClicked"
        class="book-btn d-flex align-items-center justify-content-center"
        variant="transparent"
    >
        <b-spinner
            v-if="isLoading"
            variant="light"
            small
            class="position-absolute"
        ></b-spinner>
        <p
            :style="
                isLoading
                    ? 'color: transparent'
                    : buttonClass === 'btn-white'
                    ? 'color: #313131'
                    : buttonClass === 'btn-text-grey'
                    ? 'color: #afafaf'
                    : 'color: #ffffff'
            "
            :class="`text-${fontWeight}`"
        >
            {{ bookButtonText }}
        </p>
        <slot />
    </b-btn>
</template>
<script>
export default {
    name: 'BookButton',
    props: {
        isLoading: {
            type: Boolean,
            default: false
        },
        isDisable: {
            type: Boolean,
            default: false
        },
        bookButtonText: {
            type: String,
            default: undefined
        },
        buttonClass: {
            type: String,
            default: 'btn-yellow'
        },
        buttonPadding: {
            type: String,
            default: 'p-3'
        },
        fontSize: {
            type: String,
            default: '16'
        },
        fontWeight: {
            type: String,
            default: 's-bold'
        }
    },
    methods: {
        buttonClicked() {
            this.$emit('buttonClicked')
        }
    }
}
</script>
<style scoped>
.btn-black {
    background-color: #313131;
}
.btn-white {
    background-color: #ffffff;
    color: black !important;
    border-color: black;
}
.btn-yellow {
    width: 100%;
    background-color: #f2bc1f;
    border-color: #f2bc1f;
}
.btn-grey {
    width: 100%;
    background-color: #d3d4d8;
    border-color: #d3d4d8;
}
.btn-text-grey {
    width: 100%;
    background-color: #ffffff;
    border-color: #afafaf;
    color: #afafaf;
}
</style>
