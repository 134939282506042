<template>
    <transition v-if="offer.code" name="fade">
        <div>
            <div
                class="fixed-bottom p-0 d-none d-lg-flex position-relative w-100"
            >
                <div
                    class="d-flex flex-fill justify-content-start align-items-center position-relative"
                    style="pointer-events: auto; background-color: #FEC923"
                >
                    <div
                        class="position-relative h-100 d-flex align-items-end desktop-iphone"
                    >
                        <ImageContainer
                            class="position-absolute banner-iphone"
                            width="479"
                            height="436"
                            src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/ce41ef6a-2d18-4937-979d-33014904cc00/public"
                            alt="iPhone 13 SVG"
                            style="bottom:0"
                        />
                        <ImageContainer
                            width="397"
                            height="178"
                            src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/9e76371c-0272-406b-71bf-373a4854a500/public"
                            alt="App Banner Left Ellipse"
                            style="height: 90%"
                        />
                    </div>
                    <div class="position-relative h-100">
                        <div
                            class="d-flex flex-column align-items-start justify-content-center pr-4 py-3 h-100 ml-4"
                        >
                            <!--                            <img-->
                            <!--                                class="position-absolute"-->
                            <!--                                loading="lazy"-->
                            <!--                                width="401"-->
                            <!--                                height="170"-->
                            <!--                                src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/042a08f7-58d5-47f0-17d6-9f4a13154300/public"-->
                            <!--                                alt="App Banner Right Ellipse"-->
                            <!--                                style="right: -200px; top: 0;"-->
                            <!--                            />-->
                            <div class="text-23 text-bold position-relative">
                                {{
                                    $t('web_to_app_banner.header', {
                                        price: offer.discount_amount
                                    })
                                }}
                            </div>
                            <div class="text-17 mt-2 position-relative">
                                {{ $t('web_to_app_banner.description') }}
                            </div>
                            <div class="d-flex align-items-center mt-3">
                                <div
                                    class="text-16 text-s-bold text-dark py-2 pl-3 pr-2 bg-white h-100 d-flex align-items-center"
                                    style="border-radius: 50px 0 0 50px;"
                                >
                                    {{ $t('web_to_app_banner.code') }}
                                </div>
                                <div style="color: transparent;">|</div>
                                <div
                                    class="text-16 text-bold text-primary py-2 pl-2 pr-3 bg-white"
                                    style="border-radius: 0 50px 50px 0"
                                >
                                    {{ offer.code }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="py-3 px-4 text-white d-flex flex-column align-items-center justify-content-center h-100 position-relative"
                    style="background: #333333; pointer-events: auto;"
                >
                    <ImageContainer
                        class="black-section-vector"
                        width="500"
                        height="218"
                        src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/0986bccf-1945-4974-e51a-cd03f8915100/public"
                        alt="download app vector 1"
                    />
                    <div class="position-relative">
                        <div class="d-flex w-100 justify-content-end">
                            <b-button
                                variant="transparent"
                                class="p-0 border-0"
                                @click="close"
                            >
                                <Close
                                    style="fill: #FFFFFF; z-index: 10; height: 18px; width: 18px;"
                                />
                            </b-button>
                        </div>
                        <div class="text-15 text-s-bold mb-4 text-center">
                            {{ $t('web_to_app_banner.scan_code') }}
                        </div>
                        <div class="d-flex scan-code-wrapper px-4">
                            <GetAppQRCode class="mb-3" style="width: 106px; height: 106px;"/>
                            <div class="d-flex store-wrapper">
                                <GooglePlayIcon class="ml-4 mb-3" />
                                <AppleStoreIcon class="ml-4" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="navbar navbar-dark p-0 d-block d-lg-none position-relative"
                style="background-color: #FEC923"
            >
                <div
                    class="d-flex flex-fill justify-content-start align-items-center position-relative px-3"
                    style="pointer-events: auto"
                >
                    <div class="d-flex">
                        <ImageContainer
                            class="position-absolute"
                            style="left: 0; bottom: 0; width: 200px; height: auto;"
                            width="397"
                            height="178"
                            src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/9e76371c-0272-406b-71bf-373a4854a500/public"
                            alt="App Banner Left Ellipse"
                        />
                        <MobileBannerIPhone
                            class="ml-2 mr-3 mt-3 position-relative"
                        />
                    </div>
                    <div
                        class="d-flex flex-column align-items-start justify-content-center pt-4 pb-2"
                    >
                        <!--                        <img-->
                        <!--                            class="position-absolute"-->
                        <!--                            style="top: 0; right: 0"-->
                        <!--                            loading="lazy"-->
                        <!--                            width="500"-->
                        <!--                            height="218"-->
                        <!--                            src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/0986bccf-1945-4974-e51a-cd03f8915100/public"-->
                        <!--                            alt="download app vector 2"-->
                        <!--                        />-->
                        <!--                                                <svg-->
                        <!--                                                    class="position-absolute"-->
                        <!--                                                    style="top: 0; right: 0"-->
                        <!--                                                    width="122"-->
                        <!--                                                    height="120"-->
                        <!--                                                    viewBox="0 0 122 120"-->
                        <!--                                                    fill="none"-->
                        <!--                                                    xmlns="http://www.w3.org/2000/svg"-->
                        <!--                                                >-->
                        <!--                        <svg-->
                        <!--                            class="position-absolute"-->
                        <!--                            style="top: 0; right: 0"-->
                        <!--                            width="122"-->
                        <!--                            height="120"-->
                        <!--                            viewBox="0 0 122 120"-->
                        <!--                            fill="none"-->
                        <!--                            xmlns="http://www.w3.org/2000/svg"-->
                        <!--                        >-->
                        <!--                            <circle-->
                        <!--                                cx="82.5"-->
                        <!--                                cy="37.5"-->
                        <!--                                r="82.5"-->
                        <!--                                fill="white"-->
                        <!--                                fill-opacity="0.26"-->
                        <!--                            />-->
                        <!--                        </svg>-->
                        <div class="text-18 text-bold position-relative">
                            {{
                                $t('web_to_app_banner.header', {
                                    price: offer.discount_amount
                                })
                            }}
                        </div>
                        <div
                            class="d-flex align-items-center mt-2 text-14 text-bold position-relative flex-wrap"
                            style="margin-left: -6px;"
                        >
                            <div
                                class="text-dark py-1 px-2 mb-2 bg-white h-100"
                                style="border-radius: 5px; white-space: nowrap"
                            >
                                {{ $t('web_to_app_banner.code') }}
                            </div>
                            <div class="ml-1" style="color: transparent;">|</div>
                            <div
                                class="text-primary py-1 px-2 mb-2 bg-white"
                                style="border-radius: 5px"
                            >
                                {{ offer.code }}
                            </div>
                        </div>
                        <div class="d-flex mt-3 position-relative">
                            <GooglePlayIcon :height="27" />
                            <AppleStoreIcon :height="27" class="ml-2" />
                        </div>
                    </div>
                    <CircleCloseBtn
                        :btn-color="'transparent'"
                        class="position-absolute"
                        style="right: 8px; top: 8px;"
                        @handleBtnClick="close"
                    />
                </div>
            </div>
            <div v-if="false" class="navbar-backdrop d-none d-lg-block"></div>
        </div>
    </transition>
</template>

<script>
import GooglePlayIcon from '~/components/item/GooglePlayIcon'
import AppleStoreIcon from '~/components/item/AppleStoreIcon'
import Close from '@/components/iconSvg/Close'
import CircleCloseBtn from '~/components/item/CircleCloseBtn'
import MobileBannerIPhone from '~/components/iconSvg/MobileBannerIPhone'
import GetAppQRCode from "@/components/item/GetAppQRCode.vue";
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'WebToAppBanner',
    components: {
        ImageContainer,
        GetAppQRCode,
        MobileBannerIPhone,
        CircleCloseBtn,
        AppleStoreIcon,
        GooglePlayIcon,
        Close
    },
    data() {
        return {}
    },
    computed: {
        offer() {
            return this.$store.getters['api/webToAppOffer']
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.35s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
@media (min-width: 992px) and (max-width: 1700px) {
    .banner-iphone {
        height: 350px;
    }
}
@media (max-width: 1400px) {
    .desktop-iphone {
        width: 50%;
    }
    .store-wrapper {
        flex-wrap: wrap;
    }
    .scan-code-wrapper {
        align-items: start;
    }
}
@media (min-width: 1401px) {
    .scan-code-wrapper {
        align-items: center;
    }
}
.navbar-backdrop {
    z-index: 1029;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}
.black-section-vector {
    right: 0;
    position: absolute;
}
</style>
