import _isEmpty from 'lodash/isEmpty'
import { add, endOfDay, format, roundToNearestMinutes, parse } from 'date-fns'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import { enUS, ms, vi, zhHK } from 'date-fns/locale'

function getCurrentYear() {
    const today = new Date()
    return today.getFullYear().toString()
}
function getToday() {
    const today = new Date()
    return getDateByFormat(today)
}
function getTodayByFormat() {
    const today = new Date()
    return (
        today.toLocaleString('en-us', { month: 'short' }) +
        ' ' +
        today.getDate()
    )
}
function getTomorrow() {
    const tmr = new Date()
    tmr.setDate(tmr.getDate() + 1)
    return getDateByFormat(tmr)
}

function getTomorrowByFormat() {
    const tmr = new Date()
    tmr.setDate(tmr.getDate() + 1)
    const tmrDate = tmr.getDate()
    return tmr.toLocaleString('en-us', { month: 'short' }) + ' ' + tmrDate
}

function getChooseDateByFormat(chooseDate, displayYear = false) {
    if (displayYear) {
        const year = chooseDate
            .getFullYear()
            .toString()
            .substr(2, 2)
        return (
            chooseDate.getDate() +
            ' ' +
            chooseDate.toLocaleString('en-us', { month: 'short' }) +
            ' ' +
            year
        )
    } else {
        return (
            chooseDate.toLocaleString('en-us', { month: 'short' }) +
            ' ' +
            chooseDate.getDate()
        )
    }
}
function getDateWithYearByFormat(date, english = true) {
    const newDate = new Date(date)
    let formattedDate = ''
    if (english) {
        const year = newDate.getFullYear().toString()
        formattedDate =
            newDate.getDate() +
            ' ' +
            newDate.toLocaleString('en-us', { month: 'short' }) +
            ' ' +
            year
    } else {
        formattedDate = newDate
            .toLocaleString('zh-CN', {
                year: 'numeric',
                day: 'numeric',
                month: 'long'
            })
            .substr(2)
    }
    return formattedDate
}
// function getChineseDateWithYearByFormat(date, displayFullYear = false) {
//     console.log(
//         date
//             .toLocaleString('zh-CN', {
//                 year: 'numeric',
//                 day: 'numeric',
//                 month: 'long'
//             })
//             .substr(2)
//     )
// }

function getDateByFormat(date, displayDay = true) {
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yyyy = date.getFullYear()
    if (displayDay) {
        return yyyy + '-' + mm + '-' + dd
    } else {
        return yyyy + '-' + mm
    }
}
function getMomentFormat() {
    const date = new Date()
    const DD = String(date.getDate()).padStart(2, '0')
    const MM = String(date.getMonth() + 1).padStart(2, '0')
    const YYYY = date.getFullYear()
    const HH = date.getHours()
    const mm = date.getMinutes()
    const ss = date.getSeconds()
    return YYYY + MM + DD + HH + mm + ss
}

function getDisplayDateByFormat(date, displayDay = true) {
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0')
    const yyyy = date.getFullYear()
    if (displayDay) {
        return yyyy + '/' + mm + '/' + dd
    } else {
        return yyyy + '/' + mm
    }
}

function getFormattedDateList(list) {
    if (!_isEmpty(list)) {
        const disable = []
        // _times(list.disable.length, (i) => {
        //     disable.push(new Date(list.disable[i]))
        // })
        Object.keys(list.disable_date).map((disableDate) => {
            disable.push(new Date(disableDate))
        })

        return {
            to: new Date(list.min_date),
            from: new Date(list.max_date),
            dates: disable
        }
    }
}

function getMonthDateTime(item) {
    let startDate = ''
    let endDate = ''
    if (
        item.start_value.split(' ').length > 0 &&
        item.end_value.split(' ').length > 0
    ) {
        startDate =
            item.start_value.split(' ')[0].split('-')[1] +
            '/' +
            item.start_value.split(' ')[0].split('-')[2]
        endDate =
            item.end_value.split(' ')[0].split('-')[1] +
            '/' +
            item.end_value.split(' ')[0].split('-')[2]
    }

    if (startDate === endDate) {
        return startDate + '   ' + item.start_string + '-' + item.end_string
    } else {
        return (
            startDate +
            ' ' +
            item.start_string +
            ' - ' +
            endDate +
            ' ' +
            item.end_string
        )
    }
}

function getDiffDay(date) {
    const today = new Date()
    const day = new Date(date)
    day.setDate(day.getDate() + 1)
    if (today >= day) {
        return parseInt((today - day) / (1000 * 60 * 60 * 24), 10)
    } else {
        return parseInt((day - today) / (1000 * 60 * 60 * 24), 10)
    }
}

const getTimeslotsIntervals = (selectedDate, intervalMinute) => {
    const todayDate = format(new Date(), 'yyyy-MM-dd')
    const isNotToday = selectedDate.length > 0 && todayDate !== selectedDate
    const startIntervalCeil = roundToNearestMinutes(
        isNotToday ? new Date(selectedDate).setHours(0) : new Date(),
        {
            nearestTo: intervalMinute,
            roundingMethod: 'ceil'
        }
    )
    const startIntervalTrunc = roundToNearestMinutes(
        isNotToday ? new Date(selectedDate).setHours(0) : new Date(),
        {
            nearestTo: intervalMinute,
            roundingMethod: 'trunc'
        }
    )
    const startInterval =
        startIntervalTrunc.valueOf() > new Date().valueOf()
            ? startIntervalTrunc
            : startIntervalCeil

    const intervals = []
    const endInterval = endOfDay(
        isNotToday ? new Date(selectedDate) : new Date()
    )
    let currentInterval = startInterval
    while (currentInterval < endInterval) {
        intervals.push(format(currentInterval, 'HH:mm'))
        currentInterval = add(currentInterval, {
            minutes: intervalMinute
        })
    }
    return intervals
}

export const getTimezoneByRegion = (region) => {
    const map = {
        hk: 'Asia/Hong_Kong',
        mo: 'Asia/Macau',
        sg: 'Asia/Singapore',
        my: 'Asia/Kuala_Lumpur',
        vn: 'Asia/Saigon'
    }
    return region ? map[region] : 'UTC'
}

export const getRegionByTimezone = (timezone) => {
    const map = {
        'Asia/Hong_Kong': 'hk',
        'Asia/Macau': 'mo',
        'Asia/Singapore': 'sg',
        'Asia/Kuala_Lumpur': 'my',
        'Asia/Saigon': 'vn'
    }
    if (map[timezone] === undefined) {
        return 'hk'
    }
    return timezone ? map[timezone] : 'hk'
}

const getOffsetByRegion = (region) => {
    const map = {
        hk: '+08:00',
        mo: '+08:00',
        sg: '+08:00',
        my: '+08:00',
        vn: '+07:00'
    }
    return region ? map[region] : '+00:00'
}

const getCurrentStartInterval = (intervalMinute, region) => {
    const nowDate = utcToZonedTime(new Date(), getTimezoneByRegion(region))

    const startIntervalCeil = roundToNearestMinutes(nowDate, {
        nearestTo: intervalMinute,
        roundingMethod: 'ceil'
    })
    const startIntervalTrunc = roundToNearestMinutes(nowDate, {
        nearestTo: intervalMinute,
        roundingMethod: 'trunc'
    })
    return startIntervalTrunc.valueOf() > nowDate.valueOf()
        ? startIntervalTrunc
        : startIntervalCeil
}

const getTimestampFromDateTime = (selectedDate, selectedTime, region) => {
    const offset = getOffsetByRegion(region)
    const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm xxx'
    if (selectedDate === getToday()) {
        if (selectedTime.length === 0) {
            return getCurrentStartInterval(15, region).valueOf()
        }
        return parse(
            `${selectedDate} ${selectedTime} ${offset}`,
            DATE_TIME_FORMAT,
            new Date()
        ).valueOf()
    }
    if (selectedTime.length === 0) {
        return parse(
            `${selectedDate} 00:00 ${offset}`,
            DATE_TIME_FORMAT,
            new Date()
        ).valueOf()
    }
    return parse(
        `${selectedDate} ${selectedTime} ${offset}`,
        DATE_TIME_FORMAT,
        new Date()
    ).valueOf()
}

const getDefaultTimePickerValue = (date, region) => {
    // if (date === getToday()) {
    //     return ASAP_CHECK_IN_TIME
    // } else if (date === getTomorrow()) {
    //     const isHalfHourToNextDay =
    //         new Date().valueOf() >
    //         getTimestampFromDateTime(getToday(), '23:30', region)
    //     return isHalfHourToNextDay ? '00:00' : '11:00'
    // } else {
    //     return '11:00'
    // }
    if (date === getToday()) {
        return ANY_CHECK_IN_TIME
    } else {
        return ANY_CHECK_IN_TIME
    }
}

const getAsapMinuteInterval = (region) => {
    return format(getCurrentStartInterval(15, region), 'HH:mm')
}

const isDayEnded = (date, region) => {
    return (
        new Date().valueOf() > getTimestampFromDateTime(date, '23:45', region)
    )
}

const ANY_CHECK_IN_TIME = 'any_time'
const ASAP_CHECK_IN_TIME = 'asap_time'

function getCountdownText(timestamp, region) {
    const timezone = getTimezoneByRegion(region)
    return formatInTimeZone(timestamp, timezone, 'mm:ss')
}
const formatSearchBarDisplayDate = (date, lang) => {
    let locale
    let dateFormat
    if (lang === 'zh') {
        locale = zhHK
        dateFormat = 'MMMdo'
    } else if (lang === 'ms') {
        locale = ms
        dateFormat = 'MMM do'
    } else if (lang === 'vi') {
        locale = vi
        dateFormat = 'MMM do'
    } else {
        locale = enUS
        dateFormat = 'MMM do'
    }
    return format(parse(date, 'yyyy-MM-dd', new Date()), dateFormat, {
        locale
    })
}
const displayDate = (date, lang) => {
    let locale
    let dateFormat
    if (lang === 'zh') {
        locale = zhHK
        dateFormat = 'MMMdo'
    } else if (lang === 'ms') {
        locale = ms
        dateFormat = 'MMM do'
    } else if (lang === 'vi') {
        locale = vi
        dateFormat = 'MMM do'
    } else {
        locale = enUS
        dateFormat = 'MMM do'
    }
    return format(parse(date, 'yyyy-MM-dd', new Date()), dateFormat, {
        locale
    })
}
function covertDateFormatHyphenToSlash(date) {
    return date.replaceAll('-', '/')
}

function subtractYears(years, date = new Date()) {
    date.setFullYear(date.getFullYear() - years)
    return date
}

export default {
    getCurrentYear,
    getToday,
    getTodayByFormat,
    getTomorrow,
    getTomorrowByFormat,
    getChooseDateByFormat,
    getDateWithYearByFormat,
    getDateByFormat,
    getMomentFormat,
    getDisplayDateByFormat,
    getFormattedDateList,
    getDiffDay,
    getMonthDateTime,
    getTimeslotsIntervals,
    getCurrentStartInterval,
    getTimestampFromDateTime,
    getDefaultTimePickerValue,
    getAsapMinuteInterval,
    isDayEnded,
    ANY_CHECK_IN_TIME,
    ASAP_CHECK_IN_TIME,
    getCountdownText,
    formatSearchBarDisplayDate,
    displayDate,
    covertDateFormatHyphenToSlash,
    subtractYears
}
