function firstLetterToUpper(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}
function twoDigits(str) {
    return str.toString().slice(0, 3)
}

function lastArrItem(arr) {
    return arr[arr.length -1]
}

function cleanQueryParams(str) {
    const index = str.indexOf('?')
    return index !== -1 ? str.slice(0, index) : str
}

export default {
    firstLetterToUpper,
    twoDigits,
    lastArrItem,
    cleanQueryParams
}
