export const state = () => ({
    locales: ['en', 'zh', 'ms', 'vi'],
    locale: 'en',
    regions: ['hk', 'sg', 'mo', 'my', 'vn'],
    region: 'hk',
    localeRegion: {
        hk: ['en', 'zh'],
        mo: ['en', 'zh'],
        sg: ['en', 'zh'],
        my: ['en', 'zh', 'ms'],
        vn: ['en', 'vi']
    },
    spaces: ['hotel', 'co-working', 'meeting', 'staycation', 'party'],
    spaceTypes: {
        hotel: 'rest',
        'co-working': 'work',
        meeting: 'meet',
        party: 'party'
    },
    isSpaceDetailsVisible: false,
    prevRoute: '',
    isBlockGooglebotFromButton: false
})

export const mutations = {
    setLanguage(state, locale) {
        if (state.locales.includes(locale)) {
            state.locale = locale
        }
    },
    setRegion(state, region) {
        if (state.regions.includes(region)) {
            state.region = region
        }
    },
    setSpaceDetails(state, action) {
        state.isSpaceDetailsVisible = action
    },
    SET_PREV_ROUTE(state, route) {
        state.prevRoute = route
    },
    SET_BLOCK_GOOGLEBOT_FROM_BUTTON(state, route) {
        state.isBlockGooglebotFromButton = route
    }
}
