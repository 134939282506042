export default function(context)  {
    // Every time the route changes (fired on initialization too)
    context.app.router.beforeEach((to, from, next) => {
        /* get ttclid from cookie */
        const ttclid = context.$cookies.get('ttclid') || from.query.ttclid
            // const _ttp = context.$cookies.get('_ttp')
        // console.log('ttclid', ttclid)
        // console.log('_ttp', _ttp)
        context.app.store.commit('SET_PREV_ROUTE', from.fullPath)
        const fromDetailsToCheckout =
            from.name === 'region-locale-spaceName-space' &&
            to.name === 'region-locale-order-checkout'
        const isAddOverride = from.query.override && !to.query.override
        const isAddGroup =
            from.query.group && !to.query.group && to.params.region === 'my'
        const isAddTtclid = ttclid && !to.query.ttclid
        const toQuery = to.query
        if (isAddGroup){
            toQuery.group = from.query.group
        }
        if (isAddOverride){
            toQuery.override = true
        }
        if (isAddTtclid){
            toQuery.ttclid = ttclid
        }
        const isAddGroupOrOverride = (isAddGroup || isAddOverride) && !fromDetailsToCheckout
        if (isAddGroupOrOverride || isAddTtclid){
            next({ name: to.name, params: to.params, query: toQuery, hash: to.hash })
        } else {
            next()
        }
    })
}
