<template>
    <p class="text-20 text-s-bold text-3c3c3c mb-0">
        <slot />
    </p>
</template>
<script>
export default {
    name: 'ItemTitle'
}
</script>
