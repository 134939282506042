<template>
    <div class="position-absolute w-100" style="top: 0; z-index: 1030">
        <div class="main-container">
            <p class="text-bold text-36">{{ $t('oops') }}</p>
            <pre class="text-mid text-18 text-center">
                {{ $t('tiktok_open_external_browser_hint') }}
            </pre>
            <ImageContainer
                alt="arrow up"
                src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/072ad9da-0396-4726-b86c-b392ce24af00/public"
                width="219"
                height="150"
                class="arrow-up"
            />
            <ImageContainer
                alt="mobile app"
                src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/3cff69c2-912c-4fd9-503d-6534a1cbd500/large"
                class="w-100 h-auto"
            />
        </div>
    </div>
</template>
<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'OpenExternalBrowserGuide',
    components: {ImageContainer},
    mounted() {
        this.scrollToTop()
        this.hiddenScrollBar()
    },
    destroyed() {
        this.addScrollBar()
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0
            })
        }
    }
}
</script>
<style scoped>
.main-container {
    background: linear-gradient(
        180deg,
        rgb(254, 220, 1) 0%,
        rgb(255, 192, 0) 100%
    );
    min-height: 100vh;
    padding-top: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.arrow-up {
    position: absolute;
    top: 1vh;
    right: 0;
    width: 100px;
    height: auto;
}
</style>
