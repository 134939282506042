import axios from 'axios'

const BASE_URL = 'https://cms.flowtheroom.com'

const ERROR_POST_NOT_FOUND = 'ERROR_POST_NOT_FOUND'
const ERROR_AUTHOR_NOT_FOUND = 'ERROR_AUTHOR_NOT_FOUND'
const ERROR_TAG_NOT_FOUND = 'ERROR_TAG_NOT_FOUND'

const create = (baseURL = BASE_URL + '/wp-json') => {
    const api = axios.create({
        baseURL,
        headers: {}
    })

    const getPosts = (params) => {
        const POSTS_URL =
            '/wp/v2/posts' +
            '?_fields=id,date,modified,slug,status,link,title,content,excerpt,' +
            '_links.author,' +
            '_links.wp:featuredmedia,' +
            '_links.wp:term,' +
            '_embedded.author,' +
            '_embedded.wp:featuredmedia,' +
            '_embedded.wp:term' +
            '&_envelope' +
            '&_embed' +
            `&page=${params.page}` +
            `&per_page=${params.limit}` +
            params.queryParams

        return api.get(POSTS_URL)
    }

    const getPostDetails = (params) => {
        const POST_DETAILS_URL =
            '/wp/v2/posts' +
            `?slug=${params.postId}` +
            '&_fields=id,date,modified,slug,status,link,title,content,excerpt,' +
            '_links.author,' +
            '_links.wp:featuredmedia,' +
            '_links.wp:term,' +
            '_embedded.author,' +
            '_embedded.wp:featuredmedia,' +
            '_embedded.wp:term' +
            '&_embed'

        return api.get(POST_DETAILS_URL)
    }

    const getCategories = (slugs) => {
        const CATEGORIES_URL = `/wp/v2/categories?slug=${encodeURIComponent(
            slugs
        )}`
        return api.get(CATEGORIES_URL)
    }

    const getTags = (slugs) => {
        const TAGS_URL = `/wp/v2/tags?slug=${encodeURIComponent(slugs)}`
        return api.get(TAGS_URL)
    }

    const getAuthors = (slugs) => {
        const AUTHORS_URL = `/wp/v2/users?slug=${encodeURIComponent(slugs)}`
        return api.get(AUTHORS_URL)
    }

    const getHeadMeta = (url) => {
        const META_URL = 'rankmath/v1/getHead' + `?url=${url}`
        return api.get(META_URL)
    }

    return {
        getPosts,
        getPostDetails,
        getCategories,
        getTags,
        getAuthors,
        getHeadMeta
    }
}

const api = create()

const getPostTagLang = (region, locale) => {
    if ((region === 'sg' || region === 'my') && locale === 'zh') {
        return 'sc'
    } else if (locale === 'zh') {
        return 'tc'
    } else {
        return locale
    }
}

export const getIdBySlug = (slug) => {
    const map = {
        'hash-all-region': '47',
        'hash-hidden': '51',
        'hash-promotion': '55',
        'hash-hk': '49',
        'hash-my': '54',
        'hash-sg': '50',
        'hash-vn': '66',
        'hash-en': '46',
        'hash-ms': '60',
        'hash-sc': '62',
        'hash-tc': '48',
        'hash-vi': '59'
    }
    return map[slug]
}

export default {
    api,
    BASE_URL,
    ERROR_POST_NOT_FOUND,
    ERROR_AUTHOR_NOT_FOUND,
    ERROR_TAG_NOT_FOUND,
    getPostTagLang
}
