import _times from 'lodash/times'
import Api from '@/services/api'
import NextApi from '@/services/nextApi'
import {
    computeSuggestionIndex,
    searchSuggestionIndex
} from '@/lib-flow-main-search/src/search'
import { getSpaceTypeId } from '@/services/algoliaApi'

const api = Api.api
const nextApi = NextApi.api

const state = () => ({
    getDataFail: false,
    error: {},
    homeData: [],
    subLandingData: [],
    magicButtonsData: [],
    regionList: [],
    spaceTypeInfo: [],
    dealsList: [],
    paymentIcons: [],
    defaultSetting: {},
    availableDate: {},
    suggestionsList: {},
    homeSpacesList: {},
    homeArticles: [],
    searchBarSetting: {},
    animatedPlaceholders: [],
    isFetchingPlaceholders: true,
    searchResult: {},
    detailsResult: {},
    spaceDetailsResult: {},
    spaceReviewDetailsResult: {},
    venueDetailsResult: {},
    venueRateDetailsResult: {},
    detailsBookLengthResult: {},
    detailsCheckInResult: [],
    detailsCheckOutResult: [],
    reservationCheckOutDetailsResult: {},
    detailsRoomResult: [],
    detailsRefundTerms: {},
    detailsReservationResult: {},
    retrieveReservationResult: {},
    priceStatement: {},
    priceUpdate: {},
    reservationDetails: {},
    cardListResult: [],
    likedSpaces: {},
    memberDashBroad: {},
    membershipDetails: [],
    memberMessage: {},
    messageContent: {},
    rewardHistory: [],
    myBookings: {},
    flowDollarPackageList: {},
    referCodeDetails: {},
    memberProfile: {},
    memberFlowDollarInfo: {},
    memberCards: [],
    memberVoucher: [],
    paymentMethods: [],
    slugList: [],
    faqContent: [],
    cancellationTerms: {},
    availableEnquiryMethods: {
        list: [],
        phone: ''
    },
    districtList: [],
    locationDetails: {},
    webToAppOffer: {},
    cardBrandList: [],
    CMSPosts: [],
    currentOffers: []
})

const getters = {
    getDataFail: (state) => {
        return state.getDataFail
    },
    getError: (state) => {
        return state.error
    },
    homeData: (state) => {
        return state.homeData
    },
    subLandingData: (state) => {
        return state.subLandingData
    },
    magicButtonsData: (state) => {
        return state.magicButtonsData
    },
    spaceTypeInfo: (state) => {
        return state.spaceTypeInfo
    },
    dealsList: (state) => {
        return state.dealsList
    },
    paymentIcons: (state) => {
        return state.paymentIcons
    },
    slugList: (state) => {
        return state.slugList
    },
    regionList: (state) => {
        return state.regionList
    },
    defaultSetting: (state) => {
        return state.defaultSetting
    },
    availableDateList: (state) => {
        return state.availableDate
    },
    suggestionsList: (state) => {
        return state.suggestionsList
    },
    searchBarSetting: (state) => {
        return state.searchBarSetting
    },
    animatedPlaceholders: (state) => {
        return state.animatedPlaceholders
    },
    searchConfig: (state) => {
        return state.searchBarSetting.search
    },
    isFetchingPlaceholders: (state) => {
        return state.isFetchingPlaceholders
    },
    popularCheckInTime: (state) => {
        return state.searchBarSetting?.popular?.check_in ?? []
    },
    checkInPeriods: (state) => {
        return state.searchBarSetting?.popular?.periods ?? []
    },
    searchingSeo: (state) => {
        return state.searchBarSetting?.seo
    },
    searchBarPriceRange: (state) => {
        return (
            state.searchBarSetting?.filtering?.find(
                (_) => _.key === 'f_priceRange'
            )?.value ?? {
                max: 2500,
                min: 0
            }
        )
    },
    searchBarPlaceholder: (state) => {
        return state.searchBarSetting?.placeholder?.home_search_bar
    },
    homeSpacesList: (state) => {
        return state.homeSpacesList
    },
    homeArticles: (state) => {
        return state.homeArticles
    },
    searchResult: (state) => {
        return state.searchResult
    },
    detailsResult: (state) => {
        return state.detailsResult
    },
    spaceDetailsResult: (state) => {
        return state.spaceDetailsResult
    },
    spaceReviewDetailsResult: (state) => {
        return state.spaceReviewDetailsResult
    },
    venueDetailsResult: (state) => {
        return state.venueDetailsResult
    },
    venueRateDetailsResult: (state) => {
        return state.venueRateDetailsResult
    },
    cardListResult: (state) => {
        return state.cardListResult
    },
    detailsBookLengthResult: (state) => {
        return state.detailsBookLengthResult
    },
    detailsCheckInResult: (state) => {
        return state.detailsCheckInResult
    },
    detailsCheckOutResult: (state) => {
        return state.detailsCheckOutResult
    },
    reservationCheckOutDetailsResult: (state) => {
        return state.reservationCheckOutDetailsResult
    },
    detailsRoomResult: (state) => {
        return state.detailsRoomResult
    },
    detailsRefundTerms: (state) => {
        return state.detailsRefundTerms
    },
    detailsReservationResult: (state) => {
        return state.detailsReservationResult
    },
    retrieveReservationResult: (state) => {
        return state.retrieveReservationResult
    },
    priceStatement: (state) => {
        return state.priceStatement
    },
    priceUpdate: (state) => {
        return state.priceUpdate
    },
    reservationDetails: (state) => {
        return state.reservationDetails
    },
    allLikedSpacesInfo: (state) => {
        return state.likedSpaces
    },
    memberDashBroadInfo: (state) => {
        return state.memberDashBroad
    },
    membershipDetails: (state) => {
        return state.membershipDetails
    },
    memberMessage: (state) => {
        return state.memberMessage
    },
    messageContent: (state) => {
        return state.messageContent
    },
    rewardHistory: (state) => {
        return state.rewardHistory
    },
    myBookings: (state) => {
        return state.myBookings
    },
    referCodeDetails: (state) => {
        return state.referCodeDetails
    },
    memberProfile: (state) => {
        return state.memberProfile
    },
    memberFlowDollarInfo: (state) => {
        return state.memberFlowDollarInfo
    },
    flowDollarPackageList: (state) => {
        return state.flowDollarPackageList
    },
    memberCards: (state) => {
        return state.memberCards
    },
    memberVoucher: (state) => {
        return state.memberVoucher
    },
    paymentMethods: (state) => {
        return state.paymentMethods
    },
    faqContent: (state) => {
        return state.faqContent
    },
    cancellationTerms: (state) => {
        return state.cancellationTerms
    },
    availableEnquiryMethods: (state) => {
        return state.availableEnquiryMethods
    },
    districtList: (state) => {
        return state.districtList
    },
    locationDetails: (state) => {
        return state.locationDetails
    },
    webToAppOffer: (state) => {
        return state.webToAppOffer
    },
    cardBrandList: (state) => {
        return state.cardBrandList
    },
    CMSPosts: (state) => {
        return state.CMSPosts
    },
    currentOffers: (state) => {
        return state.currentOffers
    }
}

const mutations = {
    SET_GET_DATA_FAIL: (state, payload) => {
        state.getDataFail = payload
    },
    SET_ERROR: (state, payload) => {
        state.error = payload
    },
    SET_HOME_DATA: (state, payload) => {
        state.homeData = payload
    },
    SET_SUB_LANDING_DATA: (state, payload) => {
        state.subLandingData = payload
    },
    SET_MAGIC_BUTTONS_DATA: (state, payload) => {
        state.magicButtonsData = payload
    },
    RESET_MAGIC_BUTTONS_DATA: (state, payload) => {
        state.magicButtonsData = []
    },
    SET_SPACE_TYPE_INFO: (state, payload) => {
        state.spaceTypeInfo = payload
    },
    SET_DEALS_LIST: (state, payload) => {
        state.dealsList = payload
    },
    SET_SLUG_LIST: (state, payload) => {
        state.slugList = payload
    },
    SET_REGION_LIST: (state, payload) => {
        state.regionList = payload
    },
    SET_DEFAULT_SETTING: (state, payload) => {
        state.defaultSetting = payload
    },
    SET_AVAILABLE_LIST: (state, payload) => {
        state.availableDate = payload
    },
    SET_SUGGESTIONS_LIST: (state, payload) => {
        state.suggestionsList = payload
    },
    SET_HOME_SPACES_LIST: (state, payload) => {
        state.homeSpacesList = payload
    },
    SET_HOME_ARTICLES: (state, payload) => {
        state.homeArticles = payload
    },
    SET_LIST_SEARCH_RESULT: (state, payload) => {
        state.searchResult = payload
    },
    SET_SEARCH_BAR_SETTING: (state, payload) => {
        state.searchBarSetting = payload
    },
    SET_ANIMATED_PLACEHOLDER: (state, payload) => {
        state.animatedPlaceholders = payload
    },
    RESET_LIST_SEARCH_RESULT: (state, payload) => {
        state.searchResult = {}
    },
    APPEND_LIST_SEARCH_RESULT: (state, payload) => {
        const currentList = state.searchResult
        _times(payload.list.length, (i) => {
            currentList.list.push(payload.list[i])
        })
        currentList.next_page = payload.next_page
        currentList.total = payload.total
        currentList.has_next = payload.has_next

        state.searchResult = currentList
    },
    SET_DETAILS_LIST: (state, payload) => {
        state.detailsResult = payload
    },
    SET_SPACE_DETAILS_LIST: (state, payload) => {
        state.spaceDetailsResult = payload
    },
    SET_SPACE_REVIEW_DETAILS: (state, payload) => {
        state.spaceReviewDetailsResult = payload
    },
    SET_VENUE_DETAILS_RES: (state, payload) => {
        state.venueDetailsResult = payload
    },
    SET_VENUE_RATE_DETAILS_RES: (state, payload) => {
        state.venueRateDetailsResult = payload
    },
    SET_DETAILS_BOOK_LENGTH_LIST: (state, payload) => {
        state.detailsBookLengthResult = payload
    },
    SET_DETAILS_CHECK_IN_LIST: (state, payload) => {
        state.detailsCheckInResult = payload
    },
    SET_DETAILS_CHECK_OUT_LIST: (state, payload) => {
        state.detailsCheckOutResult = payload
    },
    SET_RESERVATION_CHECK_OUT_DETAILS_RES: (state, payload) => {
        state.reservationCheckOutDetailsResult = payload
    },
    SET_DETAILS_ROOM_LIST: (state, payload) => {
        state.detailsRoomResult = payload
    },
    SET_REFUND_TERMS: (state, payload) => {
        state.detailsRefundTerms = payload
    },
    RESET_DETAILS_BOOK_LENGTH_RES: (state) => {
        state.detailsBookLengthResult = {}
    },
    RESET_DETAILS_CHECKIN_RES: (state) => {
        state.detailsCheckInResult = []
    },
    RESET_DETAILS_CHECKOUT_RES: (state) => {
        state.detailsCheckOutResult = []
    },
    RESET_DETAILS_ROOM_RES: (state) => {
        state.detailsRoomResult = []
    },
    RESET_DETAILS_INPUT: (state) => {
        state.detailsResult = {}
        state.spaceDetailsResult = {}
        state.detailsBookLengthResult = {}
        state.detailsCheckInResult = []
        state.detailsCheckOutResult = []
        state.detailsRoomResult = []
    },
    RESET_AVAILABLE_VOUCHER: (state) => {
        state.memberVoucher = []
    },
    SET_RESERVATION_RESULT: (state, payload) => {
        state.detailsReservationResult = payload
    },
    SET_RETRIEVE_RESERVATION_RESULT: (state, payload) => {
        state.retrieveReservationResult = payload
    },
    SET_RESERVATION_DETAILS: (state, payload) => {
        state.reservationDetails = payload
    },
    SET_PRICE_STATEMENT: (state, payload) => {
        state.priceStatement = payload
    },
    SET_PRICE_UPDATE: (state, payload) => {
        state.priceUpdate = payload
    },
    RESET_PRICE_STATEMENT: (state) => {
        state.priceStatement = {}
    },
    RESET_PRICE_UPDATE: (state) => {
        state.priceUpdate = {}
    },
    RESET_RESERVATION_RESULT: (state) => {
        state.detailsReservationResult = {}
    },
    SET_CARD_LIST_RESULT: (state, payload) => {
        state.cardListResult = payload
    },
    SET_LIKED_SPACES_INFO: (state, payload) => {
        state.likedSpaces = payload
    },
    SET_MEMBER_DASH_BROAD_INFO: (state, payload) => {
        state.memberDashBroad = payload
    },
    SET_MEMBERSHIP_DETAILS: (state, payload) => {
        state.membershipDetails = payload
    },
    SET_MEMBER_MESSAGE: (state, payload) => {
        state.memberMessage = payload
    },
    SET_MESSAGE_CONTENT: (state, payload) => {
        state.messageContent = payload
    },
    SET_MEMBER_VOUCHER: (state, payload) => {
        state.memberVoucher = payload
    },
    SET_REWARD_HISTORY: (state, payload) => {
        state.rewardHistory = payload
    },
    SET_MY_BOOKINGS: (state, payload) => {
        state.myBookings = payload
    },
    SET_REFER_CODE_DETAILS: (state, payload) => {
        state.referCodeDetails = payload
    },
    SET_MEMBER_PROFILE: (state, payload) => {
        state.memberProfile = payload
    },
    SET_FLOW_DOLLAR_SCREEN: (state, payload) => {
        state.memberFlowDollarInfo = payload
    },
    SET_FLOW_DOLLAR_PACKAGE_LIST: (state, payload) => {
        state.flowDollarPackageList = payload
    },
    SET_MEMBER_CARDS: (state, payload) => {
        state.memberCards = payload
    },
    SET_AVAILABLE_VOUCHER: (state, payload) => {
        state.memberVoucher = payload
    },
    SET_BOOK_PRICE: (state, payload) => {
        if (state.detailsReservationResult) {
            state.detailsReservationResult.booked_price = payload
        }
    },
    SET_PAYMENT_METHOD_LIST: (state, payload) => {
        state.paymentMethods = payload
    },
    SET_FAQ_CONTENT: (state, payload) => {
        state.faqContent = payload
    },
    SET_CANCELLATION_TERMS: (state, payload) => {
        state.cancellationTerms = payload
    },
    SET_AVAILABLE_ENQUIRY_METHODS: (state, payload) => {
        state.availableEnquiryMethods = payload
    },
    SET_DISTRICT_LIST: (state, payload) => {
        state.districtList = payload
    },
    SET_LOCATION_DETAILS: (state, payload) => {
        state.locationDetails = payload
    },
    SET_IS_FETCHING_PLACEHOLDERS: (state, payload) => {
        state.isFetchingPlaceholders = payload
    },
    SET_PAYMENT_ICONS: (state, payload) => {
        state.paymentIcons = payload
    },
    SET_WEB_TO_APP_OFFER: (state, payload) => {
        state.webToAppOffer = payload
    },
    SET_CARD_BRAND_LIST: (state, payload) => {
        state.cardBrandList = payload
    },
    SET_CMS_POSTS: (state, payload) => {
        state.CMSPosts = payload
    },
    SET_CURRENT_OFFERS: (state, payload) => {
        state.currentOffers = payload
    },
}

const actions = {
    GET_HOME_RES: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getHomeInfo(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_HOME_DATA', res.data.Data)
                        // context.commit(
                        //     'SET_AVAILABLE_LIST',
                        //     res.data.Data.available
                        // )
                        // context.commit(
                        //     'SET_SUGGESTIONS_LIST',
                        //     res.data.Data.suggestions
                        // )
                        // context.commit(
                        //     'SET_HOME_SPACES_LIST',
                        //     res.data.Data.recommend
                        // )
                        // context.commit(
                        //     'SET_HOME_ARTICLES',
                        //     res.data.Data.articles
                        // )
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_SUB_LANDING_RES: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getSubLanding(payload.urlType, payload.obj)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_SUB_LANDING_DATA', res.data.Data)
                        resolve(res.data.Data)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_MAGIC_BUTTON_RES: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getMagicButtons(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_MAGIC_BUTTONS_DATA', res.data.Data)
                        resolve(res.data.Data)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    RESET_MAGIC_BUTTONS_DATA: (context) => {
        context.commit('RESET_MAGIC_BUTTONS_DATA')
    },
    GET_SPACE_TYPE_INFO: (context, payload) => {
        return new Promise((resolve, reject) => {
            nextApi
                .getSpaceTypeInfo(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_SPACE_TYPE_INFO', res.data.Data)
                        resolve(res.data.Data)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_DEALS_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            nextApi
                .getDealsList(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_DEALS_LIST', res.data.Data.deals)
                        resolve(res.data.Data.deals)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_SLUG_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getSlugList(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_SLUG_LIST', res.data.Data)
                        resolve(res.data.Data)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_REGION_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            nextApi
                .getRegionList(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_REGION_LIST', res.data.Data)
                        resolve(res.data.Data)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', {
                        statusCode: error.response.status,
                        message: error.response.data
                    })
                })
        })
    },
    /* TODO: Confirm NOT in use
    GET_DEFAULT_SETTING: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getDefaultSetting(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_DEFAULT_SETTING', res.data.Data)
                        resolve(res.data.Data)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    */
    GET_SEARCH_RES: (context, params) => {
        return new Promise((resolve, reject) => {
            api.getListSearchRes(params)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        if (params.page > 1) {
                            context.commit(
                                'APPEND_LIST_SEARCH_RESULT',
                                res.data.Data
                            )
                        } else {
                            context.commit(
                                'SET_LIST_SEARCH_RESULT',
                                res.data.Data
                            )
                        }
                        resolve(res.data.Data.has_next)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_SEARCH_BAR_SETTING: async (context, params) => {
        context.commit('SET_IS_FETCHING_PLACEHOLDERS', true)
        const searchBarParams = {
            platform: 'web',
            region: params.region,
            lang: params.lang,
            device: params.device
        }
        if (params.group) {
            searchBarParams.group = params.group
        }
        try {
            const res = await nextApi.getSearchBarSetting(
                params.space_type,
                searchBarParams
            )
            if (res.data.Success) {
                context.commit('SET_GET_DATA_FAIL', false)
                const filterOrder = [
                    'f_book_type',
                    'f_theme',
                    'f_priceRange',
                    'f_star_rating'
                ]
                const settings = res.data.Data
                settings.filtering = settings.filtering
                    .slice()
                    .sort(
                        (a, b) =>
                            filterOrder.indexOf(a.key) -
                            filterOrder.indexOf(b.key)
                    )
                let suggestionResults
                if (
                    !params.isStaticGenerate &&
                    settings.placeholder.animated?.length > 0
                ) {
                    suggestionResults = await searchSuggestionIndex({
                        region: params.region,
                        algoliaIndex: computeSuggestionIndex(
                            params.region,
                            getSpaceTypeId(params.space_type),
                            process.env.TYPESENSE_ENV
                        ),
                        suggestionIDs: settings.placeholder.animated
                    })
                }
                context.commit('SET_SEARCH_BAR_SETTING', settings)
                if (!params.isStaticGenerate && suggestionResults?.found > 0) {
                    context.commit(
                        'SET_ANIMATED_PLACEHOLDER',
                        suggestionResults.hits
                    )
                } else {
                    context.commit(
                        'SET_ANIMATED_PLACEHOLDER',
                        []
                    )
                }
                context.commit('SET_IS_FETCHING_PLACEHOLDERS', false)
            } else {
                context.commit('SET_GET_DATA_FAIL', true)
                context.commit('SET_IS_FETCHING_PLACEHOLDERS', false)
                context.commit('SET_ERROR', {
                    statusCode: 404,
                    message: 'Get search bar setting failed'
                })
            }
        } catch (error) {
            context.commit('SET_GET_DATA_FAIL', true)
            context.commit('SET_IS_FETCHING_PLACEHOLDERS', false)
            context.commit('SET_ERROR', error)
        }
    },
    RESET_SEARCH_RES: (context) => {
        context.commit('RESET_LIST_SEARCH_RESULT')
    },
    // GET_DETAILS_RES: (context, data, dispatch) => {
    //     return new Promise((resolve, reject) => {
    //         api.getDetailsRes(data.params, data.spaceType)
    //             .then((res) => {
    //                 if (res.data.Success) {
    //                     context.commit('SET_DETAILS_LIST', res.data.Data)
    //                     resolve(res.data.Data)
    //                 } else {
    //                     // console.log('getDetailsRes else')
    //                     // console.log(res)
    //                     context.commit('SET_GET_DATA_FAIL', true)
    //                     context.commit('SET_ERROR', {
    //                         statusCode: 404,
    //                         message: 'Get details response failed'
    //                     })
    //                     resolve(res)
    //                 }
    //             })
    //             .catch((error) => {
    //                 context.commit('SET_GET_DATA_FAIL', true)
    //                 // context.commit('SET_ERROR', error)
    //                 context.commit('SET_ERROR', {
    //                     statusCode: 404,
    //                     message: 'Get details response failed'
    //                 })
    //                 reject(error)
    //             })
    //     })
    // },
    GET_SPACE_DETAILS_RES: (context, data, dispatch) => {
        return new Promise((resolve, reject) => {
            api.getSpaceDetailsRes(
                data.params,
                data.spaceType,
                data.params.slug
            )
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_SPACE_DETAILS_LIST', res.data.Data)
                    } else {
                        // context.commit('SET_GET_DATA_FAIL', true)
                        // context.commit('SET_ERROR', {
                        //     statusCode: 404,
                        //     message: 'Get space details response failed'
                        // })
                    }
                    resolve(res)
                })
                .catch((error) => {
                    context.commit('SET_GET_DATA_FAIL', true)
                    // context.commit('SET_ERROR', error)
                    context.commit('SET_ERROR', {
                        statusCode: 404,
                        message: 'Get space details response failed'
                    })
                    reject(error)
                })
        })
    },
    GET_SPACE_REVIEW_DETAILS_RES: (context, data, dispatch) => {
        return new Promise((resolve, reject) => {
            api.getSpaceReviewDetails(
                data.params,
                data.spaceType,
                data.params.slug
            )
                .then((res) => {
                    if (res.data.Success) {
                        context.commit(
                            'SET_SPACE_REVIEW_DETAILS',
                            res.data.Data
                        )
                        resolve(res.data.Data)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'Get space review details response failed'
                        })
                        resolve(res)
                    }
                })
                .catch((error) => {
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', {
                        statusCode: 404,
                        message: 'Get space review details response failed'
                    })
                    reject(error)
                })
        })
    },
    GET_VENUE_DETAILS_RES: (context, data, dispatch) => {
        return new Promise((resolve, reject) => {
            api.getVenueDetailsRes(
                data.params,
                data.spaceType,
                data.params.slug,
                data.venueType
            )
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_VENUE_DETAILS_RES', res.data.Data)
                        resolve(res.data.Data)
                    } else {
                        // console.log('getDetailsRes else')
                        // console.log(res)
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'Get venue details response failed'
                        })
                        resolve(res)
                    }
                })
                .catch((error) => {
                    context.commit('SET_GET_DATA_FAIL', true)
                    // context.commit('SET_ERROR', error)
                    context.commit('SET_ERROR', {
                        statusCode: 404,
                        message: 'Get venue details response failed'
                    })
                    reject(error)
                })
        })
    },
    GET_VENUE_RATE_DETAILS_RES: (context, data, dispatch) => {
        return new Promise((resolve, reject) => {
            api.getVenueRateDetailsRes(
                data.params,
                data.spaceType,
                data.params.slug,
                data.venueType
            )
                .then((res) => {
                    if (res.data.Success) {
                        context.commit(
                            'SET_VENUE_RATE_DETAILS_RES',
                            res.data.Data
                        )
                        resolve(res.data.Data)
                    } else {
                        // console.log('getDetailsRes else')
                        // console.log(res)
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'Get venue rate details response failed'
                        })
                        resolve(res)
                    }
                })
                .catch((error) => {
                    context.commit('SET_GET_DATA_FAIL', true)
                    // context.commit('SET_ERROR', error)
                    context.commit('SET_ERROR', {
                        statusCode: 404,
                        message: 'Get venue rate details response failed'
                    })
                    reject(error)
                })
        })
    },
    GET_DETAILS_BOOK_LENGTH_RES: (context, data) => {
        return new Promise((resolve, reject) => {
            api.getDetailsBookLengthRes(data.params, data.spaceType, data.slug)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit(
                            'SET_DETAILS_BOOK_LENGTH_LIST',
                            res.data.Data
                        )
                        resolve(res.data.Data)
                    } else if (res.data.Data.error_id === 25) {
                        context.commit(
                            'SET_DETAILS_BOOK_LENGTH_LIST',
                            res.data.Data
                        )
                        resolve(res.data.Data)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'GET DETAILS BOOK LENGTH FROM API FAILED'
                        })
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    GET_DETAILS_CHECK_IN_RES: (context, data) => {
        return new Promise((resolve, reject) => {
            api.getDetailsCheckInRes(data.params, data.spaceType, data.slug)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit(
                            'SET_DETAILS_CHECK_IN_LIST',
                            res.data.Data.list
                        )
                        resolve(res.data.Data.list)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'GET DETAILS CHECK IN FROM API FAILED'
                        })
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    GET_DETAILS_CHECK_OUT_RES: (context, data) => {
        return new Promise((resolve, reject) => {
            api.getDetailsCheckOutRes(data.params, data.spaceType, data.slug)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit(
                            'SET_DETAILS_CHECK_OUT_LIST',
                            res.data.Data.list
                        )
                        resolve(res.data.Data.list)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'GET DETAILS CHECK OUT FROM API FAILED'
                        })
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    GET_RESERVATION_CHECK_OUT_DETAILS_RES: (context, data) => {
        return new Promise((resolve, reject) => {
            api.getReservationCheckOutDetailsRes(data.params, data.spaceType)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit(
                            'SET_RESERVATION_CHECK_OUT_DETAILS_RES',
                            res.data.Data
                        )
                        resolve(res.data.Data)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message:
                                'GET RESERVATION CHECK OUT DETAILS FROM API FAILED'
                        })
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    GET_DETAILS_ROOM_RES: (context, data) => {
        return new Promise((resolve, reject) => {
            api.getDetailsRoomRes(data.params, data.spaceType, data.slug)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_DETAILS_ROOM_LIST', res.data.Data)
                        resolve(res.data.Data)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'GET DETAILS ROOM RESPONSE FAILED'
                        })
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_REFUND_TERMS: (context, data) => {
        return new Promise((resolve, reject) => {
            api.getRefundTerms(data.params)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_REFUND_TERMS', res.data.Data)
                        resolve(res.data.Data)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'GET REFUND TERMS FAILED'
                        })
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    RESET_DETAILS_BOOK_LENGTH_RESULT: (context) => {
        context.commit('RESET_DETAILS_BOOK_LENGTH_RES')
    },
    RESET_DETAILS_CHECKIN_RESULT: (context) => {
        context.commit('RESET_DETAILS_CHECKIN_RES')
    },
    RESET_DETAILS_CHECKOUT_RESULT: (context) => {
        context.commit('RESET_DETAILS_CHECKOUT_RES')
    },
    RESET_DETAILS_ROOM_RESULT: (context) => {
        context.commit('RESET_DETAILS_ROOM_RES')
    },
    RESET_DETAILS_INPUT_RESULT: (context) => {
        context.commit('RESET_DETAILS_INPUT')
    },
    MAKE_RESERVATION: (context, data) => {
        return new Promise((resolve, reject) => {
            api.makeReservation(data.params, data.space)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_RESERVATION_RESULT', res.data.Data)
                        resolve(res)
                    } else if (!res.data.Success && res.data.Data.message) {
                        resolve(res)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'MAKE RESERVATION FAILED'
                        })
                        resolve()
                        // reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    RETRIEVE_RESERVATION: (context, data) => {
        return new Promise((resolve, reject) => {
            api.verifyAndSearchReservation(data.body, data.lang, data.region)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit(
                            'SET_RETRIEVE_RESERVATION_RESULT',
                            res.data.Data
                        )
                        resolve(res)
                    } else if (!res.data.Success && res.data.Data.message) {
                        resolve(res)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'RETRIEVE RESERVATION FAILED'
                        })
                        resolve()
                        // reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_RESERVATION_DETAILS: (context, data) => {
        return new Promise((resolve, reject) => {
            api.getReservationDetails(data.params, data.bookingId)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_RESERVATION_DETAILS', res.data.Data)
                        resolve(res)
                    } else if (!res.data.Success && res.data.Data.message) {
                        resolve(res)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'GET RESERVATION DETAILS FAILED'
                        })
                        resolve()
                        // reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_PRICE_STATEMENT: (context, data) => {
        return new Promise((resolve, reject) => {
            api.getPriceStatement(data.params, data.spaceType)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_PRICE_STATEMENT', res.data.Data)
                        resolve(res)
                    } else if (!res.data.Success && res.data.Data.message) {
                        context.commit('RESET_PRICE_STATEMENT')
                        resolve(res)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'GET PRICE STATEMENT FAILED'
                        })
                        resolve()
                        // reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_PRICE_UPDATE: (context, data) => {
        return new Promise((resolve, reject) => {
            api.getPriceUpdate(data.params, data.spaceType)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_PRICE_UPDATE', res.data.Data)
                        resolve(res)
                    } else if (!res.data.Success && res.data.Data.message) {
                        context.commit('RESET_PRICE_UPDATE')
                        resolve(res)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'GET PRICE STATEMENT FAILED'
                        })
                        resolve()
                        // reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_CARD_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getCardList(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit(
                            'SET_CARD_LIST_RESULT',
                            res.data.Data.list
                        )
                        resolve(res)
                    } else if (!res.data.Success && res.data.Data.message) {
                        resolve(res)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'GET CARD LIST FAILED'
                        })
                        resolve()
                        // reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    RESET_RESERVATION_RESULT: (context) => {
        context.commit('RESET_RESERVATION_RESULT')
    },
    // CANCEL_RESERVATION: (context, data) => {
    //     return new Promise((resolve, reject) => {
    //         api.cancelReservation(data.space, data.body)
    //             .then((res) => {
    //                 if (res.data.Success) {
    //                     resolve(res.data.Success)
    //                 }
    //             })
    //             .catch((error) => {
    //                 reject(error)
    //             })
    //     })
    // },
    GET_PAYMENT_METHOD_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getPaymentMethodList(payload.spaceType, payload.params)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit(
                            'SET_PAYMENT_METHOD_LIST',
                            res.data.Data.list
                        )
                        resolve(res)
                    } else if (!res.data.Success && res.data.Data.message) {
                        resolve(res)
                    } else {
                        context.commit('SET_GET_DATA_FAIL', true)
                        context.commit('SET_ERROR', {
                            statusCode: 404,
                            message: 'GET PAYMENT METHOD LIST'
                        })
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    // MAKE_PAYMENT: (context, data) => {
    //     return new Promise((resolve, reject) => {
    //         api.makePayment(data.space, data.body, data.lang)
    //             .then((res) => {
    //                 if (res.data.Success) {
    //                     resolve(res.data.Success)
    //                 } else {
    //                     reject(res.data.Data.message)
    //                 }
    //             })
    //             .catch((error) => {
    //                 reject(error.message)
    //             })
    //     })
    // },
    // GET_ALL_LIKED_SPACES_INFO: (context, payload) => {
    //     api.getAllLikedSpacesInfo(payload)
    //         .then((res) => {
    //             if (res.data.Success) {
    //                 context.commit('SET_GET_DATA_FAIL', false)
    //                 context.commit('SET_LIKED_SPACES_INFO', res.data.Data)
    //             }
    //         })
    //         .catch((error) => {
    //             context.commit('SET_GET_DATA_FAIL', true)
    //             context.commit('SET_ERROR', error)
    //         })
    // },
    GET_MEMBER_DASH_BROAD_INFO: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getMemberDashBroadInfo(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit(
                            'SET_MEMBER_DASH_BROAD_INFO',
                            res.data.Data
                        )
                        resolve(res)
                    } else {
                        reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_MEMBERSHIP_DETAILS: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getMembershipDetails(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_MEMBERSHIP_DETAILS', res.data.Data)
                        resolve(res)
                    } else {
                        reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_MEMBER_MESSAGE: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getMemberMessage(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_MEMBER_MESSAGE', res.data.Data)
                        resolve()
                    } else {
                        reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_MESSAGE_CONTENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getMessageContent(payload, payload.message_recipient_id)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_MESSAGE_CONTENT', res.data.Data)
                        resolve()
                    } else {
                        reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_MEMBER_VOUCHER: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getMemberVoucher(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_MEMBER_VOUCHER', res.data.Data)
                        resolve()
                    } else {
                        reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_REWARD_HISTORY: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getRewardHistory(payload, payload.spaceType)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_REWARD_HISTORY', res.data.Data)
                        resolve(res)
                    } else {
                        reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_MY_BOOKINGS: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getMyBookings(payload, payload.type)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_MY_BOOKINGS', res.data.Data)
                        resolve(res)
                    } else {
                        reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_REFER_CODE_DETAILS: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getReferCodeDetails(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_REFER_CODE_DETAILS', res.data.Data)
                        resolve(res)
                    } else {
                        reject(Error.prototype.message, 'Reservation failed')
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_MEMBER_PROFILE: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getMemberProfile(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_MEMBER_PROFILE', res.data.Data)
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_FLOW_DOLLAR_INFO: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getFlowDollarInfo(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_FLOW_DOLLAR_SCREEN', res.data.Data)
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_FLOW_DOLLAR_PACKAGE_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getFlowDollarPackageList(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit(
                            'SET_FLOW_DOLLAR_PACKAGE_LIST',
                            res.data.Data
                        )
                        resolve()
                    }
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_MEMBER_CARDS: (context, payload) => {
        api.getMemberCards(payload)
            .then((res) => {
                if (res.data.Success) {
                    context.commit('SET_GET_DATA_FAIL', false)
                    context.commit('SET_MEMBER_CARDS', res.data.Data.list)
                }
            })
            .catch((error) => {
                context.commit('SET_GET_DATA_FAIL', true)
                context.commit('SET_ERROR', error)
            })
    },
    GET_AVAILABLE_VOUCHER: (context, payload) => {
        api.getAvailableVoucher(payload.spaceType, payload.obj)
            .then((res) => {
                if (res.data.Success) {
                    context.commit('SET_GET_DATA_FAIL', false)
                    context.commit('SET_AVAILABLE_VOUCHER', res.data.Data)
                }
            })
            .catch((error) => {
                context.commit('SET_GET_DATA_FAIL', true)
                context.commit('SET_ERROR', error)
            })
    },
    RESET_AVAILABLE_VOUCHER: (context) => {
        context.commit('RESET_AVAILABLE_VOUCHER')
    },
    SET_BOOK_PRICE: (context, payload) => {
        context.commit('SET_BOOK_PRICE', payload)
    },
    GET_AFFILIATE_SITE_INFO: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getAffiliateSiteInfo(
                payload.domain,
                payload.lang,
                payload.region
            )
                .then((res) => {
                    if (res.data.Success) {
                        const icon = res.data.Data.icon
                        resolve(icon.is_public === 1 ? icon.url : undefined)
                    } else {
                        resolve(undefined)
                    }
                })
                .catch(() => {
                    resolve(undefined)
                })
        })
    },
    GET_FAQ_CONTENT: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getFaqContent(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_FAQ_CONTENT', res.data.Data)
                        resolve(true)
                    } else {
                        resolve(false)
                    }
                })
                .catch(() => {
                    resolve(false)
                })
        })
    },
    CHECK_BOOKING_CANCELABLE: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.checkBookingCancelable(payload)
                .then((res) => {
                    resolve(res.data)
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    SEND_ENQUIRY: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.sendEnquiry(payload.body, payload.lang, payload.region)
                .then((res) => {
                    resolve(res.data.Success)
                })
                .catch((error) => {
                    reject(error.message)
                })
        })
    },
    GET_CANCELLATION_TERMS: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getCancellationTerms(payload.bookingId, payload.params)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_CANCELLATION_TERMS', res.data.Data)
                    }
                    resolve(res)
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    SEND_PRICE_GUARANTEE_ENQUIRY: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.sendPriceGuaranteeEnquiry(
                payload.params,
                payload.lang,
                payload.region
            )
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(Error.prototype.message, error.message)
                })
        })
    },
    GET_MEMBER_PROFILE_PREFILL: (context, payload) => {
        return new Promise((resolve) => {
            api.getMemberProfile(payload)
                .then((res) => {
                    if (res.data.Success) {
                        resolve(res.data.Data)
                    } else {
                        resolve(undefined)
                    }
                })
                .catch(() => {
                    resolve(undefined)
                })
        })
    },
    GET_AVAILABLE_ENQUIRY_METHODS: (context, payload) => {
        return new Promise((resolve) => {
            api.getAvailableEnquiryMethods(
                payload.idToken,
                payload.bookingId,
                payload.lang,
                payload.region
            )
                .then((res) => {
                    if (res.data.Success) {
                        context.commit(
                            'SET_AVAILABLE_ENQUIRY_METHODS',
                            res.data.Data
                        )
                        resolve(true)
                    } else {
                        resolve(undefined)
                    }
                })
                .catch(() => {
                    resolve(undefined)
                })
        })
    },
    GET_LOCATION_PAGE_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getLocationPageList(payload.spaceType, payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_DISTRICT_LIST', res.data.Data)
                        resolve(res.data.Data)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_LOCATION_DETAILS: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getLocationDetails(payload.spaceType, payload.slug, payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_LOCATION_DETAILS', res.data.Data)
                        resolve(res.data.Data)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_PAYMENT_ICONS: (context, payload) => {
        return new Promise((resolve, reject) => {
            nextApi
                .getPaymentIcons(payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_PAYMENT_ICONS', res.data.Data.list)
                        resolve(res.data.Data.list)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_WEB_TO_APP_OFFER: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getWebToAppOffer(payload.idToken, payload.lang, payload.region)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_WEB_TO_APP_OFFER', res.data.Data)
                        resolve(res.data.Data)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_CARD_BRAND_LIST: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getCardBrandList(payload.lang, payload.region, payload.action)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit(
                            'SET_CARD_BRAND_LIST',
                            res.data.Data.list
                        )
                    } else {
                        context.commit('SET_CARD_BRAND_LIST', [])
                    }
                    resolve(true)
                })
                .catch(() => {
                    context.commit('SET_CARD_BRAND_LIST', [])
                    resolve(false)
                })
        })
    },
    GET_CMS_POSTS: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getCMSPosts(payload.type, payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_CMS_POSTS', res.data.Data.posts)
                        resolve(res.data.Data)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    },
    GET_CURRENT_OFFERS: (context, payload) => {
        return new Promise((resolve, reject) => {
            api.getCMSPosts(payload.type, payload)
                .then((res) => {
                    if (res.data.Success) {
                        context.commit('SET_GET_DATA_FAIL', false)
                        context.commit('SET_CURRENT_OFFERS', res.data.Data?.posts)
                        resolve(res.data.Data)
                    }
                })
                .catch((error) => {
                    reject(error)
                    context.commit('SET_GET_DATA_FAIL', true)
                    context.commit('SET_ERROR', error)
                })
        })
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
