import _chunk from 'lodash/chunk'
import _merge from 'lodash/merge'
import _keyBy from 'lodash/keyBy'
import _values from 'lodash/values'
import {
    searchIndex,
    mapToWorkerIdentifier,
    fetchAdditionalInfo,
    compareSpaceByAvailability,
    compareSpaceByNextAvailable,
    computeTimeByFetchState
} from '@/lib-flow-main-search/src/search'
import {
    ALGOLIA_EVENT_NAME,
    sendObjectViewedEvent
} from '@/services/algoliaApi'
import {
    FETCH_STATE,
    SEARCH,
    DATE_TIME,
    EXACT_SEARCH_TYPE,
    FALLBACK_SEARCH_TYPE,
    VERSIONS
} from '@/lib-flow-main-search/src/constants'
import firebaseService from '@/services/firebase'
import NextApi from '@/services/nextApi'

const nextApi = NextApi.api
const state = () => ({
    fetchedIDs: [],
    queryState: FETCH_STATE.SPACE,
    isCurrentIndexFetched: false,
    stateHistory: [],
    spaceResult: {
        totalHits: 0,
        hasNext: false,
        nextPage: 0,
        totalPage: 1,
        reservedWorkerResults: [],
        exactHit: [],
        nearbyHits: [],
        areaHits: [],
        districtHits: [],
        availableHits: [],
        newAlternativeHits: [],
        unavailableHits: [],
        displayTotalHits: 0
    },
    popularSpaceResult: {
        totalHits: 0,
        popularHits: [],
        threeStarHits: [],
        fourStarHits: [],
        fiveStarHits: [],
        swimmingPoolHits: [],
        fitnessCenterHits: [],
        hourlyHotelHits: []
    },
    airportHotelsResult: {
        hits: []
    },
    dealsListResult: {
        totalDeals: 0,
        hits: []
    },
    blogSpaceListResult: {
        hits: []
    }
})

const getters = {
    spaceResult: (state) => {
        return state.spaceResult
    },
    spaceResultHits: (state) => {
        return state.spaceResult.exactHit
            .concat(state.spaceResult.nearbyHits)
            .concat(state.spaceResult.areaHits)
            .concat(state.spaceResult.districtHits)
            .concat(state.spaceResult.availableHits)
            .concat(state.spaceResult.newAlternativeHits)
            .concat(state.spaceResult.unavailableHits)
    },
    popularSpaceResultHits: (state) => {
        return state.popularSpaceResult.popularHits
    },
    availablePopularSpaceIn28DaysHits: (state) => {
        const hits = state.popularSpaceResult.popularHits
        const availableHits = []
        hits.map((hit) => {
            let isAvailableIn28Days = false
            const perBookTypes = hit?.status?.min_prices?.perBookType
            if (perBookTypes) {
                const perBookType = Object.values(perBookTypes)
                perBookType.map((item) => {
                    const price = item.day28?.perHour
                    if (price !== undefined && price !== '') {
                        isAvailableIn28Days = true
                        return ''
                    }
                })
            }
            if (isAvailableIn28Days) availableHits.push(hit)
        })
        return availableHits
    },
    threeStarSpaceResultHits: (state) => {
        return state.popularSpaceResult.threeStarHits
    },
    fourStarSpaceResultHits: (state) => {
        return state.popularSpaceResult.fourStarHits
    },
    fiveStarSpaceResultHits: (state) => {
        return state.popularSpaceResult.fiveStarHits
    },
    swimmingPoolSpaceResultHits: (state) => {
        return state.popularSpaceResult.swimmingPoolHits
    },
    fitnessCenterSpaceResultHits: (state) => {
        return state.popularSpaceResult.fitnessCenterHits
    },
    hourlyHotelSpaceResultHits: (state) => {
        return state.popularSpaceResult.hourlyHotelHits
    },
    airportHotelsResultHits: (state) => {
        return state.airportHotelsResult.hits
    },
    dealsListResultHits: (state) => {
        return state.dealsListResult.hits
    },
    exactHit: (state) => {
        return state.spaceResult.exactHit
    },
    nearbyHits: (state) => {
        return state.spaceResult.nearbyHits
    },
    areaHits: (state) => {
        return state.spaceResult.areaHits
    },
    districtHits: (state) => {
        return state.spaceResult.districtHits
    },
    availableHits: (state) => {
        return state.spaceResult.availableHits
    },
    alternativeHits: (state) => {
        return state.spaceResult.newAlternativeHits
    },
    unavailableHits: (state) => {
        return state.spaceResult.unavailableHits
    },
    /** Determine whether Available Hits should be the first section */
    isExactResults: (state) => {
        return (
            state.spaceResult.exactHit.length > 0 ||
            state.spaceResult.nearbyHits.length > 0 ||
            state.spaceResult.areaHits.length > 0 ||
            state.spaceResult.districtHits.length > 0
        )
    },
    isFallbackResults: (state) => {
        return (
            state.stateHistory.includes(FETCH_STATE.FALLBACK_ANYTIME) ||
            state.stateHistory.includes(FETCH_STATE.FALLBACK_ASAP_TIME) ||
            state.stateHistory.includes(FETCH_STATE.FALLBACK_EXACT_TIME)
        )
    },
    stateHistory: (state) => {
        return state.stateHistory
    },
    displayTotalHits: (state) => {
        return state.spaceResult.displayTotalHits
    },
    blogSpaceList: (state) => {
        return state.blogSpaceListResult.hits
    }
}

const mutations = {
    SET_SEARCH_RESULT: (state, payload) => {
        const searchResults = payload.results.searchResults
        const pageResults = payload.results.pageResults
        if (payload.isPriceQueryResult) {
            const workerResults = payload.results.workerResults
            const reservedWorkerResults = workerResults.slice(
                SEARCH.HITS_PER_PAGE_BATCH_WEB + 1
            )
            const merged = _merge(
                _keyBy(
                    workerResults.slice(0, SEARCH.HITS_PER_PAGE_BATCH_WEB),
                    'objectID'
                ),
                _keyBy(searchResults.hits, 'id')
            )
            searchResults.hits = _values(merged)
            state.spaceResult.reservedWorkerResults = reservedWorkerResults
            state.isCurrentIndexFetched = pageResults.isCurrentIndexFetched
        }
        searchResults.hits = searchResults.hits.filter(
            (_) => !state.fetchedIDs.includes(_.id)
        )
        const appendIDs = searchResults.hits.map((_) => _.id)
        state.fetchedIDs = [...state.fetchedIDs, ...appendIDs]

        state.stateHistory = [...state.stateHistory, payload.currFetchState]
        let hitsToBeAdded
        if (payload.currFetchState === FETCH_STATE.SPACE) {
            state.spaceResult.exactHit = searchResults.hits
        } else if (payload.currFetchState === FETCH_STATE.NEARBY) {
            state.spaceResult.nearbyHits = [
                ...state.spaceResult.nearbyHits,
                ...searchResults.hits
            ]
        } else if (payload.currFetchState === FETCH_STATE.AREA) {
            if (payload.isPriceQueryResult) {
                state.spaceResult.areaHits = [
                    ...state.spaceResult.areaHits,
                    ...searchResults.hits
                ].sort(
                    payload.isAsapFilter
                        ? compareSpaceByNextAvailable
                        : compareSpaceByAvailability
                )
            } else {
                state.spaceResult.areaHits = [
                    ...state.spaceResult.areaHits,
                    ...searchResults.hits
                ]
            }
        } else if (
            payload.currFetchState === FETCH_STATE.DISTRICT ||
            payload.currFetchState === FETCH_STATE.META_DISTRICT
        ) {
            if (payload.isPriceQueryResult) {
                state.spaceResult.districtHits = [
                    ...state.spaceResult.districtHits,
                    ...searchResults.hits
                ].sort(
                    payload.isAsapFilter
                        ? compareSpaceByNextAvailable
                        : compareSpaceByAvailability
                )
            } else {
                state.spaceResult.districtHits = [
                    ...state.spaceResult.districtHits,
                    ...searchResults.hits
                ]
            }
        } else if (
            payload.currFetchState === FETCH_STATE.AVAILABLE ||
            payload.currFetchState === FETCH_STATE.FALLBACK_ANYTIME ||
            payload.currFetchState === FETCH_STATE.FALLBACK_ASAP_TIME ||
            payload.currFetchState === FETCH_STATE.FALLBACK_EXACT_TIME
        ) {
            state.spaceResult.availableHits = [
                ...state.spaceResult.availableHits,
                ...searchResults.hits
            ]
        } else if (payload.currFetchState === FETCH_STATE.ALTERNATIVE) {
            state.spaceResult.newAlternativeHits = [
                ...state.spaceResult.newAlternativeHits,
                ...searchResults.hits
            ]
        } else if (payload.currFetchState === FETCH_STATE.UNAVAILABLE) {
            state.spaceResult.unavailableHits = [
                ...state.spaceResult.unavailableHits,
                ...searchResults.hits
            ]
        }
        if (state.stateHistory?.[0] === FETCH_STATE.SPACE && searchResults.page === 1) {
            hitsToBeAdded = 1
        } else if (state.stateHistory?.[0] === payload.currFetchState && searchResults.page === 1) {
            hitsToBeAdded = searchResults.found
        }
        trackItemResults(searchResults.hits, payload.algoliaIndex)
        state.spaceResult.hasNext = pageResults.hasNext
        state.spaceResult.nextPage = pageResults.nextPage
        state.queryState = pageResults.nextState
        state.spaceResult.totalHits = state.fetchedIDs.length
        state.spaceResult.totalPage = searchResults.nbPages
        if (hitsToBeAdded !== undefined) {
            state.spaceResult.displayTotalHits = hitsToBeAdded
        }
    },
    SET_POPULAR_SEARCH_RESULT: (state, payload) => {
        const searchResults = payload.results.searchResults
        state.popularSpaceResult.popularHits = searchResults?.hits ?? []
    },
    SET_THREE_STAR_SEARCH_RESULT: (state, payload) => {
        const searchResults = payload.results.searchResults
        state.popularSpaceResult.threeStarHits = searchResults?.hits ?? []
    },
    SET_FOUR_STAR_SEARCH_RESULT: (state, payload) => {
        const searchResults = payload.results.searchResults
        state.popularSpaceResult.fourStarHits = searchResults?.hits ?? []
    },
    SET_FIVE_STAR_SEARCH_RESULT: (state, payload) => {
        const searchResults = payload.results.searchResults
        state.popularSpaceResult.fiveStarHits = searchResults?.hits ?? []
    },
    SET_SWIMMING_POOL_SEARCH_RESULT: (state, payload) => {
        const searchResults = payload.results.searchResults
        state.popularSpaceResult.swimmingPoolHits = searchResults?.hits ?? []
    },
    SET_FITNESS_CENTER_SEARCH_RESULT: (state, payload) => {
        const searchResults = payload.results.searchResults
        state.popularSpaceResult.fitnessCenterHits = searchResults?.hits ?? []
    },
    SET_HOURLY_HOTEL_SEARCH_RESULT: (state, payload) => {
        const searchResults = payload.results.searchResults
        state.popularSpaceResult.hourlyHotelHits = searchResults?.hits ?? []
    },
    SET_AIRPORT_HOTELS_INDEX: (state, payload) => {
        const searchResults = payload.results.searchResults
        state.airportHotelsResult.hits = searchResults?.hits ?? []
    },
    SET_DEALS_LIST: (state, payload) => {
        const searchResults = payload.results.searchResults
        state.dealsListResult.totalDeals = payload.results.totalDeals
        if (
            state.dealsListResult.hits.length < state.dealsListResult.totalDeals
        ) {
            let isDealExist = false
            state.dealsListResult.hits.map((hit) => {
                if (hit.tag === payload.results.tag) {
                    isDealExist = true
                }
            })
            if (!isDealExist) {
                state.dealsListResult.hits.push({
                    tag: payload.results.tag,
                    priceField: payload.results.priceField,
                    priceFilterValue: payload.results.priceFilterValue,
                    spaceTypes: payload.results.spaceTypes,
                    deal: searchResults.hits ?? [],
                    date: payload.results.date,
                    uspTag: payload.results.uspTag
                })
            } else {
                state.dealsListResult.hits.map((hit) => {
                    if (hit.tag === payload.results.tag) {
                        hit.deal = searchResults.hits ?? []
                    }
                })
            }
        } else {
            state.dealsListResult.hits.map((hit) => {
                if (hit.tag === payload.results.tag) {
                    hit.deal = searchResults.hits ?? []
                }
            })
        }
    },
    SET_BLOG_SPACE_LIST: (state, payload) => {
        const searchResults = payload.results.searchResults
        state.blogSpaceListResult.hits = searchResults.hits
    },
    SET_SPACE_STATUS: (state, payload) => {
        payload.data.map((item) => {
            let resultHits
            if (payload.currFetchState === FETCH_STATE.SPACE) {
                resultHits = state.spaceResult.exactHit
            } else if (payload.currFetchState === FETCH_STATE.NEARBY) {
                resultHits = state.spaceResult.nearbyHits
            } else if (payload.currFetchState === FETCH_STATE.AREA) {
                resultHits = state.spaceResult.areaHits
            } else if (payload.currFetchState === FETCH_STATE.DISTRICT) {
                resultHits = state.spaceResult.districtHits
            } else if (payload.currFetchState === FETCH_STATE.META_DISTRICT) {
                resultHits = state.spaceResult.districtHits
            } else if (
                payload.currFetchState === FETCH_STATE.AVAILABLE ||
                payload.currFetchState === FETCH_STATE.FALLBACK_ANYTIME ||
                payload.currFetchState === FETCH_STATE.FALLBACK_ASAP_TIME ||
                payload.currFetchState === FETCH_STATE.FALLBACK_EXACT_TIME
            ) {
                resultHits = state.spaceResult.availableHits
            } else if (payload.currFetchState === FETCH_STATE.ALTERNATIVE) {
                resultHits = state.spaceResult.newAlternativeHits
            } else {
                resultHits = state.spaceResult.unavailableHits
            }
            const foundIndex = resultHits.findIndex(
                (_) => _.id === item.objectID
            )
            if (foundIndex >= 0) {
                if (item.isNotFound) {
                    resultHits.splice(foundIndex, 1)
                } else {
                    const minPrices = resultHits[foundIndex]?.status?.min_prices
                    const hitAvailable =
                        resultHits[foundIndex]?.isAvailableByBookType ?? true
                    resultHits.splice(foundIndex, 1, {
                        ...resultHits[foundIndex],
                        minPrices,
                        image_tags: excludeDiscountTags(item.image_tags),
                        isAvailable: item.isAvailable && hitAvailable,
                        space_tags: item.space_tags,
                        status: item.status,
                        button_list: item.button_list,
                        fomo_tags: item.fomo_tags,
                        capacity_str: item.capacity_str,
                        prevStartTimes: item.prevStartTimes,
                        afterStartTimes: item.afterStartTimes
                    })
                }
            }
        })
    },
    RESET_FETCH_UNAVAILABLE: (state, payload) => {
        state.spaceResult.reservedWorkerResults = []
        state.fetchedIDs = []
        state.queryState = payload.initialState ?? FETCH_STATE.AVAILABLE
        state.isCurrentIndexFetched = false
        state.stateHistory = []
        state.spaceResult.exactHit = []
        state.spaceResult.nearbyHits = []
        state.spaceResult.areaHits = []
        state.spaceResult.districtHits = []
        state.spaceResult.availableHits = []
        state.spaceResult.newAlternativeHits = []
        state.spaceResult.unavailableHits = []
        state.spaceResult.totalHits = 0
        state.spaceResult.displayTotalHits = 0
    },
    RESET_DEALS: (state) => {
        state.dealsListResult.totalDeals = 0
        state.dealsListResult.hits = []
    },
    SORT_RESULTS_BY_AVAILABILITY: (state, payload) => {
        if (payload.currFetchState === FETCH_STATE.AREA) {
            state.spaceResult.areaHits = [...state.spaceResult.areaHits].sort(
                payload.isAsapFilter
                    ? compareSpaceByNextAvailable
                    : compareSpaceByAvailability
            )
        } else if (
            payload.currFetchState === FETCH_STATE.DISTRICT ||
            payload.currFetchState === FETCH_STATE.META_DISTRICT
        ) {
            state.spaceResult.districtHits = [
                ...state.spaceResult.districtHits
            ].sort(
                payload.isAsapFilter
                    ? compareSpaceByNextAvailable
                    : compareSpaceByAvailability
            )
        }
    }
}

const actions = {
    FETCH_SPACE_STATUS: async (context, payload) => {
        const isPriceSorting =
            payload.s_price !== undefined ||
            payload.s_price_per_hour !== undefined
        const isPriceFiltering =
            payload.f_priceRange_min !== undefined ||
            payload.f_priceRange_max !== undefined
        try {
            const isPriceQuery = isPriceSorting || isPriceFiltering
            while (payload.workerHits.length > 0) {
                const response = await fetchAdditionalInfo({
                    date: payload.f_date,
                    lang: payload.lang,
                    region: payload.region,
                    platform: 'web',
                    spaceType: payload.space_type,
                    spaceTypeNum: payload.space_type_num,
                    priceMin: payload.f_priceRange_min,
                    priceMax: payload.f_priceRange_max,
                    numPpl: payload.f_num_ppl,
                    startMinute: payload.startMinute,
                    endMinute: payload.endMinute,
                    bookType: payload.f_book_type,
                    workerHits: payload.workerHits,
                    isOverride: payload.isOverride,
                    s_price: payload.s_price,
                    s_price_per_hour: payload.s_price_per_hour,
                    pageSize: SEARCH.ITEMS_PER_WORKER,
                    isPriceQuery,
                    currFetchState: payload.currFetchState,
                    version: VERSIONS.WEB_1_0_1,
                    time: computeTimeByFetchState(payload.currFetchState, payload.f_time)
                })
                if (response.success) {
                    await context.commit('SET_SPACE_STATUS', {
                        data: response.results,
                        currFetchState: payload.currFetchState
                    })
                } else {
                    await context.commit('SET_SPACE_STATUS', {
                        data: [],
                        currFetchState: payload.currFetchState
                    })
                }
            }
            if (!isPriceQuery) {
                context.commit('SORT_RESULTS_BY_AVAILABILITY', {
                    currFetchState: payload.currFetchState,
                    isAsapFilter:
                        payload.f_time === DATE_TIME.ASAP_CHECK_IN_TIME
                })
            }
        } catch (error) {
            context.commit('SET_SPACE_STATUS', {
                data: [],
                currFetchState: payload.currFetchState
            })
        }
    },
    SEARCH_SPACE_INDEX: async (context, payload) => {
        const userToken = await firebaseService.getUserId()
        const searchConfigRes = await nextApi.getSearchConfig(payload.space_type, {
            platform: 'web',
            region: payload.region,
            lang: payload.lang,
        })
        const searchConfig = searchConfigRes.data.Data.search.listing
        const isNewSearch = payload.isNewSearch
        if (isNewSearch) {
            const initialState = getStateFromSearchType(
                payload.es_type ?? payload.fallbackType
            )
            context.commit('RESET_FETCH_UNAVAILABLE', { initialState })
        }
        const currFetchState = context.state.queryState
        const isExactResults = context.getters.isExactResults
        const isPriceSorting =
            payload.s_price !== undefined ||
            payload.s_price_per_hour !== undefined
        const isPriceFiltering =
            payload.f_priceRange_min !== undefined ||
            payload.f_priceRange_max !== undefined
        const isCurrentIndexFetched = context.state.isCurrentIndexFetched
        const reservedWorkerResults =
            context.state.spaceResult.reservedWorkerResults
        const results = await searchIndex({
            query: payload.q,
            date: payload.f_date,
            time: payload.f_time,
            isTest: payload.isTestTag,
            region: payload.region,
            lang: payload.lang,
            spaceType: payload.space_type,
            spaceTypeNum: payload.space_type_num,
            priceMin: payload.f_priceRange_min,
            priceMax: payload.f_priceRange_max,
            numPpl: payload.f_num_ppl,
            bookType: payload.f_book_type,
            page: payload.page,
            querySource: payload.qs,
            algoliaIndex: payload.algoliaIndex,
            platform: 'web',
            analyticsTags: payload.analyticsTags,
            isPriceQuery: isPriceFiltering || isPriceSorting,
            s_price: payload.s_price,
            s_price_per_hour: payload.s_price_per_hour,
            s_star_rating: payload.s_star_rating,
            s_user_rating: payload.s_user_rating,
            group: payload.group,
            pageSize: SEARCH.HITS_PER_PAGE_BATCH_WEB,
            starRanking: payload.f_star_rating,
            theme: payload.f_theme,
            aroundLatLng: payload.user_lat_long,
            exactSpace:
                currFetchState === FETCH_STATE.NEARBY
                    ? context.state.spaceResult.exactHit[0]
                    : undefined,
            area: payload.area,
            district: payload.district,
            metaDistrict: payload.meta_district,
            suggestionId: payload.suggestionId,
            isExactResults, // redux
            currFetchState,
            isCurrentIndexFetched,
            workerResults: reservedWorkerResults,
            userToken,
            isOverride: payload.isOverride,
            searchConfig,
            override_tags: payload.f_theme ? payload.themeOverrideTagsDict?.[payload.f_theme] : undefined
        })
        /**
        console.log(
            'store/algolia, ALGOLIA PARAMETERS, \n',
            `
            f_date ${payload.f_date}
            f_time ${payload.f_time}
            f_priceRange_min ${payload.f_priceRange_min}
            f_priceRange_max ${payload.f_priceRange_max}
            user_lat_long ${payload.user_lat_long}
            algoliaIndex ${payload.algoliaIndex}
            region ${payload.region}
            zonedStartMinute ${startMinute}
            zonedEndMinute ${endMinute}
            payload ${JSON.stringify(payload)}
        `
        )
        */
        if (isPriceFiltering || isPriceSorting) {
            context.commit('SET_SEARCH_RESULT', {
                results,
                currFetchState,
                algoliaIndex: payload.algoliaIndex,
                isPriceQueryResult: true,
                isAsapFilter: payload.f_time === DATE_TIME.ASAP_CHECK_IN_TIME
            })
            return {
                hasNext: results.pageResults.hasNext,
                isEmptyResults:
                    (currFetchState === FETCH_STATE.SPACE ||
                        currFetchState === FETCH_STATE.AVAILABLE) &&
                    results.searchResults.found <= 0,
                isFetchMoreResult:
                    results.searchResults.found <= 15 &&
                    results.pageResults.hasNext
            }
        } else {
            context.commit('SET_SEARCH_RESULT', {
                results,
                currFetchState,
                algoliaIndex: payload.algoliaIndex,
                isPriceQueryResult: false,
                isAsapFilter: payload.f_time === DATE_TIME.ASAP_CHECK_IN_TIME
            })
            const searchResults = results.searchResults
            const pageResults = results.pageResults
            const workerIdentifier = searchResults.hits.map(
                mapToWorkerIdentifier
            )
            context.dispatch('FETCH_SPACE_STATUS', {
                ...payload,
                currFetchState,
                workerHits: workerIdentifier,
                startMinute: results.startMinute,
                endMinute: results.endMinute
            })
            return {
                hasNext: pageResults.hasNext,
                isEmptyResults:
                    (currFetchState === FETCH_STATE.SPACE ||
                        currFetchState === FETCH_STATE.AVAILABLE) &&
                    searchResults.found <= 0,
                isFetchMoreResult:
                    searchResults.found <= 15 && pageResults.hasNext
            }
        }
    },
    SEARCH_POPULAR_SPACE_INDEX: async (context, payload) => {
        const _payload = {
            ...payload,
            attrRetrieve: [...SEARCH.INCLUDE_FIELDS, 'status']
        }
        const results = await getSearchIndex(_payload)
        context.commit('SET_POPULAR_SEARCH_RESULT', {
            results
        })
    },
    SEARCH_THREE_STAR_SPACE_INDEX: async (context, payload) => {
        const results = await getSearchIndex(payload)
        context.commit('SET_THREE_STAR_SEARCH_RESULT', {
            results
        })
    },
    SEARCH_FOUR_STAR_SPACE_INDEX: async (context, payload) => {
        const results = await getSearchIndex(payload)
        context.commit('SET_FOUR_STAR_SEARCH_RESULT', {
            results
        })
    },
    SEARCH_FIVE_STAR_SPACE_INDEX: async (context, payload) => {
        const results = await getSearchIndex(payload)
        context.commit('SET_FIVE_STAR_SEARCH_RESULT', {
            results
        })
    },
    SEARCH_SWIMMING_POOL_SPACE_INDEX: async (context, payload) => {
        const results = await getSearchIndex(payload)
        context.commit('SET_SWIMMING_POOL_SEARCH_RESULT', {
            results
        })
    },
    SEARCH_FITNESS_CENTER_SPACE_INDEX: async (context, payload) => {
        const results = await getSearchIndex(payload)
        context.commit('SET_FITNESS_CENTER_SEARCH_RESULT', {
            results
        })
    },
    SEARCH_HOURLY_HOTEL_SPACE_INDEX: async (context, payload) => {
        const results = await getSearchIndex(payload)
        context.commit('SET_HOURLY_HOTEL_SEARCH_RESULT', {
            results
        })
    },
    SEARCH_AIRPORT_HOTELS_INDEX: async (context, payload) => {
        // get next availability
        const _payload = {
            ...payload,
            attrRetrieve: [...SEARCH.INCLUDE_FIELDS, 'status']
        }
        const results = await getSearchIndex(_payload)
        context.commit('SET_AIRPORT_HOTELS_INDEX', {
            results
        })
    },
    SEARCH_DEALS: async (context, payload) => {
        const searchConfigRes = await nextApi.getSearchConfig(payload.space_type, {
            platform: 'web',
            region: payload.region,
            lang: payload.lang,
        })
        const searchConfig = searchConfigRes.data.Data.search.listing
        const searchPayload = {
            query: '*',
            page: 1,
            isTest: false,
            region: payload.region,
            lang: payload.lang,
            spaceType: payload.space_type,
            algoliaIndex: payload.algoliaIndex,
            platform: 'web',
            date: payload.date,
            time: '',
            isAnyAvailability: true,
            tagsFilter: payload.tag,
            pageSize: 10,
            attributesToRetrieve: [...SEARCH.INCLUDE_FIELDS, 'status'],
            isSkipAnalytics: payload.isSkipAnalytics,
            priceField: payload.priceField,
            priceFilterValue: payload.priceFilterValue,
            isCustomHost: true,
            override_tags: payload.override_tags,
            searchConfig
        }
        if (payload.group) {
            searchPayload.group = payload.group
        }
        const results = await searchIndex(searchPayload)
        results.tag = payload.tag
        results.totalDeals = payload.totalDeals
        results.priceField = payload.priceField
        results.priceFilterValue = payload.priceFilterValue
        results.spaceTypes = payload.spaceTypes
        results.date = payload.date
        results.uspTag = payload.uspTag
        context.commit('SET_DEALS_LIST', { results })
    },
    RESET_DEALS: (context) => {
        context.commit('RESET_DEALS')
    },
    SEARCH_BLOG_SPACE_INDEX: async (context, payload) => {
        const searchConfigRes = await nextApi.getSearchConfig(payload.space_type, {
            platform: 'web',
            region: payload.region,
            lang: payload.lang,
        })
        const searchConfig = searchConfigRes.data.Data.search.listing
        const searchPayload = {
            query: payload.q === '' ? '*' : payload.q,
            page: 1,
            isTest: false,
            region: payload.region,
            lang: payload.lang,
            spaceType: payload.space_type,
            algoliaIndex: payload.algoliaIndex,
            platform: 'web',
            date: payload.date,
            time: payload.time,
            bookType: payload.f_book_type,
            isAnyAvailability: false,
            pageSize: 5,
            attributesToRetrieve: [...SEARCH.INCLUDE_FIELDS, 'status'],
            isSkipAnalytics: payload.isSkipAnalytics,
            searchConfig
        }
        const results = await searchIndex(searchPayload)
        context.commit('SET_BLOG_SPACE_LIST', { results })
    }
}

const trackItemResults = (hits, algoliaIndex) => {
    if (process.client) {
        const hitIDs = hits.map((_) => _.id)
        const chunkedIDs = _chunk(
            hitIDs,
            SEARCH.VIEWED_EVENT_BATCH_SIZE
        )
        chunkedIDs.map((chunk) => {
            sendObjectViewedEvent(
                ALGOLIA_EVENT_NAME.LIST_ITEMS_VIEWED,
                algoliaIndex,
                chunk
            )
        })
    }
}

const excludeDiscountTags = (imageTags) => {
    return imageTags.filter(
        (tag) => !(tag.name.startsWith('-') && tag.name.endsWith('%'))
    )
}
const getSearchIndex = async (payload) => {
    let ua = ''
    if (process.client){
        ua = navigator.userAgent
    }
    if (!ua.match(/googlebot/i)){
        const searchConfigRes = await nextApi.getSearchConfig(payload.space_type, {
            platform: 'web',
            region: payload.region,
            lang: payload.lang,
        })
        const searchConfig = searchConfigRes.data.Data.search.listing
        // Remove and display next_available
        const currFetchState = FETCH_STATE.AVAILABLE
        let locationFilter = ''
        if (payload.type !== 'country') locationFilter = payload.q
        const _payload = {
            query: payload.q,
            page: payload.page,
            isTest: payload.isTestTag,
            region: payload.region,
            lang: payload.lang,
            algoliaLang: payload.algoliaLang,
            spaceType: payload.space_type,
            algoliaIndex: payload.algoliaIndex,
            starRanking: payload.f_star_rating,
            swimmingPoolFilter: payload.f_swimming_pool,
            fitnessCenterFilter: payload.f_fitness_center,
            showStatus: payload.f_show_status,
            platform: 'web',
            time: '',
            isAnyAvailability: true,
            isSkipAnalytics: payload.isSkipAnalytics,
            locationFilter,
            currFetchState,
            version: VERSIONS.WEB_1_0_1,
            idFilter: payload.idFilter,
            isCustomHost: true,
            searchConfig
        }
        if (payload.attrRetrieve) {
            _payload.attrRetrieve = payload.attrRetrieve
        }
        return searchIndex(_payload)
    } else {
        return ''
    }
}

export const getStateFromSearchType = (type) => {
    if (type === EXACT_SEARCH_TYPE.TYPE_EXACT_SPACE_SEARCH) {
        return FETCH_STATE.SPACE
    } else if (type === EXACT_SEARCH_TYPE.TYPE_EXACT_AREA_SEARCH) {
        return FETCH_STATE.AREA
    } else if (type === EXACT_SEARCH_TYPE.TYPE_EXACT_DISTRICT_SEARCH) {
        return FETCH_STATE.DISTRICT
    } else if (type === EXACT_SEARCH_TYPE.TYPE_EXACT_META_DISTRICT_SEARCH) {
        return FETCH_STATE.META_DISTRICT
    } else if (type === FALLBACK_SEARCH_TYPE.TYPE_ANY_TIME) {
        return FETCH_STATE.FALLBACK_ANYTIME
    } else if (type === FALLBACK_SEARCH_TYPE.TYPE_ASAP_TIME) {
        return FETCH_STATE.FALLBACK_ASAP_TIME
    } else if (type === FALLBACK_SEARCH_TYPE.TYPE_EXACT_TIME) {
        return FETCH_STATE.FALLBACK_EXACT_TIME
    }
    return FETCH_STATE.AVAILABLE
}

export default {
    state,
    getters,
    mutations,
    actions
}
