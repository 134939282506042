import _filter from "lodash/filter";

const PAGE_NAME = {
    HOME: 'homepage',
    SUB_LANDING: 'sublanding page',
    LISTING: 'listing page',
    DETAILS: 'details page',
    LOCATION: 'location page',
    BLOG: 'blog page',
    BLOG_LIST: 'blog list page',
    PROMOTIONS: 'promotion page',
    PROMOTIONS_LIST: 'promotion list page',
    MAP_SEARCH: 'map search page',
    DAYUSE_VS_FLOW: 'dayuse vs flow page',
    RESERVATION_RECORD: 'reservation record page',
    OTHER: 'other page',
    CRAWLER: 'crawler',
    GOOGLE_ORGANIC_SEARCH: 'google organic search',
}

const SECTION_NAME = {
    SEARCH_RESULT_BAR: 'search result bar',
    SEARCH_FILTER: 'search filter',
    THEME_FILTER: 'theme filter',
    SORT: 'sort',
    BLOG: 'get inspired',
    PROMOTIONS: 'current offers',
    EXPLORE_FLEXIBLE_SPACES: 'explore flexible spaces',
    SPACE_CARD: 'space card',
    FOOTER: 'footer',
    MAIN_GALLERY: 'main gallery',
    BREADCRUMB: 'breadcrumb',
    POPULAR_DESTINATIONS: 'popular destinations',
    EXPLORE_WITH_FLOW: 'explore with flow',
    DESKTOP_BOOKING_FORM: 'desktop booking form'
}

const ELEMENT_NAME = {
    LOCATION_NAME: 'location link',
    SEARCH_YOUR_DAYCATION_BUTTON: 'search your daycation button',
    EXPLORE_DAYCATIONS: 'explore daycations',
    MAP_PILL_BUTTON: 'map pill button',
    MAP_IMAGE_BUTTON: 'map image button'
    // IMAGE_HOVER_MAP_BUTTON: 'image hover map button'
}

const FILTER_KEY_MAPPING = {
    priceRange: 'pc',
    starRating: 's',
    theme: 'th'
}

const getGAEventName = (page, section, name, position, remark) => {

    let eventName = PAGE_NAME[page]

    if (section) {
        eventName += `_${SECTION_NAME[section] ?? section}`
    }

    if (position >= 0) {
        position += 1
        eventName += `_${position}`
    }

    if (name) {
        eventName += `_${ELEMENT_NAME[name] ?? name}`
    }

    if (remark) {
        eventName += `_${remark}`
    }

    return eventName.toLowerCase()
}

const getFormattedFilterValue = (obj) => {
    const keys = Object.keys(obj);
    let value = ''
    for (let key of keys) {
        // console.log(`Key: ${key}, Value: ${obj[key]}`);
        if (value) {
            value += '_'
        }
        value += `${FILTER_KEY_MAPPING[key]}=${obj[key]}`
    }
    return value
}

const getMetaLocationParams = (country, city= '', region = '') => {
    return {
        city: city,
        region: region,
        country: country
    }
}

const getMetaCityName = (region, currentRegionObj, paramsGroup) => {
    if (region === 'my') {
        return getGroupNameFromRegionObj(currentRegionObj, paramsGroup)
    } else {
        return getMetaCountryName(region)
    }
}

const getMetaRegionName = (esType, searchTerm, region, currentRegionObj, paramsGroup) => {
    if (esType === 'area' || esType === 'district') {
        return searchTerm
    } else {
        return getMetaCityName(region, currentRegionObj, paramsGroup)
    }
}

const getMetaCountryName = (region) => {
    switch (region) {
        case 'hk':
            return 'Hong Kong'
        case 'mo':
            return 'Macau'
        case 'sg':
            return 'Singapore'
        case 'my':
            return 'Malaysia'
        case 'vi':
            return 'Vietnam'
        default:
            return 'Hong Kong'
    }
}

const getFirstFiveHitsSlug = (exactHit, nearbyHits, areaHits, districtHits, availableHits, alternativeHits, unavailableHits) => {
    const combinedHits = [...exactHit, ...nearbyHits, ...areaHits, ...districtHits, ...availableHits, ...alternativeHits, ...unavailableHits];
    return combinedHits.slice(0, 5).map(item => item.slug);
}

const getGroupNameFromRegionObj = (obj, paramsGroup) => {
    if (obj && obj.groups?.length > 0) {
        const currentArea = _filter(obj.groups, [
            'slug',
            paramsGroup
        ])
        return currentArea?.[0]?.name_translated?.en
    }
    return ''

}

const isFromGoogleOrganicSearch = (referrer) => {
    return referrer.includes('www.google.com')
}

export { getGAEventName, getFormattedFilterValue, getMetaLocationParams, getMetaCityName, getMetaRegionName, getMetaCountryName, getFirstFiveHitsSlug, isFromGoogleOrganicSearch }
