import _concat from 'lodash/concat'
import firebaseService from '@/services/firebase'

export const debouncePromise = (fn, time) => {
    let timerId

    return function debounced(...args) {
        if (timerId) {
            clearTimeout(timerId)
        }

        return new Promise((resolve) => {
            timerId = setTimeout(() => resolve(fn(...args)), time)
        })
    }
}

export const getSpaceTypeId = (spaceType) => {
    let spaceTypeId
    switch (spaceType) {
        case 'rest':
            spaceTypeId = 1
            break
        case 'work':
            spaceTypeId = 2
            break
        case 'meet':
            spaceTypeId = 3
            break
        case 'party':
            spaceTypeId = 5
            break
        default:
            spaceTypeId = 1
            break
    }
    return spaceTypeId
}

export const getAlgoliaLang = (region, lang) => {
    let algoliaLang
    if ((region === 'sg' || region === 'my') && lang === 'zh') {
        algoliaLang = 'sc'
    } else if (lang === 'zh') {
        algoliaLang = 'tc'
    } else {
        algoliaLang = lang
    }
    return algoliaLang
}

export const sendObjectViewedEvent = async (eventName, index, objectIDs) => {
    const userToken = await firebaseService.getUserId()
    if (!userToken) return
    const params = {
        userToken,
        eventName,
        index,
        objectIDs
    }
    // TODO: Typesense tracking
    // aa('viewedObjectIDs', params)
}

export const sendObjectClickedEvent = async (
    eventName,
    queryId,
    index,
    objectID,
    position
) => {
    const userToken = await firebaseService.getUserId()
    if (!userToken) return
    const params = {
        userToken,
        eventName,
        index,
        objectIDs: [objectID]
    }
    if (queryId && queryId.length > 0) {
        params.queryID = queryId
        params.positions = [position]
    }
    // TODO: Typesense tracking
    // aa(
    //     params.queryID ? 'clickedObjectIDsAfterSearch' : 'clickedObjectIDs',
    //     params
    // )
}

export const sendConversionEvent = async (
    eventName,
    queryId,
    index,
    objectID
) => {
    const userToken = await firebaseService.getUserId()
    if (!userToken) return
    const params = {
        userToken,
        eventName,
        index,
        objectIDs: [objectID]
    }
    if (queryId && queryId.length > 0) {
        params.queryID = queryId
    }
    // TODO: Typesense tracking
    // aa(
    //     params.queryID ? 'convertedObjectIDsAfterSearch' : 'convertedObjectIDs',
    //     params
    // )
}

export const getAllFiltersValue = (
    themeFilter,
    starRatingFilter,
    timeFrameFilter
) => {
    const theme = themeFilter.map((theme) => `tags.slug:${theme}`)
    const starRating = starRatingFilter.map(
        (rating) => `ranking.star_rating:${rating}`
    )
    const timeFrame = timeFrameFilter.map(
        (timeFrame) => `book_type:${timeFrame}`
    )
    return _concat(theme, starRating, timeFrame)
}

export const sendFilterClickedEvent = async (index, isMapFilter, filters) => {
    const allFilters = getAllFiltersValue(
        filters.theme,
        filters.starRating,
        filters.timeFrame
    )
    if (allFilters.length === 0) return

    const userToken = await firebaseService.getUserId()
    if (!userToken) return
    const params = {
        userToken,
        eventName: isMapFilter
            ? ALGOLIA_EVENT_NAME.MAP_FILTER_CLICKED
            : ALGOLIA_EVENT_NAME.LIST_FILTER_CLICKED,
        index,
        filter_by: allFilters
    }
    // TODO: Typesense tracking
    // aa('clickedFilters', params)
}

export const sendConvertedFiltersEvent = async (index, filters) => {
    const userToken = await firebaseService.getUserId()
    if (!userToken) return
    const params = {
        userToken,
        eventName: ALGOLIA_EVENT_NAME.CHECKOUT_FILTER_SELECTED,
        index,
        filters
    }
    // TODO: Typesense tracking
    // aa('convertedFilters', params)
}

export const ALGOLIA_EVENT_NAME = {
    AUTOCOMPLETE_ITEM_VIEWED: 'autocomplete_item_viewed',
    AUTOCOMPLETE_ITEM_CLICKED: 'autocomplete_item_clicked',
    TODAY_DEAL_ITEM_VIEWED: 'today_deal_item_viewed',
    TODAY_DEAL_ITEM_CLICKED: 'today_deal_item_clicked',
    LIST_ITEMS_VIEWED: 'list_items_viewed',
    LIST_FILTER_CLICKED: 'list_filter_clicked',
    LIST_ITEM_CLICKED: 'list_item_clicked',
    MAP_FILTER_CLICKED: 'map_filter_clicked',
    MAP_ITEM_CLICKED: 'map_item_clicked',
    DETAIL_ITEM_VIEWED: 'detail_item_viewed',
    PERSONAL_INFO_ITEM_VIEWED: 'personal_info_item_viewed',
    CHECKOUT_ITEM_CONFIRMED: 'checkout_item_confirmed',
    CHECKOUT_FILTER_SELECTED: 'checkout_filter_selected'
}

export const getQuerySourceQueryParam = (
    currentLocationValue,
    input,
    querySourceValue,
    querySourceType
) => {
    if (input === currentLocationValue) {
        return QUERY_SOURCE_CURRENT_LOCATION
    } else if (input === querySourceValue) {
        return querySourceType
    } else if (input === '') {
        return QUERY_SOURCE_EMPTY_SEARCH
    } else {
        return QUERY_SOURCE_USER_INPUT
    }
}

export const getExactSearchQueryParam = (
    input,
    querySourceValue,
    querySourceType,
    suggestCategory
) => {
    if (
        ((querySourceType === QUERY_SOURCE_POPULAR_SEARCH ||
            querySourceType === QUERY_SOURCE_SUGGESTION) &&
            input === querySourceValue) ||
        querySourceType === QUERY_SOURCE_MAGIC_SEARCH_BUTTON
    ) {
        if (suggestCategory === 'NAME') {
            return TYPE_EXACT_SPACE_SEARCH
        } else if (suggestCategory === 'LOCATION-AREA') {
            return TYPE_EXACT_AREA_SEARCH
        } else if (suggestCategory === 'LOCATION-DISTRICT') {
            return TYPE_EXACT_DISTRICT_SEARCH
        } else if (suggestCategory === 'LOCATION-META_DISTRICT') {
            return TYPE_EXACT_META_DISTRICT_SEARCH
        }
    }
    return ''
}

export const getSuggestionIcon = (spaceType, category) => {
    if (category === 'NAME') {
        if (spaceType === 'rest') {
            return 'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/d2abe5a8-1540-42e0-0b39-b919d9a16d00/edm'
        } else if (spaceType === 'work') {
            return 'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/c5d832a7-f421-4b8f-5c32-bf7a0e324300/edm'
        } else if (spaceType === 'meet') {
            return 'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/62593f56-b570-44e1-9ec2-4e51cf60dc00/edm'
        } else if (spaceType === 'party') {
            return 'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/8017c079-afb9-4960-bb8b-ecfc7fa8fb00/edm'
        }
    } else if (category.includes('LOCATION')) {
        return 'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/34957d43-98ec-4c43-cd14-36d8b6251000/edm'
    } else if (category === 'AMENITY') {
        return 'https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/217c7c78-7bd1-4e66-b727-41aabb51d800/edm'
    }
    return ''
}

export const TEST_TAG_DOMAIN = '@flowtheroom.com'
export const AUTOCOMPLETE_RESET_CLICK = 'AUTOCOMPLETE_RESET_CLICK'

export const QUERY_SOURCE_SUGGESTION = 'qs'
export const QUERY_SOURCE_CURRENT_LOCATION = 'cl'
export const QUERY_SOURCE_SEARCH_HISTORY = 'sh'
export const QUERY_SOURCE_POPULAR_SEARCH = 'ps'
export const QUERY_SOURCE_USER_INPUT = 'ui'
export const QUERY_SOURCE_EMPTY_SEARCH = 'es'
export const QUERY_SOURCE_MAGIC_SEARCH_BUTTON = 'msb'
export const TYPE_EXACT_SPACE_SEARCH = 'space'
export const TYPE_EXACT_AREA_SEARCH = 'area'
export const TYPE_EXACT_DISTRICT_SEARCH = 'district'
export const TYPE_EXACT_META_DISTRICT_SEARCH = 'mdistrict'
